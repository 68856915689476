import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import ReportsGrid from '../components/ReportsGrid';

export default class ReportPage extends Component {

    state = {
        loading: false,
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <ReportsGrid />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
