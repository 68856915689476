import * as firebase from 'firebase';
import 'firebase/firestore';

import Firestore from '../api/firebase/Firestore';


export default class GeographicHelper {
    static getDistanceBetweenPoints(start, end) {
        const R = 6371;

        let dLat = (end.lat - start.lat) * Math.PI / 180;
        let dLng = (end.lng - start.lng) * Math.PI / 180;

        let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(start.lat * Math.PI / 180) * Math.cos(end.lat * Math.PI / 180) *
            Math.sin(dLng / 2) * Math.sin(dLng / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = R * c;

        let distanceObject = {};

        if (d < 1) {
            distanceObject = {
                value: parseInt(this.kmToMeters(d)),
                unit: 'm'
            }
        } else {
            distanceObject = {
                value: this.epsilonRound(d),
                unit: 'km'
            }
        }

        return distanceObject;
    }

    static epsilonRound(value) {
        return Math.round(((parseFloat(value)) + Number.EPSILON) * 100) / 100;
    }

    static kmToMeters(km) {
        return this.epsilonRound(parseFloat(km * 1000))
    }

    static metersToKm(meters) {
        return this.epsilonRound(parseFloat(meters / 1000))
    }

    static setFirebaseGeopoint(lat, lng) {
        return new firebase.firestore.GeoPoint(lat, lng);
    }

    static getLatLngFromGeopoint(geoPoint) {
        return {
            lat: geoPoint.latitude,
            lng: geoPoint.longitude
        }
    }
}
