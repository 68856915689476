import React, { Component } from 'react';
import Colors from '../constants/Colors';
import SearchIcon from '@material-ui/icons/Search';
import Firestore from '../api/firebase/Firestore';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import ClearIcon from '@material-ui/icons/Clear';

export default class MapSearchBar extends Component {

    state = {
        search: '',
        technicians: [],
        clients: [],
        clientDocs: [],
        loading: false,
    }

    componentDidMount() {

        this.getClients();
    }

    async getClients() {

        let query = await Firestore.customQuery('company').get();

        if (query.size > 0) {

            this.setState({ clientDocs: query.docs });
        }
    }

    clearSearch() { this.setState({ technicians: [], clients: [], search: '', }) }

    searchCallback(doc, type) {

        this.clearSearch();

        if (doc && type && this.props.onResult) {

            let data = doc.data();
            data.id = doc.id;

            this.props.onResult(data, type);
        }
    }

    renderResults() {

        if (this.state.technicians.length || this.state.clients.length) {

            return (
                <div style={{ alignItems: 'center', display: 'flex', borderRadius: 15, flexDirection: 'column', marginRight: '20%', right: 0, position: 'absolute', top: 83, width: '60%', backgroundColor: '#FFF' }}>
                    {this.state.technicians.map((technician, key) => {
                        return (
                            <div onClick={() => { this.searchCallback(technician, 'technician') }} style={{ width: '100%', cursor: 'pointer', borderTop: '0.3px solid lightgrey', paddingLeft: 15, paddingTop: 15, paddingBottom: 15, alignItems: 'center', display: 'flex', borderBottomLeftRadius: 10, borderBottomRightRadius: 10, height: 60, backgroundColor: '#FFF' }}>
                                <PersonIcon style={{ color: Colors.semil.green, marginRight: 15 }} />
                                <div>{technician.data().name}</div>
                            </div>
                        )
                    })
                    }
                    {this.state.clients.map((company, key) => {
                        return (
                            <div onClick={() => { this.searchCallback(company, 'company') }} style={{ width: '100%', cursor: 'pointer', borderTop: '0.3px solid lightgrey', paddingLeft: 15, paddingTop: 15, paddingBottom: 15, alignItems: 'center', display: 'flex', borderBottomLeftRadius: 10, borderBottomRightRadius: 10, height: 60, backgroundColor: '#FFF' }}>
                                <BusinessIcon style={{ color: Colors.semil.red, marginRight: 15 }} />
                                <div>{company.data().name} <a style={{paddingLeft: 5, textAlign: 'right', fontSize: 12, color: Colors.text.secondaryText}}>{company.data().google_formatted_address ? company.data().google_formatted_address: ""}</a></div>
                            </div>
                        )
                    })
                    }
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <div style={{ width: '60%', alignItems: 'center', justifyContent: 'center', display: 'flex', marginRight: '20%', borderRadius: 10, right: 0, position: 'absolute', top: 30, height: 60, backgroundColor: '#FFF', shadowColor: "#000", shadowOffset: { width: 0, height: 2, }, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 5, }}>
                    <input value={this.state.search} onChange={(evt) => { this.handleSearch(evt.target.value) }} style={{ height: '100%', width: '95%', paddingTop: 5, paddingBottom: 5, marginRight: 5, paddingLeft: 15, paddingRight: 15, fontSize: 20, border: 'none', borderRadius: 10 }} placeholder={'Pesquise por Clientes ou Técnicos...'} />
                    {this.state.search.length ? <ClearIcon onClick={() => { this.clearSearch() }} style={{ fontSize: 34, cursor: 'pointer', marginRight: 5 }} /> : <SearchIcon style={{ fontSize: 34, cursor: 'pointer', marginRight: 5 }} />}
                </div>
                {this.renderResults()}
            </div>
        )
    }

    async handleSearch(text) {

        await this.setState({ search: text });

        if (this.state.search.toString().length > 3) {

            let result = [];
            let resultCompany = [];

            let technician = await Firestore.customQuery('technician').orderBy('name', 'asc').get();

            if (technician.size > 0) {

                let technicianArray = technician.docs;

                result = technicianArray.filter((technician) => {

                    const name = technician.data().name ? technician.data().name.toUpperCase() : ''.toUpperCase();
                    const search = this.state.search.toString().toUpperCase();

                    if (name.indexOf(search) > -1) {

                        return true;
                    }
                });
            }

            if (this.state.clientDocs.length) {

                let companyArray = this.state.clientDocs;

                resultCompany = companyArray.filter((company) => {

                    const name = company.data().name ? company.data().name.toUpperCase() : ''.toUpperCase();
                    const search = this.state.search.toString().toUpperCase();

                    if (name.indexOf(search) > -1) {

                        return true;
                    }
                });
            }

            this.setState({ technicians: result, clients: resultCompany });
        }
    }
}
