import React from 'react';
import StarIcon from '@material-ui/icons/Star';

export default class StarRating extends React.Component {

    state = {
        stars: null,
    }

    componentDidMount() {
        this.getStars();
    }

    getStars() {

        let stars = {};

        for (let i = 0; i < this.props.stars; i++) {

            stars[i] = { marked: this.props.marked && i < this.props.marked ? true : false, value: i };
        }

        this.setState({ stars: stars });
    }

    markStar(key) {

        if (this.state.stars[key]) {

            let stars = this.state.stars;

            for (let i = 0; i <= this.props.stars - 1; i++) {
                stars[i].marked = false;
            }

            for (let i = 0; i <= key; i++) {
                stars[i].marked = true;
            }

            if (this.props.onStarChange) {
                this.props.onStarChange(key + 1);
            }

            this.setState({ stars: stars });
        }
    }

    renderStars() {

        let stars = [];

        for (let i = 0; i < this.props.stars; i++) {

            let star = (
                <div key={i} onClick={() => { this.markStar(i) }}>

                    <StarIcon style={{color: this.state.stars[i].marked ? '#f09c00' : 'lightgrey', fontSize: this.props.starSize ? this.props.starSize : 30, cursor: 'pointer'}}/>

                </div>
            )

            stars.push(star);
        }

        return stars;
    }

    render() {
        return (
            <div style={this.props.containerStyle ? this.props.containerStyle : {}}>
                <div style={{flexDirection: 'row', display: 'flex'}}>
                    { this.state.stars ? this.renderStars() : null }
                </div>
            </div>
        )
    }
}