import React from "react";
import { AppBar, CssBaseline, Divider, Drawer, Hidden, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, Card, Button } from '@material-ui/core';
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import { Switch, Route, Link, BrowserRouter } from "react-router-dom";
import * as firebase from 'firebase';
import Colors from '../constants/Colors';

import CustomerAppointmentPage from '../page/CustomerAppointmentPage';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import SessionHelper from '../helper/SessionHelper';
import CustomerDataPage from "../page/CustomerDataPage";

const drawerWidth = 240;

export default class CustomerNavigation extends React.Component {

    logout() { firebase.auth().signOut(); window.location.href = '/' }

    state = {
        actualRoute: null,
    }

    componentDidMount() {
        
        this.setState({ actualRoute: window.location.pathname });
    }

    handleRouteChange() {
        setTimeout(() => {
            this.setState({ actualRoute: window.location.pathname });
        }, 10)
    }

    renderRoutes() {
        return (
            <div style={{display: 'flex'}}>
                <CssBaseline />
                <AppBar position="fixed" style={{ width: `calc(100% - ${drawerWidth}px)`, marginLeft: drawerWidth, backgroundColor: Colors.semil.green}}>
                    <Toolbar>
                        <Typography variant="h6" noWrap>
                            {'Seja bem vindo, ' + SessionHelper.getData().name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <BrowserRouter forceRefresh={false}>
                    <nav style={{width: drawerWidth, flexShrink: 0,}} aria-label="mailbox folders">
                        <Hidden xsDown implementation="css">
                            <Drawer
                                PaperProps={{ style: {width: drawerWidth} }}
                                variant="permanent"
                                open>
                                <div>
                                <div/>
                                <Divider />
                                <List>
                                    
                                    <ListItem component={Link} button to={'/'}>
                                        <img style={{ height: 45}} src={process.env.PUBLIC_URL + '/assets/image/logo.png'} />
                                    </ListItem>
                                    
                                    <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/' && true} style={ this.state.actualRoute === '/' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/'}>
                                        <ListItemIcon><HeadsetMicIcon style={ this.state.actualRoute === '/' ? {color: '#fff'} : {}} /></ListItemIcon>
                                        <ListItemText style={ this.state.actualRoute === '/' ? {color: '#fff'} : {}} primary={'Assistência Técnica'} />
                                    </ListItem>

                                    <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/customer_data' && true} style={ this.state.actualRoute === '/customer_data' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/customer_data'}>
                                        <ListItemIcon><PersonIcon style={ this.state.actualRoute === '/customer_data' ? {color: '#fff'} : {}} /></ListItemIcon>
                                        <ListItemText style={ this.state.actualRoute === '/customer_data' ? {color: '#fff'} : {}} primary={'Meus Dados'} />
                                    </ListItem>
                                    
                                    <ListItem component={Link} button onClick={() => { this.logout() }} to={'/'}>
                                        <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                                        <ListItemText primary={'Sair'} />
                                    </ListItem>
                                </List>
                                </div>
                            </Drawer>
                        </Hidden>
                    </nav>

                    <main style={{ flexGrow: 1, paddingTop: 60 }}>
                        <Switch>
                            <Route exact path="/" render={() => <CustomerAppointmentPage/> } />
                            <Route exact path="/customer_data" render={() => <CustomerDataPage/>} />
                        </Switch>
                    </main>
                </BrowserRouter>
            </div>
        )
    }

    goBack() {

        SessionHelper.destroy();
        this.logout();
    }

    renderApprovalMessage() {

        return (
            <div style={{height: '100vh', width: '100%', backgroundColor: Colors.semil.green, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Card style={{ padding: 30, filter: this.state.loading ? 'blur(5px)' : '', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 800}}>     
                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <img style={{height: 400}} src={process.env.PUBLIC_URL + '/assets/image/no_data.png'}/>
                            <p style={{fontWeight: 'bold', color: Colors.semil.green, fontSize: 40}}>{'Aprovação Pendente'}</p>
                            <div style={{color: 'grey', textAlign: 'center'}}>{'O seu cadastro ainda está em faze de análise'}</div>
                            <div style={{color: 'grey', textAlign: 'center', paddingTop: 15, paddingBottom: 25}}>{'Enviaremos um e-mail de confirmação em breve.'}</div>
                            <Button onClick={() => { this.goBack() }} style={{ width: '100%', fontWeight: 'bold', marginTop: 30, padding: 15, backgroundColor: Colors.semil.green, color: 'white' }} variant="contained" >VOLTAR</Button>
                        </div>
        
                </Card>
            </div>
        )
    }

    render() {
        return (
            <div>
                { SessionHelper.getData().active ? this.renderRoutes() : this.renderApprovalMessage()}
                <ToastContainer style={{paddingTop: 100}}/>
            </div>
        )
    }
}
