import React, { Component } from 'react';
import { InputLabel, Button } from '@material-ui/core';
import DefaultLoader from '../components/DefaultLoader';
import Firestore from '../api/firebase/Firestore';
import { toast } from 'react-toastify';
import DefaultButton from '../components/DefaultButton';
import DefaultModal from '../components/DefaultModal';
import DefaultTable from '../components/DefaultTable';
import DefaultInput from '../components/DefaultInput';
import Functions from '../constants/Functions';
import DefaultSelect from '../components/DefaultSelect';
import Colors from '../constants/Colors';

export default class TechnicianPage extends Component {

    state = {
        docs: [],
        companyDocs: [],
        loading: true,
        addUserModal: false,
        email: '',
        password: '',
        confirmPassword: '',
        name: '',
        company: '',
    }

    componentDidMount() {

        this.getDocs();
        this.getClients();
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('user').where('type', '==', 'customer').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    async getClients() {

        let query = await Firestore.customQuery('company').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ companyDocs: docs });
    }

    async addUser() {

        if (this.state.name && this.state.email && this.state.password && this.state.confirmPassword && this.state.company) {

            if (this.state.password === this.state.confirmPassword) {

                try {

                    this.setState({ loading: true });

                    let company = this.state.companyDocs[this.state.companyDocs.findIndex(item => item.id === this.state.company)];

                    let body = {
                        email: this.state.email,
                        password: this.state.password,
                        doc: {
                            name: this.state.name,
                            cnpj: company.cnpj,
                            zipcode: '',
                            country: company.country,
                            state: company.state,
                            city: company.city,
                            email: this.state.email,
                            address: company.address,
                            neighborhood: company.address_bairro,
                            type: 'customer',
                            active: false,
                        },
                    };

                    let request = await fetch(`${Functions.getEndPoint()}/registerCustomer`, {headers: {"Content-Type": "application/json"}, method: 'POST', body: JSON.stringify(body) });
                    let response = await request.json();

                    if (response.status === 200) {

                        this.setState({ loading: false, addUserModal: false });
                        toast.success('Usuário criado com sucesso');

                        this.getDocs();

                    } else {

                        this.setState({ loading: false });

                        throw new Error('Erro ao cadastrar usuário');
                    }

                } catch (error) {

                    this.setState({ loading: false });
                    toast.error('Houve um problema ao cadastrar, entre em contato com o suporte');
                }

            } else {

                toast.warn('As senhas são diferentes');
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    addUserModal() {
        return (
            <div>
                <InputLabel>Dados da Empresa</InputLabel>
                <DefaultInput onBlur={(v) => { this.setState({ name: v }) }} required={true} label={'Nome'}/>

                <div style={{paddingTop: 22}}/>
                <DefaultSelect search={true} searchField={'name'} id={'company_selection'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ company: v.target.value }) }} value={this.state.company} docs={this.state.companyDocs} label={'Selecionar empresa'}/>
                
                <InputLabel style={{paddingTop: 45}}>Dados de Acesso</InputLabel>
                <DefaultInput onBlur={(v) => { this.setState({ email: v }) }} required={true} label={'E-mail'}/>
                <DefaultInput onBlur={(v) => { this.setState({ password: v }) }} required={true} type={'password'} label={'Senha'}/>
                <DefaultInput onBlur={(v) => { this.setState({ confirmPassword: v }) }} required={true} type={'password'} label={'Confirme a senha'}/>

                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addUser() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addUserModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultButton onClick={() => { this.setState({ addUserModal: true }) }} title={'Adicionar'}/>
                <DefaultTable
                title={'Área do Cliente'}
                filtering={true}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'name', editable: false, },
                    { title: 'E-mail', field: 'email', editable: false, },
                    { title: 'Cnpj', field: 'cnpj', editable: false, },
                    { title: 'Cep', field: 'zipcode', editable: false, },
                    { title: 'País', field: 'country', editable: false, },
                    { title: 'Estado', field: 'state', editable: false, },
                    { title: 'Cidade', field: 'city', editable: false, },
                    { title: 'Endereço', field: 'address', editable: false, },
                    { title: 'Ativo', type: 'boolean', field: 'active'},
                ]}
                
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let update = {
                            active: newData.active
                        };

                        await Firestore.update(update, 'user', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}

                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        await Firestore.delete('user', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addUserModal()} title={'Adicionar Usuário'} onClose={() => { this.setState({ addUserModal: false }) }} open={this.state.addUserModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
