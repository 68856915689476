import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, FormLabel, MenuItem, FormControlLabel, Checkbox, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import DescriptionIcon from '@material-ui/icons/Description';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import moment from 'moment';
import DefaultSelect from '../components/DefaultSelect';
import SatisfactionResearchHelper from '../helper/SatisfactionResearchHelper';

export default class SatisfactionResearch extends Component {

    state = {
        docs: [],
        satDocs: [],
        name: '',
        data: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        type: 'none',
        types: [
            { value: 'text', label: 'Resposta de texto'},
            { value: 'multiple_select', label: 'Seleção de múltiplos valores'},
            { value: 'individual_select', label: 'Seleção individual'},
            { value: 'star_rating', label: 'Avaliação em estrelas'},
        ],
        required: false,
        options: [],
        optionName: '',
        dataModal: null,
        id: null,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('satisfaction_research').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    async getSATs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('appointment').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ satDocs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
        this.getSATs();
    }

    async saveResearch() {

        if (this.state.name) {

            let data = {
                name: this.state.name,
                data: this.state.data.length ? this.state.data : [],
                answers: [],
                created_at: new Date(),
            };

            try {

                this.setState({ loadingModal: true });

                await Firestore.insert(data, 'satisfaction_research');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, addModal: false, name: '', questions: [] });
                await this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    addQuestion() {

        if (this.state.type === 'text') {

            let data = this.state.data;

            let push = {
                type: this.state.type,
                label: this.state.question_name,
                min_char: this.state.min_char,
                max_char: this.state.max_char,
                required: this.state.required,
                order: this.state.data.length + 1,
            };

            data.push(push);

            this.setState({ data: data });

        } else if (this.state.type === 'multiple_select' || this.state.type === 'individual_select') {

            let data = this.state.data;

            let push = {
                type: this.state.type,
                label: this.state.question_name,
                required: this.state.required,
                order: this.state.data.length + 1,
                options: this.state.options,
            };

            data.push(push);

            this.setState({ data: data });

        } else if (this.state.type === 'star_rating') {
        
            let data = this.state.data;

            let push = {
                type: this.state.type,
                label: this.state.question_name,
                required: this.state.required,
                order: this.state.data.length + 1,
                min_star: this.state.min_star,
                max_star: this.state.max_star,
            };

            data.push(push);

            this.setState({ data: data });
        }

        this.setState({ type: 'none', question_name: '', min_char: '', max_char: '', required: false, options: [], optionName: '' });
    }

    removeItem(key) {
        let questions = this.state.data;
        questions.splice(key, 1);

        this.setState({ data: questions });
    }

    removeOption(key) {
        let options = this.state.options;
        options.splice(key, 1);

        this.setState({ options: options });
    }

    renderQuestions() {
        return (
            <div>
                { !this.state.data.length && <p style={{color: 'grey', textAlign: 'center', paddingTop: 25}}>{'Nenhuma pergunta cadastrada.'}</p> }
                { this.state.data.map((question, key) => {

                    return (
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <p>{`${question.order}. ${question.label} (${this.state.types[this.state.types.findIndex(item => item.value === question.type)].label})`}</p>
                            <DeleteOutlineIcon onClick={() => { this.removeItem(key) }} style={{color: Colors.semil.red, marginLeft: 15, cursor: 'pointer'}}/>
                        </div>
                    )

                }) }
            </div>
        )
    }

    addOption() {

        if (this.state.optionName !== '') {
            
            let options = this.state.options;

            let data = {
                label: this.state.optionName,
                order: this.state.options.length + 1,
            }
    
            options.push(data);
    
            this.setState({ options: options});
        }
    }

    renderQuestionMaker() {
        return (
            <div style={{paddingTop: 15}}>

                { this.state.type === 'text' ? 
                
                <div>
                    <DefaultInput onBlur={(v) => { this.setState({ question_name: v }) }} label={'Nome'}/>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <DefaultInput onBlur={(v) => { this.setState({ min_char: v }) }} label={'Mínimo de Caractéres'}/>
                        <div style={{paddingLeft: 15, paddingRight: 15}}/>
                        <DefaultInput onBlur={(v) => { this.setState({ max_char: v }) }} label={'Máximo de Caractéres'}/>
                    </div>
                    <FormControlLabel
                    control={<Checkbox checked={this.state.required} onChange={(v) => { this.setState({ required: v.target.checked }) }} name={'required'} />}
                    label={'Campo Obrigatório'}
                    />
                    <div style={{paddingTop: 20}}/>
                    <DefaultButton width={'100%'} title={'Adicionar'} onClick={() => { this.addQuestion() } }/>
                </div>

                : null}

                { this.state.type === 'multiple_select' || this.state.type === 'individual_select' ? 
                
                <div>
                    <DefaultInput onBlur={(v) => { this.setState({ question_name: v }) }} label={'Nome'}/>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <div style={{width: '90%', paddingRight: 15}}><DefaultInput label={'Adicionar Opção'} onBlur={(v) => { this.setState({ optionName: v }) }}/></div>
                        <div onClick={() => { this.addOption() }} style={{width: '10%'}}><div style={{display: 'flex', height: 40, borderRadius: 3, cursor: 'pointer', backgroundColor: Colors.semil.green, alignItems: 'center', justifyContent: 'center'}}><AddIcon style={{color: '#FFF'}}/></div></div>
                    </div>
                    <FormLabel style={{paddingTop: 15, paddingBottom: 15}} component="legend">Opções</FormLabel>

                    <div>
                        { !this.state.options.length && <p style={{color: 'grey', textAlign: 'center', paddingTop: 25}}>{'Nenhuma opção cadastrada.'}</p> }
                        { this.state.options.map((option, key) => {

                            return (
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <p>{`${option.label}`}</p>
                                    <DeleteOutlineIcon onClick={() => { this.removeOption(key) }} style={{color: Colors.semil.red, marginLeft: 15, cursor: 'pointer'}}/>
                                </div>
                            )

                        }) }
                    </div>
                    
                    <FormControlLabel
                    control={<Checkbox checked={this.state.required} onChange={(v) => { this.setState({ required: v.target.checked }) }} name={'required'} />}
                    label={'Campo Obrigatório'}
                    />
                    <div style={{paddingTop: 20}}/>
                    <DefaultButton width={'100%'} title={'Adicionar'} onClick={() => { this.addQuestion() } }/>
                </div>

                : null}

                { this.state.type === 'star_rating' ? 
                
                <div>
                    <DefaultInput onBlur={(v) => { this.setState({ question_name: v }) }} label={'Nome'}/>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <DefaultInput onBlur={(v) => { this.setState({ min_star: v }) }} label={'Mínimo de Estrelas'}/>
                        <div style={{paddingLeft: 15, paddingRight: 15}}/>
                        <DefaultInput onBlur={(v) => { this.setState({ max_star: v }) }} label={'Máximo de Estrelas'}/>
                    </div>
                    <FormControlLabel
                    control={<Checkbox checked={this.state.required} onChange={(v) => { this.setState({ required: v.target.checked }) }} name={'required'} />}
                    label={'Campo Obrigatório'}
                    />
                    <div style={{paddingTop: 20}}/>
                    <DefaultButton width={'100%'} title={'Adicionar'} onClick={() => { this.addQuestion() } }/>
                </div>

                : null}

            </div>
        )
    }

    addModal() {
        return (
            <div>
                <DefaultInput onChange={(v) => { this.setState({ name: v }) }} label={'Nome'}/>
                <FormLabel style={{paddingTop: 15, paddingBottom: 15}} component="legend">Conteúdo do Questionário</FormLabel>
                
                <DefaultSelect value={this.state.type} id={'type'} onChange={(v) => { this.setState({ type: v.target.value }) }} docs={this.state.types} valueField={'value'} displayField={'label'} allItems={<MenuItem value={'none'}>{'Adicione uma nova pergunta'}</MenuItem>}/>
                {this.state.type !== 'none' && this.renderQuestionMaker()}

                <FormLabel style={{paddingTop: 15}} component="legend">Questionário Final</FormLabel>
                {this.renderQuestions()}

                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.saveResearch() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    goToSurvery(rowData) {

        try {

            let link = SatisfactionResearchHelper.getLink(rowData.id, '', rowData.name);
            window.open(link, '_blank');

        } catch (error) {

            toast.error('Erro ao acessar');
        }
    }

    getQuestionType(type) {
        
        return this.state.types[this.state.types.findIndex(item => item.value === type)].label;
    }

    answersModal() {
        if (this.state.dataModal) {
            return (
                <div>
                    {          
                        this.state.satDocs.map((sat, sat_key) => {
                            if (sat.satisfaction_research_id === this.state.id && sat.satisfaction_research_answers) {

                                let data = !Array.isArray(sat.satisfaction_research_answers) ? [sat.satisfaction_research_answers] : sat.satisfaction_research_answers;

                                return data.map((answer, key) => {
                                    return (
                                        <Accordion style={{boxShadow: 'rgba(50, 50, 50, 0.47) 1px 1px 10px 0px'}}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                                <div style={{fontWeight: 'bold', color: Colors.semil.green}}>{sat.title + ' - ' + moment(answer.date.toDate()).format('DD/MM/YYYY HH:mm')}</div>
                                            </AccordionSummary>

                                            <AccordionDetails style={{display: 'flex', flexDirection: 'column'}}>
                                                { this.state.dataModal.map((question, key) => {
                                                        return (
                                                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                                                <div style={{fontWeight: 'bold'}}>{question.label + ' (' + this.getQuestionType(question.type) + ')'}</div>
                                                                <div style={{paddingBottom: 15}}>{answer.answers[answer.answers.findIndex(item => item.question_key === key)].answer}</div>
                                                                
                                                                {  question.options && question.type === 'multiple_select' ?
                                                                
                                                                    question.options.map((option, optionKey) => {

                                                                        let answer = answer.answers[answer.answers.findIndex(item => item.question_key === key)].option_key[optionKey] ? 'Marcado': 'Não marcado';

                                                                        return <div style={{paddingBottom: 15}}>{option.label + ' - ' + answer + ' '}</div>;
                                                                    }) : null
                                                                }

                                                            </div>
                                                        )
                                                }) }
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })
                            }
                        })
                    }
                </div>
            )
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultButton onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>

                <DefaultTable
                actions={[
                    {
                        icon: QuestionAnswerIcon,
                        tooltip: 'Ver Respostas',
                        onClick: (event, rowData) => { this.setState({ answersModal: true, dataModal: rowData.data, id: rowData.id }) } 
                    },
                    {
                        icon: DescriptionIcon,
                        tooltip: 'Acessar Questionário',
                        onClick: (event, rowData) => { this.goToSurvery(rowData) } 
                    }
                ]}
                title={'Pesquisa de Satisfação'}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'name' },
                    { title: 'Data de Criação', field: 'created_at', editable: false, render: rowData => moment(rowData.created_at.toDate()).format('DD/MM/YYYY HH:mm') },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)].name = newData.name;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let update = {
                            name: newData.name,
                        };

                        await Firestore.update(update, 'satisfaction_research', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        await Firestore.delete('satisfaction_research', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />

                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Nova Pesquisa de Satisfação'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.answersModal()} title={'Respostas'} onClose={() => { this.setState({ answersModal: false }) }} open={this.state.answersModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
