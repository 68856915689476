import React from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import HomeIcon from "@material-ui/icons/Home";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import ForumIcon from "@material-ui/icons/Forum";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import StarIcon from "@material-ui/icons/Star";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import LanguageIcon from "@material-ui/icons/Language";
import DescriptionIcon from "@material-ui/icons/Description";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import EventIcon from "@material-ui/icons/Event";
import TelegramIcon from "@material-ui/icons/Telegram";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import TuneIcon from "@material-ui/icons/Tune";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import LockIcon from "@material-ui/icons/Lock";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BusinessIcon from "@material-ui/icons/Work";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import MapIcon from "@material-ui/icons/Map";
import EmailIcon from "@material-ui/icons/Email";
import PeopleIcon from "@material-ui/icons/People";
import BuildIcon from "@material-ui/icons/Build";
import ShareIcon from "@material-ui/icons/Share";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Switch, Route, Link, BrowserRouter } from "react-router-dom";
import * as firebase from 'firebase';
import Colors from '../constants/Colors';
import NewsLetterPage from '../page/NewsLetterPage';
import HourPricingPage from '../page/HourPricingPage';
import EmailTemplatePage from '../page/EmailTemplatePage';
import PermissionPage from '../page/PermissionPage';
import ClientPage from '../page/ClientPage';
import AppointmentPage from '../page/AppointmentPage';
import MapPage from '../page/MapPage';
import OrderPage from '../page/OrderPage';
import BudgetPage from '../page/BudgetPage';
import EmailAccount from '../page/EmailAccountPage';
import EmailAutomatizationPage from '../page/EmailAutomatization';
import ProductPage from '../page/ProductPage';
import TechnicianPage from '../page/TechnicianPage';
import UserPage from '../page/UserPage';
import IntegrationPage from '../page/IntegrationPage';
import SatisfactionResearchPage from '../page/SatisfactionResearchPage';
import SettingsPage from '../page/SettingsPage';
import HomePage from '../page/HomePage';
import CalendarPage from '../page/CalendarPage';
import ReportPage from '../page/ReportPage';
import CustomerLogin from '../page/CustomerLogin';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import SessionHelper from '../helper/SessionHelper';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Collapse from '@material-ui/core/Collapse';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ChromeReaderModeIcon from '@material-ui/icons/MenuBook';
import ImagesPage from "../page/ImagesPage";
import WarrantyPage from "../page/WarrantyPage";
import CurriculumPage from "../page/CurriculumPage";
import ManualPage from "../page/ManualPage";
import { AccountTree, RecordVoiceOver } from "@material-ui/icons";
import OFPage from "../page/OFPage";
import ComplaintPage from "../page/ComplaintPage";
import HighlightPage from "../page/HighlightPage";

const drawerWidth = 240;

export default class navigation extends React.Component {

    logout() { firebase.auth().signOut(); window.location.href = '/' }

    state = {
        actualRoute: null,
        settingsMenuOpen: false,
        websiteMenuOpen: false,
        accessMenuOpen: false,
        marketingMenuOpen: false,
        appMenuOpen: false,
    }

    componentDidMount() {
        
        this.setState({ actualRoute: window.location.pathname });
    }

    handleClick(menu) {
        let state = this.state;
        state[menu] = !state[menu];

        this.setState(state);
    }

    handleRouteChange() {
        setTimeout(() => {
            this.setState({ actualRoute: window.location.pathname });
        }, 10)
    }

    render() {
        return (
            <div style={{display: 'flex'}}>
                <CssBaseline />
                <AppBar position="fixed" style={{ width: `calc(100% - ${drawerWidth}px)`, marginLeft: drawerWidth, backgroundColor: Colors.semil.green}}>
                    <Toolbar>
                        <Typography variant="h6" noWrap>
                            Semil Connect
                        </Typography>
                    </Toolbar>
                </AppBar>
                <BrowserRouter forceRefresh={false}>
                    <nav style={{width: drawerWidth, flexShrink: 0,}} aria-label="mailbox folders">
                        <Hidden xsDown implementation="css">
                            <Drawer
                                PaperProps={{ style: {width: drawerWidth} }}
                                variant="permanent"
                                open>
                                <div>
                                <div/>
                                <Divider />
                                <List>
                                    <ListItem component={Link} button to={'/'}>
                                        <img style={{ height: 45}} src={process.env.PUBLIC_URL + '/assets/image/logo.png'} />
                                    </ListItem>
                                    <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/' && true} style={ this.state.actualRoute === '/' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/'}>
                                        <ListItemIcon><HomeIcon style={ this.state.actualRoute === '/' ? {color: '#fff'} : {}} /></ListItemIcon>
                                        <ListItemText style={ this.state.actualRoute === '/' ? {color: '#fff'} : {}} primary={'Dashboard'} />
                                    </ListItem>

                                    { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.chamados ?
                                    
                                    <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/chamados' && true} style={ this.state.actualRoute === '/chamados' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/chamados'}>
                                        <ListItemIcon><HeadsetMicIcon style={ this.state.actualRoute === '/chamados' ? {color: '#fff'} : {}} /></ListItemIcon>
                                        <ListItemText style={ this.state.actualRoute === '/chamados' ? {color: '#fff'} : {}} primary={'SAT'} />
                                    </ListItem> : null

                                    }

                                    { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.map ?
                                    
                                    <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/map' && true} style={ this.state.actualRoute === '/map' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/map'}>
                                        <ListItemIcon><MapIcon style={ this.state.actualRoute === '/map' ? {color: '#fff'} : {}} /></ListItemIcon>
                                        <ListItemText style={ this.state.actualRoute === '/map' ? {color: '#fff'} : {}} primary={'Mapa'} />
                                    </ListItem> : null

                                    }

                                    { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.calendar ?
                                    
                                    <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/calendar' && true} style={ this.state.actualRoute === '/calendar' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/calendar'}>
                                        <ListItemIcon><EventIcon style={ this.state.actualRoute === '/calendar' ? {color: '#fff'} : {}} /></ListItemIcon>
                                        <ListItemText style={ this.state.actualRoute === '/calendar' ? {color: '#fff'} : {}} primary={'Agenda'} />
                                    </ListItem> : null

                                    }

                                    { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.report ?
                                    
                                    <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/report' && true} style={ this.state.actualRoute === '/report' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/report'}>
                                        <ListItemIcon><AssessmentIcon style={ this.state.actualRoute === '/report' ? {color: '#fff'} : {}} /></ListItemIcon>
                                        <ListItemText style={ this.state.actualRoute === '/report' ? {color: '#fff'} : {}} primary={'Relatórios'} />
                                    </ListItem> : null

                                    }
                                    
                                    { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.client ?
                                    
                                    <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/client' && true} style={ this.state.actualRoute === '/client' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/client'}>
                                        <ListItemIcon><BusinessIcon style={ this.state.actualRoute === '/client' ? {color: '#fff'} : {}} /></ListItemIcon>
                                        <ListItemText style={ this.state.actualRoute === '/client' ? {color: '#fff'} : {}} primary={'Clientes'} />
                                    </ListItem> : null

                                    }
                                    
                                    { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.order ?
                                    
                                    <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/order' && true} style={ this.state.actualRoute === '/order' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/order'}>
                                        <ListItemIcon><AssignmentIcon style={ this.state.actualRoute === '/order' ? {color: '#fff'} : {}} /></ListItemIcon>
                                        <ListItemText style={ this.state.actualRoute === '/order' ? {color: '#fff'} : {}} primary={'Pedidos de Venda'} />
                                    </ListItem> : null

                                    }

                                    { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.warranty ?
                                    
                                    <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/warranty' && true} style={ this.state.actualRoute === '/warranty' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/warranty'}>
                                        <ListItemIcon><ThumbUpIcon style={ this.state.actualRoute === '/warranty' ? {color: '#fff'} : {}} /></ListItemIcon>
                                        <ListItemText style={ this.state.actualRoute === '/warranty' ? {color: '#fff'} : {}} primary={'Garantias'} />
                                    </ListItem> : null

                                    }

                                    { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.curriculum ?
                                    
                                    <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/curriculum' && true} style={ this.state.actualRoute === '/curriculum' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/curriculum'}>
                                        <ListItemIcon><AssignmentIndIcon style={ this.state.actualRoute === '/curriculum' ? {color: '#fff'} : {}} /></ListItemIcon>
                                        <ListItemText style={ this.state.actualRoute === '/curriculum' ? {color: '#fff'} : {}} primary={'Currículos'} />
                                    </ListItem> : null

                                    }

                                    { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.complaint ?
                                    
                                    <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/complaint' && true} style={ this.state.actualRoute === '/complaint' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/complaint'}>
                                        <ListItemIcon><PhoneInTalkIcon style={ this.state.actualRoute === '/complaint' ? {color: '#fff'} : {}} /></ListItemIcon>
                                        <ListItemText style={ this.state.actualRoute === '/complaint' ? {color: '#fff'} : {}} primary={'Denúncias'} />
                                    </ListItem> : null

                                    }

                                    { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.manual ?
                                    
                                    <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/manual' && true} style={ this.state.actualRoute === '/manual' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/manual'}>
                                        <ListItemIcon><ChromeReaderModeIcon style={ this.state.actualRoute === '/manual' ? {color: '#fff'} : {}} /></ListItemIcon>
                                        <ListItemText style={ this.state.actualRoute === '/manual' ? {color: '#fff'} : {}} primary={'Manuais'} />
                                    </ListItem> : null

                                    }

                                    { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.of ?
                                    
                                    <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/of' && true} style={ this.state.actualRoute === '/of' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/of'}>
                                        <ListItemIcon><AccountTree style={ this.state.actualRoute === '/of' ? {color: '#fff'} : {}} /></ListItemIcon>
                                        <ListItemText style={ this.state.actualRoute === '/of' ? {color: '#fff'} : {}} primary={'OF'} />
                                    </ListItem> : null

                                    }

                                    <ListItem selected={this.state.accessMenuOpen ? true : false} button onClick={() => { this.handleClick('accessMenuOpen') }}>
                                        <ListItemIcon>
                                        <PeopleIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Acessos" />
                                        {this.state.accessMenuOpen ? <ExpandMore /> : <ChevronRight />}
                                    </ListItem>
                                    <Collapse in={this.state.accessMenuOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>

                                        { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.technician ?
                                    
                                        <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/technician' && true} style={ this.state.actualRoute === '/technician' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/technician'}>
                                            <ListItemIcon><BuildIcon style={ this.state.actualRoute === '/technician' ? {color: '#fff'} : {}} /></ListItemIcon>
                                            <ListItemText style={ this.state.actualRoute === '/technician' ? {color: '#fff'} : {}} primary={'Técnicos'} />
                                        </ListItem> : null

                                        }

                                        { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.user ?
                                        
                                        <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/user' && true} style={ this.state.actualRoute === '/user' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/user'}>
                                            <ListItemIcon><PersonIcon style={ this.state.actualRoute === '/user' ? {color: '#fff'} : {}} /></ListItemIcon>
                                            <ListItemText style={ this.state.actualRoute === '/user' ? {color: '#fff'} : {}} primary={'Usuários'} />
                                        </ListItem> : null

                                        }

                                        { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.customer_login ?
                                    
                                        <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/customer_login' && true} style={ this.state.actualRoute === '/customer_login' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/customer_login'}>
                                            <ListItemIcon><EmojiPeopleIcon style={ this.state.actualRoute === '/customer_login' ? {color: '#fff'} : {}} /></ListItemIcon>
                                            <ListItemText style={ this.state.actualRoute === '/customer_login' ? {color: '#fff'} : {}} primary={'Área do Cliente'} />
                                        </ListItem> : null

                                        }

                                        </List>
                                    </Collapse>

                                    <ListItem selected={this.state.marketingMenuOpen ? true : false} button onClick={() => { this.handleClick('marketingMenuOpen') }}>
                                        <ListItemIcon>
                                        <RecordVoiceOver />
                                        </ListItemIcon>
                                        <ListItemText primary="Marketing" />
                                        {this.state.marketingMenuOpen ? <ExpandMore /> : <ChevronRight />}
                                    </ListItem>
                                    <Collapse in={this.state.marketingMenuOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                        
                                        { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.satisfaction_research ?
                                    
                                        <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/satisfaction_research' && true} style={ this.state.actualRoute === '/satisfaction_research' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/satisfaction_research'}>
                                            <ListItemIcon><EmojiEmotionsIcon style={ this.state.actualRoute === '/satisfaction_research' ? {color: '#fff'} : {}} /></ListItemIcon>
                                            <ListItemText style={ this.state.actualRoute === '/satisfaction_research' ? {color: '#fff'} : {}} primary={'Pesquisa Satisfação'} />
                                        </ListItem> : null

                                        }

                                        { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.email_template ?
                                    
                                            <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/email_template' && true} style={ this.state.actualRoute === '/email_template' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/email_template'}>
                                                <ListItemIcon><EmailIcon style={ this.state.actualRoute === '/email_template' ? {color: '#fff'} : {}} /></ListItemIcon>
                                                <ListItemText style={ this.state.actualRoute === '/email_template' ? {color: '#fff'} : {}} primary={'Templates de E-mail'} />
                                            </ListItem> : null

                                        }

                                        { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.email_automatization ?
                                    
                                            <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/email_automatization' && true} style={ this.state.actualRoute === '/email_automatization' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/email_automatization'}>
                                                <ListItemIcon><TelegramIcon style={ this.state.actualRoute === '/email_automatization' ? {color: '#fff'} : {}} /></ListItemIcon>
                                                <ListItemText style={ this.state.actualRoute === '/email_automatization' ? {color: '#fff'} : {}} primary={'Automação E-mail'} />
                                            </ListItem> : null

                                        }

                                        </List>
                                    </Collapse>

                                    <ListItem selected={this.state.websiteMenuOpen ? true : false} button onClick={() => { this.handleClick('websiteMenuOpen') }}>
                                        <ListItemIcon>
                                        <LanguageIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Website" />
                                        {this.state.websiteMenuOpen ? <ExpandMore /> : <ChevronRight />}
                                    </ListItem>
                                    <Collapse in={this.state.websiteMenuOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>

                                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.product ?
                                        
                                                <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/product' && true} style={ this.state.actualRoute === '/product' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/product'}>
                                                    <ListItemIcon><LocalOfferIcon style={ this.state.actualRoute === '/product' ? {color: '#fff'} : {}} /></ListItemIcon>
                                                    <ListItemText style={ this.state.actualRoute === '/product' ? {color: '#fff'} : {}} primary={'Produtos'} />
                                                </ListItem> : null

                                            }

                                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.highlight ?
                                        
                                                <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/highlight' && true} style={ this.state.actualRoute === '/highlight' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/highlight'}>
                                                    <ListItemIcon><StarIcon style={ this.state.actualRoute === '/highlight' ? {color: '#fff'} : {}} /></ListItemIcon>
                                                    <ListItemText style={ this.state.actualRoute === '/highlight' ? {color: '#fff'} : {}} primary={'Destaques'} />
                                                </ListItem> : null

                                            }

                                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.newsletter ?
                                                
                                                <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/newsletter' && true} style={ this.state.actualRoute === '/newsletter' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/newsletter'}>
                                                    <ListItemIcon><ForumIcon style={ this.state.actualRoute === '/newsletter' ? {color: '#fff'} : {}} /></ListItemIcon>
                                                    <ListItemText style={ this.state.actualRoute === '/newsletter' ? {color: '#fff'} : {}} primary={'Blog de Notícias'} />
                                                </ListItem> : null

                                            }

                                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.images ?
                                                
                                                <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/images' && true} style={ this.state.actualRoute === '/images' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/images'}>
                                                    <ListItemIcon><PhotoLibraryIcon style={ this.state.actualRoute === '/images' ? {color: '#fff'} : {}} /></ListItemIcon>
                                                    <ListItemText style={ this.state.actualRoute === '/images' ? {color: '#fff'} : {}} primary={'Imagens'} />
                                                </ListItem> : null

                                            }

                                        </List>
                                    </Collapse>

                                    <ListItem selected={this.state.appMenuOpen ? true : false} button onClick={() => { this.handleClick('appMenuOpen') }}>
                                        <ListItemIcon>
                                        <PhoneIphoneIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="App" />
                                        {this.state.appMenuOpen ? <ExpandMore /> : <ChevronRight />}
                                    </ListItem>
                                    <Collapse in={this.state.appMenuOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>

                                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.budget ?
                                        
                                                <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/budget' && true} style={ this.state.actualRoute === '/budget' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/budget'}>
                                                    <ListItemIcon><DescriptionIcon style={ this.state.actualRoute === '/budget' ? {color: '#fff'} : {}} /></ListItemIcon>
                                                    <ListItemText style={ this.state.actualRoute === '/budget' ? {color: '#fff'} : {}} primary={'Orçamentos'} />
                                                </ListItem> : null

                                            }

                                        </List>
                                    </Collapse>

                                    <ListItem selected={this.state.settingsMenuOpen ? true : false} button onClick={() => { this.handleClick('settingsMenuOpen') }}>
                                        <ListItemIcon>
                                        <SettingsIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Configurações" />
                                        {this.state.settingsMenuOpen ? <ExpandMore /> : <ChevronRight />}
                                    </ListItem>
                                    <Collapse in={this.state.settingsMenuOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                        
                                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.settings ?
                                        
                                                <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/settings' && true} style={ this.state.actualRoute === '/settings' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/settings'}>
                                                    <ListItemIcon><TuneIcon style={ this.state.actualRoute === '/settings' ? {color: '#fff'} : {}} /></ListItemIcon>
                                                    <ListItemText style={ this.state.actualRoute === '/settings' ? {color: '#fff'} : {}} primary={'Parâmetros'} />
                                                </ListItem> : null

                                            }

                                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.integration ?
                                            
                                            <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/integration' && true} style={ this.state.actualRoute === '/integration' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/integration'}>
                                                <ListItemIcon><ShareIcon style={ this.state.actualRoute === '/integration' ? {color: '#fff'} : {}} /></ListItemIcon>
                                                <ListItemText style={ this.state.actualRoute === '/integration' ? {color: '#fff'} : {}} primary={'Integrações'} />
                                            </ListItem> : null

                                            }

                                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.permission ?
                                            
                                            <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/permission' && true} style={ this.state.actualRoute === '/permission' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/permission'}>
                                                <ListItemIcon><LockIcon style={ this.state.actualRoute === '/permission' ? {color: '#fff'} : {}} /></ListItemIcon>
                                                <ListItemText style={ this.state.actualRoute === '/permission' ? {color: '#fff'} : {}} primary={'Permissões'} />
                                            </ListItem> : null

                                            }

                                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.hour_pricing ?
                                            
                                            <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/hour_pricing' && true} style={ this.state.actualRoute === '/hour_pricing' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/hour_pricing'}>
                                                <ListItemIcon><AccessTimeIcon style={ this.state.actualRoute === '/hour_pricing' ? {color: '#fff'} : {}} /></ListItemIcon>
                                                <ListItemText style={ this.state.actualRoute === '/hour_pricing' ? {color: '#fff'} : {}} primary={'Tipo de Horas'} />
                                            </ListItem> : null

                                            }

                                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.email_account ?
                                            
                                            <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/email_account' && true} style={ this.state.actualRoute === '/email_account' ? {backgroundColor: Colors.semil.green} : {}} component={Link} button to={'/email_account'}>
                                                <ListItemIcon><VpnKeyIcon style={ this.state.actualRoute === '/email_account' ? {color: '#fff'} : {}} /></ListItemIcon>
                                                <ListItemText style={ this.state.actualRoute === '/email_account' ? {color: '#fff'} : {}} primary={'Contas de E-mail'} />
                                            </ListItem> : null

                                            }

                                        </List>
                                    </Collapse>
                                    
                                    <ListItem component={Link} button onClick={() => { this.logout() }} to={'/'}>
                                        <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                                        <ListItemText primary={'Sair'} />
                                    </ListItem>
                                </List>
                                </div>
                            </Drawer>
                        </Hidden>
                    </nav>

                    <main style={{ flexGrow: 1, paddingTop: 60 }}>
                        <Switch>
                            <Route exact path="/" render={() => <HomePage/>} />
                            
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.chamados ? <Route path="/chamados" render={() => <AppointmentPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.map ? <Route path="/map" render={() => <MapPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.calendar ? <Route path="/calendar" render={() => <CalendarPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.report ? <Route path="/report" render={() => <ReportPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.newsletter ? <Route path="/newsletter" render={() => <NewsLetterPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.hour_pricing ? <Route path="/hour_pricing" render={() => <HourPricingPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.email_account ? <Route path="/email_account" render={() => <EmailAccount/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.email_template ? <Route path="/email_template" render={() => <EmailTemplatePage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.permission ? <Route path="/permission" render={() => <PermissionPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.client ? <Route path="/client" render={() => <ClientPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.email_automatization ? <Route path="/email_automatization" render={() => <EmailAutomatizationPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.product ? <Route path="/product" render={() => <ProductPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.highlight ? <Route path="/highlight" render={() => <HighlightPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.images ? <Route path="/images" render={() => <ImagesPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.technician ? <Route path="/technician" render={() => <TechnicianPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.user ? <Route path="/user" render={() => <UserPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.satisfaction_research ? <Route path="/satisfaction_research" render={() => <SatisfactionResearchPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.customer_login ? <Route path="/customer_login" render={() => <CustomerLogin/> } /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.order ? <Route path="/order" render={() => <OrderPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.warranty ? <Route path="/warranty" render={() => <WarrantyPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.curriculum ? <Route path="/curriculum" render={() => <CurriculumPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.complaint ? <Route path="/complaint" render={() => <ComplaintPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.integration ? <Route path="/integration" render={() => <IntegrationPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.settings ? <Route path="/settings" render={() => <SettingsPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.budget ? <Route path="/budget" render={() => <BudgetPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.manual ? <Route path="/manual" render={() => <ManualPage/>} /> : null }
                            { SessionHelper.getData().permission && SessionHelper.getData().permission.routes.of ? <Route path="/of" render={() => <OFPage/>} /> : null }

                        </Switch>
                    </main>
                </BrowserRouter>
                <ToastContainer style={{paddingTop: 100}}/>
            </div>
        )
    }
}
