import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import TechnicianCalendar from '../components/TechnicianCalendar';

export default class SettingsPage extends Component {

    state = {
        loading: false,
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <TechnicianCalendar height={'82vh'} export={true}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
