import React, { Component } from 'react';
import { Tabs, Tab, Tooltip, IconButton, Select, MenuItem, InputLabel, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import moment from 'moment';
import CallMadeIcon from '@material-ui/icons/CallMade';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import WorkIcon from '@material-ui/icons/Work';
import DefaultButton from '../components/DefaultButton';
import DefaultSelect from '../components/DefaultSelect';
import DateFnsUtils from '@date-io/date-fns';
import brLocale from "date-fns/locale/pt-BR";
import DefaultModal from '../components/DefaultModal';
import SessionHelper from '../helper/SessionHelper';
import SATHelper from '../helper/SATHelper';
import { toast } from 'react-toastify';

export default class CompanyMapTabs extends Component {

    state = {
        orderDocs: [],
        companyDocs: [],
        satDocs: [],
        hourPricingDocs: [],
        technicianDocs: [],
        loading: false,
        activeTab: 0,
        satModal: false,
        satPriority: null,
        satOrder: null,
        hour_pricing: null,
        estimated_end_date: null,
        loadingModal: false,
    }
    
    async componentDidMount() {
        await this.getOrders();
        await this.getSATs();
        await this.getHourPricing();
        await this.getTechnicians();
        await this.getCompanies();
    }

    async getCompanies() {

        let query = await Firestore.customQuery('company').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ companyDocs: docs, loading: false });
    }

    async getOrders() {
        
        let query = await Firestore.customQuery('order').where('billing_cnpj', '==', this.props.company.doc.cnpj).orderBy('order_date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ orderDocs: docs });
    }

    async getSATs() {

        let query = await Firestore.customQuery('appointment').where('company_id', '==', this.props.company.id).orderBy('start_date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });
        this.setState({ satDocs: docs });
    }

    async getHourPricing() {

        let query = await Firestore.customQuery('hour_pricing').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ hourPricingDocs: docs, loading: false });
    }

    async getTechnicians() {

        let query = await Firestore.customQuery('technician').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ technicianDocs: docs, loading: false });
    }

    satModal() {
        
        return (
            <div>

                <div style={{marginTop: 30}}>
                    <DefaultSelect search={true} searchField={'order_code'} id={'order_attatchment'} valueField={'id'} displayField={'order_code'} onChange={(v) => { this.setState({ satOrder: v.target.value }) }} docs={this.state.orderDocs} label={'Vincular Pedido'}/>
                </div>
                
                <div style={{marginTop: 30}}>
                    <InputLabel id="demo-simple-select-label">Prioridade</InputLabel>
                    <Select
                    style={{width: '100%'}}
                    labelId="demo-simple-select-label"
                    value={this.state.satPriority}
                    onChange={(v) => { this.setState({ satPriority: v.target.value }) }}>
                            <MenuItem style={{color: 'green'}} value={'low'}>{'Baixa'}</MenuItem>
                            <MenuItem style={{color: 'orange'}} value={'normal'}>{'Normal'}</MenuItem>
                            <MenuItem style={{color: 'red'}} value={'high'}>{'Alta'}</MenuItem>
                    </Select>
                </div>

                <div style={{marginTop: 30}}>
                    <InputLabel id="hour-type">Tipo de Hora</InputLabel>
                    <Select
                    style={{width: '100%'}}
                    labelId="hour-type"
                    value={this.state.hour_pricing}
                    onChange={(v) => { this.setState({ hour_pricing: v.target.value }) }}>
                            { this.state.hourPricingDocs.map((doc, key) => {
                                return <MenuItem key={key} value={doc.id}>{doc.type}</MenuItem>
                            }) }
                    </Select>
                </div>

                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    style={{width: '100%', marginTop: 15}}
                    invalidDateMessage={false}
                    format={'dd/MM/yyyy'}
                    autoOk={true}
                    label="Data Prevista"
                    cancelLabel={'Cancelar'}
                    okLabel={'Confirmar'}
                    onChange={(v) => { this.setState({ estimated_end_date: v }) }}
                    value={this.state.estimated_end_date}
                    />
                </MuiPickersUtilsProvider>

                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addSat() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ satModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>

            </div>
        )
    }

    async addSat(){

        if (this.state.hour_pricing && this.state.estimated_end_date && this.state.satPriority) {

            this.setState({ loadingModal: true })

            try {

                let data = {
                    answered_satisfaction_research: false,
                    attachments: [],
                    company_id: this.props.company.id,
                    end_date: null,
                    estimated_end_date: this.state.estimated_end_date,
                    history: [],
                    hour_pricing_id: this.state.hour_pricing,
                    order_id: this.state.satOrder,
                    priority: this.state.satPriority,
                    satisfaction_research_id: '',
                    start_date: new Date(),
                    status: 'open',
                    technicians: [],
                    order_items: [],
                    title: `Assistência #${moment().format('DDMMYYYYHHmmss')}`,
                    user_id: SessionHelper.getFirebaseAuth().uid,
                };

                let doc = await Firestore.getId('appointment');

                await Firestore.insert(data, 'appointment', doc);
                await SATHelper.historyIteration(doc, data.user_id, 'Usuário criou a SAT');

                await this.setState({ loadingModal: false })
                await this.setState({ SatModal: false });
                toast.success('SAT cadastrado com sucesso');

            } catch (error) {
                console.log(error);
                await this.setState({ loadingModal: false });
                await this.setState({ SatModal: false });
                toast.error('Erro ao cadastrar SAT');
            }

        } else {
            toast.warn('Preencha todos os campos');
        }

    }

    render() {
        return (
            <div>
                <Tabs
                value={this.state.activeTab}
                variant={'fullWidth'}
                TabIndicatorProps={{ style: { backgroundColor: Colors.semil.green } }}
                style={{color: Colors.semil.green}}
                onChange={(evt, value) => { this.setState({ activeTab: value }); }}>

                    <Tab label={'Pedidos'} />
                    <Tab label={'SAT'} />
                    <Tab label={'Contatos'} />

                </Tabs>

                <div role="tabpanel" hidden={this.state.activeTab !== 0}>

                    {this.state.activeTab === 0 && (

                        <div style={{marginTop: 20}}>

                            { this.state.orderDocs.map((item, key) => {
                                return (
                                    <div key={key} style={{marginBottom: 20, borderBottom: '0.5px solid lightgrey', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <p><b>{'Pedido: '}</b>{item.order_code} <b>{'Data Emissão: '}</b> {moment(item.order_date.toDate()).format('DD/MM/YYYY HH:mm')} <b>{'Data Entrega: '}</b> { moment(item.deliver_date.toDate()).format('DD/MM/YYYY HH:mm') } </p>
                                    </div>
                                )

                                }) 
                            }

                            { !this.state.orderDocs.length && <p style={{color: 'grey', textAlign: 'center', marginTop: 50}}>{'Nenhum pedido encontrada.'}</p> }

                        </div>
                    )}

                </div>

                <div role="tabpanel" hidden={this.state.activeTab !== 1}>

                    {this.state.activeTab === 1 && (

                    <div style={{marginTop: 10}}>

                        <div>
                            <DefaultButton onClick={() => { this.setState({ satModal: true }) }} title={'Adicionar SAT'}/>
                        </div>

                        { this.state.satDocs.map((item, key) => {
                            return (
                                <div key={key} style={{marginBottom: 20, borderBottom: '0.5px solid lightgrey', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <p><b>{'Nome: '}</b>{item.title} <Tooltip onClick={() => { window.open(`/chamados?id=${item.id}`, '_blank') }} title="Acessar SAT"><IconButton><CallMadeIcon style={{color: Colors.semil.green}}/></IconButton></Tooltip> <br></br> <b>{'Data Abertura: '}</b> {moment(item.start_date.toDate()).format('DD/MM/YYYY HH:mm')} <b>{'Data Prevista: '}</b> { moment(item.estimated_end_date.toDate()).format('DD/MM/YYYY HH:mm') }</p>
                                </div>
                            )

                            }) 
                        }

                        { !this.state.satDocs.length && <p style={{color: 'grey', textAlign: 'center', marginTop: 50}}>{'Nenhuma SAT encontrada.'}</p> }

                    </div>
                    )}

                </div>

                <div role="tabpanel" hidden={this.state.activeTab !== 2}>

                    {this.state.activeTab === 2 && (

                    <div style={{marginTop: 10}}>

                        { this.props.company.doc.contacts.map((element, key) => {

                        return (
                            <div style={{borderBottom: '0.5px solid lightgrey'}}>
                                { element.contact_name &&
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <PersonIcon style={{color: Colors.semil.green}}/>
                                        <p style={{paddingLeft: 8}}>{`${element.contact_name}`}</p>
                                    </div>
                                }
                                { element.contact_email &&
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <EmailIcon style={{color: Colors.semil.green}}/>
                                        <p style={{paddingLeft: 8}}>{`${element.contact_email}`}</p>
                                    </div>
                                }
                                { element.contact_phone &&
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <PhoneIcon style={{color: Colors.semil.green}}/>
                                        <p style={{paddingLeft: 8}}>{`${element.contact_phone}`}</p>
                                    </div>
                                }
                                { element.contact_role &&
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <WorkIcon style={{color: Colors.semil.green}}/>
                                        <p style={{paddingLeft: 8}}>{`${element.contact_role}`}</p>
                                    </div>
                                }
                            </div>
                        )

                        }) }

                        { !this.props.company.doc.contacts.length && <p style={{color: 'grey', textAlign: 'center', marginTop: 50}}>{'Nenhum contato encontrado.'}</p> }

                    </div>
                    )}

                </div>

                <DefaultModal loading={this.state.loadingModal} content={this.satModal()} title={'Criar nova SAT'} onClose={() => { this.setState({ satModal: false }) }} open={this.state.satModal}/>

            </div>
        )
    }
}