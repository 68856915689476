import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import DefaultInput from '../components/DefaultInput';
import DefaultSelect from '../components/DefaultSelect';
import Storage from '../api/firebase/Storage';
import Functions from '../constants/Functions';

export default class UserPage extends Component {

    state = {
        docs: [],
        permissionDocs: [],
        loading: true,
        loadingModal: false,
        addUserModal: false,
        photoModal: false,
        photoModalURI: '',
        photoBlob: null,
        editId: null,
        name: '',
        email: '',
        permission: '',
        password: '',
        password_confirmation: '',
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('user').where('type', '==', 'admin').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = { name: doc.data().name, email: doc.data().email, photo: doc.data().photo, id_permission_group: doc.data().id_permission_group, id: doc.id };

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    async getPermissions() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('permission_group').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = { name: doc.data().name, id: doc.id };

            docs.push(data);
        });

        this.setState({ permissionDocs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
        this.getPermissions();
    }

    async uploadPhoto() {

        if (this.state.editId && this.state.photoBlob) {

            try {

                this.setState({ loadingModal: true });

                let ref = await Storage.uploadFile('user_images', this.state.photoBlob);
                let downloadPath = await Storage.getFileFromRef(ref);
        
                if (!downloadPath) {
                    throw new Error('upload error');
                }

                await Firestore.update({ photo: downloadPath }, 'user', this.state.editId);
    
                toast.success("Imagem atualizada com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ photoModal: false, loadingModal: false });

                this.getDocs();
    
            } catch (error) {
                toast.error("Houve um problema ao salvar a imagem", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        }
    }

    async addUser() {

        if (this.state.name && this.state.email && this.state.permission && this.state.password) {

            if (this.state.password === this.state.password_confirmation) {

                try {

                    this.setState({ loadingModal: true });
    
                    let headers = new Headers();
                    headers.set('content-type', 'application/json');
    
                    let body = {
                        email: this.state.email,
                        password: this.state.password,
                        doc: {
                            name: this.state.name,
                            email: this.state.email,
                            id_permission_group: this.state.permission,
                            photo: '',
                            type: 'admin',
                        },
                    };
    
                    await fetch(`${Functions.getEndPoint()}/registerCustomer`, {headers: headers, method: 'POST', body: JSON.stringify(body)});
    
                    toast.success('Cadastrado com sucesso');
                    this.setState({ loadingModal: false, name: '', email: '', permission: '', addUserModal: false });

                    this.getDocs();
    
                } catch (error) {

                    toast.error('Erro ao cadastrar usuário');
                    this.setState({ loadingModal: false, name: '', email: '', permission: '', addUserModal: false });
                }
            
            } else {

                toast.warn('As senhas são diferentes');
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    getPermissionLookup() {
        let permissions = this.state.permissionDocs;
        let lookup = {};

        permissions.forEach((permission, key) => {
            lookup[permission.id] = permission.name;
        });

        return lookup;
    }

    photoModal() {

        if (this.state.photoModal) {

            return (
                <div style={{}}>
                    
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        { this.state.photoModalURI ?  <img style={{ paddingTop: 15, paddingBottom: 15, height: 350 }} src={this.state.photoModalURI}/> : <p>{'Técnico sem foto.'}</p> }
                    </div>
    
                    <input
                    accept="image/*"
                    style={{display: 'none'}}
                    id="contained-button-file"
                    type="file"
                    onChange={(v) => { this.setState({ photoBlob: v.target.files[0], photoModalURI: URL.createObjectURL(v.target.files[0]) }) }}
                    />
                    <label htmlFor="contained-button-file">
                        <Button startIcon={<CloudUploadIcon/>} component="span" style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'UPLOAD'}</Button>
                    </label>
    
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.uploadPhoto() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ photoModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    addUserModal() {

        if (this.state.addUserModal) {
            return (
                <div>
                    <DefaultInput onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'}/>
                    <DefaultInput onBlur={(v) => { this.setState({ email: v }) }} label={'E-mail'}/>
                    <DefaultInput type={'password'} onBlur={(v) => { this.setState({ password: v }) }} label={'Senha'}/>
                    <DefaultInput type={'password'} onBlur={(v) => { this.setState({ password_confirmation: v }) }} label={'Confirme a senha'}/>
                    
                    <div style={{paddingTop: 22}}/>
                    <DefaultSelect search={true} searchField={'name'} id={'permission_selection'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ permission: v.target.value }) }} value={this.state.permission} docs={this.state.permissionDocs} label={'Grupo de Permissão'}/>
                    
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.addUser() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ addUserModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultButton onClick={() => { this.setState({ addUserModal: true }) }} title={'Adicionar'}/>
                <DefaultTable
                title={'Usuários'}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'name', editable: false, },
                    { title: 'E-mail', field: 'email', editable: false, },
                    { title: 'Grupo de Permissão', field: 'id_permission_group', lookup: this.getPermissionLookup() },
                    { title: 'Foto', field: 'photo', editable: false, render: rowData => <PhotoCameraIcon onClick={() => { this.setState({ editId: rowData.id, photoModalURI: rowData.photo, photoModal: true }) }} style={{cursor: 'pointer', color: Colors.semil.green}}/> },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let update = {
                            id_permission_group: newData.id_permission_group,
                        };

                        await Firestore.update(update, 'user', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        await Firestore.delete('user', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addUserModal()} title={'Adicionar Usuário'} onClose={() => { this.setState({ addUserModal: false }) }} open={this.state.addUserModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.photoModal()} title={'Foto'} onClose={() => { this.setState({ photoModal: false }) }} open={this.state.photoModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
