import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, InputLabel, Select, MenuItem, Tooltip, IconButton } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import brLocale from "date-fns/locale/pt-BR";
import { MTableToolbar } from 'material-table';
import SessionHelper from '../helper/SessionHelper';
import DefaultInput from '../components/DefaultInput';

export default class WarrantyPage extends Component {

    state = {
        docs: [],
        userDocs: [],
        companyDocs: [],
        loading: true,
        loadingModal: false,
        historyModal: false,
        historyModalData: [],
        orderItems: [],
        warrantyAddModal: false,
        warrantyDate: null,
        warrantyItemKey: null,
        editId: null,
        allItems: false,
        showExpired: false,
        showToExpire: false,
        showWithout: true,
        showClosed: false,
        month: 'all',
        order_date: 'all',
        deliver_date: 'all',
        historyMessage: '',
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('order').where('order_situation', '==', 'O').where('order_status', '==', 5).orderBy('order_date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            let show = true;
            let hasWarrantyOnMonth = false;

            if (data.order_items.length) {

                if (this.state.showToExpire) {

                    data.order_items.forEach((item, key) => {
    
                        if (item.warranty) {

                            let diff = moment(item.warranty.toDate()).diff(moment().startOf('day'));

                            if (diff < 0) {

                                show = false;
                            }

                        } else {

                            show = false;
                        }
                    });
    
                } else if (this.state.showExpired) {
    
                    data.order_items.forEach((item, key) => {
    
                        if (item.warranty) {

                            let diff = moment(item.warranty.toDate()).diff(moment().startOf('day'));

                            if (diff > 0) {

                                show = false;
                            }

                        } else {
                            
                            show = false;
                        }
                    });
    
                } else if (this.state.showWithout) {
    
                    data.order_items.forEach((item, key) => {
    
                        if (item.warranty) {
                            show = false;
                        }
                    });

                } else if (this.state.showClosed) {
    
                    if (!data.warranty_closed) {
                        show = false;
                    }
                }

                if (this.state.order_date >= 0) {

                    let month = moment.months()[this.state.order_date];
                    let date = moment(data.order_date.toDate()).format('MMMM');

                    if (date !== month) {
                        show = false;
                    }
                }

                if (this.state.deliver_date >= 0) {

                    let month = moment.months()[this.state.deliver_date];
                    let date = moment(data.deliver_date.toDate()).format('MMMM');

                    if (date !== month) {
                        show = false;
                    }
                }

                data.order_items.forEach((item, key) => {

                    if (item.warranty && this.state.month >= 0 && !hasWarrantyOnMonth) {

                        let month = moment.months()[this.state.month];
                        let warranty = moment(item.warranty.toDate()).format('MMMM');
        
                        if (warranty !== month) {
                            hasWarrantyOnMonth = true;
                        }
                    }
                });

            } else {

                show = false;
            }

            if (show && ((this.state.month >= 0 && hasWarrantyOnMonth) || (this.state.month === 'all' && !hasWarrantyOnMonth))) {
                docs.push(data);
            }
            
        });

        this.setState({ docs: docs, loading: false });
    }

    async getUsers() {

        let query = await Firestore.customQuery('user').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ userDocs: docs });
    }

    async getCompany() {

        let query = await Firestore.customQuery('company').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ companyDocs: docs });
    }

    async componentDidMount() {

        await this.getCompany();
        await this.getDocs();
        await this.getUsers();
    }

    renderOrderItens(data) {

        if (data.order_items) {

            return (
                <div style={{ paddingLeft: 18 }}>
                    <p style={{ fontWeight: 'bold' }}>{'Garantias do Pedido'}</p>
                    
                    <div style={{paddingBottom: 15}}>
                        <DefaultButton onClick={() => { data.warranty_closed ? window.alert('A garantia desse pedido foi encerrada') : this.setState({ warrantyAddModal: true, editId: data.id, allItems: true }) }} width={'auto'} title={'Cadastrar para todos os itens'}/>
                    </div>

                    { data.order_items.map((item, key) => {

                        let text = item.warranty ? moment(item.warranty.toDate ? item.warranty.toDate() : item.warranty).format('DD/MM/YYYY') : 'Não Informado';
                        let color = 'orange';

                        if (item.warranty) {

                            let diff = moment(item.warranty.toDate ? item.warranty.toDate() : item.warranty).diff(moment().startOf('day'), 'days');
                            let date = diff > 9 ? diff : '0' + diff;
                            
                            if (diff === 0) {
                                
                                text+= ` (Termina hoje)`;

                            } else if (diff > 0) {

                                text+= ` (termina em ${date} dia${diff > 1 ? 's' : ''})`;
                                color = 'green';

                            } else if (diff < 0) {

                                text+= ` (terminou à ${date} dia${diff > 1 ? 's' : ''})`;
                                color = 'red';
                            }
                        }

                        return (
                            <div style={{ marginBottom: 20, borderTop: '0.5px solid lightgrey' }}>
                                <p><b>{'Descrição: '}</b>{item.name} <b>{'Código do item: '}</b> {item.item_code} <b>{'Quantidade: '}</b> {item.quantity_ordered}</p>
                                
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 15}}>
                                    <b>{'Término de Garantia: '}</b>
                                    <div style={{ paddingLeft: 10, color: color, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>{!item.warranty && <ErrorIcon style={{marginRight: 5}}/>}{text}</div>
                                </div>

                                <div style={{paddingBottom: 15}}>
                                    {!item.warranty && <DefaultButton onClick={() => { data.warranty_closed ? window.alert('A garantia desse pedido foi encerrada') : this.setState({ warrantyAddModal: true, warrantyItemKey: key, editId: data.id, allItems: false }) }} title={'Cadastrar'}/>}
                                    
                                    { item.warranty && 
                                        <Tooltip onClick={() => { this.setState({ warrantyDate: moment(item.warranty.toDate ? item.warranty.toDate() : item.warranty), warrantyAddModal: true, warrantyItemKey: key, editId: data.id, allItems: false }) }} style={{color: 'green'}} title="Editar">
                                            <IconButton>
                                                <EditIcon/>
                                            </IconButton>
                                        </Tooltip> 
                                    }

                                    { item.warranty && 
                                        <Tooltip onClick={() => { this.removeWarranty(data.id, key) }} style={{color: 'red'}} title="Remover Garantia">
                                            <IconButton>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip> 
                                    }

                                </div>

                                {!data.order_items[key + 1] && <div style={{ borderBottom: '0.5px solid lightgrey' }} />}
                            </div>
                        )

                    })}

                </div>
            )

        } else {

            return <div />
        }
    }

    async removeWarranty(id, key) {

        if (id && key >= 0) {
            
            let confirm = window.confirm('Tem certeza que deseja remover?');

            if (confirm) {

                let orderKey = this.state.docs.findIndex(item => item.id === id);

                let order = this.state.docs[orderKey];
                order.order_items[key].warranty = null;
                
                if (!order.history) {
                    order.history = [];
                }

                order.history.push({
                    message: 'Garantia removida',
                    item: key,
                    date: new Date(),
                    id_user: SessionHelper.getFirebaseAuth().uid,
                });

                let docs = this.state.docs;
                docs[orderKey] = order;

                await Firestore.update({ order_items: order.order_items, history: order.history }, 'order', id);
                await this.setState({ docs: docs });

                this.forceUpdate();
                toast.success('Garantia removida com sucesso');
            }
        }
    }

    async saveAllWarranty() {

        if (this.state.warrantyDate && this.state.editId) {

            await this.setState({ loadingModal: true });

            let orderKey = this.state.docs.findIndex(item => item.id === this.state.editId);
            let order = this.state.docs[orderKey];

            if (!order.history) {
                order.history = [];
            }

            order.order_items.forEach((item, key) => {
                
                order.order_items[key].warranty = this.state.warrantyDate;

                order.history.push({
                    message: 'Garantia adicionada',
                    warranty: this.state.warrantyDate,
                    item: key,
                    date: new Date(),
                    id_user: SessionHelper.getFirebaseAuth().uid,
                });
            });

            let docs = this.state.docs;
            docs[orderKey] = order;

            await Firestore.update({ order_items: order.order_items, history: order.history }, 'order', this.state.editId);
            await this.setState({ loadingModal: false, warrantyAddModal: false, warrantyDate: null, docs: docs });

            this.forceUpdate();
            toast.success('Garantia cadastrada com sucesso');
        }
    }

    async saveWarranty() {

        if (this.state.warrantyDate && this.state.warrantyItemKey >= 0 && this.state.editId) {

            await this.setState({ loadingModal: true });

            let orderKey = this.state.docs.findIndex(item => item.id === this.state.editId);

            let order = this.state.docs[orderKey];
            order.order_items[this.state.warrantyItemKey].warranty = this.state.warrantyDate;
            
            if (!order.history) {
                order.history = [];
            }

            order.history.push({
                message: 'Garantia adicionada',
                warranty: this.state.warrantyDate,
                item: this.state.warrantyItemKey,
                date: new Date(),
                id_user: SessionHelper.getFirebaseAuth().uid,
            });

            let docs = this.state.docs;
            docs[orderKey] = order;

            await Firestore.update({ order_items: order.order_items, history: order.history }, 'order', this.state.editId);
            await this.setState({ loadingModal: false, warrantyAddModal: false, warrantyDate: null, warrantyItemKey: null, docs: docs });

            this.forceUpdate();
            toast.success('Garantia cadastrada com sucesso');
        }
    }

    async togglePanel(panel) {
        
        let state = this.state;
        let value = !state[panel];

        state.showExpired = false;
        state.showToExpire = false;
        state.showWithout = false;

        state[panel] = value;

        await this.setState(state);
        await this.getDocs();
    }

    addModal() {

        if (this.state.warrantyAddModal) {

            return (
                <div>
                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{ width: '100%' }}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy'}
                            autoOk={true}
                            label="Data"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={(v) => { this.setState({ warrantyDate: v }) }}
                            value={this.state.warrantyDate}
                        />
                    </MuiPickersUtilsProvider>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { !this.state.allItems ? this.saveWarranty() : this.saveAllWarranty() }} style={{ fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ warrantyAddModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    async addManualHistory() {

        if (this.state.historyMessage) {

            try {

                this.setState({ loadingModal: true });

                let history = this.state.historyModalData;

                if (!history) {
                    history = [];
                }

                history.push({
                    message: this.state.historyMessage,
                    date: new Date(),
                    id_user: SessionHelper.getFirebaseAuth().uid,
                    manual: true,
                });

                await Firestore.update({ history: history }, 'order', this.state.editId);

                this.setState({ loadingModal: false, historyMessage: '', historyModalData: history });
                toast.success('Interação adicionada com sucesso');

            } catch (error) {

                toast.error('Erro ao inserir interação');
                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn('Preencha a mensagem');
        }
    }

    historyModal() {

        if (this.state.historyModal) {

            return (
                <div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
                        <DefaultInput value={this.state.historyMessage} label={'Mensagem'} onChange={(v) => { this.setState({ historyMessage: v }) }}/>
                        <Button onClick={() => { this.addManualHistory() }} variant={'contained'} style={{ backgroundColor: Colors.semil.green, fontSize: 30, color: 'white', fontWeight: 'bold', height: 50, marginLeft: 8 }}>{'+'}</Button>
                    </div>

                    {
                        this.state.historyModalData && this.state.historyModalData.length ? this.state.historyModalData.reverse().map((item, key) => {

                            return (
                                <div style={{ borderTop: '0.5px solid lightgrey' }}>
                                    <p><b>Mensagem: </b>{item.message}</p>
                                    { item.warranty && <p><b>Garantia: </b>{moment(item.warranty.toDate ? item.warranty.toDate() : item.warranty).format('DD/MM/YYYY')}</p> }
                                    { item.item >= 0 && <p><b>Item do pedido: </b>{this.state.orderItems[item.item].name}</p> }
                                    <p><b>Data: </b>{moment(item.date.toDate ? item.date.toDate() : item.date).format('DD/MM/YYYY HH:mm')}</p>
                                    { item.id_user && <p><b>Usuário: </b>{this.state.userDocs[this.state.userDocs.findIndex(user => user.id === item.id_user)].name}</p> }

                                    { !this.state.historyModalData[key + 1] && <div style={{ borderBottom: '0.3px solid lightgrey' }} /> }
                                </div>
                            )
                        })
                    : null }

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.setState({ historyModal: false, historyModalData: null }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    renderToolbar(props) {
        return (
            <div>
                <MTableToolbar {...props} />
                <div style={{paddingLeft: 24, display: 'flex', flexDirection: 'row'}}>
                    <div onClick={() => { this.togglePanel('showToExpire') }} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10, backgroundColor: this.state.showToExpire ? Colors.semil.green : '', color: this.state.showToExpire ? '#fff' : Colors.semil.green, border: `2px solid ${Colors.semil.green}`, fontWeight: 'bold', cursor: 'pointer', borderRadius: 25}}>
                        <HourglassEmptyIcon style={{marginRight: 8}}/>
                        <div>{'À Vencer'}</div>
                    </div>
                    <div onClick={() => { this.togglePanel('showExpired') }} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10, marginLeft: 10, backgroundColor: this.state.showExpired ? Colors.semil.green : '', color: this.state.showExpired ? '#fff' : Colors.semil.green, border: `2px solid ${Colors.semil.green}`, fontWeight: 'bold', cursor: 'pointer', borderRadius: 25}}>
                        <AccessTimeIcon style={{marginRight: 8}}/>
                        <div>{'Vencidas'}</div>
                    </div>
                    <div onClick={() => { this.togglePanel('showWithout') }} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10, marginLeft: 10, backgroundColor: this.state.showWithout ? Colors.semil.green : '', color: this.state.showWithout ? '#fff' : Colors.semil.green, border: `2px solid ${Colors.semil.green}`, fontWeight: 'bold', cursor: 'pointer', borderRadius: 25}}>
                        <EditOutlinedIcon style={{marginRight: 8}}/>
                        <div>{'Sem Cadastro'}</div>
                    </div>
                    <div onClick={() => { this.togglePanel('showClosed') }} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10, marginLeft: 10, backgroundColor: this.state.showClosed ? Colors.semil.green : '', color: this.state.showClosed ? '#fff' : Colors.semil.green, border: `2px solid ${Colors.semil.green}`, fontWeight: 'bold', cursor: 'pointer', borderRadius: 25}}>
                        <HighlightOffIcon style={{marginRight: 8}}/>
                        <div>{'Encerradas'}</div>
                    </div>

                    <div style={{width: '252px', marginLeft: 'auto', marginRight: 25}}>
                        <InputLabel style={{}}>Garantia</InputLabel>
                        <Select
                        style={{width: '252px', marginLeft: 'auto', marginRight: 8}}
                        labelId="demo-simple-select-label"
                        value={this.state.month}
                        onChange={ async (v) => { await this.setState({ month: v.target.value }); await this.getDocs() }}>
                                <MenuItem value={'all'}>{'Todos os Meses'}</MenuItem>
                                {
                                    moment.months().map((month, key) => {
                                        return <MenuItem value={key}>{month}</MenuItem>
                                    }) 
                                }
                        </Select>
                    </div>
                    <div style={{width: '252px', marginRight: 8}}>
                        <InputLabel style={{}}>Entrega do Pedido</InputLabel>
                        <Select
                        style={{width: '252px', marginLeft: 'auto', marginRight: 8}}
                        labelId="demo-simple-select-label3"
                        value={this.state.deliver_date}
                        onChange={ async (v) => { await this.setState({ deliver_date: v.target.value }); await this.getDocs() }}>
                                <MenuItem value={'all'}>{'Todos os Meses'}</MenuItem>
                                {
                                    moment.months().map((month, key) => {
                                        return <MenuItem value={key}>{month}</MenuItem>
                                    }) 
                                }
                        </Select>
                    </div>
                    <div style={{width: '252px', marginRight: 8}}>
                        <InputLabel style={{}}>Emissão do Pedido</InputLabel>
                        <Select
                        style={{width: '252px', marginLeft: 'auto', marginRight: 8}}
                        labelId="demo-simple-select-label2"
                        value={this.state.order_date}
                        onChange={ async (v) => { await this.setState({ order_date: v.target.value }); await this.getDocs() }}>
                                <MenuItem value={'all'}>{'Todos os Meses'}</MenuItem>
                                {
                                    moment.months().map((month, key) => {
                                        return <MenuItem value={key}>{month}</MenuItem>
                                    }) 
                                }
                        </Select>
                    </div>
                </div>
            </div>
        )
    }

    async closeWarranty(id) {

        let confirm = window.confirm('Tem certeza que deseja encerrar a garantia desse pedido?');

        if (confirm) {

            let orderKey = this.state.docs.findIndex(item => item.id === id);

            let order = this.state.docs[orderKey];
            order.warranty_closed = true;
            
            if (!order.history) {
                order.history = [];
            }

            order.history.push({
                message: 'Garantia encerrada',
                date: new Date(),
                id_user: SessionHelper.getFirebaseAuth().uid,
            });

            let docs = this.state.docs;
            docs[orderKey] = order;

            await Firestore.update({ warranty_closed: true, history: order.history }, 'order', id);
            await this.setState({ docs: docs });

            this.forceUpdate();

            toast.success('Garantia encerrada com sucesso');
        }
    }

    renderCompany(orderCnpj) {

        let company = this.state.companyDocs[this.state.companyDocs.findIndex(item => item.cnpj === orderCnpj)];

        if (company) {
            return <div>{company.name}</div>
        } else {
            return <div/>
        }
    }

    render() {
        return (
            <div style={styles.container}>
                <DefaultTable
                    actions={[
                        {
                            icon: HighlightOffIcon,
                            tooltip: 'Encerrar Garantia',
                            onClick: (event, rowData) => { !rowData.warranty_closed ? this.closeWarranty(rowData.id) : toast.warn('Garantia já encerrada') }
                        },
                        {
                            icon: HistoryIcon,
                            tooltip: 'Histórico de Alterações',
                            onClick: (event, rowData) => { this.setState({ historyModal: true, historyModalData: rowData.history, orderItems: rowData.order_items, editId: rowData.id }) }
                        },
                    ]}
                    onRowClick={(evt, rowData, togglePanel) => { togglePanel() }}
                    isLoading={this.state.loading}
                    detailPanel={(evt) => { return this.renderOrderItens(evt) }}
                    title={'Garantias'}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Pedido', field: 'order_code' },
                        { title: 'Cliente', field: 'company', render: rowData => this.renderCompany(rowData.billing_cnpj) },
                        { title: 'Garantia Ativa', field: 'warranty_closed', render: rowData => rowData.warranty_closed ? <ClearIcon style={{color: 'red'}}/> : <CheckIcon style={{color: 'green'}}/> },
                        { title: 'Data Emissão', field: 'order_date', render: rowData => moment(rowData.order_date.toDate()).format('DD/MM/YYYY HH:mm') },
                        { title: 'Data Entrega', field: 'deliver_date', render: rowData => moment(rowData.deliver_date.toDate()).format('DD/MM/YYYY HH:mm') },
                        { title: 'Items do Pedido', field: 'order_items', hidden: true },
                    ]}
                    components={{
                        Toolbar: props => ( this.renderToolbar(props) ),
                    }}
                    data={this.state.docs}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Cadastrar Garantia'} onClose={() => { this.setState({ warrantyAddModal: false }) }} open={this.state.warrantyAddModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.historyModal()} title={'Histórico'} onClose={() => { this.setState({ historyModal: false }) }} open={this.state.historyModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
