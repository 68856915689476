import React, { Component } from 'react';
import { Tooltip } from '@material-ui/core';
import Colors from '../constants/Colors';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import Functions from '../constants/Functions';
import DescriptionIcon from '@material-ui/icons/Description';
import moment from 'moment';
import ReCAPTCHA from "react-google-recaptcha";
import DefaultButton from '../components/DefaultButton';
import { toast } from 'react-toastify';
import { Clear, Search } from '@material-ui/icons';

export default class UserManualPage extends Component {

    state = {
        loading: true,
        id: null,
        orderItem: null,
        manual: null,
        name: '',
        email: '',
        captcha: null,
        authLoading: false,
        authSuccess: false,
        search: '',
        files: [],
        auxFiles: [],
    }

    async componentDidMount() {

        await this.getParams();
        await this.getManual();

        this.setState({ loading: false });
    }

    async getManual() {

        let request = await fetch(`${Functions.getEndPoint()}/getManual`, {headers: {"Content-Type": "application/json"}, method: 'POST', body: JSON.stringify({ id: this.state.id }) });
        let json = await request.json();

        let files = [];
        let orderItems = json.manual.order_items;

        if (this.state.orderItem !== null) {

            let orderItemFiles = orderItems[this.state.orderItem].files && orderItems[this.state.orderItem].files.length ? orderItems[this.state.orderItem].files : [];
            files = orderItemFiles;

        } else {

            orderItems.forEach((item, key) => {

                let orderItemFiles = item.files || [];
                files = [...files, ...orderItemFiles];
            });
        }

        json.manual.files = files;

        this.setState({ manual: json.manual, files, auxFiles: files });
    }

    async getParams() {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        
        const id = urlParams.get('id');
        const order_item = urlParams.get('order_item');

        if (id) { await this.setState({ id: id, orderItem: order_item }) }
    }

    async auth() {

        if (this.state.email && this.state.name) {

            if (this.state.captcha) {

                try {
    
                    await this.setState({ authLoading: true });
        
                    let ipRequest = await fetch('https://jsonip.com', { mode: 'cors' })
                    let ipJson = await ipRequest.json();

                    await fetch(`${Functions.getEndPoint()}/manualAuth`, {headers: {"Content-Type": "application/json"}, method: 'POST', body: JSON.stringify({ name: this.state.name, email: this.state.email, id: this.state.id, ip: ipJson.ip || '' }) });

                    this.setState({ authLoading: false, email: '', name: '', captcha: null, authSuccess: true });
        
                } catch (error) {
        
                    this.setState({ authLoading: false });
                    toast.error('Houve um problema ao validar os dados, entre em contato com o suporte');
                }
                
            } else {
    
                toast.warn('Preencha o captcha corretamente');
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    clearSearch() {

        this.setState({ search: '', files: this.state.auxFiles });
    }

    handleSearch(text) {

        this.setState({ search: text });

        if (text.length) {

            let files = this.state.files;
            let result = [];

            files.forEach(file => {
                if (file.name.toLowerCase().indexOf(text.toLowerCase()) >= 0) {
                    result.push(file);
                }
            });

            this.setState({ files: result });

        } else {

            this.clearSearch();
        }
    }

    renderLoading() {
        return (
            <ClipLoader
            css={css`position:absolute;top:38vh;left:49%`}
            size={50}
            color={'white'}
            loading={true}
            />
        );
    }

    renderSearch() {
        return (
            <div style={{ padding: 15, display: 'flex', borderRadius: 10, backgroundColor: '#FFF', shadowColor: "#000", shadowOffset: { width: 0, height: 2, }, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 5, }}>
                <input value={this.state.search} onChange={(evt) => { this.handleSearch(evt.target.value) }} style={{ height: '100%', width: '95%', paddingTop: 5, paddingBottom: 5, marginRight: 5, paddingLeft: 15, paddingRight: 15, fontSize: 20, border: 'none', borderRadius: 10 }} placeholder={'Busque aqui...'} />
                {this.state.search.length ? <Clear onClick={() => { this.clearSearch() }} style={{ fontSize: 34, cursor: 'pointer', marginRight: 5 }} /> : <Search style={{ fontSize: 34, cursor: 'pointer', marginRight: 5 }} />}
            </div>
        )
    }

    renderContent() {

        return (

            <div>
                <img style={{ height: 50, width: 200, marginBottom: 25, marginTop: 25 }} src={process.env.PUBLIC_URL + '/assets/image/semil white.png'} />
                <div style={{ display: 'flex', flexDirection: 'column', gap: 25 }}>
                    { this.renderSearch() }
                    {
                        this.state.files.map((file, key) => {

                            return (
                                <div onClick={() => { window.open(file.link, '_blank') }} style={{ position: 'relative', backgroundColor: 'white', color: Colors.semil.green, display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer', justifyContent: 'center', width: '100%', minHeight: 125, borderRadius: 8 }}>
                                    <DescriptionIcon style={{ fontSize: 40, marginBottom: 8 }}/>
                                    <Tooltip title={file.name}>
                                        <div style={{ fontWeight: 'bold' }}>{file.name.length > 15 ? file.name.slice(0, 15) + '...' : file.name}</div>
                                    </Tooltip>
                                    <div style={{ fontSize: 10, fontStyle: 'italic', right: 0, bottom: 0, marginRight: 8, marginBottom: 8, position: 'absolute' }}>{moment(file.date.toDate ? file.date.toDate() : file.date).format('DD/MM/YYYY HH:mm')}</div>
                                </div>
                            );
                        })
                    }
                    { !this.state.files.length ?
                
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p style={{ color: 'white' }}>Nenhum manual encontrado para esse item.</p>
                        </div>
                    
                    : null }
                </div>
            </div>
        )
    }

    renderAuth() {

        return (
            <div>
                <img style={{ height: 50, width: 200, marginBottom: 25, marginTop: 25 }} src={process.env.PUBLIC_URL + '/assets/image/semil white.png'} />
                <h2 style={{ color: 'white' }}>Identificação</h2>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <input tabIndex={1} onBlur={(v) => { this.setState({ name: v.target.value }) }} style={{ padding: 15, fontSize: 14, borderRadius: 5, border: '0px solid red' }} placeholder={'Nome'}/>
                    <input tabIndex={2} onBlur={(v) => { this.setState({ email: v.target.value }) }} style={{ padding: 15, fontSize: 14, borderRadius: 5, border: '0px solid red', marginTop: 15 }} placeholder={'E-mail'}/>
                    <div tabIndex={3} style={{ marginBottom: 50, marginTop: 20 }}>
                        <ReCAPTCHA
                            sitekey="6LdJ1M0ZAAAAANMwNSJIkK--NHKYYSQyD8aZ3m0u"
                            onChange={(v) => { this.setState({ captcha: v }) }}
                        />
                    </div>
                    <DefaultButton loading={this.state.authLoading} color={'#399e4b'} title={'Acessar Manual'} onClick={() => { this.auth() }}/>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div style={{ height: '100vh', display: 'flex',  flexDirection: 'column', padding: 25, backgroundColor: Colors.semil.green }}>
                { this.state.loading ? this.renderLoading() : this.state.authSuccess ? this.renderContent() : this.renderAuth() }
            </div>
        );
    }
}
 