import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import Colors from '../constants/Colors';
import { Card, IconButton, Tooltip } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import moment from 'moment';
import { Doughnut, Bar } from 'react-chartjs-2';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import RefreshIcon from '@material-ui/icons/Refresh';
import { momentLocalizer } from 'react-big-calendar';
import ComplaintTypes from '../constants/ComplaintTypes';

moment.locale('pt-br');
const localizer = momentLocalizer(moment);

export default class ComplaintDashboard extends Component {

    state = {
        loading: true,
        finishedComplaintChart: [],
        finishedComplaint_start: moment().startOf('month').startOf('day').toDate(),
        finishedComplaint_end: moment().endOf('month').endOf('day').toDate(),
        complaintByTypeChart: {},
        complaintByTypeChart_start: moment().startOf('month').startOf('day').toDate(),
        complaintByTypeChart_end: moment().endOf('month').endOf('day').toDate(),
    }

    async componentDidMount() {

        this.setState({ loading: true });

        await this.finishedComplaintChart();
        await this.complaintByTypeChart();

        this.setState({ loading: false });
    }

    async finishedComplaintChart() {

        let data = {
            open: 0,
            closed: 0,
        };

        let docs = [];
        let query = await Firestore.customQuery('complaint').where('date', '>=', this.state.finishedComplaint_start).where('date', '<=', this.state.finishedComplaint_end).get();

        query.forEach((doc, key) => {

            docs.push({ ...doc.data(), id: doc.id });
        });

        docs.forEach((complaint, key) => {

            if (complaint.finished) {
                data.closed++;

            } else {

                data.open++;
            }
        });

        this.setState({ finishedComplaintChart: [data.open, data.closed] });
    }

    async complaintByTypeChart() {

        let data = {
            labels: [],
            datasets: [
                {
                    label: 'Quantidade de Denúncias',
                    backgroundColor: '#36A2EB',
                    borderWidth: 1,
                    data: []
                }
            ]
        };

        let docs = [];
        let query = await Firestore.customQuery('complaint').where('date', '>=', this.state.finishedComplaint_start).where('date', '<=', this.state.finishedComplaint_end).get();
        let complaintByTypeChart = {};

        query.forEach((doc, key) => {

            docs.push({ ...doc.data(), id: doc.id });
        });

        docs.forEach((complaint, key) => {

            if (complaint.subject) {

                if (complaintByTypeChart[complaint.subject] === undefined) {

                    complaintByTypeChart[complaint.subject] = 0;
                    data.labels.push(ComplaintTypes[complaint.subject]);
                }

                complaintByTypeChart[complaint.subject]++;
            }
        });

        Object.keys(complaintByTypeChart).forEach((value, key) => {

            data.datasets[0].data.push(complaintByTypeChart[value]);
        });

        this.setState({ complaintByTypeChart: data });
    }

    renderEmptyChart() {
        return <div style={{ position: 'relative', bottom: 100, color: 'grey' }}>{'Nenhum dado a ser exibido.'}</div>
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10 }}>
                <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'calc(50% - 10px)' }}>

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: 20 }}>{'Denúncias em Aberto'}</div>
                        <Tooltip onClick={async () => { await this.finishedComplaintChart() }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                    </div>

                    <Doughnut
                        height={95}
                        options={{ maintainAspectRatio: true }}
                        data={{
                            labels: [
                                'Pendentes',
                                'Concluídas',
                            ],
                            datasets: [{
                                data: this.state.finishedComplaintChart,
                                backgroundColor: [
                                    'lightgrey',
                                    Colors.semil.green,
                                ],

                            }]
                        }}
                    />
                    {!this.state.finishedComplaintChart[0] > 0 && !this.state.finishedComplaintChart[1] > 0 && !this.state.finishedComplaintChart[2] > 0 ? this.renderEmptyChart() : null}
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', alignSelf: 'flex-end', marginTop: 20 }}>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ width: '100%', padding: 15 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy'}
                                autoOk={true}
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={async (v) => { await this.setState({ finishedComplaint_start: v }); this.finishedComplaintChart(); }}
                                value={this.state.finishedComplaint_start}
                            />
                        </MuiPickersUtilsProvider>
                        <div style={{ color: 'grey' }}>{'até'}</div>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ width: '100%', padding: 15 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy'}
                                autoOk={true}
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={async (v) => { await this.setState({ finishedComplaint_end: v }); this.finishedComplaintChart(); }}
                                value={this.state.finishedComplaint_end}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </Card>

                <Card style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: 20 }}>{'Denúncias por Tipo'}</div>
                        <Tooltip onClick={async () => { await this.complaintByTypeChart() }} title="Atualizar"><IconButton><RefreshIcon style={{}} /></IconButton></Tooltip>
                    </div>

                    <Bar
                        height={95}
                        options={{ maintainAspectRatio: true, scales: { yAxes: [{ ticks: { beginAtZero: true, precision: 0 } }] } }}
                        data={this.state.complaintByTypeChart} />
                    {!this.state.complaintByTypeChart.datasets[0].data.length ? this.renderEmptyChart() : null}
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', alignSelf: 'flex-end', marginTop: 20 }}>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ width: '100%', padding: 15 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy'}
                                autoOk={true}
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={async (v) => { await this.setState({ complaintByTypeChart_start: v }); this.complaintByTypeChart(); }}
                                value={this.state.complaintByTypeChart_start}
                            />
                        </MuiPickersUtilsProvider>
                        <div style={{ color: 'grey' }}>{'até'}</div>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ width: '100%', padding: 15 }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy'}
                                autoOk={true}
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={async (v) => { await this.setState({ complaintByTypeChart_end: v }); this.complaintByTypeChart(); }}
                                value={this.state.complaintByTypeChart_end}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    </Card>
            </div>
        );
    }
}
