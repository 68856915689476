import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, InputLabel, Select, MenuItem } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import DefaultSelect from '../components/DefaultSelect';
import RelationField from '../components/RelationField';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import moment from 'moment';
import SessionHelper from '../helper/SessionHelper';

export default class HighlightPage extends Component {

    state = {
        docs: [],
        productDocs: [],
        product: null,
        order: 0,
        loading: true,
        loadingModal: false,
        addModal: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('highlight').orderBy('order', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async getProducts() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('product').orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ productDocs: docs });
    }

    async componentDidMount() {

        await this.getProducts();
        await this.getDocs();

        this.setState({ loading: false });
    }

    async highlightExists(idProduct) {

        let query = await Firestore.customQuery('highlight').where('id_product', '==', idProduct).limit(1).get();
        return query.size;
    }

    async addHighlight() {

        if (this.state.product && !isNaN(this.state.order)) {

            this.setState({ loadingModal: true });
            
            if (await (this.highlightExists(this.state.product))) {

                this.setState({ loadingModal: false });
                toast.warn('Esse produto já está nos destaques');
                return;
            }

            let data = {
                id_product: this.state.product,
                id_user: SessionHelper.getFirebaseAuth().uid,
                order: this.state.docs.length + 1,
                date: new Date(),
            };

            try {

                await Firestore.insert(data, 'highlight');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, addModal: false, product: null, order: 0 });
                await this.getDocs();

                this.setState({ loading: false });

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha o produto", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    async handleReorder(direction, id) {

        try {
            
            if (this.state.loading) {
                toast.info('Aguarde, carregando...');
                return;
            }
    
            if (direction === -1 && this.state.docs[0].id === id) {
                return;
            }
    
            if (direction === 1 && this.state.docs[this.state.docs.length - 1].id === id) {
                return;
            }
    
            this.setState({ loading: true });
    
            let docs = [...this.state.docs];
    
            let index = docs.findIndex(item => item.id === id);
            let indexAux = index + direction;
            
            let doc = docs[index];
            let docAux = docs[indexAux];
    
            docs[index] = docAux;
            docs[indexAux] = doc;
    
            await Firestore.update({ order: index + 1 }, 'highlight', docAux.id);
            await Firestore.update({ order: indexAux + 1 }, 'highlight', doc.id);
    
            await this.getDocs();
    
            this.setState({ loading: false });
            toast.success('Produto reordenado com sucesso');

        } catch (error) {
            
            toast.error('Houve um problema ao reordenar o produto');
            this.setState({ loading: false });
        }
    }

    async handleReorderRemove(removedAt) {

        for (let index = removedAt; index < this.state.docs.length; index++) {
            const doc = this.state.docs[index];
            await Firestore.update({ order: doc.order - 1 }, 'highlight', doc.id);
        }

        await this.getDocs();

        this.setState({ loading: false });
    }

    addModal() {
        return (
            <div>
                <DefaultSelect search={true} searchField={'name'} id={'product'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ product: v.target.value }) }} value={this.state.product} docs={this.state.productDocs} label={'Produto'}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addHighlight() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultButton onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>

                <DefaultTable
                title={'Destaques'}
                actions={[
                    {
                        icon: ArrowUpward,
                        tooltip: 'Reordenar',
                        onClick: (event, rowData) => { this.handleReorder(-1, rowData.id) }
                    },
                    {
                        icon: ArrowDownward,
                        tooltip: 'Reordenar',
                        onClick: (event, rowData) => { this.handleReorder(1, rowData.id) } 
                    },
                ]}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Ordem', field: 'order', editable: false },
                    { title: 'Produto', field: 'id_product', render: rowData => <RelationField loading={false} render={(doc) => doc.name} id={rowData.id_product} collection={'product'} field={'name'}/>, editComponent: props => <DefaultSelect search={true} searchField={'name'} id={'product_edit'} valueField={'id'} displayField={'name'} onChange={(v) => { props.onChange(v.target.value) }} value={props.value} docs={this.state.productDocs} label={''}/> },
                    { title: 'Data', field: 'date', render: rowData => <div>{moment(rowData.date.seconds ? new Date(rowData.date.seconds * 1000) : rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('DD/MM/YYYY HH:mm')}</div>, editable: false },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let update = {
                            id_product: newData.id_product,
                        };

                        await Firestore.update(update, 'highlight', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    let index = prev.indexOf(oldData);
                    prev.splice(index, 1);
                    
                    this.setState({ docs: prev, loading: true });

                    if (oldData.id) {
                        await Firestore.delete('highlight', oldData.id);
                        await this.handleReorderRemove(index);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Destaque'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
