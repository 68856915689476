import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import moment from 'moment';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';

export default class HourPricingPage extends Component {

    state = {
        docs: [],
        email: '',
        password: '',
        host: '',
        port: '',
        loading: true,
        loadingModal: false,
        addModal: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('email_account').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = { email: doc.data().email, password: doc.data().password, host: doc.data().host, port: doc.data().port, id: doc.id };

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
    }

    async addEmail() {

        if (this.state.email && this.state.password) {

            let data = {
                email: this.state.email,
                password: this.state.password,
                host: this.state.host,
                port: this.state.port,
            };

            try {

                this.setState({ loadingModal: true });

                await Firestore.insert(data, 'email_account');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ addModal: false, email: '', password: '', host: '', port: '' });
                await this.getDocs();

                this.setState({ loadingModal: false });

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    addModal() {
        return (
            <div>
                <DefaultInput onBlur={(v) => { this.setState({ email: v }) }} label={'E-mail'}/>
                <DefaultInput type={'password'} onBlur={(v) => { this.setState({ password: v }) }} label={'Senha'}/>
                <DefaultInput onBlur={(v) => { this.setState({ host: v }) }} label={'Host'}/>
                <DefaultInput onBlur={(v) => { this.setState({ port: v }) }} label={'Porta'}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addEmail() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultButton onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>

                <DefaultTable
                title={'Contas de E-mail'}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'E-mail', field: 'email' },
                    { title: 'Senha', field: 'password', render: rowData => (<div>{rowData.password.replace(/./g, '•')}</div>)},
                    { title: 'Host', field: 'host' },
                    { title: 'Porta', field: 'port' },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let update = {
                            email: newData.email,
                            password: newData.password,
                            host: newData.host,
                            port: newData.port,
                        };

                        await Firestore.update(update, 'email_account', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        await Firestore.delete('email_account', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />

                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Nova Conta de E-mail'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
