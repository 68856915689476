import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DescriptionIcon from '@material-ui/icons/Description';
import Firestore from '../api/firebase/Firestore';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import moment from 'moment';
import EstadosCidades from '../constants/EstadosCidades';
import ComplaintTypes from '../constants/ComplaintTypes';
import { MTableToolbar } from 'material-table';
import { Archive, CheckCircle } from '@material-ui/icons';
import Colors from '../constants/Colors';
import SessionHelper from '../helper/SessionHelper';
import DefaultModal from '../components/DefaultModal';
import { Button, TextareaAutosize, FormControlLabel, Checkbox } from '@material-ui/core';
import DefaultInput from '../components/DefaultInput';
import Functions from '../constants/Functions';

export default class ComplaintPage extends Component {

    state = {
        docs: [],
        loading: true,
        loadingModal: false,
        showArchived: false,
        finishComplaintModal: false,
        editData: null,
        obs: '',
        sendEmail: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('complaint').orderBy('date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = { ...doc.data(), id: doc.id };

            if (this.state.showArchived && data.finished) {
             
                docs.push(data);
            
            } else if (!this.state.showArchived && !data.finished) {

                docs.push(data);
            }
        });

        this.setState({ docs: docs, loading: false });
    }

    async componentDidMount() {

        await this.getDocs();
    }

    renderUf(ufKey) {

        let uf = '';

        if (EstadosCidades.estados[ufKey]) {

            uf = EstadosCidades.estados[ufKey].nome;
        }

        return uf;
    }

    renderCity(ufKey, cityKey) {

        let city = '';

        if (EstadosCidades.estados[ufKey] && EstadosCidades.estados[ufKey].cidades[cityKey]) {

            city = EstadosCidades.estados[ufKey].cidades[cityKey];
        }

        return city;
    }

    renderStatus(finished) {

        let text = '';
        let color = '';

        if (finished) {

            color = 'green';
            text = 'Finalizado';

        } else {

            color = 'orange';
            text = 'Pendente';
        }

        return (
            <div style={{ backgroundColor: color, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5, width: 250, display: 'inline' }}>
                <b>{text}</b>
            </div>
        )
    }

    statusSort(a, b) {

        return (a.finished === b.finished) ? 0 : a.finished ? -1 : 1;
    } 

    async showArchived() {
        await this.setState({ showArchived: !this.state.showArchived}, async () => await this.getDocs());
    }

    async finish() {

        if (this.state.editData) {

            if (this.state.obs) {

                try {

                    this.setState({ loadingModal: true });
        
                    await Firestore.update({ obs: this.state.obs, finished: true, finished_by: SessionHelper.getFirebaseAuth().uid, finished_date: new Date() }, 'complaint', this.state.editData.id);

                    if (this.state.sendEmail) {

                        let headers = new Headers();
                        headers.set('content-type', 'application/json');

                        await fetch(`${Functions.getEndPoint()}/sendComplaintFinishedEmail`, { method: 'POST', headers: headers, body: JSON.stringify({ email: this.state.editData.email, id: this.state.editData.id }) });
                    }

                    await this.getDocs();
                    
                    this.setState({ loadingModal: false, finishComplaintModal: false });
                    toast.success('Denúncia finalizada com sucesso');
        
                } catch (error) {
        
                    this.setState({ loadingModal: false });
                    toast.error('Houve um problema ao finalizar a denúncia');
                }
            
            } else {

                toast.warn('Informe a resolução do problema');
            }
        }
    }

    finishComplaintModal() {

        if (this.state.finishComplaintModal && this.state.editData) {
            return (
                <div>
                    <TextareaAutosize
                        rowsMax={8}
                        rowsMin={8}
                        placeholder="Informe o que foi feito para solucionar o problema..."
                        style={{width: '100%', fontSize: 'inherit', padding: 10, borderRadius: 8, border: `0.7px solid grey`}}
                        onBlur={(evt) => { this.setState({ obs: evt.target.value }) }}
                    />
                    {
                        this.state.editData.email ?
                        
                        <FormControlLabel
                            control={<Checkbox style={{color: Colors.semil.green}} checked={this.state.sendEmail} onChange={(v) => { this.setState({ sendEmail: v.target.checked }) }}/>}
                            label="Enviar e-mail de retorno"
                        />
                        
                        : null
                    }
                    {
                        this.state.sendEmail ?
                        
                        <div style={{ marginTop: 10 }}>
                            <DefaultInput value={this.state.editData.email} disabled={true} label={'E-mail'}/>
                        </div>

                        : null
                    }
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.finish() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ finishComplaintModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    renderToolbar(props) {
        return (
            <div>
                <MTableToolbar {...props} />
                <div style={{paddingLeft: 24, display: 'flex', flexDirection: 'row'}}>
                    <div onClick={() => { this.showArchived() }} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10, backgroundColor: this.state.showArchived ? Colors.semil.green : '', color: this.state.showArchived ? '#fff' : Colors.semil.green, border: `2px solid ${Colors.semil.green}`, fontWeight: 'bold', cursor: 'pointer', borderRadius: 25}}>
                        <Archive style={{marginRight: 8}}/>
                        <div>{'Finalizadas'}</div>
                    </div>
                </div>
            </div>
        )
    }


    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultTable
                title={'Denúncias'}
                actions={[
                    (rowData) => rowData.file ?
                        {
                            icon: DescriptionIcon,
                            tooltip: 'Visualizar Anexo',
                            onClick: (event, rowData) => {  window.open(rowData.file, '_blank') } 
                        }
                    : null,
                    (rowData) => !rowData.finished ?
                        {
                            icon: CheckCircle,
                            tooltip: 'Marcar como Concluído',
                            onClick: (event, rowData) => {  this.setState({ editData: rowData, obs: '', finishComplaintModal: true }) } 
                        }
                    : null,
                ]}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Protocolo', field: 'protocol', editable: false, },
                    { title: 'Status', field: 'finished', editable: false, render: rowData => this.renderStatus(rowData.finished), customSort: (row, value) => this.statusSort(row, value) },
                    { title: 'Tipo de Relato', field: 'subject', editable: false, render: rowData => <div>{ComplaintTypes[rowData.subject]}</div> },
                    { title: 'Relato', field: 'report', editable: false, },
                    { title: 'Estado', field: 'uf', render: rowData => this.renderUf(rowData.uf), editable: false, },
                    { title: 'Cidade', field: 'city', render: rowData => this.renderCity(rowData.uf, rowData.city), editable: false, },
                    { title: 'E-mail', field: 'email', render: rowData => <div>{rowData.email ? rowData.email : 'Usuário Anônimo'}</div> },
                    { title: 'Telefone', field: 'phone', render: rowData => <div>{rowData.phone ? rowData.phone : 'Usuário Anônimo'}</div> },
                    { title: 'Data da Ocorrência', field: 'report_date', render: rowData => <div>{moment(rowData.report_date.toDate()).format('DD/MM/YYYY')}</div>, editable: false, },
                    { title: 'Data', field: 'date', render: rowData => <div>{moment(rowData.date.toDate()).format('DD/MM/YYYY HH:mm')}</div>, editable: false, },
                ]}
                data={this.state.docs}
                components={{
                    Toolbar: props => ( this.renderToolbar(props) ),
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.finishComplaintModal()} title={'Concluir Denúncia'} onClose={() => { this.setState({ finishComplaintModal: false }) }} open={this.state.finishComplaintModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
