import React, { Component } from 'react';
import { Switch, Route, BrowserRouter } from "react-router-dom";
import LoginPage from '../page/LoginPage';
import { ToastContainer } from 'react-toastify';
import RegisterPage from '../page/RegisterPage';
import UserManualPage from '../page/UserManualPage';

export default class LoginNavigator extends Component {
    render() {
        return (
            <BrowserRouter forceRefresh={true}>
                <Switch>
                    <Route exact path="/" render={() => <LoginPage/> } />
                    <Route path="/register" render={() => <RegisterPage/> } />
                    <Route path="/user_manual" render={() => <UserManualPage/> } />
                </Switch>
                <ToastContainer/>
            </BrowserRouter>
        )
    }
}
