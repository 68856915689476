export default {
    '1': 'Corrupção, fraude e desvios',
    '2': 'Venda de vantagens indevidas',
    '3': 'Sonegação; produtos de origem duvidosa',
    '4': 'Trabalho não declarado ou forçado',
    '5': 'Agressão ao meio ambiente; poluição',
    '6': 'Violência; assédio',
    '7': 'Racismo; homofobia',
    '8': 'Tráfico',
    '9': 'Outros',
    '10': 'Sugestão ou Elogio',
}