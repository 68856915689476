import React from "react";
import FirebaseConfig from './constants/Firebase';
import FirebaseConfigTest from './constants/FirebaseTest';
import * as firebase from 'firebase';
import LoginNavigator from './navigation/LoginNavigator';
import RootNavigator from './navigation/RootNavigator';
import CustomerNavigator from './navigation/CustomerNavigator';
import DefaultLoader from './components/DefaultLoader';
import SessionHelper from './helper/SessionHelper';
import Firestore from './api/firebase/Firestore';
import SurveryPage from "./page/SurveryPage";
import TechnicianNavigator from "./navigation/TechnicianNavigator";

let config = isDevelopmentMode() ? FirebaseConfigTest : FirebaseConfig;
firebase.initializeApp(config);

function isDevelopmentMode(){ 
    return '_self' in React.createElement('div');
}

function authUser() {
    return new Promise(function (resolve, reject) {
       firebase.auth().onAuthStateChanged( async (user) => {

            if (user) {
                SessionHelper.setFirebaseAuth(user);

                let userDoc = await Firestore.getDoc('user', user.uid);
                let data;

                if (userDoc.exists) {

                    data = userDoc.data();

                    if (userDoc.data().id_permission_group) {
                        
                        let permission = await Firestore.getDoc('permission_group', userDoc.data().id_permission_group);

                        if (permission.exists) {

                            data.permission = permission.data();
                        }
                    }

                    SessionHelper.setData(data)
                }

            } else {

                SessionHelper.destroy();
            }

            resolve(user);            
       });
    });
 }

export default class  App extends React.Component {

    state = {
        isAuthenticating: true,
        user: null,
        survery: this.checkSurvery(),
    }

    checkSurvery() {

        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('survery');
    }

    componentDidMount() {

        authUser().then((user) => {
           this.setState({ isAuthenticating: false, user: user });
        }, (error) => {
           this.setState({ isAuthenticating: false });
           alert(error);
        });
    }

    renderRootNavigator() {
        if(SessionHelper.getData().type === 'admin') return <RootNavigator/>;
        if(SessionHelper.getData().type === 'technician') return <TechnicianNavigator/>;
        if(SessionHelper.getData().type === 'customer') return <CustomerNavigator/>;

        return <LoginNavigator/>;
    }

    render() {

        if (this.state.isAuthenticating) return <DefaultLoader/>;

        if (!this.state.survery) {

            return this.state.user ? this.renderRootNavigator() : <LoginNavigator/>;

        } else {

            return <SurveryPage/>
        }
    }
}