import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, InputLabel, Select, MenuItem } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoIcon from '@material-ui/icons/Photo';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import Storage from '../api/firebase/Storage';
import ReactPlayer from 'react-player/lazy';
import { Movie } from '@material-ui/icons';

export default class ProductPage extends Component {

    state = {
        docs: [],
        name: '',
        description: '',
        ytLink: '',
        category: [],
        productImage: '',
        productVideo: '',
        blobImage: null,
        blobVideo: null,
        loading: true,
        loadingModal: false,
        photoModalURI: null,
        videoModalURI: null,
        photoModal: false,
        videoModal: false,
        addModal: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('product').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
    }

    async addProduct() {

        if (this.state.name && this.state.blobImage && this.state.category) {

            this.setState({ loadingModal: true });
            
            let ref = await Storage.uploadFile('product_images', this.state.blobImage);
            let downloadPath = await Storage.getFileFromRef(ref);

            if (!downloadPath) {
                throw new Error('upload error');
            }

            let data = {
                name: this.state.name,
                description: this.state.description,
                youtube_link: this.state.ytLink ? this.state.ytLink : '',
                category: this.state.category,
                photo: downloadPath,
                date: new Date(),
            };

            if (this.state.blobVideo) {

                let refVideo = await Storage.uploadFile('product_videos', this.state.blobVideo);
                let downloadPathVideo = await Storage.getFileFromRef(refVideo);
                data.video_link = downloadPathVideo;
            }

            try {

                await Firestore.insert(data, 'product');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, addModal: false, name: '', description: '', youtube_link: '', productImage: '', blobImage: null, productVideo: '', blobVideo: null, category: [], });
                await this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha ao menos o Nome, Categoria, e Imagem do produto", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    addModal() {
        return (
            <div>
                <DefaultInput onBlur={(v) => { this.setState({ name: v }) }} label={'Nome do Produto'}/>
                <DefaultInput onBlur={(v) => { this.setState({ description: v }) }} label={'Descrição'}/>
                <DefaultInput onBlur={(v) => { this.setState({ ytLink: v }) }} label={'Link do YouTube'}/>

                <div style={{marginTop: 30}}>
                    <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                    <Select
                    multiple={true}
                    style={{width: '100%'}}
                    labelId="category"
                    value={this.state.category}
                    onChange={(v) => { this.setState({ category: v.target.value }) }}>
                            <MenuItem value={'aves'}>{'Aves'}</MenuItem>
                            <MenuItem value={'peixes'}>{'Peixes'}</MenuItem>
                            <MenuItem value={'industrializados'}>{'Industrializados'}</MenuItem>
                            <MenuItem value={'embalagens'}>{'Embalagens'}</MenuItem>
                            <MenuItem value={'suinos'}>{'Suinos'}</MenuItem>
                            <MenuItem value={'bovinos'}>{'Bovinos'}</MenuItem>
                            <MenuItem value={'customizados'}>{'Projetos Customizados'}</MenuItem>
                    </Select>
                </div>

                <InputLabel style={{paddingTop: 15}} htmlFor="upload-button">Imagem</InputLabel>
                { this.state.productImage ? <img style={{ paddingTop: 15, paddingBottom: 15, height: 250 }} src={this.state.productImage}/> : <p style={{paddingTop: 30, paddingBottom: 30, textAlign: 'center'}}>{'Nenhuma imagem selecionada.'}</p> }

                <input
                accept="image/*"
                style={{display: 'none'}}
                id="contained-button-file"
                type="file"
                onChange={(v) => { this.setState({ blobImage: v.target.files[0], productImage: URL.createObjectURL(v.target.files[0]) }) }}
                />
                <label htmlFor="contained-button-file">
                    <Button startIcon={<CloudUploadIcon/>} component="span" style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'UPLOAD'}</Button>
                </label>
                
                <InputLabel style={{paddingTop: 15}} htmlFor="upload-button">Vídeo</InputLabel>
                { this.state.productVideo ? <p style={{paddingTop: 30, paddingBottom: 30, textAlign: 'center'}}>{this.state.productVideo}</p> : <p style={{paddingTop: 30, paddingBottom: 30, textAlign: 'center'}}>{'Nenhum vídeo selecionado.'}</p> }

                <input
                accept="video/mp4,video/x-m4v,video/*"
                style={{display: 'none'}}
                id="video-upload"
                type="file"
                onChange={(v) => { this.setState({ blobVideo: v.target.files[0], productVideo: v.target.files[0].name }) }}
                />
                <label htmlFor="video-upload">
                    <Button startIcon={<CloudUploadIcon/>} component="span" style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'UPLOAD'}</Button>
                </label>

                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addProduct() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    async uploadPhoto() {

        if (this.state.editId && this.state.photoBlob) {

            try {

                this.setState({ loadingModal: true });

                if (this.state.editData && this.state.editData.photo) {

                    await Storage.removeFile(this.state.editData.photo);
                }

                let ref = await Storage.uploadFile('product_images', this.state.photoBlob);
                let downloadPath = await Storage.getFileFromRef(ref);
        
                if (!downloadPath) {
                    throw new Error('upload error');
                }

                await Firestore.update({ photo: downloadPath }, 'product', this.state.editId);
    
                toast.success("Imagem atualizada com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ photoModal: false, loadingModal: false });

                this.getDocs();
    
            } catch (error) {
                toast.error("Houve um problema ao salvar a imagem", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        }
    }

    async uploadVideo() {

        if (this.state.editId && this.state.videoBlob) {

            try {

                this.setState({ loadingModal: true });

                if (this.state.editData && this.state.editData.video_link) {

                    await Storage.removeFile(this.state.editData.video_link);
                }

                let ref = await Storage.uploadFile('product_videos', this.state.videoBlob);
                let downloadPath = await Storage.getFileFromRef(ref);
        
                if (!downloadPath) {
                    throw new Error('upload error');
                }

                await Firestore.update({ video_link: downloadPath }, 'product', this.state.editId);
    
                toast.success("Vídeo atualizado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ photoModal: false, loadingModal: false });

                this.getDocs();
    
            } catch (error) {
                toast.error("Houve um problema ao salvar o vídeo", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        }
    }

    photoModal() {

        if (this.state.photoModalURI) {
            return (
                <div style={{}}>
                    
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img style={{ paddingTop: 15, paddingBottom: 15, height: 350 }} src={this.state.photoModalURI}/>
                    </div>

                    <input
                    accept="image/*"
                    style={{display: 'none'}}
                    id="contained-button-file"
                    type="file"
                    onChange={(v) => { this.setState({ photoBlob: v.target.files[0], photoModalURI: URL.createObjectURL(v.target.files[0]) }) }}
                    />
                    <label htmlFor="contained-button-file">
                        <Button startIcon={<CloudUploadIcon/>} component="span" style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'UPLOAD'}</Button>
                    </label>
    
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.uploadPhoto() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ photoModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    videoModal() {

        if (this.state.videoModal) {
            return (
                <div style={{}}>
                    
                    {
                        this.state.videoModalURI ?

                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <ReactPlayer controls={true} url={this.state.videoModalURI} />
                            </div>

                        : <p style={{paddingTop: 30, paddingBottom: 30, textAlign: 'center'}}>{'Nenhum vídeo selecionado.'}</p>
                    }

                    <input
                    accept="video/mp4,video/x-m4v,video/*"
                    style={{display: 'none'}}
                    id="video-upload-edit"
                    type="file"
                    onChange={(v) => { this.setState({ videoBlob: v.target.files[0], videoModalURI: URL.createObjectURL(v.target.files[0]) }) }}
                    />
                    <label htmlFor="video-upload-edit">
                        <Button startIcon={<CloudUploadIcon/>} component="span" style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'UPLOAD'}</Button>
                    </label>
    
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.uploadVideo() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ videoModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    renderCategory(category) {

        let name = '';

        if (category.length) {

            category.forEach((item, key) => {

                if (item === 'aves') {
                    name += 'Aves';

                } else if (item === 'peixes') {
                    name += 'Peixes';

                } else if (item === 'industrializados') {
                    name += 'Industrializados';

                } else if (item === 'embalagens') {
                    name += 'Embalagens';

                } else if (item === 'suinos') {
                    name += 'Suinos';

                } else if (item === 'bovinos') {
                    name += 'Bovinos';

                } else if (item === 'customizados') {
                    name += 'Projetos Customizados';
                }

                if (category[key + 1]) {
                    name += ', ';
                }

            });
        }

        return <div>{name}</div>
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultButton onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>

                <DefaultTable
                title={'Produtos'}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome do Produto', field: 'name' },
                    { title: 'Descrição', field: 'description' },
                    { title: 'Link do YouTube', field: 'youtube_link', render: rowData => rowData.youtube_link && <a target={'_blank'} href={`${rowData.youtube_link}`}>Link do YouTube</a> },
                    { title: 'Categoria', field: 'category', render: rowData => this.renderCategory(rowData.category), editComponent: props => <Select multiple={true} onChange={(v) => { props.onChange(v.target.value) }} value={props.value}><MenuItem value={'aves'}>{'Aves'}</MenuItem><MenuItem value={'peixes'}>{'Peixes'}</MenuItem><MenuItem value={'industrializados'}>{'Industrializados'}</MenuItem><MenuItem value={'embalagens'}>{'Embalagens'}</MenuItem><MenuItem value={'suinos'}>{'Suinos'}</MenuItem><MenuItem value={'bovinos'}>{'Bovinos'}</MenuItem><MenuItem value={'customizados'}>{'Projetos Customizados'}</MenuItem></Select>, },
                    { title: 'Imagem', editable: false, field: 'photo', render: rowData => <PhotoIcon onClick={() => { this.setState({ photoModal: true, photoModalURI: rowData.photo, editId: rowData.id, editData: rowData }) }} style={{cursor: 'pointer', color: Colors.semil.green}}/> },
                    { title: 'Vídeo', editable: false, field: 'video_link', render: rowData => <Movie onClick={() => { this.setState({ videoModal: true, videoModalURI: rowData.video_link, editId: rowData.id, editData: rowData }) }} style={{cursor: 'pointer', color: Colors.semil.green}}/> },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let update = {
                            name: newData.name,
                            description: newData.description,
                            youtube_link: newData.youtube_link,
                            category: newData.category,
                        };

                        await Firestore.update(update, 'product', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        if (oldData.photo) {
                            await Storage.removeFile(oldData.photo);
                        }
                        if (oldData.video_link) {
                            await Storage.removeFile(oldData.video_link);
                        }
                        await Firestore.delete('product', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Produto'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.photoModal()} title={'Imagem do Produto'} onClose={() => { this.setState({ photoModal: false }) }} open={this.state.photoModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.videoModal()} title={'Vídeo do Produto'} onClose={() => { this.setState({ videoModal: false }) }} open={this.state.videoModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
