import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, MenuItem, Select, InputLabel, FormLabel, FormGroup, FormControlLabel, Checkbox, ListItem, List, Tooltip } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import Firestore from '../api/firebase/Firestore';
import moment from 'moment';
import DeleteModal from '../components/DeleteModal';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import RichTextEditor from 'react-rte';
import { toast } from 'react-toastify';

export default class SettingsPage extends Component {

    state = {
        config: {
            id_default_satisfaction_research: null,
            id_default_email_account: null,
            send_automatic_satisfaction_research: false,
            send_satisfaction_research_email: false,
            work_types: [
                'Startup de Máquina',
                'Assistência Técnica',
                'Re-Trabalho',
                'Visita Técnica',
            ],
        },
        researchDocs: [],
        emailAccountDocs: [],
        loading: true,
        selectedResearch: null,
        autoResearch: false,
        workTypeHover: '',
        addWorkType: false,
        addWorkTypeText: '',
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('settings').get();
        let data = this.state.config;

        if (query.size === 1 && query.docs[0]) {
            data = query.docs[0].data();
            data.id = query.docs[0].id;
        }

        this.setState({ config: data, loading: false });
    }

    async getSatisfactionResearch() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('satisfaction_research').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = { id: doc.id, name: doc.data().name };

            docs.push(data);
        });

        this.setState({ researchDocs: docs });
    }

    async getEmailAccounts() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('email_account').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = { id: doc.id, email: doc.data().email };

            docs.push(data);
        });

        this.setState({ emailAccountDocs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
        this.getSatisfactionResearch();
        this.getEmailAccounts();
    }

    async save() {

        if (this.state.config && this.state.config.id) {

            let data = {
                id_default_satisfaction_research: this.state.config.id_default_satisfaction_research ? this.state.config.id_default_satisfaction_research : null,
                id_default_email_account: this.state.config.id_default_email_account ? this.state.config.id_default_email_account : null,
                send_automatic_satisfaction_research: this.state.config.send_automatic_satisfaction_research ? this.state.config.send_automatic_satisfaction_research : false,
                send_satisfaction_research_email: this.state.config.send_satisfaction_research_email ? this.state.config.send_satisfaction_research_email : false,
                work_types: this.state.config.work_types,
            };

            try {

                await Firestore.update(data, 'settings', this.state.config.id);

                toast.success("Salvo com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    removeWorkType(type) {

        let confirm = window.confirm('Tem certeza que deseja remover?');

        if (confirm) {

            let config = this.state.config;
            let array = this.state.config.work_types;
            let index = array.findIndex(item => item === type);

            if (index > -1) {

                array.splice(index, 1);
                config.work_types = array;

                this.setState({ config: config });
            }
        }
    }

    addWorkType() {

        if (this.state.addWorkTypeText.length) {

            let config = this.state.config;
            config.work_types.push(this.state.addWorkTypeText);

            this.setState({ addWorkTypeText: '', addWorkType: false, config: config });

        } else {

            toast.warn('Preencha o tipo de trabalho');
        }
    }

    renderTravelSheetWorkTypes() {

        return (
            <div>
                <List style={{ boxShadow: Colors.boxShadow, marginTop: 10 }}>
                    { 
                        this.state.config.work_types.map((type, key) => {
                            return (
                                <ListItem onMouseEnter={() => { this.setState({ workTypeHover: type }) }} onMouseLeave={() => { this.setState({ workTypeHover: '' }) }} style={{ borderBottom: this.state.config.work_types[key + 1] ? '1px solid lightgrey' : '', height: 50 }}>
                                    {type}
                                    { this.state.workTypeHover === type ?
                                    
                                        <Tooltip title={'Remover'}>
                                            <DeleteIcon onClick={() => { this.removeWorkType(type) }} style={{ marginLeft: 'auto', cursor: 'pointer', color: 'red' }}/>
                                        </Tooltip>
                                    
                                    : null }
                                </ListItem>
                            )
                        }) 
                    }
                    { !this.state.addWorkType ?
                    
                        <ListItem onClick={() => { this.setState({ addWorkType: true }) }} button style={{ borderTop: '1px solid lightgrey', height: 50 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: Colors.semil.green, fontWeight: 'bold' }}>
                                <AddIcon/>
                                <div style={{ marginLeft: 3 }}>{'Adicionar'}</div>
                            </div>
                        </ListItem>
                    
                    :

                        <div style={{ paddingLeft: 8, paddingRight: 8, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <DefaultInput onBlur={(v) => { this.setState({ addWorkTypeText: v }) }} label={'Nome'}/>
                            <Tooltip title={'Salvar'}>
                                <CheckIcon onClick={() => { this.addWorkType() }} style={{ color: Colors.semil.green, cursor: 'pointer' }}/>
                            </Tooltip>
                            <Tooltip title={'Cancelar'}>
                                <ClearIcon onClick={() => { this.setState({ addWorkType: false, addWorkTypeText: '' }) }} style={{ color: 'red', cursor: 'pointer' }}/>
                            </Tooltip>
                        </div>

                    }
                </List>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultButton onClick={() => { this.save(); } } title={'Salvar'}/>
                <div style={{paddingTop: 40}}>
                    <Card style={{paddingLeft: 20, paddingRight: 20, marginBottom: 15, paddingBottom: 40}}>
                        <p style={{fontSize: 18, fontWeight: 'bold',}}>{'Configurações'}</p>
                        
                        <FormLabel style={{paddingTop: 18}} component="legend">Geral</FormLabel>

                        <div style={{paddingTop: 18}}>
                            <InputLabel id="email-account">Conta de envio de e-mail padrāo</InputLabel>
                            <Select
                            style={{width: '100%'}}
                            labelId="email-account"
                            value={this.state.config.id_default_email_account}
                            onChange={(v) => { let config = this.state.config; config.id_default_email_account = v.target.value; this.setState({ config: config }) }}>
                                {
                                    this.state.emailAccountDocs.map((account, key) => {
                                        return <MenuItem value={account.id}>{account.email}</MenuItem>
                                    })
                                }
                            </Select>
                        </div>

                        <FormLabel style={{paddingTop: 18}} component="legend">Chamados</FormLabel>

                        <div style={{paddingTop: 18}}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.config.send_automatic_satisfaction_research} onChange={(v) => { let config = this.state.config; config.send_automatic_satisfaction_research = v.target.checked; this.setState({ config: config }) }} name="check_all" />}
                                label="Disponibilizar pesquisa de satisfação automática no app ao finalizar chamado"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.config.send_satisfaction_research_email} onChange={(v) => { let config = this.state.config; config.send_satisfaction_research_email = v.target.checked; this.setState({ config: config }) }} name="check_all" />}
                                label="Enviar pesquisa de satisfação também por e-mail ao cliente"
                            />
                        </FormGroup>
                        </div>
                        <div style={{paddingTop: 18}}>
                            <InputLabel id="satisfaction-research">Pesquisa de satisfação padrão para chamados finalizados</InputLabel>
                            <Select
                            style={{width: '100%'}}
                            labelId="satisfaction-research"
                            value={this.state.config.id_default_satisfaction_research}
                            onChange={(v) => { let config = this.state.config; config.id_default_satisfaction_research = v.target.value; this.setState({ config: config }) }}>
                                {
                                    this.state.researchDocs.map((research, key) => {
                                        return <MenuItem value={research.id}>{research.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </div>

                        <FormLabel style={{paddingTop: 18}} component="legend">Ficha de Viagem</FormLabel>
                        { this.renderTravelSheetWorkTypes() }

                    </Card>
                </div>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
