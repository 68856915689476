import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SendIcon from '@material-ui/icons/Send';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import { toast } from 'react-toastify';
import RichTextEditor from 'react-rte';
import DefaultTable from '../components/DefaultTable';
import moment from 'moment';
import { Button } from '@material-ui/core';
import Colors from '../constants/Colors';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Storage from '../api/firebase/Storage';
import ReviewedParts from '../constants/ReviewedParts';

export default class HourPricingPage extends Component {

    state = {
        docs: [],
        productDocs: [],
        userDocs: [],
        loading: true,
        loadingModal: false,
        responseModal: false,
        sendResponseModal: false,
        id_edit: null,
        filesToUpload: [],
        editor: RichTextEditor.createEmptyValue(),
        editorHtml: RichTextEditor.createEmptyValue(),
        responseModal: false,
        responseModalData: null,
    }

    async getClients() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('user').where('type', '==', 'customer').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ userDocs: docs });
    }

    async getProducts() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('product').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ productDocs: docs });
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('budget').orderBy('date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async componentDidMount() {

        await this.setState({ loading: true });

        await this.getProducts();
        await this.getClients();
        await this.getDocs();

        await this.setState({ loading: false });
    }

    async sendResponse() {

        if (this.state.editor) {

            try {

                this.setState({ loadingModal: true });

                let files = await this.handleAttatchmentUpload(this.state.filesToUpload);
                let data = {
                    response_sended: true,
                    response: this.state.editorHtml,
                    response_date: new Date(),
                    files: files,
                };

                await Firestore.update(data, 'budget', this.state.id_edit);

                if (this.state.idUser) {

                    let userDoc = this.state.userDocs.find(item => item.id === this.state.idUser);

                    if (userDoc.notification_token && userDoc.notification_token.data) {

                        const message = {
                            to: userDoc.notification_token.data,
                            title: this.state.title,
                            body: `O seu ${this.state.title} foi respondido! Abra o app e confira.`,
                            data: {
                                navigate: 'Estimate',
                                id: this.state.id_edit,
                            },
                            sound: 'default',
                            priority: 'normal',
                        };

                        await fetch('https://exp.host/--/api/v2/push/send', {
                            mode: 'no-cors',
                            method: 'POST',
                            headers: {
                                'Accept-encoding': 'gzip, deflate',
                                'Content-Type': 'application/json',
                                'Accept': 'application/json',
                            },
                            body: JSON.stringify(message),
                        });
                    }
                }

                toast.success("Resposta enviada com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, sendResponseModal: false, filesToUpload: [], editor: RichTextEditor.createEmptyValue(), editorHtml: RichTextEditor.createEmptyValue() });
                await this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao enviar a resposta", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        } else {

            toast.warn("Informe a resposta", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    async handleAttatchmentUpload(files) {

        let filesArray = [];
        this.setState({ loadingModal: true });

        if (files.length) {

            await new Promise( async (resolve, reject) => {

                Array.from(files).forEach( async (file, key) => {
    
                    let ref = await Storage.uploadFile('budget_attatchments', file);
                    let downloadPath = await Storage.getFileFromRef(ref);
            
                    if (!downloadPath) {
                        throw new Error('upload error');
                    }
    
                    filesArray.push(downloadPath);
    
                    if (key === files.length - 1) {
                        resolve();
                    }      
                });          
            });
        }

        return filesArray;
    }

    onChange = (value) => { this.setState({editor: value, editorHtml: value.toString('html')}) };

    removeFile(key) {

        let files = Array.from(this.state.filesToUpload);
        files.splice(key, 1);

        this.setState({ filesToUpload: files });
    }

    renderFiles() {

        return <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 25, paddingBottom: 25 }}>
            {
                Array.from(this.state.filesToUpload).map((file, key) => {

                    return (
                        <div style={{ padding: 5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {file.name}
                            <DeleteIcon onClick={() => { this.removeFile(key) }} style={{ marginLeft: 5, color: 'red', cursor: 'pointer' }}/>
                        </div>
                    )
                })
            }
        </div>
    }

    sendResponseModal() {

        if (this.state.sendResponseModal) {

            return (

                <div>
                    <RichTextEditor
                    editorStyle={{height: 500}}
                    value={this.state.editor}
                    onChange={this.onChange}
                    toolbarConfig={{
                        // Optionally specify the groups to display (displayed in the order listed).
                        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'IMAGE_BUTTON', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
                        INLINE_STYLE_BUTTONS: [
                        {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
                        {label: 'Italico', style: 'ITALIC'},
                        {label: 'Sublinhado', style: 'UNDERLINE'}
                        ],
                        BLOCK_TYPE_DROPDOWN: [
                        {label: 'Normal', style: 'unstyled'},
                        {label: 'Título Grande', style: 'header-one'},
                        {label: 'Título Médio', style: 'header-two'},
                        {label: 'Título Pequeno', style: 'header-three'}
                        ],
                        BLOCK_TYPE_BUTTONS: [
                        {label: 'Lista', style: 'unordered-list-item'},
                        {label: 'Lista Numerada', style: 'ordered-list-item'}
                        ],
                    }}
                    />
                    <div style={{ marginTop: 20 }}>
                        { this.state.filesToUpload.length ? this.renderFiles() : null }
                        <input
                            style={{display: 'none'}}
                            id="contained-button-file"
                            type="file"
                            multiple={false}
                            onChange={(v) => { let files = this.state.filesToUpload; files.push(v.target.files[0]); this.setState({ filesToUpload: files }); }}
                            />
                        <label htmlFor="contained-button-file">
                            <Button startIcon={<CloudUploadIcon/>} component="span" style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'ANEXAR ARQUIVO'}</Button>
                        </label>
                    </div>
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.sendResponse() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ sendResponseModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    responseModal() {

        if (this.state.responseModal) {

            let data = this.state.responseModalData;

            return (
                <div style={{ paddingTop: 15 }}>
                    { !data.response_sended ? <p style={{ color: 'grey' }}>Você verá a resposta enviada aqui.</p> : null }
                    
                    { data.response_sended ? 
    
                        <div>
                            <p style={{ fontWeight: 'bold' }}>Resposta em Texto</p>
                            <div dangerouslySetInnerHTML={{ __html: data.response }}/>
    
                            <p style={{ fontWeight: 'bold' }}>Arquivos Anexados</p>
                            { data.files && data.files.length ? 
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {
                                        data.files.map((file, key) => {
                                            return <a href={file} target={'_blank'}>{`Arquivo ${key + 1}`}</a>
                                        })
                                    }
                                </div>
    
                            : <p style={{ color: 'grey' }}>Nenhum arquivo anexado</p> }
                        </div> 
    
                    : null }
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.setState({ responseModal: false, responseModalData: null }) }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    detailPanel(data) {

        return (
            <div style={{ paddingLeft: 25, paddingBottom: 25, paddingTop: 15 }}>
                
                <p>
                    <strong>Produto: </strong>
                    {data.id_product ? this.state.productDocs.find(item => item.id === data.id_product).name : 'Nenhum produto vinculado'}
                </p>
                <p style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                    <strong style={{ marginRight: 10 }}>Revisão Geral: </strong>  
                    { data.generalReview ? <CheckIcon style={{ color: 'green' }}/> : <ClearIcon style={{ color: 'red' }}/> }
                </p>
                
                <strong style={{ marginRight: 10 }}>Itens para Revisão: </strong>  
                { !data.reviewedParts ? 'Nenhum item de revisão selecionado' : ''}
                { data.reviewedParts ? 
                
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 15 }}>
                        {
                            Object.keys(data.reviewedParts).map((key) => {
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                                        {ReviewedParts[key]}
                                        { data.reviewedParts[key] === true ? <CheckIcon style={{ color: 'green' }}/> : <ClearIcon style={{ color: 'red' }}/> }
                                    </div>
                                )
                            })
                        }
                    </div>    

                : null }

            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultTable
                title={'Orçamentos'}
                width={window.screen.width - 290}
                onRowClick={(evt, rowData, togglePanel) => { togglePanel() }}
                detailPanel={(rowData) => { return this.detailPanel(rowData) }}
                actions={[
                    {
                        icon: SendIcon,
                        tooltip: 'Enviar Resposta',
                        onClick: (event, rowData) => { rowData.response_sended ? toast.warn('Resposta já enviada') : this.setState({ sendResponseModal: true, id_edit: rowData.id, idUser: rowData.id_user, title: rowData.title, editor: RichTextEditor.createEmptyValue(), editorHtml: RichTextEditor.createEmptyValue(), filesToUpload: [] }) }
                    },
                    {
                        icon: AccessTimeIcon,
                        tooltip: 'Resposta Enviada',
                        onClick: (event, rowData) => { !rowData.response_sended ? toast.warn('Resposta não enviada') : this.setState({ responseModal: true, responseModalData: rowData }) }
                    },
                ]}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'title' },
                    { title: 'Cliente', field: 'id_user', render: rowData => <div>{this.state.userDocs.find(item => item.id === rowData.id_user).name}</div>, editable: false },
                    { title: 'E-mail', field: 'id_user', render: rowData => <div>{this.state.userDocs.find(item => item.id === rowData.id_user).email}</div>, editable: false },
                    { title: 'Cnpj', field: 'cnpj', render: row => ( row.cnpj.toString().replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5") ), editable: false },
                    { title: 'Respondido', field: 'response_sended', type: 'boolean', render: rowData => rowData.response_sended ? <CheckIcon style={{ color: 'green' }}/> : <ClearIcon style={{ color: 'red' }}/>, editable: false },
                    { title: 'Respondido Em', field: 'response_date', render: rowData => <div>{rowData.response_date ? moment(rowData.response_date.toDate()).format('DD/MM/YYYY HH:mm') : ''}</div>, editable: false },
                    { title: 'Data', field: 'date', render: rowData => <div>{moment(rowData.date.toDate()).format('DD/MM/YYYY HH:mm')}</div>, editable: false },
                ]}
                data={this.state.docs}
                />
                <DefaultModal width={1200} content={this.sendResponseModal()} loading={this.state.loadingModal} title={'Enviar Resposta'} onClose={() => { this.setState({ sendResponseModal: false }) }} open={this.state.sendResponseModal}/>
                <DefaultModal width={1200} content={this.responseModal()} loading={this.state.loadingModal} title={'Resposta Enviada'} onClose={() => { this.setState({ responseModal: false }) }} open={this.state.responseModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
