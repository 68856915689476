import React, { Component, forwardRef } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Firestore from '../api/firebase/Firestore';
import moment from 'moment';
import DeleteModal from '../components/DeleteModal';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import RichTextEditor from 'react-rte';
import { toast } from 'react-toastify';
import CurrencyInput from '../components/CurrencyInput';
import { TextField } from '@material-ui/core';
import CurrencyHelper from '../helper/CurrencyHelper';
import DefaultTable from '../components/DefaultTable';

export default class HourPricingPage extends Component {

    state = {
        docs: [],
        name: '',
        price: null,
        rawPrice: null,
        loading: true,
        loadingModal: false,
        addModal: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('hour_pricing').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = { type: doc.data().type, price: doc.data().price / 100, id: doc.id };

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
    }

    async addHour() {

        if (this.state.rawPrice && this.state.name) {

            let data = {
                price: this.state.rawPrice * 100,
                type: this.state.name,
            };

            try {

                this.setState({ loadingModal: true });

                await Firestore.insert(data, 'hour_pricing');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ addModal: false, name: '', rawPrice: null, price: null });
                await this.getDocs();

                this.setState({ loadingModal: false });

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    addModal() {
        return (
            <div>
                <DefaultInput onChange={(v) => { this.setState({ name: v }) }} label={'Nome'}/>
                <CurrencyInput label={'Valor da Hora'} onChange={(values) => { const {formattedValue, value} = values; this.setState({price: formattedValue, rawPrice: value}) }} value={this.state.price}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addHour() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultButton onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>

                <DefaultTable
                title={'Tipo de Horas'}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Tipo', field: 'type' },
                    { title: 'Valor da Hora', cellStyle: {textAlign: 'left'}, field: 'price', type: 'currency', currencySetting: {locale: 'brl', currencyCode: 'brl'}, editComponent: props => ( <CurrencyInput value={props.value} onChange={(e) => { props.onChange(e.floatValue) }} /> ) },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let update = {
                            type: newData.type,
                            price: newData.price * 100,
                        };

                        await Firestore.update(update, 'hour_pricing', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        await Firestore.delete('hour_pricing', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />

                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Tipo de Hora'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
