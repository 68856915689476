import React, { Component } from 'react';
import DefaultSelect from './DefaultSelect';
import ClipLoader from "react-spinners/ClipLoader";
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';

export default class CompanyOrderSelect extends Component {

    state = {
        docs: [],
        company: {},
        loading: true,
    }

    async componentDidMount() {

        await this.load();

        this.setState({ loading: false });
    }

    async componentDidUpdate(prev) {

        if (this.props.idCompany !== prev.idCompany) {

            await this.load();
            this.select.updateDocs();
        }
    }

    async load() {

        await this.getCompany();
        await this.getOrders();
    }

    async getCompany() {

        let query = await Firestore.getDoc('company', this.props.idCompany);
        let doc = { ...query.data(), id: query.id };

        this.setState({ company: doc });
    }

    async getOrders() {

        let query = await Firestore.customQuery('order').where('billing_cnpj', '==', this.state.company.cnpj).get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    renderLoading() {

        return (

            <ClipLoader
            size={15}
            color={Colors.semil.green}
            loading={true}
            />
        )
    }

    render() {

        return this.state.loading ? this.renderLoading() : <DefaultSelect ref={ref => this.select = ref} {...this.props} docs={this.state.docs} search={true} searchField={'order_code'} valueField={'id'} displayField={'order_code'}/>
    }
}
