import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import EmailEditor from 'react-email-editor'
import VisibilityIcon from '@material-ui/icons/Visibility';
import PostAddIcon from '@material-ui/icons/PostAdd';

export default class HourPricingPage extends Component {

    state = {
        docs: [],
        name: '',
        idEdit: null,
        loading: true,
        loadingModal: false,
        addModal: false,
        emailVisualizationModal: false,
        designModal: null,
        pasteDesign: null,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('email_template').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = { name: doc.data().name, html: doc.data().html, design: doc.data().design, id: doc.id };

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
    }

    async addTemplate() {

        this.editor.exportHtml(async data => {

            const { design, html } = data
            
            if (html && design && this.state.name) {

                let data = {
                    name: this.state.name,
                    html: html,
                    design: JSON.stringify(design),
                };

                try {

                    this.setState({ loadingModal: true });

                    await Firestore.insert(data, 'email_template');

                    toast.success("Cadastrado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    await this.setState({ loadingModal: false, addModal: false, name: '' });
                    await this.getDocs();

                } catch (error) {
                    console.log(error);
                    toast.error("Houve um problema ao cadastrar", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    this.setState({ loadingModal: false });
                }

            } else {

                toast.warn("Preencha todos os campos", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
    }

    async updateTemplate() {

        this.editor.exportHtml(async data => {

            const { design, html } = data
            
            if (html && design && this.state.idEdit) {

                let data = {
                    html: html,
                    design: JSON.stringify(design),
                };

                try {

                    this.setState({ loadingModal: true });

                    await Firestore.update(data, 'email_template', this.state.idEdit);

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    await this.setState({ loadingModal: false, emailVisualizationModal: false, idEdit: null });
                    await this.getDocs();

                } catch (error) {
                    toast.error("Houve um problema ao editar", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    this.setState({ loadingModal: false });
                }

            } else {

                toast.warn("Preencha todos os campos", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });

        this.forceUpdate();
    }

    emailVisualizationModal() {

        return (
            <div>
                <EmailEditor onLoad={() => { this.editor.loadDesign(JSON.parse(this.state.designModal)) }} style={{marginTop: 15}} options={{locale: 'pt-BR'}} ref={editor => { if (editor) this.editor = editor }}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.updateTemplate() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ emailVisualizationModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    addModal() {
        
        return (
            <div>
                <DefaultInput onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'}/>
                <EmailEditor onLoad={() => { if (this.state.pasteDesign && this.editor) { this.editor.loadDesign(JSON.parse(this.state.pasteDesign)) } }} style={{marginTop: 15}} options={{locale: 'pt-BR'}} ref={editor => { if (editor) this.editor = editor }}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addTemplate() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    seeEmail(data) { this.setState({ designModal: data.design, idEdit: data.id, emailVisualizationModal: true }) }

    newFromDesign(design) { this.setState({ addModal: true, pasteDesign: design }) }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultButton onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>

                <DefaultTable
                actions={[
                    {
                        icon: PostAddIcon,
                        tooltip: 'Novo a partir desse modelo',
                        onClick: (event, rowData) => { this.newFromDesign(rowData.design) }
                    }
                ]}
                title={'Templates de E-mail'}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'name' },
                    { title: 'Layout', editable: false, field: 'design', render: rowData => <VisibilityIcon onClick={() => { this.seeEmail(rowData) }} style={{cursor: 'pointer'}}/> },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let update = {
                            name: newData.name
                        };

                        await Firestore.update(update, 'email_template', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        await Firestore.delete('email_template', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />

                <DefaultModal loading={this.state.loadingModal} width={'85%'} content={this.addModal()} title={'Novo Template'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
                <DefaultModal loading={this.state.loadingModal} width={'85%'} content={this.emailVisualizationModal()} title={'Editar Template'} onClose={() => { this.setState({ emailVisualizationModal: false, designModal: null, idEdit: null }) }} open={this.state.emailVisualizationModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
