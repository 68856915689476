import React from "react";
import { AppBar, CssBaseline, Divider, Drawer, Hidden, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, Card, Button } from '@material-ui/core';
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import { Switch, Route, Link, BrowserRouter } from "react-router-dom";
import * as firebase from 'firebase';
import Colors from '../constants/Colors';

import CustomerAppointmentPage from '../page/CustomerAppointmentPage';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import SessionHelper from '../helper/SessionHelper';
import CustomerDataPage from "../page/CustomerDataPage";
import Firestore from "../api/firebase/Firestore";

const drawerWidth = 240;

export default class TechnicianNavigator extends React.Component {

    logout() { firebase.auth().signOut(); window.location.href = '/' }

    state = {
        actualRoute: null,
        technician: {}
    }

    async componentDidMount() {
        let query = await Firestore.customQuery('technician').where('user_id', '==', SessionHelper.getFirebaseAuth().uid).get();

        let technician = { active: "N" };

        if (query.docs.length > 0) technician = { ...query.docs[0].data(), id: query.docs[0].id };

        this.setState({ actualRoute: window.location.pathname, technician });
    }

    handleRouteChange() {
        setTimeout(() => {
            this.setState({ actualRoute: window.location.pathname });
        }, 10)
    }

    renderRoutes() {
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ height: '100vh', width: '100%', backgroundColor: Colors.semil.green, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Card style={{ padding: 30, filter: this.state.loading ? 'blur(5px)' : '', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 800 }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img style={{ height: 400 }} src={process.env.PUBLIC_URL + '/assets/image/no_data.png'} />
                            <p style={{ fontWeight: 'bold', color: Colors.semil.green, fontSize: 40 }}>{'Vá para tecnico.semil.com.br'}</p>
                            <div style={{ color: 'grey', textAlign: 'center', paddingTop: 15, paddingBottom: 25 }}>A versão web do connect para técnicos fica em tecnico.semil.com.br, clique no botão abaixo para ir para o endereço</div>
                            <Button onClick={() => { window.location('https://tecnico.semil.com.br') }} style={{ width: '100%', fontWeight: 'bold', marginTop: 30, padding: 15, backgroundColor: Colors.semil.green, color: 'white' }} variant="contained" >IR PARA APP DO TECNICO</Button>
                        </div>
                    </Card>
                </div>
            </div>
        )
    }

    goBack() {
        SessionHelper.destroy();
        this.logout();
    }

    renderApprovalMessage() {

        return (
            <div style={{ height: '100vh', width: '100%', backgroundColor: Colors.semil.green, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Card style={{ padding: 30, filter: this.state.loading ? 'blur(5px)' : '', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 800 }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img style={{ height: 400 }} src={process.env.PUBLIC_URL + '/assets/image/no_data.png'} />
                        <p style={{ fontWeight: 'bold', color: Colors.semil.green, fontSize: 40 }}>{'Seu acesso está inativo'}</p>
                        <div style={{ color: 'grey', textAlign: 'center', paddingTop: 15, paddingBottom: 25 }}>Caso você ache que isso seja um erro contate o administrador do sistema</div>
                        <Button onClick={() => { this.goBack() }} style={{ width: '100%', fontWeight: 'bold', marginTop: 30, padding: 15, backgroundColor: Colors.semil.green, color: 'white' }} variant="contained" >VOLTAR</Button>
                    </div>

                </Card>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.state.technician.active === "S" ? this.renderRoutes() : this.renderApprovalMessage()}
                <ToastContainer style={{ paddingTop: 100 }} />
            </div>
        )
    }
}
