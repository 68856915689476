import React, { Component } from 'react';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import RelationField from './RelationField';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: '100%',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    }
}))(Tooltip);

export default class CalendarEvent extends Component {

    state = {
        open: false
    }

    tooltip() {
        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                { this.props.event.appointment_number ? <div><b>{'SAT: '}</b>{this.props.event.appointment_number}</div> : null }
                { this.props.event.technician_name ? <div><b>{'Técnico: '}</b>{this.props.event.technician_name}</div> : null }
                { this.props.event.company_name ? <div><b>{'Cliente: '}</b>{this.props.event.company_name}</div> : null }
                { this.props.event.city ? <div><b>{'Cidade: '}</b>{this.props.event.city}</div> : null }
                <div><b>{'Início: '}</b>{moment(this.props.event.start).format('DD/MM/YYYY HH:mm')}</div>
                <div><b>{'Fim: '}</b>{moment(this.props.event.end).format('DD/MM/YYYY HH:mm')}</div>
            </div>
        )
    }

    render() {
        return (
            <HtmlTooltip title={this.tooltip()} placement={'bottom-start'}>
                <div {...this.props}>
                    {this.props.title}
                </div>
            </HtmlTooltip>
        )
    }
}
