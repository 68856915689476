import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button } from '@material-ui/core';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import PinDropIcon from '@material-ui/icons/PinDrop';
import WorkIcon from '@material-ui/icons/Work';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import DefaultInput from '../components/DefaultInput';
import GeographicHelper from '../helper/GeographicHelper';
import GeoHash from '../helper/Geohash';

export default class HourPricingPage extends Component {

    state = {
        docs: [],
        loading: true,
        loadingModal: false,
        contactsModal: false,
        contactsModalData: null,
        mapModal: false,
        id_edit: null,
        lat: '',
        long: '',
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('company').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
    }

    async syncClients() {

    }

    contactModal() {
        if (this.state.contactsModalData) {
            return (
                <div>
                    { this.state.contactsModalData.map((element, key) => {

                        return (
                            <div style={{borderBottom: '0.5px solid lightgrey'}}>
                                { element.contact_name &&
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <PersonIcon/>
                                        <p style={{paddingLeft: 8}}>{`${element.contact_name}`}</p>
                                    </div>
                                }
                                { element.contact_email &&
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <EmailIcon/>
                                        <p style={{paddingLeft: 8}}>{`${element.contact_email}`}</p>
                                    </div>
                                }
                                { element.contact_phone &&
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <PhoneIcon/>
                                        <p style={{paddingLeft: 8}}>{`${element.contact_phone}`}</p>
                                    </div>
                                }
                                { element.contact_role &&
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <WorkIcon/>
                                        <p style={{paddingLeft: 8}}>{`${element.contact_role}`}</p>
                                    </div>
                                }
                            </div>
                        )
 
                    }) }
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.setState({ contactsModal: false, contactsModalData: null }) }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    async saveLocation() {

        if (this.state.lat && this.state.long) {

            try {
            
                this.setState({ loadingModal: true });

                let lat = parseFloat(this.state.lat);
                let lng = parseFloat(this.state.long);

                let geopoint = GeographicHelper.setFirebaseGeopoint(lat, lng);
    
                let data = {
                    coordinates: geopoint,
                    l: geopoint,
                    g: GeoHash.encode(lat, lng),
                    invalidAddress: false,
                };

                await Firestore.customQuery('company').doc(this.state.id_edit).update(data);
                await this.getDocs();
    
                this.setState({ mapModal: false, id_edit: null, lat: null, long: null, loadingModal: false });
                toast.success('Localizaçāo cadastrada com sucesso');
    
            } catch (error) {

                this.setState({ loadingModal: false });
                toast.error('Erro ao inserir coordenadas');
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    mapModal() {
        if (this.state.mapModal) {
            return (
                <div>
                    <DefaultInput label={'Latitude'} onBlur={(v) => { this.setState({ lat: v }) }}/>
                    <DefaultInput label={'Longitude'} onBlur={(v) => { this.setState({ long: v }) }}/>
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.saveLocation() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultButton onClick={() => { this.syncClients() }} title={'Sincronizar'}/>

                <DefaultTable
                title={'Clientes'}
                filtering={true}
                actions={[
                    rowData => rowData.invalid_address ? ({
                        icon: PinDropIcon,
                        tooltip: 'Localizar no Mapa',
                        onClick: (event, rowData) => { this.setState({ mapModal: true, id_edit: rowData.id }); }
                    }) : null
                ]}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Invalid Address', field: 'invalid_address', hidden: true },
                    { title: 'Nome', field: 'name', editable: false },
                    { title: 'Cnpj', field: 'cnpj', editable: false, render: row => ( row.cnpj.toString().replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5") ) },
                    { title: 'E-mail', field: 'email', editable: false },
                    { title: 'Website', field: 'website', editable: false, render: row => ( row.website && <a target={'_blank'} href={`https://${row.website.split('@')[1]}`}>Website</a> ), filtering: false },
                    { title: 'Telefone', field: 'phone', editable: false },
                    { title: 'Contatos', field: 'contacts', editable: false, render: row => ( <ContactPhoneIcon onClick={() => { row.contacts.length ? this.setState({ contactsModal: true, contactsModalData: row.contacts }) : toast.warn('Cliente sem contatos', {position: toast.POSITION.TOP_RIGHT}) }} style={{color: Colors.semil.green, cursor: 'pointer'}}/> ), filtering: false },
                    { title: 'Endereço', field: 'address', editable: false },
                    { title: 'Bairro', field: 'address_bairro', editable: false },
                    { title: 'Cidade', field: 'city', editable: false },
                    { title: 'País', field: 'country', editable: false },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        await Firestore.delete('company', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />
                <DefaultModal content={this.contactModal()} title={'Contatos'} onClose={() => { this.setState({ contactsModal: false, contactsModalData: null }) }} open={this.state.contactsModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.mapModal()} title={'Localizaçāo de Cliente'} onClose={() => { this.setState({ mapModal: false, id_edit: null }) }} open={this.state.mapModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
