import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import InfoIcon from '@material-ui/icons/Info';
import Firestore from '../api/firebase/Firestore';
import moment from 'moment';
import DeleteModal from '../components/DeleteModal';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import RichTextEditor from 'react-rte';
import Functions from '../constants/Functions';
import { toast } from 'react-toastify';

export default class IntegrationPage extends Component {

    state = {
        docs: [],
        loading: true,
        loadingModal: false,
        artiaModal: false,
        delsoftModal: false,
        artiaDocs: [],
    }

    componentDidMount() {
        
        this.getArtiaDocs();
    }

    async getArtiaDocs() {

        let query = await Firestore.customQuery('artia_logs').orderBy('date', 'desc').limit(50).get();
        let docs = [];

        query.forEach((doc, key) => {
            docs.push(doc.data());
        });

        this.setState({ loading: false, artiaDocs: docs });
    }

    async syncArtia() {
        
        this.setState({ loadingModal: true });

        try {

            await fetch(`${Functions.getEndPoint()}/syncArtia`, {
                "method": "GET",
                "headers": {
                    "access-control-allow-origin": "*",
                    "content-type": "application/json"
                }
            });

        } catch (error) {

        }

        await this.getArtiaDocs();
    
        toast.success("Integração atualizada", {
            position: toast.POSITION.TOP_RIGHT
        });

        this.setState({ loadingModal: false });
    }

    downloadLogs() {

        let output = '';

        this.state.artiaDocs.forEach((doc, key) => {

            let data = doc;
            output += `Artia Id: ${data.artiaId} | Data de Integração: ${moment(data.date.toDate()).format('DD/MM/YYYY HH:mm')} | Erro: ${data.error ? 'Sim' : 'Não'} | Operação: ${data.operation === 'insert' ? 'Inserção' : data.operation === 'update' ? 'Atualizar' : 'Deleção'} | Tipo: ${data.type === 'project' ? 'Projeto' : data.type === 'activity' ? 'Atividade' : data.type} | Query: ${data.query}`;
        });

        var dataStr = "data:text/plain," + encodeURIComponent(output);
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", "log.txt");
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    artiaModal() {
        return (
            <div>
                <Button onClick={() => { this.syncArtia() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'SINCRONIZAR'}</Button>
                <Button onClick={() => { this.downloadLogs() }} style={{marginTop: 10, fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'BAIXAR LOGS'}</Button>
                <div style={{paddingTop: 20}}>
                    { this.state.artiaDocs.map((log, key) => {
                        return (
                            <div style={{ borderTop: '0.5px solid lightgrey' }}>
                                <p>{`Artia Id: ${log.artiaId ? log.artiaId : 'Não disponível'}`}</p>
                                <p>{`Operação: ${log.operation === 'insert' ? 'Inserção' : log.operation === 'update' ? 'Atualizar' : 'Deleção'}`}</p>
                                <p>{`Tipo: ${log.type === 'project' ? 'Projeto' : log.type === 'activity' ? 'Atividade' : log.type}`}</p>
                                <p>{`Erro: ${log.error ? 'Sim' : 'Não'}`}</p>
                                <p>{`Data de Integração: ${moment(log.date.toDate()).format('DD/MM/YYYY HH:mm')}`}</p>
                                <p>{`Query: ${log.query}`}</p>
                            </div>
                        );
                    }) }
                </div>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.setState({ artiaModal: false }) }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'FECHAR'}</Button>
                </div>
            </div>
        )
    }

    delsoftModal() {
        return (
            <div>
                <Button onClick={() => { }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'SINCRONIZAR BANCO'}</Button>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.setState({ delsoftModal: false }) }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'FECHAR'}</Button>
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={{ padding: 25, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                
                <Card onClick={() => { this.setState({ artiaModal: true }) }} style={{ marginBottom: 15, cursor: 'pointer', width: '20%', display: 'flex', justifyContent: 'center'}}>
                    <img style={{height: 80, margin: 20}} src={'https://artia.com/wp-content/themes/artia/images/logo-artia.png'}/>
                </Card>

                <Card onClick={() => { this.setState({ delsoftModal: true }) }} style={{ marginBottom: 15, marginLeft: 15, cursor: 'pointer', width: '20%', display: 'flex', justifyContent: 'center'}}>
                    <img style={{height: 80, margin: 20}} src={'https://portalerp.com/images/2019/05/17/delsoft-logog-002png.png'}/>
                </Card>

                <DefaultModal loading={this.state.loadingModal} content={this.artiaModal()} title={'Informações da integração'} onClose={() => { this.setState({ artiaModal: false }) }} open={this.state.artiaModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.delsoftModal()} title={'Informações da integração'} onClose={() => { this.setState({ delsoftModal: false }) }} open={this.state.delsoftModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
