import React, { Component } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import Colors from '../constants/Colors';

export default class DefaultLoader extends Component {

  render() {

    return (
        <ClipLoader
        css={this.props.css ? this.props.css : css`display: flex;margin: 0 auto;margin-top:22%;justify-content:center;align-items:center`}
        size={this.props.size ? this.props.size : 50}
        color={this.props.color ? this.props.color : Colors.semil.green}
        loading={true}
        />
    );
  }
}
