import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { Card, Button, TextareaAutosize, RadioGroup, FormControlLabel, Radio, Checkbox, FormGroup } from '@material-ui/core';
import DefaultInput from '../components/DefaultInput';
import Colors from '../constants/Colors';
import Auth from '../api/firebase/Auth';
import { withRouter } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import DefaultButton from '../components/DefaultButton';
import StarRating from '../components/StarRating';
import { toast } from 'react-toastify';
import Firestore from '../api/firebase/Firestore';

export default class SurveryPage extends Component {

    state = {
        loading: false,
        survery: this.checkSurvery(),
        answer: [],
        optionKey: null,
        finished: false,
    }

    componentDidMount() {

        document.body.style.backgroundColor = Colors.semil.green;

        this.getSurvery();
    }

    async getSurvery() {

        this.setState({ loading: true });

        let data = [];

        let doc = await Firestore.getDoc('satisfaction_research', this.state.survery.id);

        if (doc.exists) {

            data = doc.data().data;
        }

        let survery = this.state.survery;
        survery.data = data;

        this.setState({ loading: false, survery: survery });
    }

    checkSurvery() {

        const urlParams = new URLSearchParams(window.location.search);
        const survery = JSON.parse(atob(urlParams.get('survery')));

        return survery;
    }

    renderLoading() {
        return (
            <ClipLoader
            css={css`position:absolute;top:38vh;left:49%`}
            size={50}
            color={Colors.semil.green}
            loading={true}
            />
        );
    }

    handleFieldValue(question, key, value, optionKey = null, multiple = false) {

        let answer = this.state.answer;

        let object = {
            answer: value,
            question_key: key,
        }

        if (optionKey !== null) {

            if (multiple) {

                if (object.option_key === undefined) { object.option_key = answer[key] && answer[key].option_key !== undefined ? answer[key].option_key : [] }

                object.option_key[optionKey] = value;

            } else {

                object.option_key = optionKey;
            }
        }

        answer[key] = object;

        this.setState({ answer: answer });
    }

    renderField(question, key) {

        if (question.type === 'text') {
            return (
                <TextareaAutosize
                rowsMax={8}
                rowsMin={8}
                placeholder="Escreva a sua resposta"
                style={{width: '95%', fontSize: 'inherit', borderColor: 'lightgrey', borderRadius: 3, padding: 15}}
                onBlur={(evt) => { this.handleFieldValue(question, key, evt.target.value) }}
                />
            )
        } else if (question.type === 'star_rating') {

            return(
                <StarRating stars={question.max_star} onStarChange={(stars) => { this.handleFieldValue(question, key, stars) }} starSize={35}/>
            )

        } else if (question.type === 'individual_select') {

            return (

                <RadioGroup style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>

                {
                    question.options.map((option, optionKey) => {
                        return <FormControlLabel value={option.label} control={<Radio onChange={(v) => { this.handleFieldValue(question, key, v.target.value, optionKey) }} style={{color: Colors.semil.green}}/>} label={option.label} />
                    })
                }

                </RadioGroup>
            )

        }  else if (question.type === 'multiple_select') {

            return (

                <FormGroup style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>

                {
                    question.options.map((option, optionKey) => {
                        return <FormControlLabel value={option.label} control={<Checkbox onChange={(v) => { this.handleFieldValue(question, key, v.target.checked, optionKey, true) }} style={{color: Colors.semil.green}}/>} label={option.label} />
                    })
                }

                </FormGroup>
            )
        }
    }

    renderForm() {
        return (
            <form>
                {this.state.survery.data.map((question, key) => {
                    return (
                        <div>
                            <p style={{fontWeight: 'bold', color: Colors.semil.green}}>{`${question.order}. ${question.label} ${question.required ? '(Obrigatório)' : ''}`}</p>
                            {this.renderField(question, key)}
                        </div>
                    )
                })}
            </form>
        )
    }

    checkRequiredFields() {

        let valid = true; 

        this.state.survery.data.forEach((question, key) => {

            if (question.required) {

                if (this.state.answer.findIndex(item => item.question_key === key) < 0) {
                    valid = false;
                }
            }
        });

        return valid;
    }

    async applySurvery() {

        if (this.checkRequiredFields()) {

            try {

                this.setState({ loading: true });

                let query = await Firestore.getDoc('appointment', this.state.survery.id_sat);
                let sat = {...query.data(), id: query.id};

                if (sat) {

                    let answers = sat.satisfaction_research_answers || [];

                    if (!Array.isArray(answers)) {
                        answers = [answers];
                    }

                    answers.push({
                        answers: this.state.answer,
                        id_satisfaction_research: this.state.survery.id,
                        date: new Date(),
                    });
                    
                    let data = {
                        answered_satisfaction_research: true,
                        satisfaction_research_answers: answers,
                    };
    
                    await Firestore.update(data, 'appointment', this.state.survery.id_sat);
                    this.setState({ loading: false, answers: [], finished: true });
                }

            } catch (error) {

                this.setState({ loading: false });
                window.alert('Houve um problema ao enviar o questionário');
            }

        } else {

            window.alert('Preencha todos os campos obrigatórios');
        }
        

        // alert(invalidFields);
    }

    renderSurvery() {
        return (
            <div>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                    <img style={{height: 125}} src={'https://firebasestorage.googleapis.com/v0/b/semil-connect.appspot.com/o/logo_suporte.png?alt=media&token=ff659b40-8ef3-45d6-ae44-c1057fc5a60d'} /> 
                    <img style={{height: 50, width: 200}} src={process.env.PUBLIC_URL + '/assets/image/logo.png'} /> 
                </div>
                <h2 style={{color: Colors.semil.green, textAlign: 'center'}}>{this.state.survery.name}</h2>
                {this.renderForm()}
                <Button onClick={() => { this.applySurvery() }} style={{ width: '100%', fontWeight: 'bold', marginTop: 30, padding: 15, backgroundColor: Colors.semil.green, color: 'white' }} variant="contained" >ENVIAR QUESTIONÁRIO</Button>
            </div>
        )
    }

    renderFinished() {
        return (
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <img style={{height: 400}} src={process.env.PUBLIC_URL + '/assets/image/confirmation.png'}/>
                <p style={{fontWeight: 'bold', color: Colors.semil.green, fontSize: 40}}>{'Sucesso!'}</p>
                <div style={{color: 'grey', textAlign: 'center'}}>{'Agradecemos pela sua resposta.'}</div>
                <div style={{color: 'grey', textAlign: 'center', marginTop: 5}}>{'Conheça as nossas soluções através de nosso '} <a style={{color: Colors.semil.green}} href={'https://www.semil.com.br/produtos/?lang=pt-br'} target={'_blank'}>{'website.'}</a></div>
                <Button onClick={() => { window.close() }} style={{ width: '100%', fontWeight: 'bold', marginTop: 30, padding: 15, backgroundColor: Colors.semil.green, color: 'white' }} variant="contained" >FECHAR</Button>
            </div>
        )
    }

    render() {
        return (
            <Container style={{display: 'flex', justifyContent: 'center'}} maxWidth={false}>
                <div style={{height: '100vh', width: '60%'}}>
                    <Card style={{ padding: 30, marginTop: 20, filter: this.state.loading ? 'blur(5px)' : '', display: 'flex', flexDirection: 'column',}}>
                        { !this.state.finished ? this.renderSurvery() : this.renderFinished() }
                    </Card>
                    {this.state.loading ? this.renderLoading() : null}
                </div>
            </Container>
        );
    }
}
 