import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { Card, Button, InputLabel } from '@material-ui/core';
import DefaultInput from '../components/DefaultInput';
import DefaultSelect from '../components/DefaultSelect';
import Colors from '../constants/Colors';
import Auth from '../api/firebase/Auth';
import { withRouter } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import CountryList from '../constants/CountryList';
import ZipCodeHelper from '../helper/ZipCodeHelper';
import { toast } from 'react-toastify';
import Functions from '../constants/Functions';

class RegisterPage extends Component {

    state = {
        email: '',
        password: '',
        confirmPassword: '',
        address: '',
        neighborhood: '',
        zipcode: '',
        city: '',
        cnpj: '',
        name: '',
        country: '',
        state: '',
        loading: false,
        success: false,
    }

    renderLoading() {
        return (
            <ClipLoader
            css={css`position:absolute;top:38vh;left:49%`}
            size={50}
            color={Colors.semil.green}
            loading={true}
            />
        );
    }

    componentDidMount() {

    }

    async handleZipCodeChange(zipCode) {

        if (zipCode.length && this.state.zipcode !== zipCode) {

            this.setState({ zipcode: zipCode, loading: true });

            try {

                let info = await ZipCodeHelper.getAddress(zipCode);
    
                if (!info) { throw new Error('Erro ao buscar CEP'); }

                let address = `${info.logradouro} ${info.complemento}`;

                if (address <= 1) {
                    address = '';
                }

                this.setState({ loading: false, address: address, city: info.localidade, state: info.uf, country: 'Brasil', neighborhood: info.bairro });
    
            } catch (error) {
    
                this.setState({ loading: false });
            }
        }
    }

    async register() {

        if (this.state.name && this.state.cnpj && this.state.zipcode && this.state.country && this.state.state && this.state.city && this.state.email && this.state.password && this.state.confirmPassword) {

            if (this.state.password === this.state.confirmPassword) {

                try {

                    this.setState({ loading: true });

                    let cnpj = parseInt(this.state.cnpj.replace(/\D+/g, ''));

                    let body = {
                        email: this.state.email,
                        password: this.state.password,
                        doc: {
                            name: this.state.name,
                            cnpj: cnpj,
                            zipcode: this.state.zipcode,
                            country: this.state.country,
                            state: this.state.state,
                            city: this.state.city,
                            email: this.state.email,
                            address: this.state.address,
                            neighborhood: this.state.neighborhood,
                            type: 'customer',
                            active: false,
                        },
                    };

                let request = await fetch(`${Functions.getEndPoint()}/registerCustomer`, {headers: {"Content-Type": "application/json"}, method: 'POST', body: JSON.stringify(body) });
                    let response = await request.json();

                    if (response.status === 200) {

                        this.setState({ loading: false, success: true });

                    } else {

                        this.setState({ loading: false });

                        throw new Error('Erro ao cadastrar usuário');
                    }

                } catch (error) {

                    this.setState({ loading: false });
                    toast.error('Houve um problema ao cadastrar, entre em contato com o suporte');
                }

            } else {

                toast.warn('As senhas são diferentes');
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    renderForm() {

        return (
            <form style={{width: '100%'}}>                   
                <InputLabel>Dados da Empresa</InputLabel>
                <DefaultInput onBlur={(v) => { this.setState({ name: v }) }} required={true} label={'Nome'}/>
                <DefaultInput onBlur={(v) => { this.setState({ cnpj: v }) }} required={true} label={'Cnpj'}/>
                <DefaultInput onBlur={(v) => { this.handleZipCodeChange(v) }} required={true} label={'CEP'}/>
                
                <div style={{display: 'flex', flexDirection: 'row', paddingTop: 12}}>
                    <div style={{marginTop: 10, width: '100%'}}>
                        <DefaultSelect search={true} searchField={'nome'} id={'country-selection'} valueField={'nome'} displayField={'nome'} onChange={(v) => { this.setState({ country: v.target.value }); }} value={this.state.country} docs={CountryList} label={'País'}/>
                    </div>
                    <div style={{paddingLeft: 5, paddingRight: 5}}/>
                    <DefaultInput value={this.state.state} onChange={(v) => { this.setState({ state: v }) }} required={true} label={'Estado'}/>
                </div>

                <DefaultInput value={this.state.city} onChange={(v) => { this.setState({ city: v }) }} required={true} label={'Cidade'}/>

                <div style={{display: 'flex', flexDirection: 'row', paddingTop: 12}}>
                    <DefaultInput value={this.state.neighborhood} onChange={(v) => { this.setState({ neighborhood: v }) }} required={true} label={'Bairro'}/>
                    <div style={{paddingLeft: 5, paddingRight: 5}}/>
                    <DefaultInput value={this.state.address} onChange={(v) => { this.setState({ address: v }) }} required={true} label={'Endereço'}/>
                </div>

                <InputLabel style={{paddingTop: 45}}>Dados de Acesso</InputLabel>
                <DefaultInput onBlur={(v) => { this.setState({ email: v }) }} required={true} label={'E-mail'}/>
                <DefaultInput onBlur={(v) => { this.setState({ password: v }) }} required={true} type={'password'} label={'Senha'}/>
                <DefaultInput onBlur={(v) => { this.setState({ confirmPassword: v }) }} required={true} type={'password'} label={'Confirme a senha'}/>
                <Button onClick={() => { this.register() }} style={{ width: '100%', fontWeight: 'bold', marginTop: 30, padding: 15, backgroundColor: Colors.semil.green, color: 'white' }} variant="contained" >REGISTRAR</Button>
            </form>
        )
    }

    goBack() {

        window.location.href = '/?type=customer';
    }

    renderSuccess() {
        
        return (
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <img style={{height: 400}} src={process.env.PUBLIC_URL + '/assets/image/confirmation.png'}/>
                <p style={{fontWeight: 'bold', color: Colors.technician.defaultColor, fontSize: 40}}>{'Sucesso!'}</p>
                <div style={{color: 'grey', textAlign: 'center'}}>{'Recebemos o seu cadastro, e agora ele está em faze de análise'}</div>
                <div style={{color: 'grey', textAlign: 'center', paddingTop: 15, paddingBottom: 25}}>{'Enviaremos um e-mail de confirmação em breve.'}</div>
                <Button onClick={() => { this.goBack() }} style={{ width: '100%', fontWeight: 'bold', marginTop: 30, padding: 15, backgroundColor: Colors.semil.green, color: 'white' }} variant="contained" >VOLTAR</Button>
            </div>
        )
    }

    render() {
        return (
            <Container style={{backgroundColor: Colors.semil.green, display: 'flex', justifyContent: 'center'}} maxWidth={false}>
                <div style={{height: '100vh'}}>
                    <Card style={{ padding: 30, marginTop: '8vh', filter: this.state.loading ? 'blur(5px)' : '', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 800}}>
                        
                        { this.state.success ? this.renderSuccess() : this.renderForm() }

                    </Card>
                    {this.state.loading ? this.renderLoading() : null}
                </div>
            </Container>
        );
    }
}

export default withRouter(RegisterPage);
 