import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';

export default class PermissionPage extends Component {

    state = {
        docs: [],
        name: '',
        routes: {
            sat_dashboard: true,
            complaint_dashboard: true,
            chamados: true,
            map: true,
            calendar: true,
            report: true,
            client: true,
            order: true,
            warranty: true,
            curriculum: true,
            complaint: true,
            manual: true,
            of: true,
            technician: true,
            user: true,
            customer_login: true,
            satisfaction_research: true,
            email_template: true,
            email_automatization: true,
            product: true,
            highlight: true,
            newsletter: true,
            images: true,
            budget: true,
            settings: true,
            integration: true,
            permission: true,
            hour_pricing: true,
            email_account: true,
        },
        routesSubtitle: {
            sat_dashboard: 'Dashboard SAT',
            complaint_dashboard: 'Dashboard Denúncias',
            chamados: 'SAT',
            map: 'Mapa',
            calendar: 'Agenda',
            report: 'Relatórios',
            newsletter: 'Clientes',
            hour_pricing: 'Configurações ↳ Tipos de Horas',
            technician: 'Acessos ↳ Técnicos',
            client: 'Clientes',
            order: 'Pedidos de Venda',
            warranty: 'Garantias',
            curriculum: 'Currículos',
            complaint: 'Denúncias',
            manual: 'Manuais',
            product: 'Website ↳ Produtos',
            highlight: 'Website ↳ Destaques',
            email_automatization: 'Marketing ↳ Automação E-mail',
            email_account: 'Configurações ↳ Contas de E-mail',
            email_template: 'Marketing ↳ Templates de E-mail',
            satisfaction_research: 'Marketing ↳ Pesquisa Satisfação',
            permission: 'Configurações ↳ Permissões',
            integration: 'Configurações ↳ Integrações',
            settings: 'Configurações ↳ Parâmetros',
            user: 'Acessos ↳ Usuários',
            customer_login: 'Acessos ↳ Área do Cliente',
            images: 'Website ↳ Imagens',
            budget: 'App ↳ Orçamentos',
            of: 'OF',
        },
        loading: true,
        loadingModal: false,
        addModal: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('permission_group').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let routes = doc.data().routes;
            let data = {
                name: doc.data().name,
                id: doc.id
            };

            Object.keys(this.state.routes).forEach(key => {
                data[key] = routes[key]
            })

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
    }

    async addPermission() {

        if (this.state.name && this.state.routes) {

            let data = {
                name: this.state.name,
                routes: this.state.routes
            };

            try {

                this.setState({ loadingModal: true });

                await Firestore.insert(data, 'permission_group');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, addModal: false, name: ''});
                await this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    addModal() {
        return (
            <div>
                <DefaultInput label={'Nome'} onBlur={(v) => { this.setState({ name: v }) }}/>
                <div style={{paddingTop: 18}}>
                    <FormLabel component="legend">Menus</FormLabel>
                    <FormGroup>
                        {Object.keys(this.state.routes).map(key =>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.routes[key]} onChange={(v) => { let routes = this.state.routes; routes[key] = v; this.setState({ routes: routes }) }} />}
                                label={this.state.routesSubtitle[key]}
                            />
                        )}
                    </FormGroup>
                </div>

                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addPermission() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    getColumns() {
        let columns = [
            { title: 'Id', field: 'id', hidden: true },
            { title: 'Nome', field: 'name' }
        ];

        Object.keys(this.state.routes).forEach(key => {
            columns.push({ title: this.state.routesSubtitle[key], type: 'boolean', field: key });
        });

        return columns;
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultButton onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>

                <DefaultTable
                width={window.screen.width - 290}
                title={'Grupo de Permissões'}
                columns={this.getColumns()}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    let prev = this.state.docs;
                    const index = prev.findIndex(item => item.id === oldData.id);
                    prev[index] = newData;

                    this.setState({ docs: prev });
                    this.forceUpdate();

                    if (oldData.id) {

                        let data = {
                            name: newData.name,
                            routes: {}
                        };

                        Object.keys(this.state.routes).forEach(key => {
                            data.routes[key] = newData[key] ? newData[key] : false;
                        });

                        await Firestore.update(data, 'permission_group', oldData.id);

                    }

                    toast.success("Editado com sucesso.", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    toast.info("Clique aqui ou recarregue a página para surtir efeito no seu menu.", {
                        position: toast.POSITION.TOP_RIGHT,
                        onClick: () => { window.location.reload(); }
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        await Firestore.delete('permission_group', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />

                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Grupo de Permissão'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
