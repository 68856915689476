import React, { Component } from 'react';
import { Tabs, Tab, Tooltip, IconButton } from '@material-ui/core';
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import moment from 'moment';
import CallMadeIcon from '@material-ui/icons/CallMade';
import TechnicianCalendar from '../components/TechnicianCalendar';

export default class TechnicianMapTabs extends Component {

    state = {
        satDocs: [],
        loading: false,
        activeTab: 0,
    }
    
    componentDidMount() {
        this.getSATs();
    }

    async getSATs() {

        let query = await Firestore.customQuery('appointment').orderBy('start_date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {

            if (doc.data().technicians.length) {

                doc.data().technicians.forEach((item, key) => {
                    
                    if (item.id_technician === this.props.technician.id) {

                        let data = doc.data();
                        data.id = doc.id;
            
                        docs.push(data);
                    }
                });
            }
        });

        this.setState({ satDocs: docs });
    }

    render() {
        return (
            <div>
                <Tabs
                value={this.state.activeTab}
                variant={'fullWidth'}
                TabIndicatorProps={{ style: { backgroundColor: Colors.semil.green } }}
                style={{color: Colors.semil.green}}
                onChange={(evt, value) => { this.setState({ activeTab: value }); }}>

                    <Tab label={'SAT'} />
                    <Tab label={'Agenda'} />

                </Tabs>

                <div role="tabpanel" hidden={this.state.activeTab !== 0}>

                    {this.state.activeTab === 0 && (

                    <div style={{marginTop: 10}}>

                        { this.state.satDocs.map((item, key) => {
                            return (
                                <div key={key} style={{marginBottom: 20, borderBottom: '0.5px solid lightgrey', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <p><b>{'Nome: '}</b>{item.title} <Tooltip onClick={() => { window.open(`/chamados?id=${item.id}`, '_blank') }} title="Acessar SAT"><IconButton><CallMadeIcon style={{color: Colors.semil.green}}/></IconButton></Tooltip> <br></br> <b>{'Data Abertura: '}</b> {moment(item.start_date.toDate()).format('DD/MM/YYYY HH:mm')} <b>{'Data Prevista: '}</b> { moment(item.estimated_end_date.toDate()).format('DD/MM/YYYY HH:mm') }</p>
                                </div>
                            )

                            }) 
                        }

                        { !this.state.satDocs.length && <p style={{color: 'grey', textAlign: 'center', marginTop: 50}}>{'Nenhuma SAT encontrada.'}</p> }

                    </div>
                    )}

                </div>

                <div role="tabpanel" hidden={this.state.activeTab !== 1}>

                    {this.state.activeTab === 1 && (

                    <div style={{marginTop: 30}}>

                        <TechnicianCalendar filterTechnician={this.props.technician.id} noFilter={true}/>

                    </div>
                    )}

                </div>

            </div>
        )
    }
}