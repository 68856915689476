export default {
    semil: {
        green: 'rgb(38, 121, 53)',
        red: 'rgb(202, 10, 21)',
    },
    text: {
        secondaryText: '#999999',
        primaryText: 'rgb(51,51,51)'
    },
    technician: {
        defaultColor: '#00d084',
    },
    boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px',
    info: '#17a2b8',
    warning: '#ffc107',
    danger: '#dc3545',
}