export default {
	rolamentos: 'Rolamentos',
	mancais: 'Mancais',
	retentores: 'Retentores',
	correntes: 'Correntes',
	correias: 'Correias',
	polias: 'Polias',
	engrenagens: 'Engrenagens',
	rodas_dentadas: 'Rodas Dentadas',
	conjunto_pneumatico: 'Conjunto Pneumático',
	conjunto_hidraulico: 'Conjunto Hidráulico',
	motores: 'Motores',
	redutores: 'Redutores',
	motoredutores: 'Motoredutores',
	servomotores: 'Servomotores',
	aperto_de_parafusos: 'Aperto de Parafusos',
	fitas_lonas: 'Fitas / Lonas',
	buchas_geral: 'Buchas em Geral',
	lubrificacao_geral: 'Lubrificação em Geral',
	sensores_geral: 'Sensores em Geral',
	componentes_eletricos: 'Componentes Elétricos'
};