import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, MenuItem, Select, InputLabel, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import EditIcon from '@material-ui/icons/Edit';
import Firestore from '../api/firebase/Firestore';
import Storage from '../api/firebase/Storage';
import moment from 'moment';
import DeleteModal from '../components/DeleteModal';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import RichTextEditor from 'react-rte';
import { toast } from 'react-toastify';
import SessionHelper from '../helper/SessionHelper';

export default class CustomerDataPage extends Component {

    state = {
        loading: true,
        userData: SessionHelper.getData(),
        companyData: null,
    }

    componentDidMount() {

        this.getCompany();
    }

    async getCompany() {

        let query = await Firestore.customQuery('company').where('cnpj', '==', parseInt(this.state.userData.cnpj)).get();
        let data = null;

        if (query.size === 1) {

            data = query.docs[0].data();
        }

        this.setState({ loading: false, companyData: data});
    }

    async uploadPhoto(photo) {

        if (photo) {

            try {

                this.setState({ loadingModal: true });

                let ref = await Storage.uploadFile('customer_images', photo);
                let downloadPath = await Storage.getFileFromRef(ref);
        
                if (!downloadPath) {
                    throw new Error('upload error');
                }

                await Firestore.update({ photo: downloadPath }, 'user', SessionHelper.getFirebaseAuth().uid);
    
                toast.success("Imagem atualizada com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                let userData = this.state.userData;
                userData.photo = downloadPath;

                this.setState({ userData: userData });
    
            } catch (error) {
                console.log(error)
                toast.error("Houve um problema ao salvar a imagem", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    renderUserData() {
        return (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <div style={{width: '80%'}}>
                    <DefaultInput disabled value={this.state.userData.name} label={'Nome'}/>
                    <DefaultInput disabled value={this.state.userData.email} label={'E-mail'}/>
                    <DefaultInput disabled value={'Sim'} label={'Cadastro Ativo'}/>
                </div>
                <div style={{width: '20%', paddingLeft: 100}}>
                    <img style={{height: 150, borderRadius: 150 / 2, boxShadow: 'rgba(50, 50, 50, 0.47) 1px 1px 10px 0px'}} src={this.state.userData.photo || process.env.PUBLIC_URL + '/assets/image/empty-avatar.png'}/>
                    <input
                        accept="image/*"
                        style={{display: 'none'}}
                        id="contained-button-file"
                        type="file"
                        onChange={(v) => { this.uploadPhoto(v.target.files[0]) }}
                    />
                    <label style={{position: 'relative', bottom: 50, backgroundColor: Colors.semil.green, display: 'flex', width: 20, height: 20, alignItems: 'center', padding: 18, justifyContent: 'center', borderRadius: '100%', cursor: 'pointer'}} htmlFor="contained-button-file">
                        <EditIcon style={{color: 'white'}}/>
                    </label>
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <div style={{paddingTop: 40}}>
                    <Card style={{paddingLeft: 20, paddingRight: 20, marginBottom: 15, paddingBottom: 15}}>
                        <p style={{fontSize: 18, fontWeight: 'bold',}}>{'Meus dados'}</p>
                        {this.renderUserData()}

                        <p style={{fontSize: 18, fontWeight: 'bold',}}>{'Dados da Empresa'}</p>
                        <DefaultInput disabled value={this.state.companyData.name} label={'Razão'}/>
                        <DefaultInput disabled value={this.state.companyData.cnpj} label={'Cnpj'}/>
                        <DefaultInput disabled value={this.state.companyData.phone} label={'Telefone'}/>
                        <DefaultInput disabled value={this.state.companyData.country} label={'País'}/>
                        <DefaultInput disabled value={this.state.companyData.state} label={'Estado'}/>
                        <DefaultInput disabled value={this.state.companyData.city} label={'Cidade'}/>
                        <DefaultInput disabled value={this.state.companyData.address} label={'Endereço'}/>

                    </Card>
                </div>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
