import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import SendIcon from '@material-ui/icons/Send';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import moment from 'moment';
import { Button, FormLabel, Checkbox, Tooltip, IconButton } from '@material-ui/core';
import Colors from '../constants/Colors';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckIcon from '@material-ui/icons/Check';
import DescriptionIcon from '@material-ui/icons/Description';
import Storage from '../api/firebase/Storage';
import DefaultButton from '../components/DefaultButton';
import DefaultSelect from '../components/DefaultSelect';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import SessionHelper from '../helper/SessionHelper';
import QRCode from 'qrcode.react';
import DefaultInput from '../components/DefaultInput';
import Functions from '../constants/Functions';
import domtoimage from 'dom-to-image';
import { Loop } from '@material-ui/icons';

export default class ManualPage extends Component {

    state = {
        docs: [],
        orderDocs: [],
        companyDocs: [],
        userDocs: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        printModal: false,
        fileModal: false,
        id_edit: null,
        filesToUpload: [],
        company: null,
        order: null,
        orderItems: [],
        edit: false,
        files: [],
        emails: [],
        hover: null,
        emailHover: null,
        addEmail: false,
        emailName: '',
        contacts: [],
        selectedOrderItem: null,
        link: null,
        orderCode: null,
        emailHistoryModal: false,
        emailHistory: [],
        manualHistoryModal: false,
        manualHistory: [],
    }

    async getCompanies() {

        let query = await Firestore.customQuery('company').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ companyDocs: docs });
    }

    async getUserDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('user').where('type', '==', 'admin').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = { ...doc.data(), id: doc.id };

            docs.push(data);
        });

        this.setState({ userDocs: docs, loading: false });
    }

    async fetchOrders(company_cnpj) {

        this.setState({ loadingModal: true });

        if (company_cnpj) {

            let query = await Firestore.customQuery('order').where('billing_cnpj', '==', company_cnpj).orderBy('order_date', 'desc').get();
            let docs = [];

            query.forEach((doc, key) => {

                let row = { ...doc.data(), id: doc.id };
                docs.push(row);
            });

            this.setState({ orderDocs: docs, loadingModal: false });
        }
    }

    async getDocs() {

        let query = await Firestore.customQuery('manual').orderBy('date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async componentDidMount() {

        await this.setState({ loading: true });

        await this.getCompanies();
        await this.getUserDocs();
        await this.getDocs();

        await this.setState({ loading: false });
    }

    async handleAttatchmentUpload(files) {

        let orderItems = this.state.orderItems;

        this.setState({ loadingModal: true });

        if (files.length) {

            await new Promise( async (resolve, reject) => {

                Array.from(files).forEach( async (file, key) => {
    
                    let ref = await Storage.uploadFile('manual_attatchments', file);
                    let downloadPath = await Storage.getFileFromRef(ref);
            
                    if (!downloadPath) {throw new Error('upload error') };
    
                    let data = {
                        name: file.name,
                        link: downloadPath,
                        date: new Date(),
                    };

                    orderItems[this.state.selectedOrderItem].files.push(data);
    
                    if (key === files.length - 1) {
                        resolve();
                    }
                });          
            });
        }

        await Firestore.update({ order_items: orderItems }, 'manual', this.state.id_edit);

        this.setState({ loadingModal: false });
        toast.success('Arquivo anexado com sucesso');
    }

    onChange = (value) => { this.setState({editor: value, editorHtml: value.toString('html')}) };

    async removeFile(key) {

        let confirm = window.confirm('Tem certeza que deseja remover esse arquivo?');

        if (confirm) {

            this.setState({ loadingModal: true });

            let orderItems = this.state.orderItems;
            orderItems[this.state.selectedOrderItem].files.splice(key, 1);
    
            await Firestore.update({ order_items: orderItems }, 'manual', this.state.id_edit);

            this.setState({ orderItems: orderItems, loadingModal: false });
            toast.success('Arquivo removido com sucesso');
        }
    }

    async removeEmail(key) {

        let confirm = window.confirm('Tem certeza que deseja remover esse e-mail?');

        if (confirm) {

            this.setState({ loadingModal: true });

            let emails = this.state.emails;
            emails.splice(key, 1);
    
            await Firestore.update({ emails: emails }, 'manual', this.state.id_edit);

            this.setState({ emails: emails, loadingModal: false });
            toast.success('E-mail removido com sucesso');
        }
    }

    renderFiles() {

        return this.state.orderItems[this.state.selectedOrderItem].files.map((file, key) => {

            return (
                <div onClick={() => { window.open(file.link, '_blank') }} onMouseEnter={() => { this.setState({ hover: key }) }} onMouseLeave={() => { this.setState({ hover: null }) }} style={{ backgroundColor: Colors.semil.green, color: 'white', display: 'flex', position: 'relative', flexDirection: 'column', alignItems: 'center', cursor: 'pointer', justifyContent: 'center', width: 175, height: 175, borderRadius: 8, marginRight: 10, marginBottom: 10 }}>
                    { this.state.hover === key ? <DeleteIcon onClick={(evt) => { evt.stopPropagation(); this.removeFile(key); }} style={{ fontSize: 25, marginBottom: 8, position: 'absolute', right: 0, top: 0, marginRight: 8, marginTop: 8, color: 'white' }}/> : null }
                    <DescriptionIcon style={{ fontSize: 40, marginBottom: 8 }}/>
                    <Tooltip title={file.name}>
                        <div style={{ fontWeight: 'bold' }}>{file.name.length > 15 ? file.name.slice(0, 15) + '...' : file.name}</div>
                    </Tooltip>
                    <div style={{ fontSize: 10, fontStyle: 'italic', right: 0, bottom: 0, marginRight: 8, marginBottom: 8, position: 'absolute' }}>{moment(file.date.toDate ? file.date.toDate() : file.date).format('DD/MM/YYYY HH:mm')}</div>
                </div>
            );
        })
    }

    fileModal() {

        if (this.state.fileModal) {

            return (

                <div>
                    <DefaultSelect search={true} secondaryDisplay={'item_code'} searchField={'name'} id={'item_selection_files'} valueField={null} displayField={'name'} onChange={(v) => { this.setState({ selectedOrderItem: v.target.value }) }} value={this.state.selectedOrderItem} docs={this.state.orderItems} label={'Selecione o Item do Pedido'}/>
                    {
                        this.state.selectedOrderItem !== null ? 

                            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: 500, overflowY: 'scroll', marginTop: 50 }}>
                                { this.state.orderItems[this.state.selectedOrderItem].files && this.state.orderItems[this.state.selectedOrderItem].files.length ? this.renderFiles() : null }
                                <div>
                                <input
                                    style={{display: 'none'}}
                                    id="contained-button-file"
                                    type="file"
                                    multiple={false}
                                    onChange={(v) => { this.handleAttatchmentUpload(v.target.files) }}
                                    />
                                <label htmlFor="contained-button-file">
                                    <div style={{ backgroundColor: 'lightgrey', color: 'grey', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 175, height: 175, cursor: 'pointer', borderRadius: 8 }}>
                                        <AddIcon style={{ fontSize: 40 }}/>
                                    </div>
                                </label>
                                </div>
                            </div>

                        : this.renderEmpty('Selecione um item do pedido para anexar arquivos.')
                    }
                    <div style={{alignSelf: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.setState({ fileModal: false, files: [] }) }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    async handleClientChange(v) {

        await this.setState({ company: v.target.value, orderDocs: [], order: null, orderItems: [] });

        let company = this.state.companyDocs.find(item => item.id === v.target.value);
        await this.fetchOrders(company.cnpj);

        if (this.orderSelect) { this.orderSelect.updateDocs() };
    }

    handleOrderChange(v) {

        let order = this.state.orderDocs.find(item => item.id === v.target.value);
        let index = this.state.docs.findIndex(item => item.id_order === order.id);

        if (index >= 0) {

            toast.warn('Já existe um manual criado para esse pedido');

        } else {

            this.setState({ order: order.id, orderItems: order.order_items });
        }
    }

    async add() {

        try {

            if (this.state.order && this.state.company) {

                this.setState({ loadingModal: true });

                let selectedOrderItems = [];
    
                this.state.orderItems.forEach((item, key) => {
    
                    item.files = [];
                    selectedOrderItems.push(item);
                });
    
                let order = this.state.orderDocs.find(item => item.id === this.state.order);
                let company = this.state.companyDocs.find(item => item.id === this.state.company);

                if (this.state.edit) {

                    let data = {
                        order_code: order.order_code,
                        id_order: this.state.order,
                        id_company: this.state.company,
                        order_items: selectedOrderItems,
                    };

                    await Firestore.update(data, 'manual', this.state.id_edit);

                } else {

                    let id = await Firestore.getId('manual');
                    let emails = [];

                    let data = {
                        order_code: order.order_code,
                        id_order: this.state.order,
                        id_company: this.state.company,
                        id_user: SessionHelper.getFirebaseAuth().uid,
                        order_items: selectedOrderItems,
                        date: new Date(),
                        emails: emails,
                    };
    
                    await Firestore.insert(data, 'manual', id);
                }
                
                await this.getDocs();

                toast.success('Manual criado com sucesso');
                this.setState({ loadingModal: false, addModal: false, company: null, order: null, orderItems: [], orderDocs: [], edit: false, id_edit: null });

            } else {

                toast.warn('Preencha todos os campos');
            }

        } catch (error) {

            toast.error('Houve um problema ao salvar o manual');
            this.setState({ loadingModal: false });
        }
    }

    addModal() {

        if (this.state.addModal) {

            return (
                <div style={{ paddingTop: 15 }}>
                    <DefaultSelect search={true} secondaryDisplay={'city'} searchField={'name'} id={'company_selection'} valueField={'id'} displayField={'name'} onChange={(v) => { this.handleClientChange(v) }} value={this.state.company} docs={this.state.companyDocs} label={'Cliente'}/>
                    <div style={{ marginTop: 20, height: 100, borderRight: '3px dashed lightgrey', width: '50%' }}/>
                    <DefaultSelect ref={ref => this.orderSelect = ref} search={true} searchField={'order_code'} id={'order_selection'} valueField={'id'} displayField={'order_code'} onChange={(v) => { this.handleOrderChange(v) }} value={this.state.order} docs={this.state.orderDocs} label={'Pedido'}/>
                    <div style={{ marginTop: 30 }}>
                        <FormLabel style={{ marginBottom: 20 }} component="legend">Itens do Pedido</FormLabel>
                        { !this.state.order ? <p style={{ color: 'grey', marginLeft: 10, marginTop: 20 }}>Selecione um cliente e um pedido para marcar os itens do manual.</p> : null }
                        { this.state.order && !this.state.orderItems.length ? <p style={{ color: 'grey', marginLeft: 10, marginTop: 20 }}>Nenhum item encontrado para esse pedido.</p> : null }
                        {
                            this.state.orderItems.map((item, key) => {
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'row', padding: 10, color: Colors.semil.green, alignItems: 'center', border: `3px solid ${item.checked ? Colors.semil.green : 'lightgrey'}`, borderRadius: 8, marginBottom: 8 }}>
                                        <Checkbox style={{ color: item.checked ? Colors.semil.green : 'lightgrey' }} checked={item.checked} onChange={(v) => { let items = this.state.orderItems; items[key].checked = v.target.checked; this.setState({ orderItems: items }) }}/>
                                        <div style={{ marginLeft: 20, color: item.checked ? Colors.semil.green : 'grey' }}>{`${item.name} (${item.item_code})`}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.add() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    renderEmpty(text) {
        
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50 }}>
                <img style={{ height: 250 }} src={process.env.PUBLIC_URL + '/assets/image/no_data.png'}/>
                <p style={{ color: 'grey' }}>{text}</p>
            </div>
        )
    }

    async downloadQrCode() {

        if (this.state.loadingModal) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        // await this.setState({ loadingModal: true });

        setTimeout(async () => {

            let qrcode = document.getElementById('order-item-qrcode');

            if (qrcode) {

                    try {

                        let png = await domtoimage.toPng(qrcode)

                        var link = document.createElement('a');
                        link.download = `qrcode.png`;
                        link.href = png;
                        link.click();

                        await this.setState({ loadingModal: false });

                    } catch (error) {

                        toast.error('Houve um problema ao baixar o arquivo');
                        await this.setState({ loadingModal: false });
                    }


            }

        }, 1500);
    }

    async downloadGlobalQrCode() {

        if (this.state.loadingModal) {
            toast.warn('Aguarde, realizando ação...');
            return;
        }

        let actualLink = this.state.link;
        let actualItem = this.state.selectedOrderItem;
        await this.setState({ link: `https://connect.semil.com.br/user_manual?id=${this.state.id_edit}`, selectedOrderItem: null });

        setTimeout(async () => {

            let qrcode = document.getElementById('order-item-qrcode');

            if (qrcode) {

                try {
    
                    let png = await domtoimage.toPng(qrcode)
    
                    var link = document.createElement('a');
                    link.download = `qrcode.png`;
                    link.href = png;
                    link.click();
    
                    await this.setState({ link: actualLink, selectedOrderItem: actualItem, loadingModal: false });
    
                } catch (error) {
    
                    toast.error('Houve um problema ao baixar o arquivo');
                    await this.setState({ link: actualLink, loadingModal: false });
                }
            }

        }, 1500);
    }

    printModal() {

        if (this.state.printModal) {

            return (
                <div style={{ paddingTop: 15, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <DefaultSelect search={true} secondaryDisplay={'item_code'} searchField={'name'} id={'item_selection_print'} valueField={null} displayField={'name'} onChange={(v) => { this.setState({ selectedOrderItem: v.target.value, link: `https://connect.semil.com.br/user_manual?id=${this.state.id_edit}&order_item=${v.target.value}` }) }} value={this.state.selectedOrderItem} docs={this.state.orderItems} label={'Selecione o Item do Pedido'}/>
                    
                    { this.state.link !== null ? 
                    
                        <div id={'order-item-qrcode'}>
                            <div style={{ backgroundColor: Colors.semil.green, padding: 25, marginTop: 50, borderRadius: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <img style={{ height: 50, width: 200, marginBottom: 40 }} src={process.env.PUBLIC_URL + '/assets/image/semil white.png'} />
                                <div style={{ padding: 10, borderRadius: 8, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <QRCode value={this.state.link} bgColor={'white'} fgColor={'black'} size={200}/>
                                </div>
                                <p style={{ fontWeight: 'bold', color: 'white', marginTop: 20, textAlign: 'center', marginBottom: 0 }}>
                                    <div style={{ marginBottom: 8 }}>{'Manual de Instruções'}</div>
                                    {/* <br/> */}
                                    {`Pedido ${this.state.orderCode}`}
                                    <br/>
                                    { this.state.selectedOrderItem !== null ? `Cód. ${this.state.orderItems[this.state.selectedOrderItem].item_code}` : null}
                                </p>
                            </div>
                        </div>
                        
                    : this.renderEmpty('Selecione um item do pedido para gerar o QrCode.') }

                    { this.state.link !== null ? <p style={{ textAlign: 'center' }}>{this.state.link}</p> : null }

                    <div style={{alignSelf: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 50, width: '100%'}}>
                        <Button onClick={() => { this.downloadQrCode() }} disabled={this.state.link === null} style={{fontWeight: 'bold', backgroundColor: this.state.link === null ? 'lightgrey' : Colors.semil.green, color: this.state.link === null ? 'white' : '#fff', width: '100%', marginBottom: 15, display: 'flex', flexDirection: 'row', alignItems: 'center'}} variant={'contained'}>
                            <GetAppIcon style={{ marginRight: 8 }}/>
                            {'BAIXAR IMAGEM PARA IMPRESSÃO'}
                        </Button>
                        <Button onClick={() => { this.downloadGlobalQrCode() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%', marginBottom: 15, display: 'flex', flexDirection: 'row', alignItems: 'center'}} variant={'contained'}>
                            <GetAppIcon style={{ marginRight: 8 }}/>
                            {'BAIXAR QRCODE GLOBAL (COM TODOS OS ITENS DO PEDIDO)'}
                        </Button>
                    </div>
                </div>
            )
        }
    }

    async addEmail() {

        if (this.state.emailName.length) {

            this.setState({ loadingModal: true });

            let emails = this.state.emails;
            emails.push(this.state.emailName);

            await Firestore.update({ emails }, 'manual', this.state.id_edit);
            toast.success('E-mail adicionado com sucesso');

            this.setState({ loadingModal: false, emails: emails, emailName: '', addEmail: false });

        } else {

            toast.warn('Preencha o e-mail');
        }
    }

    emailHistoryModal() {

        if (this.state.emailHistoryModal) {

            return (
                <div style={{ paddingTop: 15 }}>
                    
                    {
                        !this.state.emailHistory.length ? 

                            <p style={{ color: 'grey' }}>{'Nenhum registro encontrado'}</p>
                        
                        : 
                            this.state.emailHistory.map((history, key) => {

                                let user = this.state.userDocs.find(item => item.id === history.id_user);
                                let emails = history.emails.map((email, emailKey) => {
                                    return email;
                                });

                                return (
                                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 8, padding: 15, color: Colors.semil.green, border: `2px solid ${Colors.semil.green}`, borderRadius: 8, position: 'relative' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: 'bold' }}>
                                            <SendIcon style={{ marginRight: 8 }}/>
                                            {`${moment(history.date.toDate()).calendar()} por ${user.name}`}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'grey', marginTop: 10 }}>
                                            <div style={{ marginRight: 8 }}>Enviado para</div>
                                            {emails.join(', ')}
                                        </div>
                                    </div>
                                )
                            })
                    }

                    <div style={{alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50, width: '100%'}}>
                        <Button onClick={() => { this.setState({ emailHistoryModal: false }) }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5}} variant={'contained'}>
                            {'FECHAR'}
                        </Button>
                    </div>
                </div>
            )
        }
    }

    manualHistoryModal() {

        if (this.state.manualHistoryModal) {

            return (
                <div style={{ paddingTop: 15 }}>
                    
                    {
                        !this.state.manualHistory.length ? 

                            <p style={{ color: 'grey' }}>{'Nenhum registro encontrado'}</p>
                        
                        : 
                            this.state.manualHistory.map((history, key) => {

                                return (
                                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 8, padding: 15, color: Colors.semil.green, border: `2px solid ${Colors.semil.green}`, borderRadius: 8, position: 'relative' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: 'bold' }}>
                                            <VisibilityIcon style={{ marginRight: 8 }}/>
                                            {`${moment(history.date.toDate()).calendar()}`}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'grey', marginTop: 10 }}>
                                            <div style={{ marginRight: 8 }}>{`${history.name} - ${history.email}`}</div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'grey', marginTop: 10 }}>
                                            <div style={{ marginRight: 8 }}>{`${history.ip ? 'IP ' + history.ip : 'IP não identificado'}`}</div>
                                        </div>
                                    </div>
                                )
                            })
                    }

                    <div style={{alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50, width: '100%'}}>
                        <Button onClick={() => { this.setState({ manualHistoryModal: false }) }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5}} variant={'contained'}>
                            {'FECHAR'}
                        </Button>
                    </div>
                </div>
            )
        }
    }

    emailModal() {

        if (this.state.emailModal) {

            return (
                <div style={{ paddingTop: 15 }}>
                    
                    {
                        !this.state.emails.length ? 

                            <p style={{ color: 'grey' }}>{'Nenhum e-mail cadastrado'}</p>
                        
                        : 
                            this.state.emails.map((email, key) => {

                                return (
                                    <div onMouseEnter={() => { this.setState({ emailHover: key }) }} onMouseLeave={() => { this.setState({ emailHover: null }) }} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 8, padding: 15, color: Colors.semil.green, border: `2px solid ${Colors.semil.green}`, borderRadius: 8, position: 'relative' }}>
                                        <EmailIcon style={{ marginRight: 8 }}/>
                                        {email}
                                        { this.state.emailHover === key ? <DeleteIcon onClick={() => { this.removeEmail(key) }} style={{ fontSize: 25, position: 'absolute', right: 0, marginRight: 8, cursor: 'pointer', color: Colors.semil.red }}/> : null }
                                    </div>
                                )
                            })
                    }

                    { 
                    
                        !this.state.addEmail ?
                        
                            <Tooltip title={'Adicionar'}>
                                <IconButton onClick={() => { this.setState({ addEmail: true }) }} style={{ backgroundColor: 'white', boxShadow: Colors.boxShadow, border: '1px solid lightgrey' }}>
                                    <AddIcon style={{ color: Colors.semil.green }}/>
                                </IconButton>
                            </Tooltip>

                        :

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <DefaultInput onBlur={(v) => { this.setState({ emailName: v }) }} label={'E-mail'}/>
                                <IconButton onClick={() => { this.addEmail() }} style={{ backgroundColor: 'white', boxShadow: Colors.boxShadow, border: '1px solid lightgrey', width: 50, height: 50, marginLeft: 5 }}>
                                    <CheckIcon style={{ color: Colors.semil.green }}/>
                                </IconButton>
                                <IconButton onClick={() => { this.setState({ addEmail: false, emailName: '' }) }} style={{ backgroundColor: 'white', boxShadow: Colors.boxShadow, border: '1px solid lightgrey', width: 50, height: 50, marginLeft: 5 }}>
                                    <DeleteIcon style={{ color: Colors.semil.red }}/>
                                </IconButton>
                            </div>
                    }

                    <h2>Sugestões</h2>
                    {
                        !this.state.contacts.length ? 

                            <p style={{ color: 'grey' }}>{'Nenhuma sugestão de e-mail encontrada'}</p>
                        
                        : 
                            this.state.contacts.map((email, key) => {

                                if (!this.state.emails.includes(email)) {

                                    return (
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 8, padding: 15, color: Colors.semil.green, border: `2px solid ${Colors.semil.green}`, borderRadius: 8, position: 'relative' }}>
                                            <FlashOnIcon style={{ marginRight: 8 }}/>
                                            {email}
                                        </div>
                                    )
                                }
                            })
                    }

                    <div style={{alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50, width: '100%'}}>
                        <Button onClick={() => { this.sendManual() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5}} variant={'contained'}>
                            <SendIcon style={{ marginRight: 8 }}/>
                            {'ENVIAR MANUAL'}
                        </Button>
                        <Button onClick={() => { this.setState({ emailModal: false, emails: [] }) }} style={{width: '100%', fontWeight: 'bold', marginLeft: 5}} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    async sendManual() {

        if (this.state.emails.length) {

            this.setState({ loadingModal: true });

            let request = await fetch(`${Functions.getEndPoint()}/sendManualEmail`, {headers: {"Content-Type": "application/json"}, method: 'POST', body: JSON.stringify({ id: this.state.id_edit }) });

            if (request.status === 200) {

                let query = await Firestore.getDoc('manual', this.state.id_edit);
                let manual = { ...query.data(), id: query.id };
                let history = manual.email_history || [];

                history.push({
                    emails: manual.emails,
                    id_user: SessionHelper.getFirebaseAuth().uid,
                    date: new Date()
                });

                await Firestore.update({ sended: true, email_history: history }, 'manual', this.state.id_edit);
                await this.getDocs();

                this.setState({ emailModal: false, loadingModal: false, emails: [], id_edit: null });
                toast.success('E-mails enviados com sucesso');

            } else {

                this.setState({ loadingModal: false });
                toast.error('Houve um problema ao enviar os E-mails');
            }

        } else {

            toast.warn('Adicione ao menos um e-mail para enviar');
        }
    }

    async handleEdit(data) {

        this.setState({ addModal: true, loadingModal: true });

        let company = this.state.companyDocs.find(item => item.id === data.id_company);

        await this.fetchOrders(company.cnpj);

        let order = this.state.orderDocs.find(item => item.id === data.id_order);
        let orderItems = order.order_items;

        orderItems.forEach((item, key) => {

            data.order_items.forEach((savedItem, key) => {

                if (savedItem.item_code === item.item_code) {
    
                    orderItems[key].checked = true;
                }
            });
        });

        if (this.orderSelect) { this.orderSelect.updateDocs() };

        this.setState({ loadingModal: false, company: company.id, order: data.id_order, edit: true, id_edit: data.id, orderItems: orderItems });
    }

    renderSended(sended) {

        let color = '';
        let text = '';

        if (sended) {

            color = Colors.semil.green;
            text = 'Enviado';

        } else {

            color = Colors.semil.red;
            text = 'Não enviado';
        }

        return <b style={{ backgroundColor: color, color: 'white', fontWeight: 'bold', padding: 6, borderRadius: 5 }}>{text}</b>
    }

    renderClient(data) {

        let company = this.state.companyDocs.find(item => item.id === data.id_company);

        return <div>{`${company.name} (${company.city})`}</div>
    }

    handleEmailModal(rowData) {

        let company = this.state.companyDocs.find(item => item.id === rowData.id_company);
        let contacts = [];

        if (company.contacts && company.contacts.length) {
    
            company.contacts.forEach((contact, key) => {
                
                if (contact.contact_email) {

                    contacts.push(contact.contact_email);
                }
            });
        }

        this.setState({ emailModal: true, id_edit: rowData.id, emails: rowData.emails || [], contacts: contacts });
    }

    companySearch(term, id) {

        term = term.toString().toUpperCase();
        let company = this.state.companyDocs.find(item => item.id === id);

        if (company) {

            if (company.name.toUpperCase().indexOf(term) != -1) {

                return true;
            }

            if (company.city.toUpperCase().indexOf(term) != -1) {

                return true;
            }
        }

        return false;
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultButton onClick={() => { this.setState({ addModal: true }) }} title={'Novo Manual'}/>
                <DefaultTable
                title={'Manuais'}
                width={window.screen.width - 290}
                actions={[
                    {
                        icon: EmailIcon,
                        tooltip: 'Enviar por E-mail',
                        onClick: (event, rowData) => { this.handleEmailModal(rowData) },
                    },
                    {
                        icon: Loop,
                        tooltip: 'Histórico de Envio',
                        onClick: (event, rowData) => { this.setState({ emailHistoryModal: true, emailHistory: rowData.email_history.reverse() }) },
                    },
                    {
                        icon: VisibilityIcon,
                        tooltip: 'Histórico de Acessos',
                        onClick: (event, rowData) => { this.setState({ manualHistoryModal: true, manualHistory: rowData.manual_history.reverse() }) },
                    },
                    {
                        icon: InsertDriveFileIcon,
                        tooltip: 'Anexar Arquivos',
                        onClick: (event, rowData) => {

                            let orderItems = [];
                            rowData.order_items.forEach((item, key) => {

                                if (item.checked) {

                                    orderItems.push(item);
                                }
                            });

                            this.setState({ fileModal: true, id_edit: rowData.id, orderItems: orderItems, selectedOrderItem: null }) 
                        },
                    },
                    {
                        icon: LocalPrintshopIcon,
                        tooltip: 'Imprimir QRCode',
                        onClick: (event, rowData) => {

                            let orderItems = [];
                            rowData.order_items.forEach((item, key) => {

                                if (item.checked) {

                                    orderItems.push(item);
                                }
                            });

                            this.setState({ printModal: true, orderItems: orderItems, id_edit: rowData.id, orderCode: rowData.order_code, selectedOrderItem: null, link: null })
                        },
                    },
                    {
                        icon: EditIcon,
                        tooltip: 'Editar',
                        onClick: (event, rowData) => { this.handleEdit(rowData) },
                    },
                ]}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Pedido', field: 'order_code', editable: false },
                    { title: 'Cliente', field: 'id_company', render: rowData => this.renderClient(rowData), editable: false, customFilterAndSearch: (term, rowData) => this.companySearch(term, rowData.id_company) },
                    { title: 'Enviado', field: 'sended', render: rowData => this.renderSended(rowData.sended), editable: false },
                    { title: 'Data', field: 'date', render: rowData => <div>{moment(rowData.date.toDate()).format('DD/MM/YYYY HH:mm')}</div>, editable: false },
                ]}
                data={this.state.docs}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        await Firestore.delete('manual', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />
                <DefaultModal width={1200} content={this.fileModal()} loading={this.state.loadingModal} title={'Anexar Arquivos'} onClose={() => { this.setState({ fileModal: false }) }} open={this.state.fileModal}/>
                <DefaultModal width={1200} content={this.addModal()} loading={this.state.loadingModal} title={'Novo Manual'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
                <DefaultModal width={1200} content={this.printModal()} loading={this.state.loadingModal} title={'QRCode'} onClose={() => { this.setState({ printModal: false }) }} open={this.state.printModal}/>
                <DefaultModal width={1200} content={this.emailModal()} loading={this.state.loadingModal} title={'Enviar por E-mail'} onClose={() => { this.setState({ emailModal: false, emails: [] }) }} open={this.state.emailModal}/>
                <DefaultModal width={1200} content={this.emailHistoryModal()} loading={this.state.loadingModal} title={'Histórico de Envio'} onClose={() => { this.setState({ emailHistoryModal: false }) }} open={this.state.emailHistoryModal}/>
                <DefaultModal width={1200} content={this.manualHistoryModal()} loading={this.state.loadingModal} title={'Histórico de Acessos'} onClose={() => { this.setState({ manualHistoryModal: false }) }} open={this.state.manualHistoryModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
