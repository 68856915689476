import React, { Component } from 'react';
import DefaultSelect from './DefaultSelect';
import ClipLoader from "react-spinners/ClipLoader";
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';

export default class CompanySelect extends Component {

    state = {
        docs: [],
        loading: true,
    }

    async componentDidMount() {

        if (this.props.onLoadStart) {

            this.props.onLoadStart();
        }

        await this.getCompanies();

        this.setState({ loading: false });

        if (this.props.onLoadEnd) {

            this.props.onLoadEnd();
        }
    }

    async getCompanies() {

        let query = await Firestore.customQuery('company').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    renderLoading() {

        if (!this.props.hideLoading) {

            return (

                <ClipLoader
                size={15}
                color={Colors.semil.green}
                loading={true}
                />
            )

        } else {

            return null;
        }
    }

    render() {

        return this.state.loading ? this.renderLoading() : <DefaultSelect {...this.props} docs={this.state.docs} secondaryDisplay={'city'} search={true} searchField={'name'} valueField={'id'} displayField={'name'}/>
    }
}
