import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DescriptionIcon from '@material-ui/icons/Description';
import TimerIcon from '@material-ui/icons/Timer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PrintIcon from '@material-ui/icons/Print';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import DefaultInput from '../components/DefaultInput';
import DefaultSelect from '../components/DefaultSelect';
import Storage from '../api/firebase/Storage';
import Functions from '../constants/Functions';
import moment from 'moment';
import jsPDF from 'jspdf';
import PDFHelper from '../helper/PDFHelper';
import { CheckCircle } from '@material-ui/icons';
import { MTableToolbar } from 'material-table';

export default class UserPage extends Component {

    state = {
        docs: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        termModal: false,
        name: '',
        email: '',
        phone: '',
        file: '',
        term: '',
        termBlob: null,
        blob: null,
        editId: null,
        showArchived: false,
        showViewed: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('curriculum').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = { ...doc.data(), id: doc.id };

            if (this.state.showArchived && !data.file) {
             
                if ((this.state.showViewed && data.status === 'viewed') || (!this.state.showViewed && (!data.status || data.status === 'not_viewed'))) {

                    docs.push(data);
                }
            
            } else if (!this.state.showArchived && data.file) {

                if ((this.state.showViewed && data.status === 'viewed') || (!this.state.showViewed && (!data.status || data.status === 'not_viewed'))) {

                    docs.push(data);
                }
            }
        });

        this.setState({ docs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
    }

    async addCurriculum() {

        if (this.state.name && this.state.phone && this.state.file) {

            this.setState({ loadingModal: true });
            
            let ref = await Storage.uploadFile('website_curriculos', this.state.blob);
            let downloadPath = await Storage.getFileFromRef(ref);

            if (!downloadPath) {
                throw new Error('upload error');
            }

            let data = {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                file: downloadPath,
                type: 'platform',
                date: new Date(),
            };

            try {

                await Firestore.insert(data, 'curriculum');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, addModal: false, name: '', email: '', phone: '', file: '', blob: null });
                await this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    async uploadTerm() {

        if (this.state.term) {

            this.setState({ loadingModal: true });
            
            let ref = await Storage.uploadFile('termos_curriculo', this.state.termBlob);
            let downloadPath = await Storage.getFileFromRef(ref);

            if (!downloadPath) {
                throw new Error('upload error');
            }

            let data = {
                term: downloadPath
            };

            try {

                await Firestore.update(data, 'curriculum', this.state.editId);

                toast.success("Termo salvo com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, termModal: false, term: '', termBlob: null });
                await this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao salvar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Selecione um arquivo", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    async printTerm(name) {

        this.setState({ loading: true });

        let doc = new jsPDF();
        let verticalPosition = 10
        let left = 25;
        let right = 190;
        let wrapWidth = 165

        name = name.toUpperCase();

        doc.setFontSize(9);
        doc.setTextColor(255,0,0)
        doc.text('Material de propriedade e uso exclusivo da SEMIL EQUIPAMENTOS INDUSTRIAIS LTDA. Este material não pode ser copiado, reproduzido ou redistribuído, total ou parcialmente, sem o', 200, 280,{angle: 90} )
        doc.text('prévio consentimento e autorização desta empresa.', 205, 190,{angle: 90})
        doc.setTextColor(0,0,0)

        doc.setLineWidth(0.7);
        doc.line(left, verticalPosition, right, verticalPosition, 'F');
        doc.setLineWidth(0.200025);

        verticalPosition += 8
        let logo = await PDFHelper.imageToBase64(process.env.PUBLIC_URL + '/assets/image/logo.png');

        doc.addImage(logo, 'PNG', left, verticalPosition - 5, 40, 0)

        doc.setFontSize(14)
        doc.setFontType('bold')
        doc.text('Instrução Normativa', 100, verticalPosition, {align: 'center'})

        doc.setFontSize(10)

        doc.text(`Nº\nIN - SGS - 006` , 170, verticalPosition - 1, {align: 'center'})

        verticalPosition += 7

        doc.text('GESTÃO DA T.I', 100, verticalPosition, {align: 'center'})
        doc.setFontType('normal')

        verticalPosition += 2

        doc.line(left, verticalPosition, right, verticalPosition, 'F');

        verticalPosition += 5
        doc.text('TERMO DE ENTREGA POLITICA DE CURRICULOS.', 100, verticalPosition, {align: 'center'});

        verticalPosition += 3
        doc.setLineWidth(0.7);
        doc.line(left, verticalPosition, right, verticalPosition, 'F');
        doc.setLineWidth(0.200025);

        //Footer;
        doc.setLineWidth(0.7);
        doc.line(left, 265, right, 265, 'F');
        doc.setLineWidth(0.200025);
        verticalPosition += 3

        doc.setFontType('bold')
        doc.text('Nº', left + 10, 273, {align: 'center'})
        doc.setFontType('normal')
        doc.text('REV. 00', left + 10, 278, {align: 'center'})
        doc.setFontSize(10);

        doc.setFontType('bold')
        doc.text('DESCRIÇÃO:', left + 30, 273)
        doc.setFontType('normal')
        doc.text('Emissão inicial.', left + 30, 278)
        doc.setFontSize(10)
        
        doc.setFontType('bold')
        doc.text('DATA:', left + 110, 273, {align: 'center'})
        doc.setFontType('normal')
        doc.text('29/06/2021', left + 110, 278, {align: 'center'})
        doc.setFontSize(10)

        doc.setFontType('bold')
        doc.text('APROVADO POR:', left + 130, 273)
        doc.setFontType('normal')
        doc.text('Cardiomar Miozzo\nDiretor Administrativo', left + 130, 278)
        doc.setFontSize(10)
        verticalPosition += 3

        doc.setLineWidth(0.7);
        doc.line(left, 285, right, 285, 'F');
        doc.setLineWidth(0.200025);
        verticalPosition += 3

        doc.text('Página: ' + '1 de 3', right - 25, 290)
        doc.setFontSize(9)

        verticalPosition+= 15;

        var line1 = `Eu ${name} recebi e estou ciente da política de currículos, fornecida no momento da entrega do meu curriculo físico na SEMIL EQUIPAMENTOS INDUSTRIAIS LTDA.`;

        var splitText = doc.splitTextToSize(line1, wrapWidth)
        
        for (var i = 0, length = splitText.length; i < length; i++) {
            doc.text(splitText[i], left, verticalPosition)
            verticalPosition = 7 + verticalPosition
        }

        verticalPosition += 3;
        doc.setFontType('bold');
        doc.text('Política de Privacidade de Currículos', left, verticalPosition);
        doc.setFontType('normal');

        verticalPosition += 10;
        var line2 = 'A SEMIL EQUIPAMENTOS INDUSTRIAIS LTDA tem como objetivo buscar profissionais para preencher vagas de emprego. Esta política de privacidade de currículos descreve como e em que circunstâncias a SEMIL EQUIPAMENTOS LTDA coleta, utiliza e compartilha sua informação quando você utilizar a entrega de currículos. Se tiver quaisquer dúvidas ou comentários relacionados a esta Política de Privacidade, fale conosco pelo endereço ti@semil.com.br ou pelo link https://semil.com.br/lgpd.html';
        var splitText = doc.splitTextToSize(line2, wrapWidth)
        
        for (var i = 0, length = splitText.length; i < length; i++) {
            doc.text(splitText[i], left, verticalPosition)
            verticalPosition = 7 + verticalPosition
        }

        verticalPosition += 3;
        doc.setFontType('bold');
        doc.text('1. Coleta de dados', left, verticalPosition);
        doc.setFontType('normal');

        verticalPosition += 10;
        doc.setFontType('bold');
        doc.text('Para candidatos', left, verticalPosition);
        doc.setFontType('normal');

        verticalPosition += 6;
        var line3 = 'Solicitamos aos usuários: Nome, Telefone, e-mail e curriculo anexado direto no sitio, sendo o único objetivo a identificação dos candidatos, podemos solicitar posteriormente informações relacionadas à sua experiência profissional, escolaridade, habilidades profissionais, etc. Essas informações são essenciais no processo seletivo da empresa. A SEMIL EQUIPAMENTOS INDUSTRIAIS também realiza a coleta física de currículos. Os currículos físicos são recebidos no setor de recepção da empresa, no momento do recebimento o candidato terá acesso a esta política e deve ser assinada e uma cópia ficara sobre domínio da Semil para fins de atendimento a LGPD, o currículo físico será descartado imediatamente e será gerado uma via DIGITAL, seguindo as mesmas regras do curriculo entregue via site.';
        var splitText = doc.splitTextToSize(line3, wrapWidth)
        
        for (var i = 0, length = splitText.length; i < length; i++) {
            doc.text(splitText[i], left, verticalPosition)
            verticalPosition = 7 + verticalPosition
        }

        verticalPosition += 6;
        doc.setFontType('bold');
        doc.text('Tempo de retenção dos dados', left, verticalPosition);
        doc.setFontType('normal');

        verticalPosition += 6;
        var splitText = doc.splitTextToSize('Currículos físicos ou digitais, serão armazenados por 90 dias, posteriormente a esta data os mesmos serão eliminados da base de dados da SEMIL. Política física assinada pelo candidato, juntamente com o nome, será armazenada por tempo indeterminado pelo motivo de segurança jurídica da SEMIL. Com o único objetivo de identificação de usuários em futuros questionamentos.', wrapWidth)
        
        for (var i = 0, length = splitText.length; i < length; i++) {
            doc.text(splitText[i], left, verticalPosition)
            verticalPosition = 7 + verticalPosition
        }

        doc.addPage();
        verticalPosition = 10;

        doc.setFontSize(9);
        doc.setTextColor(255,0,0)
        doc.text('Material de propriedade e uso exclusivo da SEMIL EQUIPAMENTOS INDUSTRIAIS LTDA. Este material não pode ser copiado, reproduzido ou redistribuído, total ou parcialmente, sem o', 200, 280,{angle: 90} )
        doc.text('prévio consentimento e autorização desta empresa.', 205, 190,{angle: 90})
        doc.setTextColor(0,0,0)

        doc.setLineWidth(0.7);
        doc.line(left, verticalPosition, right, verticalPosition, 'F');
        doc.setLineWidth(0.200025);

        verticalPosition += 8
        doc.addImage(logo, 'PNG', left, verticalPosition - 5, 40, 0)

        doc.setFontSize(14)
        doc.setFontType('bold')
        doc.text('Instrução Normativa', 100, verticalPosition, {align: 'center'})

        doc.setFontSize(10)

        doc.text(`Nº\nIN - SGS - 006` , 170, verticalPosition - 1, {align: 'center'})

        verticalPosition += 7

        doc.text('GESTÃO DA T.I', 100, verticalPosition, {align: 'center'})
        doc.setFontType('normal')

        verticalPosition += 2

        doc.line(left, verticalPosition, right, verticalPosition, 'F');

        verticalPosition += 5
        doc.text('TERMO DE ENTREGA POLITICA DE CURRICULOS.', 100, verticalPosition, {align: 'center'});

        verticalPosition += 3
        doc.setLineWidth(0.7);
        doc.line(left, verticalPosition, right, verticalPosition, 'F');
        doc.setLineWidth(0.200025);

        //Footer;
        doc.setLineWidth(0.7);
        doc.line(left, 265, right, 265, 'F');
        doc.setLineWidth(0.200025);
        verticalPosition += 3

        doc.setFontType('bold')
        doc.text('Nº', left + 10, 273, {align: 'center'})
        doc.setFontType('normal')
        doc.text('REV. 00', left + 10, 278, {align: 'center'})
        doc.setFontSize(10);

        doc.setFontType('bold')
        doc.text('DESCRIÇÃO:', left + 30, 273)
        doc.setFontType('normal')
        doc.text('Emissão inicial.', left + 30, 278)
        doc.setFontSize(10)
        
        doc.setFontType('bold')
        doc.text('DATA:', left + 110, 273, {align: 'center'})
        doc.setFontType('normal')
        doc.text('29/06/2021', left + 110, 278, {align: 'center'})
        doc.setFontSize(10)

        doc.setFontType('bold')
        doc.text('APROVADO POR:', left + 130, 273)
        doc.setFontType('normal')
        doc.text('Cardiomar Miozzo\nDiretor Administrativo', left + 130, 278)
        doc.setFontSize(10)
        verticalPosition += 3

        doc.setLineWidth(0.7);
        doc.line(left, 285, right, 285, 'F');
        doc.setLineWidth(0.200025);
        verticalPosition += 3

        doc.text('Página: ' + '2 de 3', right - 25, 290)
        doc.setFontSize(9)

        verticalPosition += 10;
        doc.setFontType('bold');
        doc.text('2. Armazenamento e Transferência de Dados', left, verticalPosition);
        doc.setFontType('normal');

        verticalPosition += 6;
        var splitText = doc.splitTextToSize('A SEMIL EQUIPAMENTOS INDUSTRIAIS LTDA não divulga ou compartilha dados pessoais privados do seu registro. Os dados de acesso (inclusive e-mail), endereço, etc, não serão fornecidos a terceiros, salvo em casos onde haver a', wrapWidth)
        
        for (var i = 0, length = splitText.length; i < length; i++) {
            doc.text(splitText[i], left, verticalPosition)
            verticalPosition = 7 + verticalPosition
        }

        verticalPosition += 6;
        var splitText = doc.splitTextToSize('autorização prévia do usuário. Em virtude de ordens judiciais, ou de disposições legais e administrativas. A SEMIL pode ser compelido a revelar informação às autoridades ou terceiras partes. Os currículos recebidos são visualizados e processados pelo setor de Recursos Humanos, onde o mesmo encaminha a seleção de cada vaga anunciada. Todos os currículos enviados são mantidos em sistema de segurança protegido por usuário e senha, os mesmos armazenados em data-center com as melhores certificações do mercado.', wrapWidth)
        
        for (var i = 0, length = splitText.length; i < length; i++) {
            doc.text(splitText[i], left, verticalPosition)
            verticalPosition = 7 + verticalPosition
        }

        verticalPosition += 10;
        doc.setFontType('bold');
        doc.text('3. Uso e divulgação de Informações', left, verticalPosition);
        doc.setFontType('normal');

        verticalPosition += 6;
        var splitText = doc.splitTextToSize('A SEMIL utiliza internamente os dados coletados. Dados e informações coletados dos usuários são utilizados internamente para:', wrapWidth)
        
        for (var i = 0, length = splitText.length; i < length; i++) {
            doc.text(splitText[i], left, verticalPosition)
            verticalPosition = 7 + verticalPosition
        }

        verticalPosition += 6;
        doc.text('• Identificar os usuários', left + 8, verticalPosition);

        verticalPosition += 10;
        doc.text('• Cumprir de ordem legal ou judicial.', left + 8, verticalPosition);

        verticalPosition += 10;
        doc.text('• Elaborar estatísticas gerais.', left + 8, verticalPosition);

        verticalPosition += 10;
        doc.text('• Demais atividades técnicas relacionadas a empresa.', left + 8, verticalPosition);

        verticalPosition += 15;
        doc.setFontType('bold');
        doc.text('4. Permissão para envio de Mensagens', left, verticalPosition);
        doc.setFontType('normal');

        verticalPosition += 6;
        var splitText = doc.splitTextToSize('Solicitamos sua autorização no momento do registro no formulário do site para o envio de comunicação para seu e-mail fornecido. Acreditamos que essas informações sejam relevantes e compatíveis com os seus objetivos de uso do site e envio do currículo. Para des-cadastramento de currículos favor enviar e- mail para ti@semil.com.br', wrapWidth)
        
        for (var i = 0, length = splitText.length; i < length; i++) {
            doc.text(splitText[i], left, verticalPosition)
            verticalPosition = 7 + verticalPosition
        }

        doc.addPage();
        verticalPosition = 10;

        doc.setFontSize(9);
        doc.setTextColor(255,0,0)
        doc.text('Material de propriedade e uso exclusivo da SEMIL EQUIPAMENTOS INDUSTRIAIS LTDA. Este material não pode ser copiado, reproduzido ou redistribuído, total ou parcialmente, sem o', 200, 280,{angle: 90} )
        doc.text('prévio consentimento e autorização desta empresa.', 205, 190,{angle: 90})
        doc.setTextColor(0,0,0)

        doc.setLineWidth(0.7);
        doc.line(left, verticalPosition, right, verticalPosition, 'F');
        doc.setLineWidth(0.200025);

        verticalPosition += 8
        doc.addImage(logo, 'PNG', left, verticalPosition - 5, 40, 0)

        doc.setFontSize(14)
        doc.setFontType('bold')
        doc.text('Instrução Normativa', 100, verticalPosition, {align: 'center'})

        doc.setFontSize(10)

        doc.text(`Nº\nIN - SGS - 006` , 170, verticalPosition - 1, {align: 'center'})

        verticalPosition += 7

        doc.text('GESTÃO DA T.I', 100, verticalPosition, {align: 'center'})
        doc.setFontType('normal')

        verticalPosition += 2

        doc.line(left, verticalPosition, right, verticalPosition, 'F');

        verticalPosition += 5
        doc.text('TERMO DE ENTREGA POLITICA DE CURRICULOS.', 100, verticalPosition, {align: 'center'});

        verticalPosition += 3
        doc.setLineWidth(0.7);
        doc.line(left, verticalPosition, right, verticalPosition, 'F');
        doc.setLineWidth(0.200025);

        //Footer;
        doc.setLineWidth(0.7);
        doc.line(left, 265, right, 265, 'F');
        doc.setLineWidth(0.200025);
        verticalPosition += 3

        doc.setFontType('bold')
        doc.text('Nº', left + 10, 273, {align: 'center'})
        doc.setFontType('normal')
        doc.text('REV. 00', left + 10, 278, {align: 'center'})
        doc.setFontSize(10);

        doc.setFontType('bold')
        doc.text('DESCRIÇÃO:', left + 30, 273)
        doc.setFontType('normal')
        doc.text('Emissão inicial.', left + 30, 278)
        doc.setFontSize(10)
        
        doc.setFontType('bold')
        doc.text('DATA:', left + 110, 273, {align: 'center'})
        doc.setFontType('normal')
        doc.text('29/06/2021', left + 110, 278, {align: 'center'})
        doc.setFontSize(10)

        doc.setFontType('bold')
        doc.text('APROVADO POR:', left + 130, 273)
        doc.setFontType('normal')
        doc.text('Cardiomar Miozzo\nDiretor Administrativo', left + 130, 278)
        doc.setFontSize(10)
        verticalPosition += 3

        doc.setLineWidth(0.7);
        doc.line(left, 285, right, 285, 'F');
        doc.setLineWidth(0.200025);
        verticalPosition += 3

        doc.text('Página: ' + '3 de 3', right - 25, 290)
        doc.setFontSize(9)

        verticalPosition += 10;
        doc.setFontType('bold');
        doc.text('5. Alterações a esta política', left, verticalPosition);
        doc.setFontType('normal');

        verticalPosition += 6;
        var splitText = doc.splitTextToSize('A SEMIL poderá revisar esta Política de Privacidade periodicamente. Quando houver alguma alteração que consideremos significativa, cabe ao usuário solicitar na sede da empresa uma nova versão da mesma. Ao assinar está política tenho conhecimento aos procedimentos adotados pela SEMIL, e concordo com os termos impostos acima.', wrapWidth)
        
        for (var i = 0, length = splitText.length; i < length; i++) {
            doc.text(splitText[i], left, verticalPosition)
            verticalPosition = 7 + verticalPosition
        }

        verticalPosition += 50;
        doc.line((doc.internal.pageSize.width / 2) - 40, verticalPosition, (doc.internal.pageSize.width / 2) + 40, verticalPosition, 'F');

        verticalPosition += 5;
        doc.text(name, (doc.internal.pageSize.width / 2), verticalPosition, { align: 'center' });

        doc.save('Termo Currículo.pdf');

        this.setState({ loading: false });
    }

    async changeStatus(id) {

        let confirm = window.confirm('Tem certeza que deseja marcar esse currículo como visualizado?');

        if (confirm) {

            try {

                this.setState({ loading: true });

                await Firestore.update({ status: 'viewed' }, 'curriculum', id);
                await this.getDocs();

                this.setState({ loading: false });
                toast.success('Status alterado com sucesso');

            } catch (error) {

                this.setState({ loading: false });
                toast.error('Houve um problema ao alterar o status');

            }
        }
    }

    async showArchived() {
        await this.setState({ showArchived: !this.state.showArchived}, async () => await this.getDocs());
    }

    async showViewed() {
        await this.setState({ showViewed: !this.state.showViewed}, async () => await this.getDocs());
    }

    addModal() {

        if (this.state.addModal) {
            return (
                <div>
                    <DefaultInput onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'}/>
                    <DefaultInput onBlur={(v) => { this.setState({ email: v }) }} label={'E-mail'}/>
                    <DefaultInput onBlur={(v) => { this.setState({ phone: v }) }} label={'Telefone'}/>
                    
                    { this.state.file ? <p style={{ textAlign: 'center', padding: 25 }}>{this.state.file}</p> : <p style={{ textAlign: 'center', padding: 25, color: 'grey' }}>Nenhum arquivo selecionado.</p>}

                    <input
                    accept="application/pdf"
                    style={{display: 'none'}}
                    id="contained-button-file"
                    type="file"
                    onChange={(v) => { this.setState({ blob: v.target.files[0], file: v.target.files[0].name }) }}
                    />
                    <label htmlFor="contained-button-file">
                        <Button startIcon={<CloudUploadIcon/>} component="span" style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'UPLOAD'}</Button>
                    </label>
                    
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.addCurriculum() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    termModal() {

        if (this.state.termModal) {
            return (
                <div>
                    
                    { this.state.term ? <p style={{ textAlign: 'center', padding: 25 }}>{this.state.term}</p> : <p style={{ textAlign: 'center', padding: 25, color: 'grey' }}>Nenhum arquivo selecionado.</p>}

                    <input
                    accept="application/pdf"
                    style={{display: 'none'}}
                    id="contained-button-file-term"
                    type="file"
                    onChange={(v) => { this.setState({ termBlob: v.target.files[0], term: v.target.files[0].name }) }}
                    />
                    <label htmlFor="contained-button-file-term">
                        <Button startIcon={<CloudUploadIcon/>} component="span" style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'UPLOAD'}</Button>
                    </label>
                    
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.uploadTerm() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ termModal: false, editId: null, term: '', termBlob: null }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    renderType(type) {

        if (type === 'website') {

            return <div>{'Website'}</div>

        } else {

            return <div>{'Físico'}</div>
        }
    }

    renderTime(date) {

        let curriculumDate = moment(date.toDate ? date.toDate() : date).startOf('day');
        let removeDate = moment(curriculumDate).add(90, 'days');
        let actualDate = moment();

        let days = removeDate.diff(actualDate, 'days') + 1;

        let color = '';
        let text = `Será removido em ${days} dias`;

        if (days > 7) {

            color = 'green';

        } else if (days > 3) {

            color = 'orange';

        } else if (days > 0) {

            color = '#db4d4d';

        } else {

            color = '#db4d4d';
            text = 'Currículo removido';
        }

        return (
            <div style={{ backgroundColor: color, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5, width: 250, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <TimerIcon style={{ fontSize: 16, marginRight: 5 }}/>
                <b>{text}</b>
            </div>
        )
    }

    renderStatus(status) {

        let color = '';
        let text = '';

        if (!status || status === 'not_viewed') {

            color = 'orange';
            text = 'Não Visualizado';

        } else if (status === 'viewed') {

            color = Colors.semil.green;
            text = 'Visualizado';
        }

        return (
            <div style={{ backgroundColor: color, color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 5, display: 'inline', whiteSpace: 'nowrap' }}>
                <b>{text}</b>
            </div>
        )
    }

    handleTermModal(rowData) {

        if (rowData.type === 'website') {

            toast.warn('Não é possível anexar um termo em um currículo via Website');

        } else {

            if (!rowData.term) {

                this.setState({ termModal: true, editId: rowData.id, term: '', termBlob: null });
    
            } else {
                
                toast.warn('Termo já anexado para esse currículo');
            }
        }
    }

    statusSort(a, b) {

        return (a.status === b.status) ? 0 : a.status === 'viewed' ? -1 : 1;
    } 

    renderToolbar(props) {
        return (
            <div>
                <MTableToolbar {...props} />
                <div style={{paddingLeft: 24, display: 'flex', flexDirection: 'row'}}>
                    <div onClick={() => { this.showArchived() }} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10, backgroundColor: this.state.showArchived ? Colors.semil.green : '', color: this.state.showArchived ? '#fff' : Colors.semil.green, border: `2px solid ${Colors.semil.green}`, fontWeight: 'bold', cursor: 'pointer', borderRadius: 25}}>
                        <TimerIcon style={{marginRight: 8}}/>
                        <div>{'Removidos'}</div>
                    </div>
                    <div onClick={() => { this.showViewed() }} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10, backgroundColor: this.state.showViewed ? Colors.semil.green : '', color: this.state.showViewed ? '#fff' : Colors.semil.green, border: `2px solid ${Colors.semil.green}`, fontWeight: 'bold', cursor: 'pointer', borderRadius: 25}}>
                        <TimerIcon style={{marginRight: 8}}/>
                        <div>{'Visualizados'}</div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultButton onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>
                <DefaultTable
                title={'Currículos'}
                actions={[
                    {
                        icon: CheckCircle,
                        tooltip: 'Marcar como Visualizado',
                        onClick: (event, rowData) => { this.changeStatus(rowData.id) } 
                    },
                    {
                        icon: PrintIcon,
                        tooltip: 'Imprimir Termo para Assinatura',
                        onClick: (event, rowData) => { this.printTerm(rowData.name) } 
                    },
                    {
                        icon: AttachFileIcon,
                        tooltip: 'Anexar Termo',
                        onClick: (event, rowData) => { this.handleTermModal(rowData) } 
                    },
                    {
                        icon: BorderColorIcon,
                        tooltip: 'Visualizar Termo',
                        onClick: (event, rowData) => { rowData.term ? window.open(rowData.term, '_blank') : toast.warn('Termo não anexado') } 
                    },
                    {
                        icon: DescriptionIcon,
                        tooltip: 'Visualizar Currículo',
                        onClick: (event, rowData) => { rowData.file ? window.open(rowData.file, '_blank') : toast.warn('Esse currículo não está mais disponível') } 
                    },
                ]}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Status', field: 'status', editable: false, render: rowData => this.renderStatus(rowData.status), customSort: (row, value) => this.statusSort(row, value) },
                    { title: 'Observação', field: 'obs' },
                    { title: 'Nome', field: 'name', editable: false, },
                    { title: 'E-mail', field: 'email', editable: false, },
                    { title: 'Telefone', field: 'phone', editable: false, },
                    { title: 'Tipo', field: 'type', render: rowData => this.renderType(rowData.type), editable: false, },
                    { title: 'Tempo', field: 'type', render: rowData => this.renderTime(rowData.date), editable: false, },
                    { title: 'Data', field: 'date', render: rowData => <div>{moment(rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('DD/MM/YYYY HH:mm')}</div>, editable: false, },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)].obs = newData.obs;

                    this.setState({ docs: prev });

                    let update = {
                        obs: newData.obs,
                    }

                    await Firestore.update(update, 'curriculum', oldData.id);

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        await Firestore.delete('curriculum', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                components={{
                    Toolbar: props => ( this.renderToolbar(props) ),
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Adicionar Currículo'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.termModal()} title={'Anexar Termo'} onClose={() => { this.setState({ termModal: false }) }} open={this.state.termModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
