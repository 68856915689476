import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, Tooltip, IconButton, Checkbox, FormControlLabel, InputLabel, Select, MenuItem, Input, FormLabel, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { MTableToolbar } from 'material-table';
import PeopleIcon from '@material-ui/icons/People';
import ArchiveIcon from '@material-ui/icons/Archive';
import StarIcon from '@material-ui/icons/Star';
import CheckIcon from '@material-ui/icons/Check';
import AirplaneIcon from '@material-ui/icons/AirplanemodeActive';
import ClearIcon from '@material-ui/icons/Clear';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PrintIcon from '@material-ui/icons/Print';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AssigmentIcon from '@material-ui/icons/Assignment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Firestore from '../api/firebase/Firestore';
import Storage from '../api/firebase/Storage';
import moment from 'moment';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CachedIcon from '@material-ui/icons/Cached';
import GetAppIcon from '@material-ui/icons/GetApp';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SATHelper from '../helper/SATHelper';
import SessionHelper from '../helper/SessionHelper';
import TechnicianCalendar from '../components/TechnicianCalendar';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DefaultSelect from '../components/DefaultSelect';
import CurrencyHelper from '../helper/CurrencyHelper';
import PDFHelper from '../helper/PDFHelper';
import Functions from '../constants/Functions';
import CompanySelect from '../components/CompanySelect';
import RelationField from '../components/RelationField';
import CompanyOrderSelect from '../components/CompanyOrderSelect';

export default class AppointmentPage extends Component {

    state = {
        docs: [],
        userDocs: [],
        selected_company: '',
        requester: '',
        requester_email: '',
        requester_phone: '',
        estimated_hours: 0,
        hourPricingDocs: [],
        satisfactionResearchDocs: [],
        technicianDocs: [],
        loading: true,
        gridLoading: false,
        loadingModal: false,
        addSatModal: false,
        historyPush: null,
        historyModal: false,
        historyMessage: null,
        satPriority: null,
        reopenSATModal: false,
        closeSATModal: false,
        expensesModal: false,
        reopen_id: null,
        expenses_id: null,
        id_edit: null,
        expenses: [],
        orderItemsModal: false,
        order: null,
        rowOrderItems: [],
        attatchmentsModal: false,
        attatchments: null,
        technicians: null,
        techniciansModal: false,
        selected_technician: '',
        showCalendar: false,
        technicianStart: null,
        technicianEnd: null,
        showArchived: false,
        showFinished: false, 
        archiveSATModal: false,
        mainTechnician: false,
        //TavelSheet Start
        travelDocs: [],
        travelSheetModal: false,
        workType: 2,
        workDescription: '',
        workData: null,
        workNotes: '',
        travelExpenses: {
            aereo: [false, 'n/a'],
            terrestre: [false, 'n/a'],
            translado: [false, 'n/a'],
            uber: [false, 'n/a'],
            locacao: [false, 'n/a'],
            hospedagem: [false, 'n/a'],
            alimentacao: [false, 'n/a']
        },
        travelResources: {
            carreta: [false, ''],
            cartao: [false, ''],
            dinheiro: [false, ''],
            nota: [false, '']
        },
        transportation: 1,
        travelFlag: false,
        tavelId: null,
        travelStateLoadede: false,
        //TavelSheet End
        sendSatisfactionResearch: false,
        satisfactionResearchEmail: '',
        satisfactionResearch: null,
        checkedOutTechnicians: [],
        config: {},
        satisfactionResearchAnswers: [],
        satisfactionResearchAnswersModal: false,
        types: [
            { value: 'text', label: 'Resposta de texto'},
            { value: 'multiple_select', label: 'Seleção de múltiplos valores'},
            { value: 'individual_select', label: 'Seleção individual'},
            { value: 'star_rating', label: 'Avaliação em estrelas'},
        ],
    }

    async getDocs(archived = false, shouldUpdate = true, finished = false) {

        if ((archived || finished) || !shouldUpdate) {

            this.setState({ gridLoading: true });

        } else if (shouldUpdate) {

            this.setState({ loading: true });
        }

        let query;

        if (archived) {

            query = await Firestore.customQuery('appointment').where('status', '==', 'archived').orderBy('start_date', 'desc');

        } else if (finished) {

            query = await Firestore.customQuery('appointment').where('status', '==', 'closed').orderBy('start_date', 'desc');

        } else {

            query = await Firestore.customQuery('appointment').where('status', '==', 'open').orderBy('start_date', 'desc');
        }

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            if (!(!archived && data.status === 'archived')) {
                docs.push(data);
            }
        });

        this.setState({ docs: docs, gridLoading: false });
    }

    async getUsers() {

        let query = await Firestore.customQuery('user').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ userDocs: docs });
    }

    async getHourPricing() {

        let query = await Firestore.customQuery('hour_pricing').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ hourPricingDocs: docs });
    }

    async getSatisfactionResearch() {

        let query = await Firestore.customQuery('satisfaction_research').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ satisfactionResearchDocs: docs });
    }

    async getTechnicians() {

        let query = await Firestore.customQuery('technician').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ technicianDocs: docs });
    }

    async getTravelData() {

        let query = await Firestore.customQuery('travel_sheet').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ travelDocs: docs });
    }

    async getConfig() {

        let query = await Firestore.customQuery('settings').get();
        let data = this.state.config;

        if (query.size === 1 && query.docs[0]) {
            data = query.docs[0].data();
            data.id = query.docs[0].id;
        }

        this.setState({ config: data });
    }

    async componentDidMount() {

        await this.getDocs();
        await this.getUsers();
        await this.getTechnicians();
        await this.getHourPricing();
        await this.getSatisfactionResearch();
        await this.getTravelData();
        await this.getConfig();

        this.setState({ loading: false });
    }

    getHourPricingLookup() {
        let docs = this.state.hourPricingDocs;
        let lookup = {};

        docs.forEach((doc, key) => {
            lookup[doc.id] = doc.type;
        });

        return lookup;
    }

    getSatisfactionResearchLookup() {
        let docs = this.state.satisfactionResearchDocs;
        let lookup = {};

        docs.forEach((doc, key) => {
            lookup[doc.id] = doc.name;
        });

        return lookup;
    }

    addSatModal() {
        if (this.state.addSatModal) {

            return (
                <div>
    
                    <CompanySelect onLoadStart={() => { this.setState({ loadingModal: true }) }} onLoadEnd={() => { this.setState({ loadingModal: false }) }} hideLoading={true} onChange={(v) => { this.setState({ selected_company: v.target.value }) }} value={this.state.selected_company} label={'Selecionar empresa'}/>
                    
                    <div style={{marginTop: 30}}>
                        <InputLabel id="demo-simple-select-label">Prioridade</InputLabel>
                        <Select
                        style={{width: '100%'}}
                        labelId="demo-simple-select-label"
                        value={this.state.satPriority}
                        onChange={(v) => { this.setState({ satPriority: v.target.value }) }}>
                                <MenuItem style={{color: 'green'}} value={'low'}>{'Baixa'}</MenuItem>
                                <MenuItem style={{color: 'orange'}} value={'normal'}>{'Normal'}</MenuItem>
                                <MenuItem style={{color: 'red'}} value={'high'}>{'Alta'}</MenuItem>
                        </Select>
                    </div>
    
                    <div style={{marginTop: 30}}>
                        <InputLabel id="hour-type">Tipo de Hora</InputLabel>
                        <Select
                        style={{width: '100%'}}
                        labelId="hour-type"
                        value={this.state.hour_pricing}
                        onChange={(v) => { this.setState({ hour_pricing: v.target.value }) }}>
                                { this.state.hourPricingDocs.map((doc, key) => {
                                    return <MenuItem key={key} value={doc.id}>{doc.type}</MenuItem>
                                }) }
                        </Select>
                    </div>
    
                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                        style={{width: '100%', marginTop: 15, marginBottom: 15}}
                        invalidDateMessage={false}
                        format={'dd/MM/yyyy'}
                        autoOk={true}
                        label="Data Prevista"
                        cancelLabel={'Cancelar'}
                        okLabel={'Confirmar'}
                        onChange={(v) => { this.setState({ estimated_end_date: v }) }}
                        value={this.state.estimated_end_date}
                        />
                    </MuiPickersUtilsProvider>

                    <DefaultInput type={'number'} onBlur={(v) => { this.setState({ estimated_hours: v }) }} label={'Horas Estimadas pelo Comercial'}/>

                    <FormLabel style={{marginTop: 10, marginBottom: 10}} component="legend">Solicitante</FormLabel>
                    <DefaultInput onBlur={(v) => { this.setState({ requester: v }) }} label={'Nome'}/>
                    <DefaultInput onBlur={(v) => { this.setState({ requester_email: v }) }} label={'E-mail'}/>
                    <DefaultInput onBlur={(v) => { this.setState({ requester_phone: v }) }} label={'Telefone'}/>
    
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.addSat() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ addSatModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
    
                </div>
            )
        }
    }

    async addSat(){

        if (this.state.selected_company && this.state.hour_pricing && this.state.estimated_end_date && this.state.satPriority) {

            this.setState({ loadingModal: true })

            try {

                let data = {
                    answered_satisfaction_research: false,
                    attachments: [],
                    company_id: this.state.selected_company,
                    end_date: null,
                    estimated_end_date: this.state.estimated_end_date,
                    history: [],
                    hour_pricing_id: this.state.hour_pricing,
                    order_id: null,
                    priority: this.state.satPriority,
                    satisfaction_research_id: '',
                    start_date: new Date(),
                    status: 'open',
                    technicians: [],
                    order_items: [],
                    title: `Assistência #${moment().format('DDMMYYYYHHmmss')}`,
                    user_id: SessionHelper.getFirebaseAuth().uid,
                    requester: this.state.requester,
                    requester_email: this.state.requester_email,
                    requester_phone: this.state.requester_phone,
                    estimated_hours: this.state.estimated_hours,
                };

                let doc = await Firestore.getId('appointment');

                await Firestore.insert(data, 'appointment', doc);
                await SATHelper.historyIteration(doc, data.user_id, 'Usuário criou a SAT');

                await this.setState({ loadingModal: false })
                await this.setState({ addSatModal: false });
                toast.success('SAT cadastrado com sucesso');
                await this.getDocs();

            } catch (error) {
                console.log(error);
                await this.setState({ loadingModal: false });
                await this.setState({ addSatModal: false });
                toast.error('Erro ao cadastrar SAT');
            }

        } else {
            toast.warn('Preencha todos os campos');
        }
    }
    
    updateTravelExpences(field, checked = null, resp = null){
        
        let travelState = this.state.travelExpenses

        let updated = false

        if(field.toLowerCase() == 'aereo'){

            if (checked !== null) {travelState.aereo = [checked, travelState.aereo[1]]}
            
            if (resp !== null) {travelState.aereo = [travelState.aereo[0], resp]}

            updated = true

        }else if(field.toLowerCase() == 'terrestre'){

            if (checked !== null) {travelState.terrestre = [checked, travelState.terrestre[1]]}
            
            if (resp !== null) {travelState.terrestre = [travelState.terrestre[0], resp]}

            updated = true

        }else if(field.toLowerCase() == 'translado'){

            if (checked !== null) {travelState.translado = [checked, travelState.translado[1]]}
            
            if (resp !== null) {travelState.translado = [travelState.translado[0], resp]}

            updated = true

        }else if(field.toLowerCase() == 'uber'){

            if (checked !== null) {travelState.uber = [checked, travelState.uber[1]]}
            
            if (resp !== null) {travelState.uber = [travelState.uber[0], resp]}

            updated = true

        }else if(field.toLowerCase() == 'locacao'){

            if (checked !== null) {travelState.locacao = [checked, travelState.locacao[1]]}
            
            if (resp !== null) {travelState.locacao = [travelState.locacao[0], resp]}

            updated = true

        }else if(field.toLowerCase() == 'hospedagem'){

            if (checked !== null) {travelState.hospedagem = [checked, travelState.hospedagem[1]]}
            
            if (resp !== null) {travelState.hospedagem = [travelState.hospedagem[0], resp]}

            updated = true

        }else if(field.toLowerCase() == 'alimentacao'){

            if (checked !== null) {travelState.alimentacao = [checked, travelState.alimentacao[1]]}
            
            if (resp !== null) {travelState.alimentacao = [travelState.alimentacao[0], resp]}

            updated = true

        }

        if (updated){
            this.setState({ travelExpenses: travelState })
        }
        
    }

    updateTravelResources(field, checked = null, obs = ''){
        
        let travelState = this.state.travelResources

        let updated = false

        if(field.toLowerCase() == 'carreta'){

            if (checked !== null) {travelState.carreta = [checked, travelState.carreta[1]]}
            
            if (obs !== null) {travelState.carreta = [travelState.carreta[0], obs]}

            updated = true

        }else if(field.toLowerCase() == 'cartao'){

            if (checked !== null) {travelState.cartao = [checked, travelState.cartao[1]]}
            
            if (obs !== null) {travelState.cartao = [travelState.cartao[0], obs]}

            updated = true

        }else if(field.toLowerCase() == 'dinheiro'){

            if (checked !== null) {travelState.dinheiro = [checked, travelState.dinheiro[1]]}
            
            if (obs !== null) {travelState.dinheiro = [travelState.dinheiro[0], obs]}

            updated = true

        }else if(field.toLowerCase() == 'nota'){

            if (checked !== null) {travelState.nota = [checked, travelState.nota[1]]}
            
            if (obs !== null) {travelState.nota = [travelState.nota[0], obs]}

            updated = true
        }

        if (updated){
            this.setState({ travelResources: travelState })
        }
        
    }

    travelSheetModal() {

        if (this.state.travelSheetModal) {

            if (!this.state.travelFlag) {this.setTravelState()}

            if (this.state.loadingModal !== false) {this.setState({ loadingModal: false })}

            return (

                <div>

                    {/*Tipo do Trabalho*/}
                    <div style={{marginTop: 30}}>
                        <InputLabel id="demo-simple-select-label">Tipo do Trabalho</InputLabel>
                        <Select
                        style={{width: '100%'}}
                        labelId="demo-simple-select-label"
                        value={this.state.workType}
                        onChange={(v) => { this.setState({ workType: v.target.value }) }}>
                            {
                                this.state.config.work_types.map((type, key) => {
                                    return <MenuItem value={key + 1}>{type}</MenuItem>
                                })
                            }
                        </Select>
                    </div>

                    {/*Descrição do Trabalho*/}
                    <div style={{marginTop: 30}}>
                        <InputLabel id="demo-simple-select-label">Descrição do Trabalho</InputLabel>
                        <DefaultInput multiline={true} defaultValue={this.state.workDescription} id={'description'} onBlur={(v) => { this.setState({ workDescription: v }) }}/>
                    </div>

                    {/*Despesas de viagem*/}
                    <div style={{marginTop: 30, width: '100%'}}>

                        <div style={{display: 'flex'}}>
                            <InputLabel style={{width: 'auto'}} id="demo-simple-select-label">Despesas de viagem </InputLabel>
                            <InputLabel style={{width: '48%', marginLeft: 'auto'}} id="demo-simple-select-label">Responsabilidade</InputLabel>
                        </div>

                        <div style={{marginTop: 3, display: 'flex'}}>

                            <FormControlLabel style={{width: 'auto'}} control={<Checkbox checked={this.state.travelExpenses.aereo[0]} onChange={(v) => {this.updateTravelExpences('aereo', v.target.checked)}}/>} label={`Transporte aéreo`}/>

                            <Select 
                            style={{width: '48%', paddingTop: 4, marginLeft: 'auto'}}
                            labelId="demo-simple-select-label"
                            value={this.state.travelExpenses.aereo[1]}
                            onChange={(v) => {this.updateTravelExpences('aereo', null, v.target.value)}}>
                                <MenuItem value={'contratada'}>{'Contratada'}</MenuItem>
                                <MenuItem value={'contratante'}>{'Contratante'}</MenuItem>
                                <MenuItem value={'n/a'}>{'N/A'}</MenuItem>
                            </Select>

                        </div>

                        <div style={{marginTop: 3, display: 'flex'}}>

                            <FormControlLabel style={{width: 'auto'}} control={<Checkbox checked={this.state.travelExpenses.terrestre[0]} onChange={(v) => {this.updateTravelExpences('terrestre', v.target.checked)}}/>} label={`Transporte terrestre`}/>

                            <Select 
                            style={{width: '48%', paddingTop: 4, marginLeft: 'auto'}}
                            labelId="demo-simple-select-label"
                            value={this.state.travelExpenses.terrestre[1]}
                            onChange={(v) => {this.updateTravelExpences('terrestre', null, v.target.value)}}>
                                <MenuItem value={'contratada'}>{'Contratada'}</MenuItem>
                                <MenuItem value={'contratante'}>{'Contratante'}</MenuItem>
                                <MenuItem value={'n/a'}>{'N/A'}</MenuItem>
                            </Select>

                        </div>

                        <div style={{marginTop: 3, display: 'flex'}}>

                            <FormControlLabel style={{width: 'auto'}} control={<Checkbox checked={this.state.travelExpenses.translado[0]} onChange={(v) => {this.updateTravelExpences('translado', v.target.checked)}}/>} label={`Translado no local do trabalho`}/>

                            <Select 
                            style={{width: '48%', paddingTop: 4, marginLeft: 'auto'}}
                            labelId="demo-simple-select-label"
                            value={this.state.travelExpenses.translado[1]}
                            onChange={(v) => {this.updateTravelExpences('translado', null, v.target.value)}}>
                                <MenuItem value={'contratada'}>{'Contratada'}</MenuItem>
                                <MenuItem value={'contratante'}>{'Contratante'}</MenuItem>
                                <MenuItem value={'n/a'}>{'N/A'}</MenuItem>
                            </Select>

                        </div>

                        <div style={{marginTop: 3, display: 'flex'}}>

                            <FormControlLabel style={{width: 'auto'}} control={<Checkbox checked={this.state.travelExpenses.uber[0]} onChange={(v) => {this.updateTravelExpences('uber', v.target.checked)}}/>} label={`Taxi/Uber`}/>

                            <Select 
                            style={{width: '48%', paddingTop: 4, marginLeft: 'auto'}}
                            labelId="demo-simple-select-label"
                            value={this.state.travelExpenses.uber[1]}
                            onChange={(v) => {this.updateTravelExpences('uber', null, v.target.value)}}>
                                <MenuItem value={'contratada'}>{'Contratada'}</MenuItem>
                                <MenuItem value={'contratante'}>{'Contratante'}</MenuItem>
                                <MenuItem value={'n/a'}>{'N/A'}</MenuItem>
                            </Select>

                        </div>
                        
                        <div style={{marginTop: 3, display: 'flex'}}>

                            <FormControlLabel style={{width: 'auto'}} control={<Checkbox checked={this.state.travelExpenses.locacao[0]} onChange={(v) => {this.updateTravelExpences('locacao', v.target.checked)}}/>} label={`Locação de veículo`}/>

                            <Select 
                            style={{width: '48%', paddingTop: 4, marginLeft: 'auto'}}
                            labelId="demo-simple-select-label"
                            value={this.state.travelExpenses.locacao[1]}
                            onChange={(v) => {this.updateTravelExpences('locacao', null, v.target.value)}}>
                                <MenuItem value={'contratada'}>{'Contratada'}</MenuItem>
                                <MenuItem value={'contratante'}>{'Contratante'}</MenuItem>
                                <MenuItem value={'n/a'}>{'N/A'}</MenuItem>
                            </Select>

                        </div>

                        <div style={{marginTop: 3, display: 'flex'}}>

                            <FormControlLabel style={{width: 'auto'}} control={<Checkbox checked={this.state.travelExpenses.hospedagem[0]} onChange={(v) => {this.updateTravelExpences('hospedagem', v.target.checked)}}/>} label={`Hospedagem`}/>

                            <Select 
                            style={{width: '48%', paddingTop: 4, marginLeft: 'auto'}}
                            labelId="demo-simple-select-label"
                            value={this.state.travelExpenses.hospedagem[1]}
                            onChange={(v) => {this.updateTravelExpences('hospedagem', null, v.target.value)}}>
                                <MenuItem value={'contratada'}>{'Contratada'}</MenuItem>
                                <MenuItem value={'contratante'}>{'Contratante'}</MenuItem>
                                <MenuItem value={'n/a'}>{'N/A'}</MenuItem>
                            </Select>

                        </div>

                        <div style={{marginTop: 3, display: 'flex'}}>

                            <FormControlLabel style={{width: 'auto'}} control={<Checkbox checked={this.state.travelExpenses.alimentacao[0]} onChange={(v) => {this.updateTravelExpences('alimentacao', v.target.checked)}}/>} label={`Alimentação`}/>

                            <Select 
                            style={{width: '48%', paddingTop: 4, marginLeft: 'auto'}}
                            labelId="demo-simple-select-label"
                            value={this.state.travelExpenses.alimentacao[1]}
                            onChange={(v) => {this.updateTravelExpences('alimentacao', null, v.target.value)}}>
                                <MenuItem value={'contratada'}>{'Contratada'}</MenuItem>
                                <MenuItem value={'contratante'}>{'Contratante'}</MenuItem>
                                <MenuItem value={'n/a'}>{'N/A'}</MenuItem>
                            </Select>

                        </div>

                    </div>

                    {/*Transporte*/}
                    <div style={{marginTop: 30}}>
                        <InputLabel id="demo-simple-select-label">Transporte</InputLabel>
                        <Select
                        style={{width: '100%'}}
                        labelId="demo-simple-select-label"
                        value={this.state.transportation}
                        onChange={(v) => {this.setState({ transportation: v.target.value })}}>
                            <MenuItem value={1}>{'Veículo Semil'}</MenuItem>
                            <MenuItem value={2}>{'Caminhão Semil'}</MenuItem>
                            <MenuItem value={3}>{'Locação de veículo'}</MenuItem>
                            <MenuItem value={4}>{'Avião'}</MenuItem>
                        </Select>
                    </div>

                    {/*Recursos necessários*/}
                    <div style={{marginTop: 30, width: '100%'}}>

                        <InputLabel style={{width: 'auto'}} id="demo-simple-select-label">Recursos necessários</InputLabel>

                        <div style={{marginTop: 3, display: 'flex'}}>

                            <FormControlLabel style={{width: '220px'}} control={<Checkbox checked={this.state.travelResources.carreta[0]} onChange={(v) => {this.updateTravelResources('carreta', v.target.checked)}}/>} label={'Carreta'}/>

                            <InputLabel style={{marginTop: 19}} id="demo-simple-select-label">Obs:</InputLabel>
                            <DefaultInput defaultValue={this.state.travelResources.carreta[1]} multiline={true} id={'description'} onBlur={(v) => {this.updateTravelResources('carreta', null, v)}}/>

                        </div>

                        <div style={{marginTop: 3, display: 'flex'}}>

                            <FormControlLabel style={{width: '220px'}} control={<Checkbox checked={this.state.travelResources.cartao[0]} onChange={(v) => {this.updateTravelResources('cartao', v.target.checked)}}/>} label={'Cartão empresarial'}/>

                            <InputLabel style={{marginTop: 19}} id="demo-simple-select-label">Obs:</InputLabel>
                            <DefaultInput defaultValue={this.state.travelResources.cartao[1]} multiline={true} id={'description'} onBlur={(v) => {this.updateTravelResources('cartao', null, v)}}/>

                        </div>

                        <div style={{marginTop: 3, display: 'flex'}}>

                            <FormControlLabel style={{width: '220px'}} control={<Checkbox checked={this.state.travelResources.dinheiro[0]} onChange={(v) => {this.updateTravelResources('dinheiro', v.target.checked)}}/>} label={'Dinheiro'}/>

                            <InputLabel style={{marginTop: 19}} id="demo-simple-select-label">Obs:</InputLabel>
                            <DefaultInput defaultValue={this.state.travelResources.dinheiro[1]} multiline={true} id={'description'} onBlur={(v) => {this.updateTravelResources('dinheiro', null, v)}}/>

                        </div>

                        <div style={{marginTop: 3, display: 'flex'}}>

                            <FormControlLabel style={{width: '220px'}} control={<Checkbox checked={this.state.travelResources.nota[0]} onChange={(v) => {this.updateTravelResources('nota', v.target.checked)}}/>} label={'Transporte de ferramentas'}/>

                            <InputLabel style={{marginTop: 19}} id="demo-simple-select-label">Obs:</InputLabel>
                            <DefaultInput defaultValue={this.state.travelResources.nota[1]} multiline={true} id={'description'} onBlur={(v) => {this.updateTravelResources('nota', null, v)}}/>

                        </div>

                    </div>

                    {/*Notas e observações*/}
                    <div style={{marginTop: 30}}>
                        <InputLabel id="demo-simple-select-label">Notas e observações</InputLabel>
                        <DefaultInput multiline={true} defaultValue={this.state.workNotes} id={'workNotes'} onBlur={(v) => {this.setState({ workNotes: v })}}/>
                    </div>

                    {/*Botões*/}
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50, marginBottom: 15}}>
                        <Button onClick={() => { this.saveTravelSheet() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'Salvar'}</Button>
                        <Button onClick={() => { this.printTravelSheet() }} style={{width: '48%', fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', marginLeft: '2%'}} variant={'contained'}>{'Imprimir'}</Button>
                        <Button onClick={() => { this.clearTravelState(); this.setState({ travelSheetModal: false, travelStateLoadede: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'Fechar'}</Button>
                    </div>
                    
                </div>
            )

        }
        
    }

    async saveTravelSheet(){

        await this.setState({ loadingModal: true })

        let reloadData = false

        try {

            let editable = false

            let data = {
                work_type: this.state.workType,
                work_description: this.state.workDescription,
                work_data: this.state.workData,
                work_notes: this.state.workNotes,

                travel_expenses: this.state.travelExpenses,
                travel_resources: this.state.travelResources,

                transportation: this.state.transportation,
                
                user_id: SessionHelper.getFirebaseAuth().uid,
                sat_id: this.state.id_edit
            }

            if (this.state.tavelId){

                await Firestore.update(data, 'travel_sheet', this.state.tavelId)
                toast.success('Formulário de viagem alterado com sucesso')
                reloadData = true
                
            }else{

                let sheet = await Firestore.getId('travel_sheet')

                await Firestore.insert(data, 'travel_sheet', sheet)
                toast.success('Formulário de viagem cadastrado com sucesso')
                reloadData = true

            }

            await this.setState({ loadingModal: false })

        } catch (error) {
            console.log(error);
            await this.setState({ loadingModal: false })
            toast.error('Erro ao cadastrar o Formulário de viagem')
        }

        if (reloadData){
            await this.getTravelData()
        }

    }

    async printSAT(rowData) {

        let clientDoc = {};
        let orderDoc = {};

        if (rowData.company_id) {

            let clientQuery = await Firestore.getDoc('company', rowData.company_id);
            clientDoc = { ...clientQuery.data(), id: clientQuery.id };
        }

        if (rowData.order_id) {

            let orderQuery = await Firestore.getDoc('order', rowData.order_id);
            orderDoc = { ...orderQuery.data(), id: orderQuery.id };
        }

        let data = {
            title: rowData.title,
            workDescription: [],
            requester: rowData.requester || '',
            requester_email: rowData.requester_email || '',
            requester_phone: rowData.requester_phone || '',
            history: [],
        }

        if (rowData.reviewedParts) {

            data.reviewedParts = [
                [0, rowData.reviewedParts.rolamentos ? 0 : null],
                [0, rowData.reviewedParts.mancais ? 1 : null],
                [0, rowData.reviewedParts.retentores ? 2 : null],
                [0, rowData.reviewedParts.correntes ? 3 : null],

                [1, rowData.reviewedParts.correias ? 0 : null],
                [1, rowData.reviewedParts.polias ? 1 : null],
                [1, rowData.reviewedParts.engrenagens ? 2 : null],
                [1, rowData.reviewedParts.rodas_dentadas ? 3 : null],

                [2, rowData.reviewedParts.conjunto_pneumatico ? 0 : null],
                [2, rowData.reviewedParts.conjunto_hidraulico ? 1 : null],
                [2, rowData.reviewedParts.motores ? 2 : null],
                [2, rowData.reviewedParts.redutores ? 3 : null],

                [3, rowData.reviewedParts.motoredutores ? 0 : null],
                [3, rowData.reviewedParts.servomotores ? 1 : null],
                [3, rowData.reviewedParts.aperto_de_parafusos ? 2 : null],
                [3, rowData.reviewedParts.fitas_lonas ? 3 : null],

                [4, rowData.reviewedParts.buchas_geral ? 0 : null],
                [4, rowData.reviewedParts.lubrificacao_geral ? 1 : null],
                [4, rowData.reviewedParts.sensores_geral ? 2 : null],
                [4, rowData.reviewedParts.componentes_eletricos ? 3 : null],
            ];
        }

        if (rowData.history.length) {

            data.history = [
                ['MENSAGEM', 'USUÁRIO', 'DATA'],
            ];

            rowData.history.forEach((interation, key) => {

                let user = this.state.userDocs[this.state.userDocs.findIndex(item => item.id === interation.id_user)];

                data.history.push([interation.message, user ? user.name : '', moment(interation.date.toDate()).format('DD/MM/YYYY HH:mm')]);
            });
        }

        if (orderDoc && orderDoc.order_items && (rowData.order_items && rowData.order_items.length)) {
            
            data.order_items = [
                ['MÁQUINA/EQUIPAMENTO', 'SERIAL', 'DATA DA SOLICITAÇÃO', 'TÉRMINO DA GARANTIA'],
            ];

            orderDoc.order_items.forEach((item, key) => {

                if (rowData.order_items.includes(item.item_code)) {

                    let warranty = orderDoc.warranty_closed ? 'Garantia Encerrada' : item.warranty ? moment(item.warranty.toDate ? item.warranty.toDate() : item.warranty).format('DD/MM/YYYY') : '';

                    data.order_items.push([item.name, item.item_code, moment(item.input_date.toDate()).format('DD/MM/YYYY HH:mm'), warranty]);
                }
            });
        }

        if (rowData.replacedParts) {
            data.replacedParts = [
                ['4.1 ITEM', '4.2 CÓDIGO', '4.3 DESCRIÇÃO', '4.4 QUANTIDADE'],
            ];

            rowData.replacedParts.forEach((part, key) => {

                data.replacedParts.push([(key + 1).toString(), part.code ? part.code.toString() : '', part.name, part.quantity.toString()]);
            });
        }

        if (rowData.checkedOutTechnicians && rowData.checkedOutTechnicians.length) {

            data.workDescription = [];

            rowData.checkedOutTechnicians.forEach((checkout, key) => {
                data.workDescription.push(checkout.description);
            });
        }

        let contact = {};

        if (clientDoc.contacts.length && (orderDoc && orderDoc.contact_id)) {
            contact = clientDoc.contacts[orderDoc.contact_id - 1];
        }

        data.client = {order: orderDoc.order_code || '', prop: orderDoc.order_status_name || '', neighbourhood: orderDoc.address_bairro || '', street: orderDoc.adress_logradouro || '', clientName: clientDoc.name || '', country: clientDoc.country || '', state: clientDoc.state || '', city: clientDoc.city || '', info: contact.contact_name || '', phone: contact.contact_phone || '', mail: contact.contact_email || ''};


        data.signature = {};

        if (rowData.attachments) {

            let signature = rowData.attachments[rowData.attachments.findIndex(item => item.type === 'signature')];

            if (signature) {
    
                let signatureTechnician = this.state.technicianDocs[this.state.technicianDocs.findIndex(item => item.user_id === signature.user_id)];
    
                data.signature = {
                    signatureInfo: signature.signatureInfo,
                    link: signature.link,
                    name: signatureTechnician.name,
                    cpf: signatureTechnician.cpf,
                }
            }
        }

        SATHelper.PrintSAT(data);
    }

    async printTravelSheet(){
        
        let doc

        let helper = new PDFHelper

        let clientDoc = {};
        let orderDoc = {};

        if (this.state.company_id) {

            let clientQuery = await Firestore.getDoc('company', this.state.company_id);
            clientDoc = { ...clientQuery.data(), id: clientQuery.id };
        }

        if (this.state.order_id) {

            let orderQuery = await Firestore.getDoc('order', this.state.order_id);
            orderDoc = { ...orderQuery.data(), id: orderQuery.id };
        }

        let data = {
            workType: null,
            workDescription: null,
            workData: null,
            workNotes: null,
            workDates: [],
            travelExpenses: {box: null, grid: null},
            travelResources: {box: null, obs: null},
            transportation: null,
            technicians: []
        }

        let contact = {};

        if (clientDoc.contacts.length && (orderDoc && orderDoc.contact_id)) {
            contact = clientDoc.contacts[orderDoc.contact_id - 1];
        }

        data.client = {order: orderDoc.order_code || '', neighbourhood: orderDoc.address_bairro || '', street: orderDoc.adress_logradouro || '', prop: orderDoc.order_status_name || '', clientName: clientDoc.name || '', country: clientDoc.country || '', state: clientDoc.state || '', city: clientDoc.city || '', info: contact.contact_name || '', phone: contact.contact_phone || '', mail: contact.contact_email || ''};
 
        let docsIndex = null
        
        for (let index = 0; index < this.state.docs.length; index++) {
            if (this.state.docs[index].id == this.state.id_edit){
                docsIndex = index
                break
            }
        }

            let tempStartDate = null
            let tempStartMinutes = null
            let tempEndDate = null
            let tempEndMinutes = null

            for (let index = 0; index < this.state.docs[docsIndex].technicians.length; index++) {
                 
                if (this.state.docs[docsIndex].technicians[index].start) {

                    tempStartDate = moment(this.state.docs[docsIndex].technicians[index].start.toDate ? this.state.docs[docsIndex].technicians[index].start.toDate() : this.state.docs[docsIndex].technicians[index].start).format('DD/MM/YYYY')
                    tempStartMinutes = moment(this.state.docs[docsIndex].technicians[index].start.toDate ? this.state.docs[docsIndex].technicians[index].start.toDate() : this.state.docs[docsIndex].technicians[index].start).format('HH:mm')
    
                }
    
                if (this.state.docs[docsIndex].technicians[index].end) {
    
                    tempEndDate = moment(this.state.docs[docsIndex].technicians[index].end.toDate ? this.state.docs[docsIndex].technicians[index].end.toDate() : this.state.docs[docsIndex].technicians[index].end).format('DD/MM/YYYY')
                    tempEndMinutes = moment(this.state.docs[docsIndex].technicians[index].end.toDate ? this.state.docs[docsIndex].technicians[index].end.toDate() : this.state.docs[docsIndex].technicians[index].end).format('HH:mm')
    
                }
                
                data.workDates.push([tempStartDate, tempStartMinutes, tempEndDate, tempEndMinutes])
            }

            data.workType = this.state.config.work_types[this.state.workType - 1];

                let boxPos = []

                if (this.state.travelExpenses.aereo[0]) {boxPos.push([0,1])} else {boxPos.push([0,2])}

                if (this.state.travelExpenses.terrestre[0]) {boxPos.push([1,1])} else {boxPos.push([1,2])}

                if (this.state.travelExpenses.translado[0]) {boxPos.push([2,1])} else {boxPos.push([2,2])}

                if (this.state.travelExpenses.uber[0]) {boxPos.push([3,1])} else {boxPos.push([3,2])}

                if (this.state.travelExpenses.locacao[0]) {boxPos.push([4,1])} else {boxPos.push([4,2])}

                if (this.state.travelExpenses.hospedagem[0]) {boxPos.push([5,1])} else {boxPos.push([5,2])}

                if (this.state.travelExpenses.alimentacao[0]) {boxPos.push([6,1])} else {boxPos.push([6,2])}

            let gridData = []

            if (this.state.travelExpenses.aereo[1] == 'contratada'){
                gridData.push(['TRANSPORTE AÉREO', '', '', '                 X', '', ''])
            }else if (this.state.travelExpenses.aereo[1] == 'contratante'){
                gridData.push(['TRANSPORTE AÉREO', '', '', '', '                 X', ''])
            }else if (this.state.travelExpenses.aereo[1] == 'n/a'){
                gridData.push(['TRANSPORTE AÉREO', '', '', '', '', '     X'])
            }

            if (this.state.travelExpenses.terrestre[1] == 'contratada'){
                gridData.push(['TRANSPORTE TERRESTRE', '', '', '                 X', '', ''])
            }else if (this.state.travelExpenses.terrestre[1] == 'contratante'){
                gridData.push(['TRANSPORTE TERRESTRE', '', '', '', '                 X', ''])
            }else if (this.state.travelExpenses.terrestre[1] == 'n/a'){
                gridData.push(['TRANSPORTE TERRESTRE', '', '', '', '', '     X'])
            }

            if (this.state.travelExpenses.translado[1] == 'contratada'){
                gridData.push(['TRANSLADO NO LOCAL DO TRABALHO', '', '', '               X', '', ''])
            }else if (this.state.travelExpenses.translado[1] == 'contratante'){
                gridData.push(['TRANSLADO NO LOCAL DO TRABALHO', '', '', '', '                 X', ''])
            }else if (this.state.travelExpenses.translado[1] == 'n/a'){
                gridData.push(['TRANSLADO NO LOCAL DO TRABALHO', '', '', '', '', '     X'])
            }
            
            if (this.state.travelExpenses.uber[1] == 'contratada'){
                gridData.push(['TAXI / UBER', '', '', '             sim', '', ''])
            }else if (this.state.travelExpenses.uber[1] == 'contratante'){
                gridData.push(['TAXI / UBER', '', '', '', '                 X', ''])
            }else if (this.state.travelExpenses.uber[1] == 'n/a'){
                gridData.push(['TAXI / UBER', '', '', '', '', '     X'])
            }

            if (this.state.travelExpenses.locacao[1] == 'contratada'){
                gridData.push(['LOCAÇÃO DE VEÍCULO', '', '', '               X', '', ''])
            }else if (this.state.travelExpenses.locacao[1] == 'contratante'){
                gridData.push(['LOCAÇÃO DE VEÍCULO', '', '', '', '                 X', ''])
            }else if (this.state.travelExpenses.locacao[1] == 'n/a'){
                gridData.push(['LOCAÇÃO DE VEÍCULO', '', '', '', '', '     X'])
            }

            if (this.state.travelExpenses.hospedagem[1] == 'contratada'){
                gridData.push(['HOSPEDAGEM', '', '', '               X', '', ''])
            }else if (this.state.travelExpenses.hospedagem[1] == 'contratante'){
                gridData.push(['HOSPEDAGEM', '', '', '', '                 X', ''])
            }else if (this.state.travelExpenses.hospedagem[1] == 'n/a'){
                gridData.push(['HOSPEDAGEM', '', '', '', '', '     X'])
            }

            if (this.state.travelExpenses.alimentacao[1] == 'contratada'){
                gridData.push(['ALIMENTAÇÃO', '', '', '             X', '', ''])
            }else if (this.state.travelExpenses.alimentacao[1] == 'contratante'){
                gridData.push(['ALIMENTAÇÃO', '', '', '', '                 X', ''])
            }else if (this.state.travelExpenses.alimentacao[1] == 'n/a'){
                gridData.push(['ALIMENTAÇÃO', '', '', '', '', '     X'])
            }

            data.travelExpenses.box = boxPos

            data.travelExpenses.grid = gridData

                if (this.state.transportation === 1){
                    data.transportation = [[0, 1]]
                } else if (this.state.transportation === 2){
                    data.transportation = [[0, 2]]
                } else if (this.state.transportation === 3){
                    data.transportation = [[0, 3]]
                } else if (this.state.transportation === 4){
                    data.transportation = [[0, 4]]
                }

                boxPos = []

                if (this.state.travelResources.carreta[0]) {boxPos.push([0,1])} else {boxPos.push([0,2])}

                if (this.state.travelResources.cartao[0]) {boxPos.push([1,1])} else {boxPos.push([1,2])}

                if (this.state.travelResources.dinheiro[0]) {boxPos.push([2,1])} else {boxPos.push([2,2])}

                if (this.state.travelResources.nota[0]) {boxPos.push([3,1])} else {boxPos.push([3,2])}

            data.travelResources.box = boxPos

            data.travelResources.obs = [
                helper.StringSeparator('Obs: ' + this.state.travelResources.carreta[1], 35),
                helper.StringSeparator('Obs: ' + this.state.travelResources.cartao[1], 35),
                helper.StringSeparator('Obs: ' + this.state.travelResources.dinheiro[1], 35),
                helper.StringSeparator('Obs: ' + this.state.travelResources.nota[1], 35)
            ]


        data.workDescription = [this.state.workDescription, 1]
        data.workNotes = [this.state.workNotes, 1]

        doc = this.state.docs[docsIndex]

        for (let cont = 0; cont < doc.technicians.length; cont++) {
                
            let technician = this.state.technicianDocs[this.state.technicianDocs.findIndex(item => item.id === doc.technicians[cont].id_technician)];

            if (technician) {

                data.technicians.push({ name: technician.name, main: doc.technicians[cont].main })
            }
        }

        data.title = this.state.docs[docsIndex].title;

        SATHelper.PrintTravelSheet(data)
    }

    setTravelState(){

        let travelSheet = this.state.travelDocs[this.state.travelDocs.findIndex(item => item.sat_id === this.state.id_edit)];

        if (travelSheet) {

            this.setState({
                workType: travelSheet.work_type,
                workDescription: travelSheet.work_description,
                workData: travelSheet.work_data,
                workNotes: travelSheet.work_notes,
                transportation: travelSheet.transportation,
                travelExpenses: travelSheet.travel_expenses,
                travelResources: travelSheet.travel_resources,
                tavelId: travelSheet.id
            });
            
        } else {

            this.setState({
                workType: 2,
                workDescription: '',
                workData: null,
                workNotes: '',
                transportation: 1,
                travelExpenses: {
                    aereo: [false, 'n/a'],
                    terrestre: [false, 'n/a'],
                    translado: [false, 'n/a'],
                    uber: [false, 'n/a'],
                    locacao: [false, 'n/a'],
                    hospedagem: [false, 'n/a'],
                    alimentacao: [false, 'n/a']
                },
                travelResources: {
                    carreta: [false, ''],
                    cartao: [false, ''],
                    dinheiro: [false, ''],
                    nota: [false, '']
                },
                tavelId: null,
            });
        }

        this.setState({ travelFlag: true })

    }

    clearTravelState(){
        
        this.setState({ workType: 2 })
        this.setState({ workDescription: '' })
        this.setState({ workData: null })
        this.setState({ workNotes: '' })
        this.setState({ transportation: 1 })
        this.setState({ travelExpenses:{
            aereo: [false, 'n/a'],
            terrestre: [false, 'n/a'],
            translado: [false, 'n/a'],
            uber: [false, 'n/a'],
            locacao: [false, 'n/a'],
            hospedagem: [false, 'n/a'],
            alimentacao: [false, 'n/a']
        } })
        this.setState({ travelResources: {
            carreta: [false, ''],
            cartao: [false, ''],
            dinheiro: [false, ''],
            nota: [false, '']
        } })
        this.setState({ tavelId: null })
        this.setState({ travelFlag: false })

        this.getTravelData()

    }

    expensesModal() {
        if (this.state.expenses_id) {

            return (
                <div>
                    {this.state.expenses.map((item, key) => {

                        return (
                            <div style={{marginBottom: 20, borderTop: '0.5px solid lightgrey'}}>
                                <p><b>{'Técnico: '}</b>{this.state.technicianDocs[this.state.technicianDocs.findIndex(technician => technician.id === item.id_technician)].name}</p>
                                <p><b>{'Data: '}</b>{moment(item.date.toDate()).format('DD/MM/YYYY HH:mm')}</p>
                                <p><b>{'Nome: '}</b>{item.name}</p>
                                <p><b>{'Valor: '}</b>{CurrencyHelper.centsToMoney(item.value)}</p>
                                <p><b>{'Arquivo: '}</b>{<Tooltip title="Baixar Arquivo"><IconButton><GetAppIcon onClick={() => { window.open(item.link) }} style={{color: Colors.primary}}/></IconButton></Tooltip>}</p>
                                {!this.state.expenses[key + 1] && <div style={{borderBottom: '0.5px solid lightgrey'}}/>}
                            </div>
                        )

                    })}
                    { !this.state.expenses.length && <p style={{color: 'grey', textAlign: 'center'}}>Nenhuma despesa adicionada ainda.</p> }
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.setState({ expensesModal: false }) }} style={{fontWeight: 'bold', width: '100%', backgroundColor: Colors.semil.green, color: '#fff'}} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    async handleAttatchmentUpload(files) {

        try {

            this.setState({ loadingModal: true });

            await new Promise( async (resolve, reject) => {

                Array.from(files).forEach( async (file, key) => {

                    let ref = await Storage.uploadFile('sat_attatchments', file);
                    let downloadPath = await Storage.getFileFromRef(ref);
            
                    if (!downloadPath) {
                        throw new Error('upload error');
                    }
        
                    let data = {
                        extension: file.name.split('.').pop(),
                        name: file.name,
                        size: file.size,
                        date: new Date(),
                        link: downloadPath,
                        id_user: SessionHelper.getFirebaseAuth().uid,
                    };
    
                    let actualAttatchments = this.state.attatchments || [];
                    actualAttatchments.push(data);
    
                    await Firestore.update({ attachments: actualAttatchments }, 'appointment', this.state.id_edit);
                    await SATHelper.historyIteration(this.state.id_edit, SessionHelper.getFirebaseAuth().uid, 'Usuário anexou arquivo', new Date(), data.link);
                    await this.setState({ attatchments: actualAttatchments });

                    if (key === files.length - 1) {
                        resolve();
                    }      
                });          
            });

            this.setState({ loadingModal: false });
            toast.success('Enviado com sucesso');

        } catch (error) {

            console.log(error);
            this.setState({ loadingModal: false });
            toast.error('Erro ao enviar arquivos');
        }
    }

    async removeFile(key) {
        
        if (this.state.attatchments[key]) {

            try {

                this.setState({ loadingModal: true });

                let link = this.state.attatchments[key].link;

                let prev = this.state.attatchments;
                prev.splice(key, 1);
    
                await Firestore.update({ attachments: prev }, 'appointment', this.state.id_edit);
                await SATHelper.historyIteration(this.state.id_edit, SessionHelper.getFirebaseAuth().uid, 'Usuário removeu arquivo', new Date(), link);
                await this.setState({ attatchments: prev, loadingModal: false });
    
                toast.success('Arquivo removido');

            } catch (error) {
                this.setState({ loadingModal: false });
                toast.error('Erro ao remover arquivo');
            }
        }
    }

    async removeTechnician(key) {

        try {

            this.setState({ loadingModal: true });

            let name = this.state.technicianDocs[key].name;

            let prev = this.state.technicians;
            prev.splice(key, 1);

            await Firestore.update({ technicians: prev }, 'appointment', this.state.id_edit);
            await SATHelper.historyIteration(this.state.id_edit, SessionHelper.getFirebaseAuth().uid, `Técnico ${name} desvinculado`);
            await this.setState({ technicians: prev, loadingModal: false });

            toast.success('Técnico desvinculado');

        } catch (error) {
            this.setState({ loadingModal: false });
            toast.error('Erro ao desvincular técnico');
        }
    }

    async closeSAT() {

        try {

            this.setState({ loadingModal: true });

            let data = { status: 'closed', end_date: new Date() };
            await Firestore.update(data, 'appointment', this.state.id_edit);

            SATHelper.historyIteration(this.state.id_edit, SessionHelper.getFirebaseAuth().uid, 'Usuário finalizou SAT');

            if (this.state.sendSatisfactionResearch && this.state.satisfactionResearchEmail) {

                let body = {
                    id_sat: this.state.id_edit,
                    id_satisfaction_research: this.state.satisfactionResearch,
                    email: this.state.satisfactionResearchEmail,
                }

                fetch(`${Functions.getEndPoint()}/sendSatisfactionResearch`, {headers: {"Content-Type": "application/json"}, method: 'POST', body: JSON.stringify(body) });
            }

            await this.getDocs();
            toast.success('SAT finalizada com sucesso');

            this.setState({ loadingModal: false, closeSATModal: false, id_edit: null });

        } catch (error) {

            this.setState({ loadingModal: false });
            toast.error('Erro ao finalizar SAT');
        }
    }

    async archiveSAT() {

        try {

            this.setState({ loadingModal: true });

            let data = { status: this.state.status !== 'archived' ? 'archived' : 'closed' };
            await Firestore.update(data, 'appointment', this.state.id_edit);

            SATHelper.historyIteration(this.state.id_edit, SessionHelper.getFirebaseAuth().uid, this.state.status !== 'archived' ? 'Usuário arquivou a SAT' : 'Usuário desarquivou a SAT');

            this.setState({ loadingModal: false, archiveSATModal: false, id_edit: null });
            toast.success('Arquivado com sucesso');

            await this.getDocs(this.state.showArchived ? true : false, false);

        } catch (error) {

            this.setState({ loadingModal: false });
            toast.error('Erro ao arquivar SAT');
        }
    }

    attatchmentsModal() {
        if (this.state.id_edit && this.state.attatchments && this.state.attatchmentsModal) {

            return (
                <div>
                    {this.state.attatchments.map((item, key) => {

                        return (
                            <div style={{marginBottom: 20, borderTop: '0.5px solid lightgrey'}}>
                                
                                <Tooltip onClick={() => { this.removeFile(key) }} style={{position: 'relative', float: 'right', color: 'red'}} title="Remover"><IconButton><DeleteIcon/></IconButton></Tooltip>
                                {item.id_user ? <p><b>{'Usuário: '}</b>{this.state.userDocs[this.state.userDocs.findIndex(user => user.id === item.id_user)].name}</p> : null}
                                <p><b>{'Data: '}</b>{item.date.toDate ? moment(item.date.toDate()).format('DD/MM/YYYY HH:mm') : moment(item.date).format('DD/MM/YYYY HH:mm') }</p>
                                <p><b>{'Nome: '}</b>{item.name}</p>
                                {item.size ? <p><b>{'Tamanho: '}</b>{(item.size / 1024 / 1024).toFixed(1) + ' MB'}</p> : null}
                                <p><b>{'Arquivo: '}</b>{<Tooltip title="Baixar Arquivo"><IconButton><GetAppIcon onClick={() => { window.open(item.link) }} style={{color: Colors.primary}}/></IconButton></Tooltip>}</p>
                                {!this.state.expenses[key + 1] && <div style={{borderBottom: '0.5px solid lightgrey'}}/>}
                            </div>
                        )

                    })}
                    { !this.state.attatchments.length && <p style={{color: 'grey', textAlign: 'center', height: 80}}>Nenhum arquivo adicionada ainda.</p> }
                    <input
                        style={{display: 'none'}}
                        id="contained-button-file"
                        type="file"
                        onChange={(v) => { this.handleAttatchmentUpload(v.target.files) }}
                        />
                    <label htmlFor="contained-button-file">
                        <Button startIcon={<CloudUploadIcon/>} component="span" style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'IMPORTAR ARQUIVO'}</Button>
                    </label>
                </div>
            )
        }
    }

    renderCheckout() {

        return this.state.closeSATTechnicians.map((technician, key) => {

            let technicianDoc = this.state.technicianDocs[this.state.technicianDocs.findIndex(item => item.id === technician.id_technician)];
            let index = this.state.checkedOutTechnicians ? this.state.checkedOutTechnicians.findIndex(item => item.technician_id === technician.id_technician) : -1;

            if (index >= 0) {

                return (
                    <Accordion style={{border: '1px solid lightgrey', boxShadow: ''}}>
                        <AccordionSummary>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'green'}}>
                                <CheckIcon style={{ paddingRight: 5}}/>
                                <div>{technicianDoc.name}</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={{display: 'flex', flexDirection: 'column'}}>
                            {this.state.checkedOutTechnicians[index].description || 'Sem descrição'}
                        </AccordionDetails>
                    </Accordion>
                )

            } else {

                return (
                    <Accordion style={{border: '1px solid lightgrey', boxShadow: ''}}>
                        <AccordionSummary>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'red'}}>
                                <ClearIcon style={{ paddingRight: 5}}/>
                                <div>{technicianDoc.name}</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={{display: 'flex', flexDirection: 'column'}}>
                            {'Sem descrição'}
                        </AccordionDetails>
                    </Accordion>
                )
            }
        });
    }

    closeSATModal() {
        if (this.state.id_edit && this.state.closeSATModal) {
            return (
                <div>
                    <div style={{paddingBottom: 15}}>
                        <FormLabel style={{marginTop: 10, marginBottom: 10}} component="legend">Status dos Técnicos</FormLabel>
                        {this.state.closeSATTechnicians.length ? this.renderCheckout() : <div>{'Nenhum técnico vinculado'}</div>}
                    </div>

                    <FormLabel style={{marginTop: 10, marginBottom: 10}} component="legend">Ações</FormLabel>
                    <FormControlLabel
                        control={<Checkbox style={{color: Colors.semil.green}} checked={this.state.sendSatisfactionResearch} onChange={(v) => { this.setState({ sendSatisfactionResearch: v.target.checked }) }}/>}
                        label="Enviar pesquisa de satisfação"
                    />
                    {
                        this.state.sendSatisfactionResearch ?
                        
                        <div>
                            <DefaultInput value={this.state.satisfactionResearchEmail} onChange={(v) => { this.setState({ satisfactionResearchEmail: v }) }} label={'Enviar para'}/>
                            <div style={{marginTop: 15}}/>
                        </div>

                        : null
                    }
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.closeSAT() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ closeSATModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    archiveSATModal() {
        if (this.state.id_edit && this.state.archiveSATModal) {
            return (
                <div>
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.archiveSAT() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ archiveSATModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    getQuestionType(type) {
        
        return this.state.types[this.state.types.findIndex(item => item.value === type)].label;
    }

    satisfactionResearchAnswersModal() {

        if (this.state.satisfactionResearchAnswersModal) {

            let data = !Array.isArray(this.state.satisfactionResearchAnswers) ? [this.state.satisfactionResearchAnswers] : this.state.satisfactionResearchAnswers;

            return data.map((answer, key) => {

                if (answer && answer.id_satisfaction_research) {

                    let satisfactionResearch = this.state.satisfactionResearchDocs.find(item => item.id === answer.id_satisfaction_research);

                    if (satisfactionResearch) {
    
                        return (
                            <Accordion style={{boxShadow: 'rgba(50, 50, 50, 0.47) 1px 1px 10px 0px'}}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                    <div style={{fontWeight: 'bold', color: Colors.semil.green}}>{moment(answer.date.toDate()).format('DD/MM/YYYY HH:mm')}</div>
                                </AccordionSummary>
        
                                <AccordionDetails style={{display: 'flex', flexDirection: 'column'}}>
                                    { satisfactionResearch.data.map((question, key) => {
                                            return (
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <div style={{fontWeight: 'bold'}}>{question.label + ' (' + this.getQuestionType(question.type) + ')'}</div>
                                                    <div style={{paddingBottom: 15}}>{answer.answers[answer.answers.findIndex(item => item.question_key === key)].answer}</div>
                                                    
                                                    {  question.options && question.type === 'multiple_select' ?
                                                    
                                                        question.options.map((option, optionKey) => {
        
                                                            let answer = answer.answers[answer.answers.findIndex(item => item.question_key === key)].option_key[optionKey] ? 'Marcado': 'Não marcado';
        
                                                            return <div style={{paddingBottom: 15}}>{option.label + ' - ' + answer + ' '}</div>;
                                                        }) : null
                                                    }
        
                                                </div>
                                            )
                                    }) }
                                </AccordionDetails>
                            </Accordion>
                        )
                    }
                }
            });
        }
    }

    async attatchTechnician() {
        
        if (this.state.selected_technician !== '' && this.state.technicianStart && this.state.technicianEnd) {

            this.setState({ loadingModal: true });

            try {

                let query = await Firestore.customQuery('appointment').where('status', '==', 'open').get();
                
                let valid = true;
                let invalidSAT = '';
                let satStart = moment(this.state.technicianStart, 'DD/MM/YYYY');
                let satEnd = moment(this.state.technicianEnd, 'DD/MM/YYYY');

                query.forEach((sat, key) => {

                    if (sat.data().technicians.length) {

                        sat.data().technicians.forEach((technician, key) => {

                            if (technician.id_technician === this.state.selected_technician) {

                                let start = moment(technician.start.toDate());
                                let end = moment(technician.end.toDate());
            
                                if (start.isValid() && end.isValid()) {

                                    if (satStart.isBetween(start, end) || satEnd.isBetween(start, end)) {

                                        valid = false;
                                        invalidSAT = sat.data().title;
                                    }
                                }
                            }
                        });
                    }
                });

                if (valid) {

                    let technicians = this.state.technicians;
                    technicians.push({ id_technician: this.state.selected_technician, start: this.state.technicianStart, end: this.state.technicianEnd, main: this.state.mainTechnician });

                    let name = this.state.technicianDocs[this.state.technicianDocs.findIndex(arr => arr.id === this.state.selected_technician)].name;

                    await Firestore.update({ technicians: technicians }, 'appointment', this.state.id_edit);
                    await SATHelper.historyIteration(this.state.id_edit, SessionHelper.getFirebaseAuth().uid, `Vinculou o técnico ${name}`);

                    toast.success('Técnico vinculado');

                    let body = {
                        technician: this.state.selected_technician,
                        notification_data: { appointment_id: this.state.id_edit },
                    };

                    fetch(`${Functions.getEndPoint()}/technicianNotification`, {headers: {"Content-Type": "application/json"}, method: 'POST', body: JSON.stringify(body) });

                } else {
                    toast.warn(`Já existem compromissos para esse técnico nessa data (${invalidSAT})`);
                }

                this.setState({ loadingModal: false, technicianStart: null, technicianEnd: null, selected_technician: null, mainTechnician: false });

            } catch (error) {

                toast.error('Erro ao vincular técnico');
                this.setState({ loadingModal: false });
            }

        } else {
            toast.warn('Preencha todos os campos');
        }
    }

    techniciansModal() {
        if (this.state.id_edit && this.state.technicians) {

            if (!this.state.showCalendar) {

                return (
                    <div>
    
                        <div style={{marginTop: 30}}>
                            
                            <DefaultSelect search={true} searchField={'name'} id={'technician_attatchment'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ selected_technician: v.target.value }) }} value={this.state.selected_technician} docs={this.state.technicianDocs} label={'Vincular Técnico'}/>

                            { this.state.selected_technician ? 
                            <div>
                                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10}}>
                                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                        <KeyboardDateTimePicker
                                        style={{width: '100%', marginTop: 15}}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy HH:mm'}
                                        autoOk={true}
                                        label="Início"
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={(v) => { this.setState({ technicianStart: v }) }}
                                        value={this.state.technicianStart}
                                        />
                                        <KeyboardDateTimePicker
                                        style={{width: '100%', marginTop: 15}}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy HH:mm'}
                                        autoOk={true}
                                        label="Fim"
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={(v) => { this.setState({ technicianEnd: v }) }}
                                        minDate={this.state.technicianStart || new Date()}
                                        value={this.state.technicianEnd}
                                        />
                                        </MuiPickersUtilsProvider>
                                </div>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.mainTechnician} onChange={(v) => { this.setState({ mainTechnician: v.target.checked }) }}/>}
                                    label="Principal"
                                />
                            </div>
                             : null}

                            <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 30}}>
                                <Button onClick={() => { this.attatchTechnician() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'VINCULAR'}</Button>
                                <Button onClick={() => { this.setState({ showCalendar: true }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'ABRIR AGENDA'}</Button>
                            </div>
                        </div>
    
                        <InputLabel style={{paddingTop: 30, paddingBottom: 30}} id="hour-type">Técnicos Vinculados</InputLabel>
    
                        {this.state.technicians.map((item, key) => {
    
                            let technician = this.state.technicianDocs[this.state.technicianDocs.findIndex(technician => technician.id === item.id_technician)];
    
                            return (
                                <div style={{marginBottom: 20, borderTop: '0.5px solid lightgrey'}}>
                                    
                                    <Tooltip onClick={() => { this.removeTechnician(key) }} style={{position: 'relative', float: 'right', color: 'red'}} title="Remover"><IconButton><DeleteIcon/></IconButton></Tooltip>
                                    <div style={{padding: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <img style={{marginRight: 10, width: 60, height: 60, borderRadius: 60 / 2}} src={technician.photo || ''}/>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <div><b>{'Técnico: '}</b>{technician.name}</div>
                                            <div><b>{'CPF: '}</b>{technician.cpf}</div>
                                            <div><b>{'Início: '}</b>{item.start.toDate ? moment(item.start.toDate()).format('DD/MM/YYYY HH:mm') : moment(item.start).format('DD/MM/YYYY HH:mm')}</div>
                                            <div><b>{'Fim: '}</b>{item.end.toDate ? moment(item.end.toDate()).format('DD/MM/YYYY HH:mm') : moment(item.end).format('DD/MM/YYYY HH:mm')}</div>
                                        </div>
                                    </div>
                                    {!this.state.technicians[key + 1] && <div style={{borderBottom: '0.5px solid lightgrey'}}/>}
                                </div>
                            )
    
                        })}
                        { !this.state.technicians.length && <p style={{color: 'grey', textAlign: 'center', height: 80}}>Nenhum técnico vinculado ainda.</p> }
                        <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                            <Button onClick={() => { this.setState({ techniciansModal: false }) }} style={{fontWeight: 'bold', width: '100%', backgroundColor: Colors.semil.green, color: '#fff'}} variant={'contained'}>{'FECHAR'}</Button>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <TechnicianCalendar/>
                        <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                            <Button onClick={() => { this.setState({ showCalendar: false }) }} style={{fontWeight: 'bold', width: '100%', backgroundColor: Colors.semil.green, color: '#fff'}} variant={'contained'}>{'FECHAR'}</Button>
                        </div>
                    </div>
                )
            }
        }
    }

    async reopenSAT() {

        try {

            this.setState({ loadingModal: true });

            await SATHelper.reopenSAT(this.state.reopen_id, 'open', SessionHelper.getFirebaseAuth().uid);

            toast.success('SAT reaberta com sucesso');
            this.setState({ loadingModal: true, reopenSATModal: false });

            this.getDocs();

        } catch (error) {

            this.setState({ loadingModal: false, reopenSATModal: false });
        }
    }

    async saveOrderItems() {

        this.setState({ loadingModal: true });

        await Firestore.customQuery('appointment').doc(this.state.id_edit).update({ order_items: this.state.rowOrderItems });

        toast.success('Editado com sucesso');

        this.setState({ orderItemsModal: false, order: null, rowOrderItems: [], id_edit: null, loadingModal: false });
    }

    reopenSATModal() {
        if (this.state.reopen_id) {
            return (
                <div>
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.reopenSAT() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ reopenSATModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    orderItemsModal() {
        if (this.state.order) {
            return (
                <div>
                    { this.state.order.order_items.map((item, key) => {
                        return (
                            <FormControlLabel
                            key={key}
                            control={<Checkbox checked={this.state.rowOrderItems.includes(item.item_code) || false} onChange={(v) => { this.handleItemSelection(item.item_code, v.target.checked) }}/>}
                            label={`${item.item_code} - ${item.name} (${item.quantity_ordered} unidade${item.quantity_ordered > 1 ? 's' : ''})`}
                            />
                        )
                    }) }
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.saveOrderItems() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ orderItemsModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    async AddHistoryManualy(docId){
        if(this.state.historyMessage){

            try {

                await SATHelper.historyIteration(docId, SessionHelper.getFirebaseAuth().uid, this.state.historyMessage, new Date(), '', false);

                toast.success('Interação gravada com Sucesso!')
            } catch (error) {

                toast.error('Erro ao incluir instancia');
            }

        } else{
            toast.warn('É necessário escrever uma mensagem!');
        }
    }

    detailPanel(data) {
        if (data.history.length) {

            data.history = data.history.reverse();

            return (
                <div style={{paddingLeft: 18}}>

                    <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row'}}>
                        <p style={{fontWeight: 'bold'}}>{'Histórico de Interações'}</p>
                        <Tooltip onClick={() => { this.setState({ historyModal: true }) }} title={'Adicionar'}><IconButton><AddCircleIcon style={{color: Colors.semil.green}}/></IconButton></Tooltip>
                    </div>

                    { this.state.historyModal && 
                    
                    <div style={{marginTop: 25, borderTop: '0.5px solid lightgrey'}}>  
                        <div style={{float: 'left', marginTop: 2}}>
                            <p><b>{'Mensagem:'}</b></p>
                        </div>
                        
                        <div style={{float: 'left', marginLeft: 5, marginRight: 0, width: 1000}}>
                            <DefaultInput onBlur={(v) => { this.setState({ historyMessage: v }) }}/>
                        </div>

                        <div onClick={async() => { this.setState({historyModal: false}); await data.history.push({date: new Date(), id_user: SessionHelper.getFirebaseAuth().uid, message: this.state.historyMessage, auto_generated: false}); this.forceUpdate(); await this.AddHistoryManualy(data.id);}} style={{float: 'left', marginTop: 15, marginLeft: 10}}>
                            <CheckIcon style={{color: Colors.semil.green, cursor: 'pointer'}}/>
                        </div>

                        <div onClick={() => { this.setState({ historyModal: false }) }} style={{marginTop: 15, marginLeft: 10}}>
                            <ClearIcon disabled style={{color: Colors.semil.red, cursor: 'pointer'}}/>
                        </div>
                    </div>
                    
                    }
                  
                    <div style={{marginTop: 25}}>
                        {data.history.map((item, key) => {
                            return (
                                <div style={{marginBottom: 20, borderTop: '0.5px solid lightgrey'}}>
                                    <p><b>{'Mensagem: '}</b>{item.message} <b>{'Data: '}</b> {item.date.toDate ? moment(item.date.toDate()).format('DD/MM/YYYY HH:mm') : moment(item.date).format('DD/MM/YYYY HH:mm')} <b>{'Usuário: '}</b> { this.state.userDocs[this.state.userDocs.findIndex(user => user.id === item.id_user)].name } {item.file ? <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><b>{'Arquivo: '}</b> <Tooltip title="Baixar Arquivo"><IconButton><GetAppIcon onClick={() => { window.open(item.file) }} style={{color: Colors.primary}}/></IconButton></Tooltip>  </div>  : ''} {item.auto_generated === false ? <Tooltip title="Remover"><IconButton><DeleteIcon onClick={() => { let confirm = window.confirm('Tem certeza que deseja remover?'); if (confirm) { this.removeInteration(data.id, key); data.history.splice(data.history.indexOf(item), 1); this.forceUpdate(); } }} style={{color: 'red', marginBottom: 5}}/></IconButton></Tooltip> : ''} </p>
                                    {!data.history[key + 1] && <div style={{borderBottom: '0.5px solid lightgrey'}}/>}
                                </div>
                            )
                        })}
                    </div>
                </div>
            )

        } else {
            return <div/>
        }
    }

    async removeInteration(id, key) {

        await SATHelper.removeIteration(id, key);
        toast.success('Removido com sucesso');
    }

    handleItemSelection(code, value) {

        if (this.state.rowOrderItems.includes(code) && !value) {

            let prev = this.state.rowOrderItems;
            prev.splice(prev.indexOf(code), 1);

            this.setState({ rowOrderItems: prev });this.setState({ rowOrderItems: prev });

        } else if (!this.state.rowOrderItems.includes(code) && value) {

            let prev = this.state.rowOrderItems;
            prev.push(code);

            this.setState({ rowOrderItems: prev });
        }
    }

    async handleOrderItensModal(rowData) {

        if (rowData.order_id) {

            this.setState({ gridLoading: true });

            let orderQuery = await Firestore.getDoc('order', rowData.order_id);
            let order = { ...orderQuery.data(), id: orderQuery.id };

            if (order) {
    
                this.setState({ orderItemsModal: true, order: order, rowOrderItems: rowData.order_items, id_edit: rowData.id, gridLoading: false });

            } else {

                this.setState({ gridLoading: true });
                window.alert('Pedido não encontrado');
            }

        } else {
            window.alert('Nenhum pedido vinculado');
        }
    }

    async showArchived() {
        await this.setState({ showArchived: !this.state.showArchived, showFinished: false });
        this.getDocs(this.state.showArchived, false);
    }

    async showFinished() {
        await this.setState({ showArchived: false, showFinished: !this.state.showFinished });
        this.getDocs(false, false, this.state.showFinished);
    }

    renderPriority(type) { return <div style={{fontWeight: 'bold', color: type === 'normal' ? 'orange' : type === 'high' ? 'red' : 'green'}}>{ type === 'normal' ? 'Normal' : type === 'high' ? 'Alta' : 'Baixa'}</div> }

    renderStatus(type) { return <div style={{fontWeight: 'bold', color: type === 'open' ? 'green' : type === 'closed' ? 'red' : ''}}>{ type === 'open' ? 'Aberto' : 'Fechado'}</div> }

    renderToolbar(props) {
        return (
            <div>
                <MTableToolbar {...props} />
                <div style={{paddingLeft: 24, display: 'flex', flexDirection: 'row'}}>
                    <div onClick={() => { this.showArchived() }} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10, backgroundColor: this.state.showArchived ? Colors.semil.green : '', color: this.state.showArchived ? '#fff' : Colors.semil.green, border: `2px solid ${Colors.semil.green}`, fontWeight: 'bold', cursor: 'pointer', borderRadius: 25}}>
                        <ArchiveIcon style={{marginRight: 8}}/>
                        <div>{'Arquivadas'}</div>
                    </div>
                    <div onClick={() => { this.showFinished() }} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10, marginLeft: 10, backgroundColor: this.state.showFinished ? Colors.semil.green : '', color: this.state.showFinished ? '#fff' : Colors.semil.green, border: `2px solid ${Colors.semil.green}`, fontWeight: 'bold', cursor: 'pointer', borderRadius: 25}}>
                        <AssignmentTurnedInIcon style={{marginRight: 8}}/>
                        <div>{'Finalizadas'}</div>
                    </div>
                </div>
            </div>
        )
    }

    renderWorkedHours(hours) {

        let total = 0;

        if (hours && hours.length) {            

            hours.forEach((hour, key) => {

                total += hour.minutesWorked;
            });
        }
    
        return (total / 60).toFixed(2).toString();
    }

    async handleFinish(rowData) {

        await this.setState({ closeSATModal: true, loadingModal: true });

        let company = await Firestore.getDoc('company', rowData.company_id);
        let satisfactionResearchEmail = '';

        if (company.exists) {

            let companyData = company.data();
            satisfactionResearchEmail = companyData.email || '';
        }

        this.setState({
            loadingModal: false,
            id_edit: rowData.id,
            sendSatisfactionResearch: false,
            satisfactionResearchEmail: satisfactionResearchEmail,
            satisfactionResearch: rowData.satisfaction_research_id,
            checkedOutTechnicians: rowData.checkedOutTechnicians,
            closeSATTechnicians: rowData.technicians
        });
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={{padding: 25}}>
                <DefaultButton onClick={() => { this.setState({ addSatModal: true }) }} title={'Adicionar SAT'}/>

                <DefaultTable
                width={window.screen.width - 290}
                onRowClick={(evt, rowData, togglePanel) => { togglePanel() }}
                detailPanel={(evt) => { return this.detailPanel(evt) }}
                isLoading={this.state.gridLoading}
                height={650}
                filtering={true}
                actions={[
                    {
                        icon: PeopleIcon,
                        tooltip: 'Técnicos',
                        onClick: (event, rowData) => { this.setState({ techniciansModal: true, id_edit: rowData.id, technicians: rowData.technicians || [] }) } 
                    },
                    {
                        icon: PrintIcon,
                        tooltip: 'Imprimir',
                        onClick: (event, rowData) => { this.printSAT(rowData) }
                    },
                    {
                        icon: AirplaneIcon,
                        tooltip: 'Ficha de viagem',
                        onClick: (event, rowData) => { this.setState({ travelSheetModal: true, loadingModal: true, id_edit: rowData.id || null, company_id: rowData.company_id, order_id: rowData.order_id }) }
                    },
                    {
                        icon: AttachFileIcon,
                        tooltip: 'Anexos',
                        onClick: (event, rowData) => { this.setState({ attatchmentsModal: true, id_edit: rowData.id, attatchments: rowData.attachments || [] }) }
                    },
                    {
                        icon: AttachMoneyIcon,
                        tooltip: 'Despesas',
                        onClick: (event, rowData) => { this.setState({ expensesModal: true, expenses: rowData.expenses || [], expenses_id: rowData.id }) }
                    },
                    {
                        icon: AssigmentIcon,
                        tooltip: 'Itens do pedido',
                        onClick: (event, rowData) => { this.handleOrderItensModal(rowData) }
                    },
                    rowData => rowData.status === 'closed' ? ({
                        icon: CachedIcon,
                        tooltip: 'Reabrir SAT',
                        onClick: (event, rowData) => { this.setState({ reopenSATModal: true, reopen_id: rowData.id }) }
                    }) : {
                        icon: CheckCircleIcon,
                        tooltip: 'Finalizar',
                        onClick: (event, rowData) => { this.handleFinish(rowData) }
                    },
                    rowData => rowData.status === 'closed' || rowData.status === 'archived' ? ({
                        icon: StarIcon,
                        tooltip: 'Avaliações',
                        onClick: (event, rowData) => { this.setState({ satisfactionResearchAnswersModal: true, satisfactionResearchAnswers: rowData.satisfaction_research_answers }) }
                    }) : null,
                ]}
                title={'Solicitação de Atendimento Técnico'}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'title', editable: false, cellStyle: {width: 300,minWidth: 300} },
                    { title: 'Startup', field: 'startup_id', cellStyle: {width: 150,minWidth: 150}, editComponent: props => <CompanyOrderSelect idCompany={props.rowData.company_id} onChange={(v) => { props.onChange(v.target.value) }} value={props.value} />, render: rowData => rowData.startup_id ? <RelationField render={(doc) => doc ? `${doc.order_code}` : ''} id={rowData.startup_id} collection={'order'} field={'order_code'}/> : 'Não Vínculado' },
                    { title: 'H/E', field: 'estimated_hours', type: 'numeric', filtering: false },
                    { title: 'H/R', field: 'work_hours', filtering: false, editable: false, render: rowData => this.renderWorkedHours(rowData.technicianHours) },
                    { title: 'Cliente', field: 'company_id', cellStyle: {width: 350,minWidth: 350}, editComponent: props => <CompanySelect onChange={(v) => { props.onChange(v.target.value) }} value={props.value} />, render: rowData => <RelationField render={(doc) => `${doc.name} ${doc.city ? `(${doc.city})` : ''}`} id={rowData.company_id} collection={'company'} field={'name'}/>, filtering: false },
                    { title: 'Hora', field: 'hour_pricing_id', lookup: this.getHourPricingLookup(), cellStyle: {width: 200,minWidth: 200} },
                    { title: 'Data Abertura', editable: false, field: 'start_date', render: rowData => rowData.start_date ? moment(rowData.start_date.toDate ? rowData.start_date.toDate() : rowData.start_date).format('DD/MM/YYYY') : <div></div>, customFilterAndSearch: (term, rowData) => moment(rowData.start_date.toDate()).format('DD/MM/YYYY HH:mm').indexOf(term) > -1},
                    { title: 'Data Prevista', editable: false, field: 'estimated_end_date', render: rowData => rowData.estimated_end_date ? moment(rowData.estimated_end_date.toDate ? rowData.estimated_end_date.toDate() : rowData.estimated_end_date).format('DD/MM/YYYY') : <div></div>, customFilterAndSearch: (term, rowData) => rowData.estimated_end_date ? moment(rowData.estimated_end_date.toDate()).format('DD/MM/YYYY HH:mm').indexOf(term) > -1 : false},
                    { title: 'Data Fechamento', editable: false, field: 'end_date', render: rowData => rowData.end_date ? moment(rowData.end_date.toDate ? rowData.end_date.toDate() : rowData.end_date).format('DD/MM/YYYY') : <div></div>, customFilterAndSearch: (term, rowData) => rowData.end_date ? moment(rowData.end_date.toDate()).format('DD/MM/YYYY HH:mm').indexOf(term) > -1 : false},
                    { title: 'Prioridade', field: 'priority', render: rowData => this.renderPriority(rowData.priority), editComponent: props => <Select style={{width: '100%'}} value={props.value} onChange={(v) => { props.onChange(v.target.value) }}><MenuItem style={{color: 'green'}} value={'low'}>{'Baixa'}</MenuItem><MenuItem style={{color: 'orange'}} value={'normal'}>{'Normal'}</MenuItem><MenuItem style={{color: 'red'}} value={'high'}>{'Alta'}</MenuItem></Select>, filtering: false },
                    { title: 'Pedido', field: 'order_id', editComponent: props => <CompanyOrderSelect idCompany={props.rowData.company_id} onChange={(v) => { props.onChange(v.target.value) }} value={props.value} />, render: rowData => <RelationField render={(doc) => doc ? `${doc.order_code}` : ''} id={rowData.order_id} collection={'order'} field={'order_code'}/> },
                    { title: 'Status', field: 'status', hidden: true, render: rowData => this.renderStatus(rowData.status) },
                    { title: 'Solicitante', field: 'requester', cellStyle: {width: 300,minWidth: 300}},
                    { title: 'E-mail Solicitante', field: 'requester_email'},
                    { title: 'Telefone Solicitante', field: 'requester_phone'},
                    { title: 'Pesquisa Satisfação', field: 'satisfaction_research_id', cellStyle: {width: 300,minWidth: 300}, lookup: this.getSatisfactionResearchLookup() },
                    { title: 'Respondeu pesquisa', editable: false, field: 'answered_satisfaction_research', type: 'boolean' },
                    { title: 'Despesas', field: 'expenses', hidden: true },
                    { title: 'Anexos', field: 'attachments', hidden: true },
                    { title: 'Técnicos', field: 'technicians', hidden: true },
                    { title: 'Peças Substituídas', field: 'replacedParts', hidden: true },
                    { title: 'checkedOutTechnicians', field: 'checkedOutTechnicians', hidden: true },
                ]}
                data={this.state.docs}
                rowStyle={(data) => {
                    
                    return (
                        { backgroundColor: data.status && data.status === 'open' ? 'rgba(133, 212, 140, 0.1)' : data.status === 'closed' ? 'rgba(242, 148, 136, 0.2)' : '' }
                    )
                }}
                ref={ref => { this.table = ref; }}
                components={{
                    Toolbar: props => ( this.renderToolbar(props) ),
                }}

                isEditable={
                    rowData => rowData.status === 'closed' || rowData.status === 'archived' ? false : true
                }

                onRowUpdate={ async (oldData, newData) => {

                    newData.start_date = newData.start_date === null ? null : newData.start_date.seconds ? new Date(newData.start_date.seconds * 1000) : newData.start_date;
                    newData.estimated_end_date = newData.estimated_end_date === null ? null : newData.estimated_end_date.seconds ? new Date(newData.estimated_end_date.seconds * 1000) : newData.estimated_end_date;
                    newData.end_date = newData.end_date === null ? null : newData.end_date.seconds ? new Date(newData.end_date.seconds * 1000) : newData.end_date.seconds;

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {

                        let update = {
                            company_id: newData.company_id,
                            hour_pricing_id: newData.hour_pricing_id,
                            hour_pricing_id: newData.hour_pricing_id,
                            priority: newData.priority,
                            order_id: newData.order_id,
                            startup_id: newData.startup_id ? newData.startup_id : null,
                            satisfaction_research_id: newData.satisfaction_research_id,
                            requester: newData.requester,
                            requester_email: newData.requester_email,
                            requester_phone: newData.requester_phone,
                            estimated_hours: newData.estimated_hours,
                        };

                        await Firestore.update(update, 'appointment', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.expensesModal()} title={'Despesas'} onClose={() => { this.setState({ expensesModal: false }) }} open={this.state.expensesModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.addSatModal()} title={'Criar nova SAT'} onClose={() => { this.setState({ addSatModal: false }) }} open={this.state.addSatModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.travelSheetModal()} title={'Ficha de Viagem'} onClose={() => { this.setState({ travelSheetModal: false, travelFlag: false }) }} open={this.state.travelSheetModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.reopenSATModal()} title={'Deseja realmente reabrir essa SAT?'} onClose={() => { this.setState({ reopenSATModal: false }) }} open={this.state.reopenSATModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.orderItemsModal()} title={'Itens do pedido'} onClose={() => { this.setState({ orderItemsModal: false }) }} open={this.state.orderItemsModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.attatchmentsModal()} title={'Anexos'} onClose={() => { this.setState({ attatchmentsModal: false }) }} open={this.state.attatchmentsModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.closeSATModal()} title={'Finalizar Assistência'} onClose={() => { this.setState({ closeSATModal: false }) }} open={this.state.closeSATModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.archiveSATModal()} title={'Tem certeza?'} onClose={() => { this.setState({ archiveSATModal: false }) }} open={this.state.archiveSATModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.satisfactionResearchAnswersModal()} title={'Pesquisa de Satisfação'} onClose={() => { this.setState({ satisfactionResearchAnswersModal: false, satisfactionResearchAnswers: [] }) }} open={this.state.satisfactionResearchAnswersModal}/>
                <DefaultModal loading={this.state.loadingModal} height={this.state.showCalendar ? '90vh' : ''} width={this.state.showCalendar ? '150vh' : ''} content={this.techniciansModal()} title={'Técnicos'} onClose={() => { this.setState({ techniciansModal: false }) }} open={this.state.techniciansModal}/>
            </div>
        );
    }
}
