import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import moment from 'moment';
import { toast } from 'react-toastify';
import MapSearchBar from '../components/MapSearchBar';
import GeographicHelper from '../helper/GeographicHelper';
import MarkerIconsHelper from '../helper/map/MarkerIconsHelper';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import MarkerClusterer from '@google/markerclusterer';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import CompanyMapTabs from '../components/CompanyMapTabs';
import TechnicianMapTabs from '../components/TechnicianMapTabs';

import ClearIcon from '@material-ui/icons/Clear';
import FilterListIcon from '@material-ui/icons/FilterList';
import ExploreIcon from '@material-ui/icons/Explore';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import SpeedIcon from '@material-ui/icons/Speed';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';

export class MapPage extends Component {
    state = {
        types: {
            company: {
                visible: true,
                label: 'Empresas',
                collection: 'company',
                clustered: true
            },
            technician: {
                visible: true,
                label: 'Técnicos',
                collection: 'technician',
                clustered: true
            }
        },
        docs: {
            company: [],
            technician: []
        },
        markers: [],
        technicianHistory: {
            visible: false,
            polygon: null,
            markers: []
        },
        loading: true,
        map: {
            zoom: 14,
            previousCenter: {
                lat: -27.066484,
                lng: -52.628256
            },
            center: {
                lat: -27.066484,
                lng: -52.628256
            }
        },
        rightPanel: {
            visible: false,
            title: 'Teste',
            subtitle: 'teste 2',
            content: <div></div>
        },
        bottomPanel: {
            visible: false
        },
        x: 0,
        y: 0,
        companyOrders: [],
        activeTab: 0,
    };

    static defaultProps = {
        initialCenter: {
            lat: -27.066484,
            lng: -52.628256
        },
    };

    async componentDidMount() {
        await this.loadTechniciansRef();
        await this.getDocs();

        this.setState({ loading: false });
        // this.intervalId = setInterval(this.loadTechnicians.bind(this), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    updateVisibility = async (collection, bool) => {
        let visible = this.state.types;
        visible[collection].visible = bool;

        if (bool === false) this.hideMarkers(collection);
        await this.getDocs();

        this.renderMarkers(true);

        this.setState({ visible });
    }

    getDocs = async () => {
        await this.loadCompanies();
        await this.loadTechnicians();
    }

    loadTechniciansRef = async () => {
        const docs = this.state.docs;
        const query = await Firestore.customQuery(this.state.types['technician'].collection).where('active', '==', 'S').get();
        const data = [];

        query.forEach(element => {
            const elemData = element.data();

            //Semils coords
            if (!element.coordinates) elemData.coordinates = GeographicHelper.setFirebaseGeopoint(-27.066573, -52.628311);
            data.push({ id: element.id, doc: elemData });
        });

        docs['technician'] = data;

        this.setState({ docs });
    }

    loadTechnicians = async () => {
        const collection = 'technician';

        if (this.state.types[collection].visible) {
            const docs = this.state.docs;

            let query = await Firestore.customQuery('location_log').orderBy('date', 'asc').get();

            docs[collection].forEach(technician => {
                technician.doc.locations = [];
                technician.doc.dates = [];
                technician.doc.speeds = [];
            });

            query.forEach(log => {
                docs[collection].forEach(technician => {
                    const locations = log.data();

                    if (technician.doc.user_id === locations.user_id) {
                        technician.doc.locations.push(locations.coordinates);
                        technician.doc.dates.push(locations.date);
                        technician.doc.speeds.push(locations.speed);
                        technician.doc.coordinates = locations.coordinates;
                        technician.doc.speed = locations.speed;
                    }
                });

            });


            //Random
            // docs[collection].forEach(technician => {
            //     if (!technician.doc.locations) technician.doc.locations = [];


            //     var r = 1000 / 111300 // = 100 meters
            //         , y0 = -27.066573
            //         , x0 = -52.628311
            //         , u = Math.random()
            //         , v = Math.random()
            //         , w = r * Math.sqrt(u)
            //         , t = 2 * Math.PI * v
            //         , x = w * Math.cos(t)
            //         , y1 = w * Math.sin(t)
            //         , x1 = x / Math.cos(y0)

            //     let newY = y0 + y1
            //     let newX = x0 + x1

            //     let random = GeographicHelper.setFirebaseGeopoint(newY, newX)

            //     technician.doc.locations.push(random);
            //     technician.doc.coordinates = random;
            // });

            this.setState({ docs });
            if (mapRef) this.renderMarkers();
        }
    }

    loadCompanies = async () => {
        const collection = 'company';

        if (this.state.types[collection].visible) {
            let distance = 15;

            if (mapRef) {
                const bounds = mapRef.map.getBounds();

                const center = bounds.getCenter();
                const ne = bounds.getNorthEast();

                const r = 3963.0;

                const lat1 = center.lat() / 57.2958;
                const lon1 = center.lng() / 57.2958;
                const lat2 = ne.lat() / 57.2958;
                const lon2 = ne.lng() / 57.2958;

                distance = r * Math.acos(Math.sin(lat1) * Math.sin(lat2) +
                    Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1));
            }


            let query = await Firestore.geoQuery(collection, this.state.map.center.lat, this.state.map.center.lng, distance).get();
            const data = [];

            query.forEach(element => {
                const elemData = element.data();

                //Semils coords
                if (!elemData.coordinates) elemData.coordinates = GeographicHelper.setFirebaseGeopoint(-27.066573, -52.628311);
                data.push({ id: element.id, doc: elemData });
            });

            let docs = this.state.docs;
            docs[collection] = data;

            this.setState({ docs });

        }
    }

    hideMarkers = (collection) => {
        const markers = this.state.markers;

        if (markers.length) {
            markers.forEach(marker => {
                if (marker.collection === collection) marker.setMap(null);
            })
        }
    }

    getPanTechnicianCoords = (id) => {
        let output = null;
        
        this.state.docs[`technician`].forEach(technician => {
            if(technician.id === id) {
                output = technician.doc.coordinates;
            }
        });

        return output;
    }

    panTo = (doc, type) => {
        let coords = null;

        if(type === 'technician') {
            coords = this.getPanTechnicianCoords(doc.id);
        }

        if(type === 'company') {
            coords = doc.coordinates;
        }        

        if(!coords) {
            toast.warn("Não foi possível encontrar as coordenadas", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        const map = this.state.map;
        map.zoom = 18;
        map.center = GeographicHelper.getLatLngFromGeopoint(coords);

        mapRef.map.panTo(GeographicHelper.getLatLngFromGeopoint(coords));
        setTimeout(() => {
            this.setState({ map })
        }, 800);
    }

    onMarkerClick = (collection, ref, marker) => {
        this.buildPanelContent(collection, ref, marker);
        this.togglePanel("right");
        this.setRightPanelHeader(ref.doc.name, ref.doc.google_formatted_address)
    }

    buildPanelContent = (collection, ref, marker) => {
        let panelRef = this.state.rightPanel;

        if (collection === 'company') {
            panelRef.content = this.getCompanyPanelBody(ref);
        }

        if (collection === 'technician') {
            panelRef.content = this.getTechnicianPanelBody(ref);
        }

        if (collection === 'location_log') {
            panelRef.content = this.getLocationLogPanelBody(marker, ref);
        }

        this.setState({ rightPanel: panelRef });
    }

    getCompanyPanelBody(ref) {

        return <CompanyMapTabs company={ref}/>
    }

    getTechnicianPanelBody = (ref) => {
        return (
            <div style={{ width: '100%' }}>
                <div style={{marginBottom: 15}}><DefaultButton title={'Ver histórico de movimentação do técnico'} width={'100%'} onClick={() => { this.renderHistory(ref) }} /></div>
                <TechnicianMapTabs technician={ref}/>
            </div>
        );
    }

    getLocationLogPanelBody = (marker, ref) => {

        let sum = ref.doc.speeds.reduce((a, b) => a + b, 0);

        return (
            <div style={{ width: '100%' }}>
                <h3 style={{color: Colors.semil.green}}>Informações do ponto de histórico: </h3>
                <Card style={{ padding: 10, marginTop: 10, filter: this.state.loading ? 'blur(5px)' : '' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <QueryBuilderIcon style={{ marginTop: 13, cursor: 'pointer', width: 64, textAlign: 'center', verticalAlign: 'center', color: Colors.semil.green }} />
                        <p style={{textAlign: 'center', verticalAlign: 'center' }}>{moment(marker.date.toDate()).format('DD/MM/YYYY HH:mm:ss')}</p>
                    </div>
                </Card>
                <Card style={{ padding: 10, marginTop: 10, filter: this.state.loading ? 'blur(5px)' : '' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <SpeedIcon style={{ marginTop: 13, cursor: 'pointer', width: 64, textAlign: 'center', verticalAlign: 'center', color: Colors.semil.green}} />
                        <p style={{ textAlign: 'center', verticalAlign: 'center' }}>{marker.speed} Km/h</p>
                    </div>
                </Card>
                <Card style={{ padding: 10, marginTop: 10, filter: this.state.loading ? 'blur(5px)' : '' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <ExploreIcon style={{marginTop: 13, cursor: 'pointer', width: 64, textAlign: 'center', verticalAlign: 'center', color: Colors.semil.green }} />
                        <p style={{textAlign: 'center', verticalAlign: 'center' }}>{marker.coords.lat}, {marker.coords.lng}</p>
                    </div>
                </Card>
                <Card style={{ padding: 10, marginTop: 10, filter: this.state.loading ? 'blur(5px)' : '' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <HourglassFullIcon style={{ marginTop: 13, cursor: 'pointer', width: 64, textAlign: 'center', verticalAlign: 'center', color: Colors.semil.green }} />
                        <p style={{ textAlign: 'center', verticalAlign: 'center' }}>{(sum / ref.doc.speeds.length).toFixed(0) + ' Km/h'} Média de velocidade</p>
                    </div>
                </Card>
            </div>
        );
    }

    setRightPanelHeader = (title, subtitle) => {
        let panelRef = this.state.rightPanel;
        panelRef.title = title;
        panelRef.subtitle = subtitle;

        this.setState({ rightPanel: panelRef });
    }

    togglePanel = (from) => {
        let panelRef = this.state[`${from}Panel`];

        panelRef.visible = !panelRef.visible;

        if (from === "right") this.setState({ rightPanel: panelRef });
        if (from === "bottom") this.setState({ bottomPanel: panelRef });
    }

    renderRightPanel() {
        return (
            <SlidingPane
                closeIcon={<ClearIcon style={{ fontSize: 34, cursor: 'pointer', width: 64, textAlign: 'center', verticalAlign: 'center' }} />}
                isOpen={this.state.rightPanel.visible}
                className="map-right-panel"
                title={this.state.rightPanel.title}
                subtitle={this.state.rightPanel.subtitle}
                from="right"
                width="40%"
                onRequestClose={() => this.togglePanel("right")}
            >
                {this.state.rightPanel.content}
            </SlidingPane>
        );
    }

    renderBottomPanel = () => {
        return (
            <SlidingPane
                closeIcon={<ClearIcon style={{ fontSize: 34, cursor: 'pointer', width: 64, textAlign: 'center', verticalAlign: 'center' }} />}
                isOpen={this.state.bottomPanel.visible}
                className="map-bottom-panel"
                title="Filtros de exibição"
                from="bottom"
                width="100%"
                onRequestClose={() => this.togglePanel("bottom")}
            >
                <h3>Exibir:</h3>

                {
                    Object.keys(this.state.types).map(type => (
                        <FormControlLabel
                            control={<Checkbox checked={this.state.types[type].visible} onChange={(v) => { this.updateVisibility(type, !this.state.types[type].visible) }} />}
                            label={this.state.types[type].label}
                        />
                    ))
                }

            </SlidingPane>
        );
    }

    handleChange = (props, map) => {
        const prevLat = this.state.map.center.lat;
        const prevLng = this.state.map.center.lng;

        this.setState({ map: { zoom: map.zoom, center: { lat: map.center.lat(), lng: map.center.lng() }, previousCenter: { lat: prevLat, lng: prevLng } } })

        this.getDocs();
    }

    getDefaultMarker = (type, history, speed) => {
        return {
            path: MarkerIconsHelper.getIcon(type, history, speed),
            fillColor: '#' + (Math.random() * 0xFFFFFF << 0).toString(16),
            fillOpacity: 1,
            origin: new this.props.google.maps.Point(0, 0),
            anchor: new this.props.google.maps.Point(420, 1000),
            strokeWeight: 1,
            strokeColor: '#fafafa',
            scale: .05
        };
    }

    renderMarkers = (redraw = false) => {
        const types = Object.keys(this.state.types);

        var markers = [];
        let markersIds = [];

        if (this.state.markers.length) {
            this.state.markers.forEach((marker) => {
                markersIds.push(marker.id);
            });
        }

        let unchanged = true;

        types.forEach(type => {
            if (this.state.docs[type] && this.state.types[type].visible) {
                this.state.docs[type].forEach(ref => {
                    if (markersIds.includes(ref.id)) {
                        this.state.markers.forEach(existingMarker => {
                            if (existingMarker.id === ref.id) {
                                existingMarker.setPosition(GeographicHelper.getLatLngFromGeopoint(ref.doc.coordinates));
                            }
                        });
                    } else {
                        unchanged = false;

                        let icon = this.getDefaultMarker(type, false, ref.doc.speed ? ref.doc.speed : 0);

                        const clustered = this.state.types[type].clustered;
                        let marker;

                        if (clustered) {
                            marker = new this.props.google.maps.Marker({
                                position: GeographicHelper.getLatLngFromGeopoint(ref.doc.coordinates),
                                icon,
                                animation: this.props.google.maps.Animation.DROP
                            });
                        } else {
                            marker = new this.props.google.maps.Marker({
                                position: GeographicHelper.getLatLngFromGeopoint(ref.doc.coordinates),
                                icon,
                                map: mapRef.map,
                                animation: this.props.google.maps.Animation.DROP
                            });
                        }

                        marker.addListener('click', (event) => {
                            this.onMarkerClick(type, ref, marker);
                        });

                        marker.doc = ref.doc;
                        marker.id = ref.id;
                        marker.collection = type;
                        marker.clustered = clustered;

                        markers.push(marker);
                    }

                })

            }
        })

        let merge = this.state.markers.concat(markers);
        this.setState({ markers: merge });

        if (!unchanged || redraw) {
            if (this.cluster) {
                this.cluster.repaint();
                this.cluster.clearMarkers();
            }

            let pushToCluster = [];

            this.state.markers.forEach((feature) => {
                if (feature.clustered && this.state.types[feature.collection].visible) pushToCluster.push(feature);
            });

            this.cluster = new MarkerClusterer(mapRef.map, pushToCluster,
                {
                    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                    maxZoom: 14
                });

        }

    }

    destroyPolygons = () => {
        if (this.state.technicianHistory.polygon) {
            this.state.technicianHistory.polygon.setMap(null);
            this.state.technicianHistory.markers.forEach(marker => {
                marker.setMap(null);
            });

            this.setState({ technicianHistory: { visible: false, polygon: null, markers: [] } })
        }
    }

    renderHistory = (ref) => {
        this.updateVisibility('company', false);
        this.updateVisibility('technician', false);
        this.togglePanel("right");

        this.destroyPolygons();

        let markers = [];
        let path = [];

        if (ref.doc.locations.length < 1) {
            toast.warn("Este técnico não possui um histórico de localizações", {
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }

        ref.doc.locations.forEach((elem, key) => {
            if (!elem.isEqual(ref.doc.coordinates)) {
                let icon = this.getDefaultMarker('technician', true, 1);

                let marker = new this.props.google.maps.Marker({
                    position: GeographicHelper.getLatLngFromGeopoint(elem),
                    icon,
                    map: mapRef.map,
                    animation: this.props.google.maps.Animation.DROP
                });

                marker.coords = GeographicHelper.getLatLngFromGeopoint(elem);
                marker.date = ref.doc.dates[key];
                marker.speed = ref.doc.speeds[key];

                markers.push(marker);

                //TODO add event to history marker
                marker.addListener('click', (event) => {
                    this.onMarkerClick('location_log', ref, marker);
                });

            }

            path.push(GeographicHelper.getLatLngFromGeopoint(elem));
        });

        let technicianHistory = this.state.technicianHistory;

        technicianHistory.polygon = new this.props.google.maps.Polyline({
            path: path,
            geodesic: true,
            strokeColor: Colors.semil.green,
            strokeOpacity: 1.0,
            strokeWeight: 3
        });

        technicianHistory.polygon.setMap(mapRef.map);
        technicianHistory.markers = markers;

        technicianHistory.visible = true;

        this.setState({ technicianHistory });
    }

    renderMapElements = () => {
        this.renderMarkers();
    }

    renderMapDiv() {
        return (
            <div style={{ width: 'calc(100% - 240px)', height: 'calc(100% - 64px)', top: '64px', position: 'fixed' }}>
                <Map
                    ref={map => { mapRef = map }}
                    google={this.props.google}
                    zoom={this.state.map.zoom}
                    initialCenter={this.props.initialCenter}
                    center={this.state.map.center}
                    onDragend={this.handleChange}
                    onZoomChanged={this.handleChange}
                    minZoom={3}
                    onTilesloaded={this.renderMapElements}
                >
                    <Button className={"map-filter-button"} style={styles.googleMapsConfigButton} onClick={() => { this.togglePanel("bottom") }}><FilterListIcon style={{ marginRight: 5 }} />Filtros</Button>
                    {this.state.technicianHistory.visible ? <Button className={"map-filter-button"} style={styles.googleMapsClearHistoryButton} onClick={() => { this.destroyPolygons(); this.updateVisibility('company', true); this.updateVisibility('technician', true); }}><ClearIcon style={{ marginRight: 5 }} />Limpar histórico</Button> : null}
                </Map>
                <MapSearchBar onResult={(doc, type) => { this.panTo(doc, type) }} />
                {this.renderRightPanel()}
                {this.renderBottomPanel()}
            </div>
        );
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : this.renderMapDiv();
    }
}

let mapRef;

const styles = {
    container: {
        padding: 25,
    },
    googleMapsConfigButton: {
        position: 'absolute',
        top: 10,
        right: 60,
        width: 'auto',
        height: 40,
        backgroundColor: 'white',
        borderRadius: 0,
        color: Colors.text.primaryText
    },
    googleMapsClearHistoryButton: {
        position: 'absolute',
        top: 60,
        right: 10,
        width: 150,
        height: 60,
        backgroundColor: 'white',
        borderRadius: 0,
        color: Colors.text.primaryText
    },
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyAWc4diUe-95wxL1U-eZCgHzwyileYlebI'),
    language: 'pt-br'
})(MapPage)
