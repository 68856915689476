import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, FormLabel, FormControlLabel, Checkbox, InputLabel, Select, MenuItem, FormGroup } from '@material-ui/core';
import PostAddIcon from '@material-ui/icons/PostAdd';
import SyncIcon from '@material-ui/icons/Sync';
import Firestore from '../api/firebase/Firestore';
import moment from 'moment';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import SATHelper from '../helper/SATHelper';
import Functions from '../constants/Functions';

export default class OrderPage extends Component {

    state = {
        docs: [],
        hourPricingDocs: [],
        clientDocs: [],
        loading: true,
        loadingOrders: false,
        loadingModal: false,
        SATModal: false,
        importModal: false,
        id_company: null,
        estimated_hours: 0,
        company_name: '',
        orderData: null,
        estimated_end_date: new Date(),
        priority: 'normal',
        hour_pricing: null,
        requester: '',
        requester_email: '',
        requester_phone: '',
        checkedAllItens: false,
        import_order: '',
        backgroundTask: false
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('company').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            let row = { name: data.name, city: data.city, address: data.address, address_bairro: data.address_bairro, cnpj: data.cnpj, country: data.country, id: doc.id };

            docs.push(row);
        });

        this.setState({ docs: docs, loading: false });
    }

    async getHourPricingDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('hour_pricing').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ hourPricingDocs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
        this.getHourPricingDocs();
    }

    async fetchOrders(company_cnpj) {

        this.setState({ loadingOrders: true });

        if (company_cnpj) {

            let query = await Firestore.customQuery('order').where('billing_cnpj', '==', company_cnpj).orderBy('order_date', 'desc').get();
            let docs = [];

            query.forEach((doc, key) => {
                let row = { order_code: doc.data().order_code, order_items: doc.data().order_items, id: doc.id, order_date: doc.data().order_date, deliver_date: doc.data().deliver_date };

                docs.push(row);
            });

            this.setState({ clientDocs: docs, loadingOrders: false });
        }
    }

    renderOrderItens(data) {

        if (data.order_items) {

            return (
                <div style={{ paddingLeft: 18 }}>
                    <p style={{ fontWeight: 'bold' }}>{'Itens do Pedido'}</p>
                    {data.order_items.map((item, key) => {

                        return (
                            <div style={{ marginBottom: 20, borderTop: '0.5px solid lightgrey' }}>
                                <p><b>{'Descrição: '}</b>{item.name} <b>{'Código do item: '}</b> {item.item_code} <b>{'Quantidade: '}</b> {item.quantity_ordered}</p>
                                {!data.order_items[key + 1] && <div style={{ borderBottom: '0.5px solid lightgrey' }} />}
                            </div>
                        )

                    })
                    }
                </div>
            )

        } else {
            return <div />
        }
    }

    async newSAT() {

        if (this.state.id_company && this.state.orderData && this.state.estimated_end_date && this.state.priority) {

            try {

                this.setState({ loadingModal: true });

                let data = {
                    answered_satisfaction_research: false,
                    attachments: [],
                    company_id: this.state.id_company,
                    end_date: null,
                    estimated_end_date: this.state.estimated_end_date,
                    history: [],
                    hour_pricing_id: this.state.hour_pricing,
                    order_id: this.state.orderData.id,
                    priority: this.state.priority,
                    satisfaction_research_id: '',
                    start_date: new Date(),
                    status: 'open',
                    technicians: [],
                    order_items: [],
                    title: `Assistência #${moment().format('DDMMYYYYHHmmss')}`,
                    user_id: SessionHelper.getFirebaseAuth().uid,
                    requester: this.state.requester,
                    requester_email: this.state.requester_email,
                    requester_phone: this.state.requester_phone,
                    estimated_hours: this.state.estimated_hours,
                };

                this.state.orderData.order_items.forEach((item, key) => {

                    if (item.checked) {

                        data.order_items.push(item.item_code);
                    }
                });

                let doc = await Firestore.getId('appointment');

                await Firestore.insert(data, 'appointment', doc);
                await SATHelper.historyIteration(doc, data.user_id, 'Usuário criou a SAT');

                this.setState({ loadingModal: false, SATModal: false });
                toast.success('SAT cadastrado com sucesso');

            } catch (error) {
                console.log(error);
                this.setState({ loadingModal: false });
                toast.error('Erro ao cadastrar SAT');
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    async importOrder() {

        if (this.state.import_order) {

            try {

                this.setState({ loadingModal: true });

                let headers = new Headers();
                headers.set('content-type', 'application/json');

                let request = await fetch(`${Functions.getEndPoint()}/syncOrderById`, { method: 'POST', headers: headers, body: JSON.stringify({ order_code: this.state.import_order }) });
                let json = await request.json();

                toast.success(`Pedido ${this.state.import_order} importado com sucesso`);
                this.setState({ import_order: '', importModal: false });

                this.getDocs();

            } catch (error) {

                this.setState({ loadingModal: false });
                toast.error('Erro ao importar pedido');
            }

        } else {

            toast.warn('Preencha o número do pedido')
        }
    }

    async updateOrder(id) {

        if (id) {

            try {

                let headers = new Headers();
                headers.set('content-type', 'application/json');

                let request = await fetch(`${Functions.getEndPoint()}/syncOrderById`, { method: 'POST', headers: headers, body: JSON.stringify({ order_code: id }) });
                let json = await request.json();

                toast.success(`Pedido ${this.state.import_order} atualizado com sucesso`);

                this.getDocs();

            } catch (error) {

                this.setState({ loadingModal: false });
                toast.error('Erro ao importar pedido');
            }

        } else {

            toast.warn('Preencha o número do pedido')
        }
    }

    checkAllItens(checked) {

        let items = this.state.orderData;
        items.checkedAll = !items.checkedAll;

        items.order_items.map((item, key) => {
            items.order_items[key].checked = checked;
        });

        this.setState({ checkedAllItens: checked, orderData: items });
    }

    importModal() {
        if (this.state.importModal) {
            return (
                <div>
                    <DefaultInput onBlur={(v) => { this.setState({ import_order: v }) }} label={'Número do Pedido'} />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.importOrder() }} style={{ fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'IMPORTAR'}</Button>
                        <Button onClick={() => { this.setState({ importModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    newSATModal() {
        if (this.state.orderData) {
            return (
                <div>
                    <DefaultInput disabled value={this.state.company_name} label={'Cliente'} />


                    <div style={{ marginTop: 15, maxHeight: 200, overflowY: 'scroll', display: 'flex', flexDirection: 'column' }}>
                        <FormLabel style={{}} component="legend">Selecione os itens do pedido</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.checkedAllItens} onChange={(v) => { this.checkAllItens(v.target.checked) }} />}
                                label={'Marcar Todos'}
                            />
                            {this.state.orderData.order_items.map((item, key) => {
                                return (
                                    <FormControlLabel
                                        control={<Checkbox checked={this.state.orderData.order_items[key].checked || false} onChange={(v) => { let data = this.state.orderData; data.order_items[key].checked = v.target.checked; this.setState({ orderData: data }) }} />}
                                        label={`${item.item_code} - ${item.name} (${item.quantity_ordered} unidade${item.quantity_ordered > 1 ? 's' : ''})`}
                                    />
                                )
                            })}
                        </FormGroup>
                    </div>

                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{ width: '100%', marginTop: 15 }}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy'}
                            autoOk={true}
                            label="Data Prevista"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={(v) => { this.setState({ estimated_end_date: v }) }}
                            value={this.state.estimated_end_date}
                        />
                    </MuiPickersUtilsProvider>

                    <div style={{ marginTop: 30 }}>
                        <InputLabel id="hour-type">Tipo de Hora</InputLabel>
                        <Select
                            style={{ width: '100%' }}
                            labelId="hour-type"
                            value={this.state.hour_pricing}
                            onChange={(v) => { this.setState({ hour_pricing: v.target.value }) }}>
                            {this.state.hourPricingDocs.map((doc, key) => {
                                return <MenuItem key={key} value={doc.id}>{doc.type}</MenuItem>
                            })}
                        </Select>
                    </div>

                    <div style={{ marginTop: 30, marginBottom: 30 }}>
                        <InputLabel id="demo-simple-select-label">Prioridade</InputLabel>
                        <Select
                            style={{ width: '100%' }}
                            labelId="demo-simple-select-label"
                            value={this.state.priority}
                            onChange={(v) => { this.setState({ priority: v.target.value }) }}>
                            <MenuItem style={{ color: 'green' }} value={'low'}>{'Baixa'}</MenuItem>
                            <MenuItem style={{ color: 'orange' }} value={'normal'}>{'Normal'}</MenuItem>
                            <MenuItem style={{ color: 'red' }} value={'high'}>{'Alta'}</MenuItem>
                        </Select>
                    </div>

                    <DefaultInput type={'number'} onBlur={(v) => { this.setState({ estimated_hours: v }) }} label={'Horas Estimadas pelo Comercial'}/>

                    <FormLabel style={{ marginTop: 10, marginBottom: 10 }} component="legend">Solicitante</FormLabel>
                    <DefaultInput onBlur={(v) => { this.setState({ requester: v }) }} label={'Nome'} />
                    <DefaultInput onBlur={(v) => { this.setState({ requester_email: v }) }} label={'E-mail'} />
                    <DefaultInput onBlur={(v) => { this.setState({ requester_phone: v }) }} label={'Telefone'} />

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.newSAT() }} style={{ fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ SATModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    async syncLatestOrders() {
        if(this.state.backgroundTask == true) {
            toast.warn(`Sincronização em progresso, aguarde...`);
            return;
        }

        try {
            this.setState({ backgroundTask: true });

            let headers = new Headers();
            headers.set('content-type', 'application/json');

            toast.warn(`Sincronizando pedidos mais recentes em segundo plano, isso pode demorar um pouco. Te avisaremos quando a atualização terminar.`);

            let request = await fetch(`${Functions.getEndPoint()}/syncLatestOrders`, { method: 'GET', headers: headers });
            let json = await request.json();

            await this.getDocs();

            this.setState({ backgroundTask: false });

            toast.success(`Pedidos recentes sincronizados com sucesso!\n Inseridos: ${json.inserted}`);
        } catch (error) {

            this.setState({ backgroundTask: false });
            toast.error('Erro ao importar pedidos');
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{ marginRight: 10 }}>
                        <DefaultButton onClick={() => { this.setState({ importModal: true }) }} title={'Importar Pedido'} />
                    </div>
                    <div style={{ marginRight: 10 }}>
                        <DefaultButton onClick={() => { this.syncLatestOrders() }} width={280} title={this.state.backgroundTask ? 'Sincronizando...' : 'Sincronizar mais recentes'} />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <DefaultTable
                        onRowClick={(evt, data) => { this.fetchOrders(data.cnpj); this.setState({ id_company: data.id, company_name: data.name, selected: data.id }) }}
                        width={'50%'}
                        title={'Cliente'}
                        columns={[
                            { title: 'Id', field: 'id', hidden: true },
                            { title: 'Nome', field: 'name', editable: false },
                            { title: 'Cnpj', field: 'cnpj', editable: false, render: row => (row.cnpj.toString().replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")) },
                            { title: 'Cidade', field: 'city', editable: false },
                            { title: 'Bairro', field: 'address_bairro', editable: false },
                            { title: 'País', field: 'country', editable: false },
                        ]}
                        data={this.state.docs}
                        rowStyle={(data) => {

                            return (
                                { backgroundColor: data.id === this.state.selected ? 'rgba(0, 0, 0, 0.08)' : '' }
                            )
                        }}
                    />

                    <div style={{ marginLeft: '2vh' }} />

                    <DefaultTable
                        actions={[
                            {
                                icon: PostAddIcon,
                                tooltip: 'Abrir SAT para esse pedido',
                                onClick: (event, rowData) => { this.setState({ SATModal: true, orderData: rowData }) }
                            },
                            {
                                icon: SyncIcon,
                                tooltip: 'Atualizar Pedido',
                                onClick: (event, rowData) => { this.updateOrder(rowData.order_code) }
                            }
                        ]}
                        onRowClick={(evt, rowData, togglePanel) => { togglePanel() }}
                        isLoading={this.state.loadingOrders}
                        detailPanel={(evt) => { return this.renderOrderItens(evt) }}
                        width={'50%'}
                        title={'Pedidos'}
                        columns={[
                            { title: 'Id', field: 'id', hidden: true },
                            { title: 'Pedido', field: 'order_code' },
                            { title: 'Data Emissão', field: 'order_date', render: rowData => moment(rowData.order_date.toDate()).format('DD/MM/YYYY HH:mm') },
                            { title: 'Data Entrega', field: 'deliver_date', render: rowData => moment(rowData.deliver_date.toDate()).format('DD/MM/YYYY HH:mm') },
                            { title: 'Items do Pedido', field: 'order_items', hidden: true },
                        ]}
                        data={this.state.clientDocs}
                    />
                </div>
                <DefaultModal loading={this.state.loadingModal} content={this.newSATModal()} title={'Nova SAT'} onClose={() => { this.setState({ SATModal: false }) }} open={this.state.SATModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.importModal()} title={'Importar Pedido'} onClose={() => { this.setState({ importModal: false }) }} open={this.state.importModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
