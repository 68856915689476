import Firestore from '../api/firebase/Firestore';
import moment from 'moment';
import jsPDF from 'jspdf';
import PDFHelper from '../helper/PDFHelper';
import Functions from '../constants/Functions'

export default class SATHelper {

    static async historyIteration(id_sat, id_user = '', message = '', date = new Date(), file = '', auto_generated = true) {

        try {

            let sat = await Firestore.customQuery('appointment').doc(id_sat).get();

            if (sat.exists) {
    
                let history = sat.data().history;
    
                if (!history) history = [];
    
                history.push({ id_user: id_user, message: message, date: date, file: file, auto_generated: auto_generated });
    
                await Firestore.update({ history: history }, 'appointment', id_sat)

                fetch(`${Functions.getEndPoint()}/interationEmail`, {headers: {"Content-Type": "application/json"}, method: 'POST', body: JSON.stringify({ id_user: id_user, message: message, id_sat: id_sat }) });

                return true;

            } else {

                throw new Error('SAT não encontrado');
            }

        } catch (error) {

            return error;
        }
    }

    static async removeIteration(id_sat, key) {

        if (id_sat && key >= 0) {

            let query = await Firestore.getDoc('appointment', id_sat);

            if (query.exists) {

                let history = query.data().history || [];

                if (history.length && history[key]) {

                    history = history.reverse();
                    history.splice(key, 1);

                    await Firestore.update({ history: history.reverse() }, 'appointment', query.id);

                    return history;
                }
            }
        }

        return false;
    }

    static async reopenSAT(id_sat, status = 'open', id_user = '') {

        try {

            if (!id_sat) throw new Error('SAT não informado');

            let query = await Firestore.customQuery('appointment').doc(id_sat).get();

            if (query.exists) {
    
                await Firestore.update({ status: status, start_date: new Date() }, 'appointment', id_sat);
                await this.historyIteration(id_sat, id_user, 'SAT reaberta');

                return true;

            } else {

                throw new Error('SAT não encontrado');
            }

        } catch (error) {

            return error;
        }
    }

    static async PrintSAT(data) {

        let doc = new jsPDF()

        let SheetHelper = new PDFHelper({}, 1, 'sat', data.title)

        let verticalPosition = 10

        let content
        let page
        let cliente

        //Verifica se a SAT tem uma order vinculada e preenche os mesmos
        if (data.client === undefined){

            cliente = ['PEDIDO:', 'TIPO DE PEDIDO:', 'CLIENTE:', ['PAIS:', 'ESTADO:', 'CIDADE:', 'RUA:', 'BAIRRO:'], ['CONTATO:', 'TELEFONE:', 'E-MAIL:'] ]

        } else{
            
            cliente = ['PEDIDO: ' + (data.client.order ? data.client.order  : ''), 
            'TIPO DE PEDIDO: ' + (data.client.prop ? data.client.prop  : ''), 
            'CLIENTE: ' + (data.client.clientName ? data.client.clientName  : ''), 
        
            ['PAIS: ' + (data.client.country ? data.client.country  : ''), 
            'ESTADO: ' + (data.client.state ? data.client.state  : ''),
            'CIDADE: ' + (data.client.city ? data.client.city  : ''),
            'RUA: ' + (data.client.street ? data.client.street  : ''), 
            'BAIRRO: ' + (data.client.neighbourhood ? data.client.neighbourhood  : '')], 
            
            ['CONTATO: ' + (data.client.info ? data.client.info  : ''), 
            'TELEFONE: ' + (data.client.phone ? data.client.phone  : ''), 
            'E-MAIL: ' + (data.client.mail ? data.client.mail  : '')]]
        }
        
        let positions  = [30, 50, 140, 160]

        let grid = data.replacedParts || [['4.1 ITEM', '4.2 CÓDIGO', '4.3 DESCRIÇÃO', '4.4 QUANTIDADE'], ['', '', '', ''], ['', '', '', ''], ['', '', '', ''], ['', '', '', ''], ['', '', '', ''], ['', '', '', ''], ['', '', '', ''], ['', '', '', ''], ['', '', '', ''], ['', '', '', '']];
        let grid_order_items = data.order_items || [];
        let grid_interation = data.history || [];

        let rows = [25, 42, 70, 170, 200]
        let rows_order_items = [25, 90, 120, 160, 200]
        let rows_interation = [25, 90, 150, 200]

        let grid2 = [
            ['ROLAMENTOS', 'MANCAIS', 'RETENTORES', 'CORRENTES'],
            ['CORREIAS', 'POLIAS', 'ENGRENAGENS', 'RODAS DENTADAS'],
            ['CONJUNTO\nPNEUMÁTICO', 'CONJUNTO HIDRÁULICO', 'MOTORES', 'REDUTORES'],
            ['MOTOREDUTORES', 'SERVOMOTORES', 'APERTO DE PARAFUSOS', 'FITAS/LONAS'],
            ['BUCHAS EM GERAL', 'LUBRIFICAÇÃO EM GERAL', 'SENSORES EM GERAL', 'COMPONENTES\nELÉTRICOS']
        ]
    
        let rows2 = [25, 68.75, 112.5, 156.25, 200]

        doc = SheetHelper.CreateSideText(doc)

        content = await SheetHelper.CreateHeaderTravel(doc, verticalPosition, 'RELATÓRIO DE START-UP\nE ASSISTÊNCIA TÉCNICA', {number: 'P - SGS - 012', rev: '00', title: data.title}, false)
        doc = content.doc
        verticalPosition = content.verticalPosition
        
        content = SheetHelper.CreateLine(doc, verticalPosition, 4, 5, 25, 200, true)
        doc = content.doc
        verticalPosition = content.verticalPosition

        content = await SheetHelper.CreateList(doc, verticalPosition, 1, 'DADOS DO CLIENTE', cliente)
        doc = content.doc
        verticalPosition = content.verticalPosition

        content = SheetHelper.CreateLine(doc, verticalPosition, 4, 5, 25, 200, true)
        doc = content.doc
        verticalPosition = content.verticalPosition

        content = SheetHelper.CreateTitle(doc, verticalPosition, 2, 'DADOS DO SERVIÇO E DA MÁQUINA')
        doc = content.doc
        verticalPosition = content.verticalPosition

        content = await SheetHelper.CreateGrid(doc, verticalPosition , grid_order_items, rows_order_items, 8, null, 35)
        doc = content.doc
        verticalPosition = content.verticalPosition;

        let requester = [['Solicitante: ' + SheetHelper.StringSeparator(data.requester, 47), 'E-mail : ' + SheetHelper.StringSeparator(data.requester_email, 55), 'Telefone : ' + data.requester_phone]]

        content = await SheetHelper.CreateList(doc, verticalPosition, 2, '', requester)
        doc = content.doc
        verticalPosition = content.verticalPosition

        verticalPosition += 2;

        content = SheetHelper.CreateLine(doc, verticalPosition, 4, 5, 25, 200, true)
        doc = content.doc
        verticalPosition = content.verticalPosition

        content = await SheetHelper.CreateDescription(doc, verticalPosition, 3 , 'DESCRIÇÃO DO SERVIÇO', data.workDescription, 16)
        doc = content.doc
        verticalPosition = content.verticalPosition
        
        if (SheetHelper.GetPage === 1){
            doc = await SheetHelper.CreateFooterSheet(doc)
            content = await SheetHelper.CreateNewPage(doc)
            doc = content.doc
            verticalPosition = content.verticalPosition

            doc = SheetHelper.CreateSideText(doc)

            content = await SheetHelper.CreateHeaderTravel(doc, verticalPosition, 'RELATÓRIO DE START-UP\nE ASSISTÊNCIA TÉCNICA', {number: 'P - SGS - 012', rev: '00', title: data.title}, false)
            doc = content.doc
            verticalPosition = content.verticalPosition
    
            verticalPosition += 5
        }

        let interation = [['HISTÓRICO DE INTERAÇÕES']]

        content = await SheetHelper.CreateList(doc, verticalPosition, 3, '', interation)
        doc = content.doc
        verticalPosition = content.verticalPosition

        verticalPosition += 4;

        content = await SheetHelper.CreateGrid(doc, verticalPosition , grid_interation, rows_interation, 8, null, 35)
        doc = content.doc
        verticalPosition = content.verticalPosition;

        verticalPosition += 8;

        if (SheetHelper.PageTracker(verticalPosition, 8)){
            doc = await SheetHelper.CreateFooterSheet(doc)
            content = await SheetHelper.CreateNewPage(doc)
            doc = content.doc
            verticalPosition = content.verticalPosition

            doc = SheetHelper.CreateSideText(doc)

            content = await SheetHelper.CreateHeaderTravel(doc, verticalPosition, 'RELATÓRIO DE START-UP\nE ASSISTÊNCIA TÉCNICA', {number: 'P - SGS - 012', rev: '00', title: data.title}, false)
            doc = content.doc
            verticalPosition = content.verticalPosition
    
            verticalPosition += 5
        }

        content = SheetHelper.CreateLine(doc, verticalPosition, 4, 5, 25, 200, true)
        doc = content.doc
        verticalPosition = content.verticalPosition

        content = SheetHelper.CreateTitle(doc, verticalPosition, 4, 'PEÇAS SUBSTITUÍDAS')
        doc = content.doc
        verticalPosition = content.verticalPosition

        verticalPosition += 4

        content = await SheetHelper.CreateGrid(doc, verticalPosition , grid, rows)
        doc = content.doc
        verticalPosition = content.verticalPosition
        
        verticalPosition += 4

        doc.text('* O código e a descrição de cada item podemser encontrados no catálogo de peças.', 25, verticalPosition)
        verticalPosition+= 5;

        content = SheetHelper.CreateLine(doc, verticalPosition, 2, 5, 25, 200, true)
        doc = content.doc
        verticalPosition = content.verticalPosition

        if (SheetHelper.PageTracker(verticalPosition, 8)){
            doc = await SheetHelper.CreateFooterSheet(doc)
            content = await SheetHelper.CreateNewPage(doc)
            doc = content.doc
            verticalPosition = content.verticalPosition

            doc = SheetHelper.CreateSideText(doc)

            content = await SheetHelper.CreateHeaderTravel(doc, verticalPosition, 'RELATÓRIO DE START-UP\nE ASSISTÊNCIA TÉCNICA', {number: 'P - SGS - 012', rev: '00', title: data.title}, false)
            doc = content.doc
            verticalPosition = content.verticalPosition
    
            verticalPosition += 5
        }

        content = SheetHelper.CreateTitle(doc, verticalPosition, 5, 'ITENS REVISADOS')
        doc = content.doc
        verticalPosition = content.verticalPosition

        verticalPosition += 6

        content = await SheetHelper.CreateGrid(doc, verticalPosition , grid2, rows2, 8, {space: 2, rows: [0, 1, 2, 3, 4], positions: data.reviewedParts })
        doc = content.doc
        verticalPosition = content.verticalPosition

        let observation = ['CONSIDERAÇÕES FINAIS']

        verticalPosition+= 6;

        if (verticalPosition > 220) {
            doc = await SheetHelper.CreateFooterSheet(doc)
            content = await SheetHelper.CreateNewPage(doc)
            doc = content.doc
            verticalPosition = content.verticalPosition

            doc = SheetHelper.CreateSideText(doc)

            content = await SheetHelper.CreateHeaderTravel(doc, verticalPosition, 'RELATÓRIO DE START-UP\nE ASSISTÊNCIA TÉCNICA', {number: 'P - SGS - 012', rev: '00', title: data.title}, false)
            doc = content.doc
            verticalPosition = content.verticalPosition
    
            verticalPosition += 5
        }

        content = SheetHelper.CreateLine(doc, verticalPosition, 4, 5, 25, 200, true)
        doc = content.doc
        verticalPosition = content.verticalPosition
        
        content = await SheetHelper.CreateList(doc, verticalPosition, 6 , 'NOTAS E OBSERVAÇÕES', observation)
        doc = content.doc
        verticalPosition = content.verticalPosition

        verticalPosition += 10;
        doc.setFontSize(14);
        doc.text('•', 29, verticalPosition + 0.5);
        doc.setFontSize(9);
        doc.text('Deverá ser gerado no mínimo duas vias (devidamente preenchidas e assinadas)\ncom igual teor de  informações. Uma via retorna a SEMIL e uma via fica com o cliente;', 34, verticalPosition, {align: 'justify', maxWidth: 160});
    
        doc = await SheetHelper.CreateFooterSheet(doc)
        content = await SheetHelper.CreateNewPage(doc)
        doc = content.doc
        verticalPosition = content.verticalPosition

        doc = SheetHelper.CreateSideText(doc)

        content = await SheetHelper.CreateHeaderTravel(doc, verticalPosition, 'RELATÓRIO DE START-UP\nE ASSISTÊNCIA TÉCNICA', {number: 'P - SGS - 012', rev: '00', title: data.title}, false)
        doc = content.doc
        verticalPosition = content.verticalPosition

        verticalPosition += 5

        content = SheetHelper.CreateLine(doc, verticalPosition, 7, 5, 25, 200, true);
        doc = content.doc
        verticalPosition = content.verticalPosition
    
        content = await SheetHelper.CreateSignature(doc, verticalPosition, 7 , 'ASSINATURAS E CARIMBOS', data.signature, positions)
        doc = content.doc
        verticalPosition = content.verticalPosition
    
        doc = await SheetHelper.CreateFooterSheet(doc)

        doc.output('save', `${data.title}.pdf`)

    }

    static async PrintTravelSheet(data){

        let doc = new jsPDF()

        let SheetHelper = new PDFHelper({left: 10}, 1, 'travel', data.title)

        let verticalPosition = 10
        let content
        let start

        let position1
        
        let cliente

        let grid2 = [
            ['ITEM', 'NOME', 'RESPONSÁVEL']
        ]
        let rows2 = [10, 25, 125, 200]

        //Verifica se a SAT tem uma order vinculada e preenche os mesmos
        if (data.client === undefined){
            cliente = ['PEDIDO:', 'TIPO DE PEDIDO:', 'CLIENTE:', ['PAIS:', 'ESTADO:', 'CIDADE:', 'RUA:', 'BAIRRO:'], ['CONTATO:', 'TELEFONE:', 'E-MAIL:'] ]
        }else{
            
            cliente = ['PEDIDO: ' + (data.client.order ? data.client.order  : ''), 
            'TIPO DE PEDIDO: ' + (data.client.prop ? data.client.prop  : ''), 
            'CLIENTE: ' + (data.client.clientName ? data.client.clientName  : ''), 
        
            ['PAIS: ' + (data.client.country ? data.client.country  : ''), 
            'ESTADO: ' + (data.client.state ? data.client.state  : ''),
            'CIDADE: ' + (data.client.city ? data.client.city  : ''),
            'RUA: ' + (data.client.street ? data.client.street  : ''), 
            'BAIRRO: ' + (data.client.neighbourhood ? data.client.neighbourhood  : '')], 
            
            ['CONTATO: ' + (data.client.info ? data.client.info  : ''), 
            'TELEFONE: ' + (data.client.phone ? data.client.phone  : ''), 
            'E-MAIL: ' + (data.client.mail ? data.client.mail  : '')]]
        }

        //Sdiciona dados na row2
        for (let index = 0; index < data.technicians.length; index++) {
            
            if (index + 1 < 10){
                content = '0' + (index + 1).toString()
            }else{
                content = (index + 1).toString()
            }

            grid2.push([content, data.technicians[index].name, data.technicians[index].main ? '           X' : ''])
        }

        content = undefined
        verticalPosition+= 4;

        content = await SheetHelper.CreateHeaderTravel(doc, verticalPosition, 'FICHA DE VIAGEM', {number: 'P - SGS - 012', rev: '00', title: data.title})
        doc = content.doc
        verticalPosition = content.verticalPosition

        content = SheetHelper.CreateLine(doc, verticalPosition - 4, 4, 5)
        doc = content.doc
        verticalPosition = content.verticalPosition

        verticalPosition+= 3;

        //#region Item 1 DADOS DO CLIENTE

        content = SheetHelper.CreateLine(doc, verticalPosition, 4, 5, 10, 200, true);
        doc = content.doc
        verticalPosition = content.verticalPosition

        content = await SheetHelper.CreateList(doc, verticalPosition, 1, 'DADOS DO CLIENTE', cliente)
        doc = content.doc
        verticalPosition = content.verticalPosition

        //#endregion

        content = SheetHelper.CreateLine(doc, verticalPosition, 4, 5, 10, 200, true)
        doc = content.doc
        verticalPosition = content.verticalPosition

        //#region Item 2 TIPO DE TRABALHO A SER EXECUTADO

        content = SheetHelper.CreateTitle(doc, verticalPosition, 2, 'TIPO DE TRABALHO A SER EXECUTADO')
        doc = content.doc
        verticalPosition = content.verticalPosition

        verticalPosition += 6;

        doc.text(data.workType, 10, verticalPosition);

        verticalPosition += 20;

        //#endregion

        content = SheetHelper.CreateLine(doc, verticalPosition, 4, 5, 10, 200, true)
        doc = content.doc
        verticalPosition = content.verticalPosition

        //#region Item 3 INFORMAÇÕES SOBRE O TRABALHO

        content = await SheetHelper.CreateList(doc, verticalPosition, 3 , 'INFORMAÇÕES SOBRE O TRABALHO', ['DESCRIÇÃO DOS TRABALHOS:'])
        doc = content.doc
        verticalPosition = content.verticalPosition

        verticalPosition += 5

        if (data.workDescription){
            doc.text(SheetHelper.StringSeparator(data.workDescription[0], 120), 10, verticalPosition)
        }

        let tempPosition = verticalPosition + 0.5

        for (let index = 0; index < data.workDescription[1]; index++) {
            
            doc.line(10, tempPosition, 200, tempPosition, 'F')

            content = SheetHelper.CreateLine(doc, tempPosition)
            doc = content.doc

            tempPosition += 3.6

        }

        verticalPosition += 2.5 * data.workDescription[1]

        content = SheetHelper.CreateLine(doc, verticalPosition, 4)
        doc = content.doc
        verticalPosition = content.verticalPosition

        start = verticalPosition

        verticalPosition += 4

        doc.setFontType('bold');
        doc.text('3.2 TÉCNICOS', rows2[0] + 2, verticalPosition);
        doc.setFontType('normal');
        
        content = SheetHelper.CreateLine(doc, verticalPosition, 2, 0)
        doc = content.doc
        verticalPosition = content.verticalPosition


        content = await SheetHelper.CreateGrid(doc, verticalPosition , grid2, rows2)
        doc = content.doc
        position1 = content.verticalPosition
        verticalPosition = position1

        start = verticalPosition

        verticalPosition += 4.5

        let grid4 = [
            ['ETAPA', 'DATA SAÍDA', 'HORÁRIO', 'DATA DE RETORNO', 'HORÁRIO'],
        ]
        let rows4 = [10, 30, 72.5, 115, 157.5, 200]
        
        let tempDate

        for (let index = 0; index < data.workDates.length; index++) {
            
            tempDate = data.workDates[index]

            grid4.push([ (index < 10 ? '0' : '') + (index + 1).toString(), tempDate[0], tempDate[1], tempDate[2] ? tempDate[2] : '', tempDate[3] ? tempDate[3] : ''])
        }

        doc.setFontSize(8);
        doc.setFontType('bold');
        doc.text('3.3 DATA DE EXECUÇÃO DOS TRABALHOS', 12, verticalPosition);
        doc.setFontType('normal');
        
        content = SheetHelper.CreateLine(doc, verticalPosition, 2, 0)
        doc = content.doc
        verticalPosition = content.verticalPosition

        content = await SheetHelper.CreateGrid(doc, verticalPosition , grid4, rows4)
        doc = content.doc
        verticalPosition = content.verticalPosition



        //#endregion

        if (SheetHelper.GetPage === 1){
            
            doc = SheetHelper.CreateFooterTravel(doc, {Number: '4'})

            content = await SheetHelper.CreateNewPage(doc)
            doc = content.doc
            verticalPosition = content.verticalPosition

            content = await SheetHelper.CreateHeaderTravel(doc, verticalPosition, 'FICHA DE VIAGEM', {number: 'P - SGS - 012', rev: '00', title: data.title})
            doc = content.doc
            verticalPosition = content.verticalPosition
    
            content = SheetHelper.CreateLine(doc, verticalPosition, 4, 5, 10, 200)
            doc = content.doc
            verticalPosition = content.verticalPosition
        }

        //#region Item 4 DESPESAS DE VIAGEM

        content = SheetHelper.CreateLine(doc, verticalPosition, 4, 5, 10, 200, true)
        doc = content.doc
        verticalPosition = content.verticalPosition

        content = SheetHelper.CreateTitle(doc, verticalPosition, 4, 'DESPESAS DE VIAGEM')
        doc = content.doc
        verticalPosition = content.verticalPosition

        doc.setFontSize(9);

        start = verticalPosition

        verticalPosition += 3.5

        let rows5 = [10, 75, 95, 115, 147.5, 185, 200]

        doc.text('NECESSIDADE', rows5[1] + 8, verticalPosition + 1.5)

        doc.text('RESPONSABILIDADE', rows5[4] - 3, verticalPosition + 1.5)

        verticalPosition += 4

        doc.text('TIPO DE DESPESAS', rows5[0] + 16, verticalPosition + 1)

        doc.text('SIM', rows5[1] + 7, verticalPosition + 5)

        doc.text('NÃO', rows5[2] + 7, verticalPosition + 5)

        doc.text('CONTRATADA', rows5[3] + 5, verticalPosition + 5)

        doc.text('CONTRATANTE', rows5[4] + 6, verticalPosition + 5)

        doc.text('N/A', rows5[5] + 5, verticalPosition + 5)

        content = SheetHelper.CreateLine(doc, verticalPosition, 0, 0, rows5[1])
        doc = content.doc

        verticalPosition += 7

        doc = SheetHelper.CreateLineCrosswise(doc, start, verticalPosition, rows5[0])
        doc = SheetHelper.CreateLineCrosswise(doc, start, verticalPosition, rows5[1])
        doc = SheetHelper.CreateLineCrosswise(doc, start + 7.5, verticalPosition, rows5[2])
        doc = SheetHelper.CreateLineCrosswise(doc, start, verticalPosition, rows5[3])
        doc = SheetHelper.CreateLineCrosswise(doc, start + 7.5, verticalPosition, rows5[4])
        doc = SheetHelper.CreateLineCrosswise(doc, start + 7.5, verticalPosition, rows5[5])
        doc = SheetHelper.CreateLineCrosswise(doc, start, verticalPosition, rows5[6])

        content = await SheetHelper.CreateGrid(doc, verticalPosition , data.travelExpenses.grid, rows5, 8, {rows: [1,2], space: 8, positions: data.travelExpenses.box})
        doc = content.doc
        verticalPosition = content.verticalPosition

        verticalPosition += 7

        //#endregion

        //#region Item 5 RECURSOS NECESSÁRIOS
        content = SheetHelper.CreateLine(doc, verticalPosition, 4, 5, 10, 200, true)
        doc = content.doc
        verticalPosition = content.verticalPosition
        
        content = SheetHelper.CreateTitle(doc, verticalPosition, 5, 'RECURSOS NECESSÁRIOS')
        doc = content.doc
        verticalPosition = content.verticalPosition

        doc.setFontSize(9);

        start = verticalPosition

        verticalPosition += 3.5

        let grid6 = [
            ['SERÁ NECESSÁRIO CARRETINHA'                      , 'sim', 'não', data.travelResources.obs[0]],
            ['CARTÃO DA EMPRESA'                             , 'sim', 'não', data.travelResources.obs[1]],
            ['DINHEIRO'                                      , 'sim', 'não', data.travelResources.obs[2]],
            ['NECESSÁRIA NF PARA TRANSPORTE\nDE FERRAMENTAS?', 'sim', 'não', data.travelResources.obs[3]]
        ]

        let rows6 = [10, 75, 104.75, 140.25, 175]

        doc.text('TIPO DO RECURSO', rows6[0] + 16, verticalPosition + 1)

        doc.text('COMO SÃO SUPRIDOS OS RECURSOS', rows6[2] + 3, verticalPosition + 1)

        verticalPosition += 4

        doc = SheetHelper.CreateLineCrosswise(doc, start, verticalPosition, rows6[0])
        doc = SheetHelper.CreateLineCrosswise(doc, start, verticalPosition, rows6[1])
        doc = SheetHelper.CreateLineCrosswise(doc, start + 7.5, verticalPosition, rows6[2])
        doc = SheetHelper.CreateLineCrosswise(doc, start + 7.5, verticalPosition, rows6[3])
        doc = SheetHelper.CreateLineCrosswise(doc, start, verticalPosition, rows6[4])
 
        content = await SheetHelper.CreateGrid(doc, verticalPosition , [['TRANSPORTE', 'Veículo Semil', 'Caminhão Semil', 'Locação de veículo', 'Avião']], rows6, 8, {rows: [1,2,3,4], space: 2, positions: data.transportation})
        doc = content.doc
        verticalPosition = content.verticalPosition

        content = await SheetHelper.CreateGrid(doc, verticalPosition , grid6, rows6, 8, {rows: [1,2], space: 2, positions: data.travelResources.box})
        doc = content.doc
        verticalPosition = content.verticalPosition

        verticalPosition += 8

        //#endregion

        //#region Item 6 NOTAS E OBSERVAÇÕES

        doc = SheetHelper.CreateFooterTravel(doc, {Number: '4'})

        content = await SheetHelper.CreateNewPage(doc)
        doc = content.doc
        verticalPosition = content.verticalPosition

        content = await SheetHelper.CreateHeaderTravel(doc, verticalPosition, 'FICHA DE VIAGEM', {number: 'P - SGS - 012', rev: '00', title: data.title})
        doc = content.doc
        verticalPosition = content.verticalPosition

        content = SheetHelper.CreateLine(doc, verticalPosition, 4, 5, 10, 200)
        doc = content.doc
        verticalPosition = content.verticalPosition

        content = SheetHelper.CreateLine(doc, verticalPosition, 4, 5, 10, 200, true)
        doc = content.doc
        verticalPosition = content.verticalPosition

        content = SheetHelper.CreateTitle(doc, verticalPosition, 6, 'NOTAS E OBSERVAÇÕES')
        doc = content.doc
        verticalPosition = content.verticalPosition

        verticalPosition += 5

        doc.setFontSize(9)

        let text = SheetHelper.StringSeparator(data.workNotes[0], 100);
        let breakLineCount = (text.match(/\n/g) || []).length;

        if (data.workNotes){
            doc.text(text, 10, verticalPosition);
        }

        tempPosition = verticalPosition + 0.5

        for (let index = 0; index <= breakLineCount; index++) {
            
            doc.line(10, tempPosition, 200, tempPosition, 'F')

            content = SheetHelper.CreateLine(doc, tempPosition)
            doc = content.doc

            tempPosition += 3.7

        }

        verticalPosition += 2.5 * data.workNotes[1]

        verticalPosition += 8 

        //#endregion

        doc = SheetHelper.CreateFooterTravel(doc, {Number: '4'})

        doc.output('save', `${data.title}_ficha_viagem.pdf`)

    }

}