import { List, ListItem } from '@material-ui/core';
import { AddBox, IndeterminateCheckBox } from '@material-ui/icons';
import React, { Component } from 'react';
import Colors from '../constants/Colors';

export default class TreeView extends Component {

    state = {
        data: this.props.data || [],   
    }

    getRowSpacing(index) {

        let spacing = this.props.spacing || 0;
        return index * spacing;
    }

    renderIcon(open, selected, data, key) {

        return open ? <IndeterminateCheckBox onClick={() => { data[key].open = !data[key].open; this.setState({ ...this.state.data, ...data }) }} style={{ cursor: 'pointer', fontSize: 30, marginRight: 8, color: selected ? 'white' : Colors.semil.green }}/> : <AddBox onClick={() => { data[key].open = !data[key].open; this.setState({ ...this.state.data, ...data }) }} style={{ cursor: 'pointer', fontSize: 30, marginRight: 8, color: selected ? 'white' : Colors.semil.green }}/>;
    }

    renderTree(data, render = [], index = 0) {

        data.map((doc, key) => {

            let selected = doc.filtered && doc.filtered === true ? true : doc.selected && doc.selected === true ? true : false;

            let selectedOf = this.props.selectedOf ? this.props.selectedOf.ORD_OrdemSequencia === (doc.item ? doc.item.ORD_OrdemSequencia : doc.ORD_OrdemSequencia) : false;
            
            render.push(

                <ListItem button={false} style={{ border: selectedOf ? `2px dotted ${selected ? `#ffff` : Colors.semil.green}` : ``, backgroundColor: selected ? Colors.semil.green : 'white', color: selected ? 'white' : '', borderRadius: 8, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginLeft: 0, marginLeft: this.getRowSpacing(index) }}>
                    { this.renderIcon(doc.open, selected, data, key) }
                    { this.props.rightIcon ? this.props.rightIcon(selected, doc) : null }
                    { this.props.renderTitle ? this.props.renderTitle(doc) : null }
                </ListItem>
            );

            if (doc.open && this.props.getChildren(doc) && this.props.getChildren(doc).length) {

                render = this.renderTree(this.props.getChildren(doc), render, index + 1);
            }
        });

        return render;
    }

    render() {

        return (

            <List>
                { this.state.data.length ? this.renderTree(this.state.data) : null }
            </List>
        )
    }
}