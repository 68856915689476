import React, { Component } from 'react';
import Colors from '../constants/Colors';
import moment from 'moment';
import 'moment/locale/pt-br';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Firestore from '../api/firebase/Firestore';
import { Button } from '@material-ui/core';
import DefaultSelect from '../components/DefaultSelect';
import DefaultLoader from '../components/DefaultLoader';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';
import PDFHelper from '../helper/PDFHelper';

moment.locale('pt-br');

export default class ReportsGrid extends Component {
    state = {
        loading: false,
        loadingModal: false,
        technicianHoursPerOrderModal: false,
        sats: [],
        technicians: [],
        recentOrdersIds: [],
        recentOrders: [],
        selectedOrderCode: null
    };

    async componentDidMount() {
        this.setState({ loading: true });

        await this.getTechnicians();
        await this.getRecentSats();

        if (this.state.recentOrdersIds.length) {
            
            await this.getRecentOrders();
        }

        this.setState({ loading: false });

    }

    async exportTechnicianHoursPerOrder() {
        this.setState({ loadingModal: true });
        if (this.state.selectedOrderCode && this.state.selectedOrderCode > 0) {
            await this.getOrderSats(this.state.selectedOrderCode);

            let doc = new jsPDF();
            let rectYOffset = 0;
            let img = await PDFHelper.imageToBase64(process.env.PUBLIC_URL + '/assets/image/logo.png');

            doc = PDFHelper.header(doc, `RELATÓRIO DE HORAS DE TÉCNICOS \n DO PEDIDO ${this.state.selectedOrderCode}`, '', img);
            doc.text(doc.internal.pageSize.getWidth() - 20, 290, 'página ' + doc.internal.getNumberOfPages());


            this.state.sats.forEach((event, key) => {
                let nonExisting = true;

                doc.setFontType('bold');
                doc.text(event.title, 16, 25 + rectYOffset);

                if (event.technicians && event.technicianHours) {
                    nonExisting = false;
                    event.technicians.forEach((technician, technicianKey) => {
                        let hoursPointed = false;
                        let totalMinutes = 0;
                        let dates = [];

                        event.technicianHours.sort((a, b) => new Date(b.startDate.toDate()).getTime() - new Date(a.startDate.toDate()).getTime());

                        event.technicianHours.forEach(hours => {
                            if (technician.id_technician === hours.technician_id) {
                                hoursPointed = true;
                                totalMinutes += hours.minutesWorked;
                                dates.push(`${moment(hours.startDate.toDate()).format('DD/MM/YYYY HH:mm')} ~ ${moment(hours.endDate.toDate()).format('DD/MM/YYYY HH:mm')} | ${hours.minutesWorked} minutos | ${hours.description ? hours.description.length > 74 ? hours.description.substring(0, 75) + '...' : hours.description : 'Sem descrição'}`);
                            }
                        });

                        if (hoursPointed) {
                            let techName = '';
                            let rectHeight = 13;

                            this.state.technicians.forEach(tech => { if (tech.id === technician.id_technician) techName = tech.name; });

                            if (rectYOffset > 220) {

                                rectYOffset = 0;
            
                                doc.addPage();
                                doc.setFontType('bold');
                                doc.text(doc.internal.pageSize.getWidth() - 20, 290, 'página ' + doc.internal.getNumberOfPages());
            
                                doc = PDFHelper.header(doc, `RELATÓRIO DE HORAS DO PEDIDO ${this.state.selectedOrderCode}`, '', img);
                                
                                doc.setFontSize(8);
                                doc.setFontType('normal');
                            }

                            doc.rect(15, 29 + rectYOffset, doc.internal.pageSize.getWidth() - 27, rectHeight);

                            doc.setFontSize(10);

                            doc.setFontType('bold');
                            doc.text(`Técnico:`, 16, 29 + rectYOffset + 4);
                            doc.setFontType('normal');

                            doc.text(`${techName}`, 32, 29 + rectYOffset + 4);

                            if (event.estimated_hours) {
                                doc.setFontType('bold');
                                doc.text(`Horas Estimadas:`, 16, 29 + rectYOffset + 8);
                                doc.setFontType('normal');

                                doc.text(`${event.estimated_hours} h (${parseInt(event.estimated_hours * 60)} minutos)`, 48, 29 + rectYOffset + 8);
                            }

                            if (event.estimated_hours) {
                                if (totalMinutes > parseInt(event.estimated_hours * 60)) {
                                    doc.setTextColor('red');
                                } else {
                                    doc.setTextColor('green');
                                }
                            }

                            doc.setFontType('bold');
                            doc.text(`Horas Realizadas:`, 16, 29 + rectYOffset + (!event.estimated_hours ? 8 : 12));
                            doc.setFontType('normal');

                            doc.text(`${(totalMinutes / 60).toFixed(2)} h (${totalMinutes} minutos)`, 48, 29 + rectYOffset + (!event.estimated_hours ? 8 : 12));

                            doc.setTextColor('black');

                            let dateOffset = rectYOffset + 12;
                            let lineOffset = 37 + rectYOffset;

                            doc.setFontSize(8);

                            dates.forEach(date => {
                                dateOffset += 5;
                                lineOffset += 5;
                                // doc.line(15, lineOffset, doc.internal.pageSize.getWidth() - 12, lineOffset);
                                doc.rect(15, lineOffset, doc.internal.pageSize.getWidth() - 27, 5);
                                doc.text(`${date}`, 16, 28 + dateOffset);

                                if (dateOffset > 220) {

                                    rectYOffset = 0;
                                    dateOffset = rectYOffset;
                                    lineOffset = 25 + rectYOffset;
                
                                    doc.addPage();
                                    doc.setFontType('bold');
                                    doc.text(doc.internal.pageSize.getWidth() - 20, 290, 'página ' + doc.internal.getNumberOfPages());
                
                                    doc = PDFHelper.header(doc, `RELATÓRIO DE HORAS DO PEDIDO ${this.state.selectedOrderCode}`, '', img);
                                    
                                    doc.setFontSize(8);
                                    doc.setFontType('normal');
                                }
                            });

                            rectYOffset += dates.length ? 18 + (dates.length * 5) : 23;

                            doc.setFontSize(10);
                        }
                    });
                }

                if (nonExisting) {
                    doc.rect(15, 28 + rectYOffset, doc.internal.pageSize.getWidth() - 27, 20);
                    doc.setFontType('normal');
                    doc.text(`Não encontramos horas apontadas por técnicos nesta assistência.`, 16, 28 + rectYOffset + 4);
                    rectYOffset += 31;
                }
            });

            window.open(doc.output('bloburl'))
        } else {
            toast.error('Selecione ou informe uma sat');

        }
        this.setState({ loadingModal: false });
    }

    async getRecentSats() {
        let query = await Firestore.customQuery('appointment').limit(10).orderBy('order_id', 'asc').orderBy('start_date', 'desc').get();
        let recentOrdersIds = [];

        query.forEach((doc, key) => {
            let orderId = doc.data().order_id;

            if (orderId) {
                recentOrdersIds.push(orderId);
            }
        });

        await this.setState({ recentOrdersIds });
    }

    async getRecentOrders() {
        let query = await Firestore.getCollectionByMultipleIds('order', this.state.recentOrdersIds);
        let recentOrders = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            recentOrders.push(data);
        });

        await this.setState({ recentOrders });
    }

    async getTechnicians() {
        let query = await Firestore.getCollection('technician');
        let arr = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            arr.push(data);
        });

        await this.setState({ technicians: arr });
    }

    async getOrderSats(order) {
        let orderQuery = await Firestore.customQuery('order').where('order_code', '==', parseInt(order)).get();

        let orderId = null;

        if (!orderQuery.docs.length) {
            toast.error('Pedido não encontrado no Semil Connect');
            return;
        } else {
            orderId = orderQuery.docs[0].id;
        }

        let query = await Firestore.customQuery('appointment').where('order_id', '==', orderId).get();
        let sats = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            sats.push(data);
        });

        await this.setState({ sats });
    }

    technicianHoursPerOrderModal() {
        return (
            <div>
                <div style={{ paddingTop: 25 }}>
                    <DefaultInput value={this.state.selectedOrderCode} type={'number'} onBlur={(v) => { this.setState({ selectedOrderCode: v }) }} label={'Número do Pedido'} />
                </div>

                <div style={{ paddingTop: 25 }}>
                    <p style={{ fontSize: 16, fontWeight: '400' }}>ou veja os pedidos de SAT's mais recentes:</p>
                </div>

                <div style={{ paddingTop: 25 }}>
                    <DefaultSelect multiple={false} id={'order_selection'} valueField={'order_code'} displayField={'order_code'} onChange={(v) => { this.setState({ selectedOrderCode: v.target.value }); }} value={this.state.selectedOrderCode} docs={this.state.recentOrders} label={'10 Pedidos de SAT recentes'} />
                </div>

                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50, width: '100%' }}>
                    <Button onClick={() => {
                        this.setState({ selectedOrderCode: null, technicianHoursPerOrderModal: false });
                    }} style={{ fontWeight: 'bold', backgroundColor: Colors.semil.red, color: '#fff', width: '100%' }} variant={'contained'}>{'Cancelar'}</Button>
                </div>

                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10, width: '100%' }}>
                    <Button onClick={() => { this.exportTechnicianHoursPerOrder() }} style={{ fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%' }} variant={'contained'}>{'Gerar PDF'}</Button>
                </div>

            </div>
        )
    }

    buildGridCard(title, description, color, onClick) {
        return (
            <Card style={{ backgroundColor: color }}>
                <CardContent>
                    <Typography color="textSecondary">
                        Relátório
                    </Typography>
                    <Typography variant="h5" component="h2">
                        {title}
                    </Typography>
                    <br />

                    <Typography variant="body2" component="p">
                        {description}
                        <br />
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="large" style={{ color: Colors.semil.green }} onClick={() => { onClick() }}>Configurar e Imprimir</Button>
                </CardActions>
            </Card>
        );
    }

    buildGrid() {
        return (
            <div className={style.root}>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        {this.buildGridCard('Horas de técnicos por Pedido', `Este é um relatório que mostra por SAT por técnico quantas horas foram trabalhadas, períodos trabalhados e descrições do técnico sobre o trabalho`, `rgba(129, 190, 247, 0.2)`, () => { this.setState({ technicianHoursPerOrderModal: true }) })}
                    </Grid>
                </Grid>

            </div>
        );

    }


    render() {

        return (this.state.loading ? <DefaultLoader /> :
            <div>
                {this.buildGrid()}

                <DefaultModal loading={this.state.loadingModal} content={this.technicianHoursPerOrderModal()} title={'Exportar Horas de técnicos por Pedido'} onClose={() => { this.setState({ technicianHoursPerOrderModal: false }) }} open={this.state.technicianHoursPerOrderModal} />
            </div>
        );
    }
}

const style = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        padding: theme.spacing(0, 3),
    }
}));