import moment from 'moment';
import jsPDF from 'jspdf';

export default class PDFHelper {

    //Constructor do PDF nele é setado as cordenadas limite do PDF e o contador de paginas
    constructor(positions, page = 1, type = 'sat', title = ''){

        if (positions !== undefined || null){

            if (positions.start) {this.start = positions.start} else {this.start = 10}

            if (positions.end) {this.end = positions.end} else {this.end = 260}
    
            if (positions.left) {this.left = positions.left} else {this.left = 25}
    
            if (positions.right) {this.right = positions.right} else {this.right = 200}
    
            if (positions.middle) {this.middle = positions.middle} else {this.middle = 120}

        }else{

            this.start = 10
            this.end = 260
            this.left = 25
            this.right = 200
            this.middle = 120

        }
        
        this.page = page
        this.type = type
        this.title = title;
    }

    AddPage(number){
        this.page += number
    }

    //Setter das posições do PDF, programado para que possa passar somente um valor dentro do objeto
    set SetPositions(positions){
        
        if (positions.start) {this.start = positions.start}

        if (positions.end) {this.end = positions.end}

        if (positions.left) {this.left = positions.left}

        if (positions.right) {this.right = positions.right}

        if (positions.middle) {this.middle = positions.middle}

    }

    get GetPage(){
        return this.page
    }

    //Cria uma linha horizontal na tela que por padrão vai de uma borda a outra
    CreateLine(doc, verticalPosition, spaceUp = 0, spaceDown = 0, left = this.left, right = this.right, bold = false){

        let page;

        verticalPosition += spaceUp

        if (bold) {
            doc.setLineWidth(0.7);
            doc.line(left, verticalPosition, right, verticalPosition, 'F');
            doc.setLineWidth(0.200025);
        } else {
            doc.line(left, verticalPosition, right, verticalPosition, 'F');
        }

        verticalPosition += spaceDown

        return {doc: doc, verticalPosition: verticalPosition}

    }

    CreateLineCrosswise(doc, startPoint, endPoint, horizontal_position){

        doc.line(horizontal_position, startPoint, horizontal_position, endPoint, 'F')

        return doc

    }

    //Adiciona uma linha sem padronização, e retorna o valor mais baixo relativo ao axis x
    CreateLineFree(doc, x1, x2, y1 = this.left, y2 = this.right){

        let maior

        doc.line(y1, x1, y2, x2, 'F')

        if (x1 >= x2){
            maior = x1
        }else{
            maior = x2
        }

        return {doc:doc, verticalPosition: maior}

    }

    //Adiciona uma lista baseado nos dados fornecidos, para mais de um item por numero forcener um array dentro de um array.
    async CreateList(doc, verticalPosition, listNumber, title, data){

        let positionIndex
        let listIndex
        let innerArray
        let content

        if (title) {
            content =  this.CreateTitle(doc, verticalPosition, listNumber, title)
            doc = content.doc
            verticalPosition = content.verticalPosition
        }

        doc.setFontSize(8);

        if (data && Array.isArray(data)){
            
            for (let index = 0; index < data.length; index++){
                
                listIndex = listNumber + '.' + (index + 1).toString()

                if (Array.isArray(data[index])){

                    innerArray = data[index]

                    for (let positionIndex = 0; positionIndex < innerArray.length; positionIndex++){

                        if (positionIndex % 2 === 0){
                            
                            if (this.PageTracker(verticalPosition, 8)){
                                doc = await this.CreateFooterSheet(doc)
                                content = await this.CreateNewPage(doc)
                                doc = content.doc
                                verticalPosition = content.verticalPosition

                                doc = this.CreateSideText(doc)

                                content = await this.CreateHeaderSheet(doc, verticalPosition, 'RELATÓRIO DE START-UP \n E ASSISTÊNCIA TÉCNICA')
                                doc = content.doc
                                verticalPosition = content.verticalPosition
                        
                                verticalPosition += 5

                            }else{ 
                                verticalPosition += 8 
                            }

                            if (positionIndex === 0){
                                doc.text(listIndex + ' ' + innerArray[positionIndex], this.left, verticalPosition)
                            }else{
                                doc.text(innerArray[positionIndex], this.left, verticalPosition)
                            }
                                
                        }else{
                            doc.text(innerArray[positionIndex], this.middle, verticalPosition)
                        }

                    }

                }else{
                    
                    if (this.PageTracker(verticalPosition, 8)){
                        
                    }else{
                        verticalPosition += 8
                    }
                
                    doc.text(listIndex + ' ' + data[index], this.left, verticalPosition)

                }

            }

        }

        doc.setFontType('normal');

        return {doc: doc, verticalPosition: verticalPosition}

    }

    async CreateDescription(doc, verticalPosition, listNumber, title, data, lines, space = 8){

        let content

        content =  this.CreateTitle(doc, verticalPosition, listNumber, title)
        doc = content.doc
        verticalPosition = content.verticalPosition

        doc.setFontSize(9);

        let text = this.StringSeparator(data[0] || '', 107, '\n\n');
        doc.text(text, 25, verticalPosition - 1);

        for (let index = 0; index < lines; index++) {
            
            if (!this.PageTracker(verticalPosition, space)){

                content = this.CreateLine(doc, verticalPosition, space)
                doc = content.doc
                verticalPosition = content.verticalPosition

            }else{
            
                doc = await this.CreateFooterSheet(doc)
                content = await this.CreateNewPage(doc)
                doc = content.doc
                verticalPosition = content.verticalPosition

                doc = this.CreateSideText(doc)

                content = await this.CreateHeaderSheet(doc, verticalPosition, 'RELATÓRIO DE START-UP \n E ASSISTÊNCIA TÉCNICA')
                doc = content.doc
                verticalPosition = content.verticalPosition
        
                verticalPosition += 5

                content = this.CreateLine(doc, verticalPosition, space)
                doc = content.doc
                verticalPosition = content.verticalPosition

            }
            
        }

        return {doc: doc, verticalPosition: verticalPosition}

    }

    //Count the number of lines a text can ocupy within 2 numbers
    LineCounter(chars, x1, x2){

        let charSize = 1.450

        let lines = (chars * charSize) / (x2 - x1)

        let rounded = Math.round(lines)
        
        if ( rounded < lines || rounded == 0){
            rounded++
        }
        
        return rounded

    }

    StringSeparator(text, limit, spacer = '\n') {

        let result = text;
        let initialLimit = limit;
        let count = 0;

        for (let i = 0; i < text.length; i++) {

            if (text[i] === '\n') {
                count = 0;
            }

            if (count === initialLimit) {

                result = result.substring(0, i) + spacer + result.substring(i);
                limit += initialLimit;

                count = 0;
            }

            count ++;
        }

        return result
    }

    async CreateGrid(doc, verticalPosition, grid, rows, space = 8, checkbox, limit = 0){

        let content

        let checkSpace = false

        let cheched = false

        let innerArray

        let spaceExtra = 0

        let startPosition

        let gridCell

        doc.setFontSize(8)

        startPosition = verticalPosition

        content = this.CreateLine(doc, startPosition)
        doc = content.doc
        verticalPosition = content.verticalPosition

        //Grid
        for (let index = 0; index < grid.length; index++) {
            
            innerArray = grid[index]

            for (let innerIndex = 0; innerIndex < innerArray.length; innerIndex++) {

                if (this.PageTracker(verticalPosition, 8)){
                    doc = await this.CreateFooterSheet(doc)
                    content = await this.CreateNewPage(doc)
                    doc = content.doc
                    verticalPosition = content.verticalPosition
        
                    if (this.type === 'sat') {

                        doc = this.CreateSideText(doc)
                        content = await this.CreateHeaderTravel(doc, verticalPosition, 'RELATÓRIO DE START-UP\nE ASSISTÊNCIA TÉCNICA', {number: 'P - SGS - 012', rev: '00', title: this.title}, false)
                        verticalPosition = content.verticalPosition

                        content = this.CreateLine(doc, verticalPosition, 4, 5, 10, 200)
                        doc = content.doc
                        verticalPosition = content.verticalPosition
                    
                    } else {

                        content = await this.CreateHeaderTravel(doc, verticalPosition, 'FICHA DE VIAGEM', {number: 'P - SGS - 012', rev: '00', title: this.title})
                        verticalPosition = content.verticalPosition

                        content = this.CreateLine(doc, verticalPosition, 4, 5, 10, 200)
                        doc = content.doc
                        verticalPosition = content.verticalPosition
                    }

                    doc.setFontSize(8)

                    content = this.CreateLine(doc, verticalPosition, space + spaceExtra)
                    doc = content.doc
                    verticalPosition = content.verticalPosition
                }

                gridCell = innerArray[innerIndex]

                if (checkbox && (checkbox.rows.indexOf(innerIndex) > -1)){

                    checkSpace = true

                    if (checkbox.positions !== undefined && checkbox.positions !== null){

                        for (let cont = 0; cont < checkbox.positions.length; cont++) {

                            let temp = checkbox.positions[cont]

                            if (temp[1] === innerIndex && temp[0] === index){
                                cheched = true
                                break
                            }else{
                                cheched = false
                            }
                            
                        }
                        
                    }

                    doc = this.CreateCheckbox(doc, {x: verticalPosition + 2, y: rows[innerIndex] + checkbox.space}, cheched)

                    cheched = false

                }
                
                if (limit > 0) {
                    gridCell = this.StringSeparator(gridCell, limit);
                }

                doc.text(gridCell, checkSpace ? rows[innerIndex] + 7 : rows[innerIndex] + 2, verticalPosition + (space / 1.5))

                let lineBreakCount = (gridCell.match(/\n/g) || []).length;

                for (let cont = 0; cont < lineBreakCount; cont++) {
                    spaceExtra += 4
                }

                checkSpace = false

            }

            content = this.CreateLine(doc, verticalPosition, space + spaceExtra)
            doc = content.doc
            verticalPosition = content.verticalPosition
    
            spaceExtra = 0

        }

        verticalPosition+= 8;

        // for (let row_index = 0; row_index < rows.length; row_index++) {
        //     doc = this.CreateLineCrosswise(doc, startPosition, verticalPosition, rows[row_index])
        // }

        return {doc: doc, verticalPosition: verticalPosition}

    }

    CreateCheckbox(doc, positions, checked){

        let x = positions.x
        let y = positions.y

        doc = this.CreateLineFree(doc, x, x + 4, y, y).doc

        doc = this.CreateLineFree(doc, x + 4, x + 4 , y + 4, y).doc

        doc = this.CreateLineFree(doc, x, x , y, y + 4).doc

        doc = this.CreateLineFree(doc, x, x + 4 , y + 4, y + 4).doc

        if(checked){

            doc = this.CreateLineFree(doc, x, x + 4, y, y + 4).doc

            doc = this.CreateLineFree(doc, x + 4, x, y, y + 4).doc
            
        }

        return doc

    }

    CreateTitle(doc, verticalPosition, number, title){
        
        let line

        doc.setFontType('bold');
        doc.setFontSize(10);

        doc.text(number + " " + title, this.left, verticalPosition)

        line = this.CreateLine(doc, verticalPosition + 4, 2, 0)
        doc = line.doc
        verticalPosition = line.verticalPosition

        doc.setFontType('normal');

        return {doc: doc, verticalPosition: verticalPosition}
    }

    PageTracker(verticalPosition, space){
        
        let addPage = false

        if ( verticalPosition + space > this.end){
            addPage = true
        }

        return addPage
        
    }

    async CreateNewPage(doc){

        let verticalPosition = this.start
        let content

        doc.addPage();

        return {doc: doc, verticalPosition: verticalPosition}

    }

    CreateSideText(doc){
    
        doc.setFontSize(9);
    
        doc.setTextColor(255,0,0)
    
        doc.text('Material de propriedade e uso exclusivo da SEMIL EQUIPAMENTOS INDUSTRIAIS LTDA. Este material não pode ser copiado, reproduzido ou redistribuído, total ou parcialmente, sem o', 10, 280,{angle: 90} )
        
        doc.text('prévio consentimento e autorização desta empresa.', 15, 190,{angle: 90})
    
        doc.setTextColor(0,0,0)
    
        return doc
    }

    CreateChangeControler(doc, verticalPosition, title, data, positions){

        let line

        let innerArray

        doc.setFontSize(12);

        doc.text(title, this.left, verticalPosition)

        doc.setFontSize(9);

        line = this.CreateLine(doc, verticalPosition, 2, 4, )
        doc = line.doc
        verticalPosition = line.verticalPosition

        doc.text('Nº', positions[0], verticalPosition)

        doc.text('Descrição', positions[1], verticalPosition)

        doc.text('Data', positions[2], verticalPosition)

        doc.text('Responsáveis', positions[3], verticalPosition)

        line = this.CreateLine(doc, verticalPosition, 2, 4)
        doc = line.doc
        verticalPosition = line.verticalPosition

        for (let index = 0; index < data.length; index++) {
            
            innerArray = data[index]

            for (let innerIndex = 0; innerIndex < innerArray.length; innerIndex++) {

                doc.text(innerArray[innerIndex], positions[innerIndex], verticalPosition)

            }

            line = this.CreateLine(doc, verticalPosition, 2, 4)
            doc = line.doc
            verticalPosition = line.verticalPosition

        }

        return {doc: doc, verticalPosition: verticalPosition}

    }

    async CreateSignature(doc, verticalPosition, listNumber, title, data, positions){

        let content
        let line

        let innerArray

        let start

        doc.setFontSize(10);
        doc.setFontType('bold');

        doc.text(listNumber + " " + title, this.left, verticalPosition)

        doc.setFontType('normal');

        line = this.CreateLine(doc, verticalPosition, 2, 8)
        doc = line.doc
        verticalPosition = line.verticalPosition

        start = verticalPosition

        doc.setFontSize(9);

        //carimbo
        line = this.CreateLine(doc, verticalPosition, 0, 20, 110)
        doc = line.doc
        verticalPosition = line.verticalPosition

        doc.text(data.signatureInfo ? data.signatureInfo.position : '', 65, verticalPosition - 1, {align: 'center'})

        line = this.CreateLine(doc, verticalPosition, 0, 4, 30, 105)
        doc = line.doc
        verticalPosition = line.verticalPosition

        doc.text('Responsável da Unidade', 49, verticalPosition)

        verticalPosition +=12

        doc.setFontSize(7);

        doc.text(`Nome: ${data.signatureInfo ? data.signatureInfo.name : ''}`, 30.5, verticalPosition)

        line = this.CreateLine(doc, verticalPosition, 1, 6, 30, 105)
        doc = line.doc
        verticalPosition = line.verticalPosition

        doc.text('CPF:', 30.5, verticalPosition)

        line = this.CreateLine(doc, verticalPosition, 1, 14, 30, 105)
        doc = line.doc
        verticalPosition = line.verticalPosition

        if (data.link) {
            
            let img = await this.PicToBase64(data.link);
            doc.addImage(img, 'PNG', 133, verticalPosition - 50, 40, 0)
        }

        doc.text('(Deve conter carimbo)', 51, verticalPosition - 6)

        verticalPosition += 12

        doc.text('(Carimbo, com CNPJ e dados da empresa)', 133, verticalPosition)

        verticalPosition += 2

        line = this.CreateLine(doc, verticalPosition, 0, 0, 110)
        doc = line.doc
        verticalPosition = line.verticalPosition
        
        doc = this.CreateLineCrosswise(doc, start, verticalPosition, 110)
        doc = this.CreateLineCrosswise(doc, start, verticalPosition, this.right)

        line = this.CreateLine(doc, verticalPosition, 6, 20)
        doc = line.doc
        verticalPosition = line.verticalPosition

        line = this.CreateLine(doc, verticalPosition, 0, 4, 67.5, 135)
        doc = line.doc
        verticalPosition = line.verticalPosition

        doc.setFontSize(9);

        doc.text('Técnico de Semil', 90, verticalPosition)

        doc.setFontSize(7);

        verticalPosition += 12

        doc.text(`Nome: ${data.name ? data.name : ''}`, 68, verticalPosition)

        line = this.CreateLine(doc, verticalPosition, 1, 6, 67.5, 135)
        doc = line.doc
        verticalPosition = line.verticalPosition

        doc.text(`CPF: ${data.cpf ? data.cpf : ''}`, 68, verticalPosition)

        line = this.CreateLine(doc, verticalPosition, 1, 8, 67.5, 135)
        doc = line.doc
        verticalPosition = line.verticalPosition

        doc.text('(Deve conter carimbo)', 90, verticalPosition)

        verticalPosition+= 20;

        line = this.CreateLine(doc, verticalPosition, 0, 0, this.left, this.left + 20)
        doc = line.doc
        verticalPosition = line.verticalPosition

        line = this.CreateLineFree(doc,verticalPosition, verticalPosition + 40, this.left + 20, this.right - 20, )
        doc = line.doc
        verticalPosition = line.verticalPosition

        line = this.CreateLine(doc, verticalPosition, 0, 0, this.right - 20, this.right)
        doc = line.doc
        verticalPosition = line.verticalPosition

        return {doc: doc, verticalPosition:verticalPosition}

    }

    async CreateHeaderSheet(doc, verticalPosition, title){

        let line

        let logo = await this.PicToBase64(process.env.PUBLIC_URL + '/assets/image/logo.png');

        doc.addImage(logo, 'PNG', this.left, 7, 40, 0)

        doc.setFontSize(14)
        doc.setFontType('bold');
        
        doc.text(title, 120, verticalPosition);

        doc.setFontType('normal');

        doc.setLineWidth(0.7);
        line = this.CreateLine(doc, verticalPosition, 10, 5);
        doc.setLineWidth(0.200025);
        
        doc = line.doc
        verticalPosition = line.verticalPosition
    
        return {doc: doc, verticalPosition: verticalPosition}
    
    }

    //Alterar o counter de páginas para funcionar corretamente e a numeração do footer
    async CreateFooterSheet(doc, data){
    
        let serialNumber = 'F-SGS-002REV. 00'

        doc.setFontSize(12)

        doc.text('Engenharia Integrada', 25, 270)

        doc.text(serialNumber, 150, 270)

        doc.line(this.left, 272, this.right, 272, 'F')

        doc.setFontSize(10)

        doc.text('Página: ' + this.page.toString(), this.left, 276)

        doc.setFontSize(9)

        let address = await this.PicToBase64(process.env.PUBLIC_URL + '/assets/image/sat_footer.jpg');
        doc.addImage(address, 'PNG', 134, 276, 70, 0);

        this.page += 1

        return doc
    }

    async CreateHeaderTravel(doc, verticalPosition, title, data, subtitle = true){

        let line

        let logo = await this.PicToBase64(process.env.PUBLIC_URL + '/assets/image/logo.png');

        doc.addImage(logo, 'PNG', this.left, verticalPosition - 5, 40, 0)

        doc.setFontSize(14)
        doc.setFontType('bold')
        doc.text(title, 90, verticalPosition)

        doc.setFontSize(10)

        doc.text(`Nº ${data.title.split(' ')[1]}` , 180, verticalPosition - 1, {align: 'center'})

        verticalPosition += 5

        if (subtitle) {

            doc.text('GESTÃO DA ASSISTÊNCIA TÉCNICA', 82, verticalPosition)
        }

        doc.setFontType('normal')

        verticalPosition += 2

    
        return {doc: doc, verticalPosition: verticalPosition}
    
    }

    //Alterar o counter de páginas para funcionar corretamente e a numeração do footer
    CreateFooterTravel(doc, data){

        let verticalPosition

        let line

        doc.setFontSize(9)

        line = this.CreateLine(doc, 265, 0, 0, 10, 200, true)
        doc = line.doc

        doc.setFontType('bold');
        doc.text('GESTÃO DA ASSISTÊNCIA TÉCNICA', doc.internal.pageSize.getWidth()/ 2, 270, {align: 'center'})

        this.page += 1

        return doc

    }

    //Conversor de imagens para uma string no padrão Base 64
    async PicToBase64(path){

        return new Promise( async (resolve, reject)=> {

            let request = await fetch(path);
            let blob = await request.blob();

            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = ()=> resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    //Conversor de imagens para uma string no padrão Base 64
    static async imageToBase64(path){

        return new Promise( async (resolve, reject)=> {

            let request = await fetch(path);
            let blob = await request.blob();

            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = ()=> resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    //Header e Footer criado pelo Weiler pra um relatório que ele fez
    static header(doc, title = '', subtitle = '', logo = null){

        doc.setFontSize(13);
        doc.line(12, 5, doc.internal.pageSize.getWidth()- 12, 5);

        if (logo){ doc.addImage(logo, 'PNG', 14, 7, 40, 0)}

        doc.setFontType('bold');
        doc.text(title, doc.internal.pageSize.getWidth()/ 2, 10, {align: 'center'});
        doc.setFontSize(10);
        doc.text(subtitle, doc.internal.pageSize.getWidth()/ 2, 17, {align: 'center'});
        doc.setFontType('bold');
        doc.line(12, 19, doc.internal.pageSize.getWidth()- 12, 19);

        return doc;
    }

    static footer(doc){

        doc.setFontType('normal');
        doc.setFontSize(10);
        doc.line(12, 266, doc.internal.pageSize.getWidth()- 12, 266);
        doc.text('Nº:', 26, 272);
        doc.setFontSize(6);
        doc.text('(Documento interno de uso exclusivo do da Ass. Técnica)', 18, 279);
        doc.setFontSize(10);
        doc.text('Área de Origem:', 80, 272);
        doc.text('Data:', 140, 272);
        doc.text('Gestor da Área:', 160, 272);
        doc.line(12, 280, doc.internal.pageSize.getWidth()- 12, 280);

        return doc;
    }

}