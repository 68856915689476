import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, FormLabel, RadioGroup, FormControlLabel, Radio, Select, InputLabel, MenuItem, Checkbox, FormGroup } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import DefaultSelect from '../components/DefaultSelect';

export default class EmailAutomatization extends Component {

    state = {
        docs: [],
        name: '',
        repeat: 'weekly',
        loading: true,
        loadingModal: false,
        addModal: false,
        emailAccounts: [],
        checkedEmail: 0,
        emailTemplates: [],
        checkedEmailTemplate: 0,
        clients: [],
        selected_company: [],
        clientsModal: false,
        clientsModalData: [],
        editId: null,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('email_automatization').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    async getEmailAccounts() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('email_account').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = { id: doc.id, email: doc.data().email };

            docs.push(data);
        });

        this.setState({ emailAccounts: docs });
    }

    async getEmailTemplates() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('email_template').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = { id: doc.id, name: doc.data().name };

            docs.push(data);
        });

        this.setState({ emailTemplates: docs });
    }

    async getClients() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('company').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ clients: docs });
    }

    componentDidMount() {

        this.getDocs();
        this.getEmailAccounts();
        this.getEmailTemplates();
        this.getClients();
    }

    async addEmailAutomatization() {

        if (this.state.name && this.state.repeat && this.state.emailAccounts[this.state.checkedEmail] && this.state.emailTemplates[this.state.checkedEmailTemplate] && this.state.selected_company.length) {

            let data = {
                name: this.state.name,
                repeat: this.state.repeat,
                email_account_id: this.state.emailAccounts[this.state.checkedEmail].id,
                email_template_id: this.state.emailTemplates[this.state.checkedEmailTemplate].id,
                clients: this.state.selected_company,
            };

            try {

                this.setState({ loadingModal: true });

                await Firestore.insert(data, 'email_automatization');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, addModal: false, name: '', name: '' });
                await this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    repeatHandler(repeat) {

        let string = repeat === 'weekly' ? 'Toda Semana' : repeat === 'monthly' ? 'Todo Mês' : 'Todo Dia';

        return ( 
            <div>{string}</div>
        )
    }

    getEmailLookup() {
        let accounts = this.state.emailAccounts;
        let lookup = {};

        accounts.forEach((account, key) => {
            lookup[account.id] = account.email;
        });

        return lookup;
    }

    getEmailTemplateLookup() {
        let accounts = this.state.emailTemplates;
        let lookup = {};

        accounts.forEach((template, key) => {
            lookup[template.id] = template.name;
        });

        return lookup;
    }

    async updateClients() {

        if (this.state.editId) {

            try {

                this.setState({ loadingModal: true });

                let data = { clients: this.state.clientsModalData };

                await Firestore.update(data, 'email_automatization', this.state.editId);

                toast.success("Editado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false, clientsModal: false, clientsModalData: null, editId: null });

                this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao editar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        }
    }

    clientsModal() {
        if (this.state.clientsModalData) {
            return (
                <div>
                    <FormGroup>
    
                        <DefaultSelect secondaryDisplay={'city'} multiple={true} search={true} searchField={'name'} id={'company_selection_edit'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ clientsModalData: v.target.value }) }} value={this.state.clientsModalData} docs={this.state.clients} label={'Selecionar empresas'}/>
    
                    </FormGroup>
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.updateClients() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ clientsModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    addModal() {
        return (
            <div>
                <DefaultInput onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'}/>

                <div style={{paddingTop: 18}}>
                    <FormLabel component="legend">Repetir</FormLabel>
                    <RadioGroup aria-label="gender" name="gender1" value={this.state.repeat} onChange={(v) => { this.setState({ repeat: v.target.value }) }}>
                        <FormControlLabel value="daily" control={<Radio style={{color: Colors.semil.green}}/>} label="Todo dia" />
                        <FormControlLabel value="weekly" control={<Radio style={{color: Colors.semil.green}}/>} label="Toda semana" />
                        <FormControlLabel value="monthly" control={<Radio style={{color: Colors.semil.green}}/>} label="Todo mês" />
                    </RadioGroup>
                </div>

                <div style={{paddingTop: 18}}>
                    <InputLabel id="demo-simple-select-label">Conta de Envio</InputLabel>
                    <Select
                    style={{width: '100%'}}
                    labelId="demo-simple-select-label"
                    value={this.state.checkedEmail}
                    onChange={(v) => { this.setState({ checkedEmail: v.target.value }) }}>
                        {
                            this.state.emailAccounts.map((accout, key) => {
                                return <MenuItem value={key}>{accout.email}</MenuItem>
                            })
                        }
                    </Select>
                </div>

                <div style={{paddingTop: 18}}>
                    <InputLabel id="email-template">Template do E-mail</InputLabel>
                    <Select
                    style={{width: '100%'}}
                    labelId="email-template"
                    value={this.state.checkedEmailTemplate}
                    onChange={(v) => { this.setState({ checkedEmailTemplate: v.target.value }) }}>
                        {
                            this.state.emailTemplates.map((accout, key) => {
                                return <MenuItem value={key}>{accout.name}</MenuItem>
                            })
                        }
                    </Select>
                </div>

                <div style={{paddingTop: 18}}>
                    <DefaultSelect secondaryDisplay={'city'} multiple={true} search={true} searchField={'name'} id={'company_selection'} valueField={'id'} displayField={'name'} onChange={(v) => { this.setState({ selected_company: v.target.value }) }} value={this.state.selected_company} docs={this.state.clients} label={'Selecionar empresas'}/>
                </div>

                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addEmailAutomatization() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={{ padding: 25 }}>
                <DefaultButton onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>

                <DefaultTable
                title={'Automação de E-mail'}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'name' },
                    { title: 'Enviar Para', editable: false, field: 'clients', render: rowData => <GroupIcon onClick={() => { this.setState({ clientsModal: true, clientsModalData: rowData.clients, editId: rowData.id }) }} style={{cursor: 'pointer', color: Colors.semil.green}}/> },
                    { title: 'Conta de Envio', field: 'email_account_id', lookup: this.getEmailLookup() },
                    { title: 'Template do E-mail', field: 'email_template_id', lookup: this.getEmailTemplateLookup() },
                    { title: 'Repetir', field: 'repeat', lookup: { daily: 'Todo Dia', monthly: 'Todo Mês', weekly: 'Toda Semana' }, render: rowData => this.repeatHandler(rowData.repeat) },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let update = {
                            name: newData.name,
                            email_account_id: newData.email_account_id,
                            email_template_id: newData.email_template_id,
                            repeat: newData.repeat,
                        };

                        await Firestore.update(update, 'email_automatization', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        await Firestore.delete('email_automatization', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Nova Automação de E-mail'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.clientsModal()} title={'Enviar Para'} onClose={() => { this.setState({ clientsModal: false }) }} open={this.state.clientsModal}/>
            </div>
        );
    }
}
