import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { Card, Button } from '@material-ui/core';
import DefaultInput from '../components/DefaultInput';
import Colors from '../constants/Colors';
import Auth from '../api/firebase/Auth';
import { withRouter } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import DefaultLoader from '../components/DefaultLoader';

class LoginPage extends Component {

    state = {
        email: '',
        password: '',
        emailError: false,
        passwordError: false,
        emailErrorMessage: '',
        passwordErrorMessage: '',
        error: false,
        loading: false,
        type: this.checkCustomerArea(),
    }

    checkCustomerArea() {

        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('type');
    }

    async login() {
        this.setState({ emailError: false, passwordError: false, emailErrorMessage: '', passwordErrorMessage: '', error: false, loading: true });

        if (this.state.email && this.state.password) {

            Auth.login(this.state.email, this.state.password).then((result) => {
                
                if (result.user) {

                    this.props.history.push('/');

                } else {

                    this.setState({ passwordError: true, emailError: true, passwordErrorMessage: 'E-mail ou senha incorretos', loading: false });
                }
            });

        } else {
            
            if (!this.state.email) {
                this.setState({ emailError: true, emailErrorMessage: 'Informe o e-mail', loading: false, });
            }

            if (!this.state.password) {
                this.setState({ passwordError: true, passwordErrorMessage: 'Informe a senha', loading: false });
            }
        }
    }

    renderLoading() {
        return (
            <DefaultLoader/>
        );
    }

    renderFooter() {
        return <p style={{position: 'absolute', bottom: 0, fontSize: 12, color: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>{'Copyright © ' +new Date().getFullYear()+ ' Todos os direitos reservados | Desenvolvido com '} <img style={{width: 20, marginLeft: 5, marginRight: 5}} src={process.env.PUBLIC_URL + '/assets/image/heart.png'}/> {'por '}<a style={{marginLeft: 5}} target={'_blank'} href={'https://easydata.net.br'}>{'EasyData Softwares'}</a></p>
    }

    renderRegister() {
        return this.state.type === 'customer' ? <a href={'/register'} className={'register-link'}>{'Não possui uma conta? Cadastre-se.'}</a> : null;
    }

    render() {
        return (
            <Container style={{backgroundColor: Colors.semil.green, display: 'flex', justifyContent: 'center'}} maxWidth={false}>
                <div style={{height: '100vh'}}>
                    <Card style={{ padding: 30, marginTop: '20vh', filter: this.state.loading ? 'blur(5px)' : '', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img style={{paddingBottom: 50, paddingTop: 15, height: 65}} src={process.env.PUBLIC_URL + '/assets/image/logo.png'} /> 
                        <form>
                            <DefaultInput error={this.state.emailError} helperText={this.state.emailErrorMessage} onChange={(v) => { this.setState({ email: v }) }} required={true} id={'email'} label={'E-mail'}/>
                            <DefaultInput error={this.state.passwordError} helperText={this.state.passwordErrorMessage} onChange={(v) => { this.setState({ password: v }) }} required={true} id={'password'} type={'password'} label={'Senha'}/>
                            <Button onClick={() => { this.login() }} style={{ width: '100%', fontWeight: 'bold', marginTop: 30, padding: 15, backgroundColor: Colors.semil.green, color: 'white' }} variant="contained" >ENTRAR</Button>
                        </form>
                        
                        {this.renderRegister()}

                    </Card>
                    {this.state.loading ? this.renderLoading() : null}
                </div>
                {this.renderFooter()}
            </Container>
        );
    }
}

export default withRouter(LoginPage);
 