import Firestore from '../api/firebase/Firestore';
import moment from 'moment';

export default class SatisfactionResearchHelper {

    static dataToSurvery(satisfactonResearchData) {

    }

    static getLink(id_satisfaction_research, id_sat, name) {

        let survery = JSON.stringify({
            id: id_satisfaction_research,
            id_sat: id_sat,
            name: name,
            data: [],
        });

        let params = `survery=${btoa(survery)}`;

        return `${window.location.protocol}//${window.location.host}/?${params}`;
    }
}