import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import Storage from '../api/firebase/Storage';
import DefaultInput from '../components/DefaultInput';
import DefaultColorPicker from '../components/DefaultColorPicker';
import Functions from '../constants/Functions';
import { VpnKey } from '@material-ui/icons';
import Auth from '../api/firebase/Auth';

export default class TechnicianPage extends Component {

    state = {
        docs: [],
        loading: true,
        loadingModal: false,
        loginModal: false,
        loginTechnicianData: null,
        photoModal: false,
        photoModalURI: '',
        photoBlob: null,
        editId: null,
        email: '',
        password: '',
        passwordConfirmation: '',
        technicianPasswordResetModal: false,
        passwordResetType: 'password',
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('technician').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = { name: doc.data().name, photo: doc.data().photo, active: doc.data().active, cpf: doc.data().cpf, user_id: doc.data().user_id, id: doc.id, color: doc.data().color,};

            if(!data.color){
                data.color = Colors.technician.defaultColor;
            }

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
    }

    async uploadPhoto() {

        if (this.state.editId && this.state.photoBlob) {

            try {

                this.setState({ loadingModal: true });

                let ref = await Storage.uploadFile('technician_images', this.state.photoBlob);
                let downloadPath = await Storage.getFileFromRef(ref);
        
                if (!downloadPath) {
                    throw new Error('upload error');
                }

                await Firestore.update({ photo: downloadPath }, 'technician', this.state.editId);
    
                toast.success("Imagem atualizada com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ photoModal: false, loadingModal: false });

                this.getDocs();
    
            } catch (error) {
                toast.error("Houve um problema ao salvar a imagem", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        }
    }

    technicianLogin(data) {

        if (data.user_id) {

            alert('Técnico já possui login no app');

        } else {

            this.setState({ loginModal: true, loginTechnicianData: data });
        }
    }

    photoModal() {

        return (
            <div style={{}}>
                
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    { this.state.photoModalURI ?  <img style={{ paddingTop: 15, paddingBottom: 15, height: 350 }} src={this.state.photoModalURI}/> : <p>{'Técnico sem foto.'}</p> }
                </div>

                <input
                accept="image/*"
                style={{display: 'none'}}
                id="contained-button-file"
                type="file"
                onChange={(v) => { this.setState({ photoBlob: v.target.files[0], photoModalURI: URL.createObjectURL(v.target.files[0]) }) }}
                />
                <label htmlFor="contained-button-file">
                    <Button startIcon={<CloudUploadIcon/>} component="span" style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'UPLOAD'}</Button>
                </label>

                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.uploadPhoto() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ photoModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    async activateLogin()  {

        if (this.state.password && this.state.passwordConfirmation && this.state.email) {

            if (this.state.password === this.state.passwordConfirmation) {

                if (this.state.password.toString().length >= 6) {

                    this.setState({ loadingModal: true });

                    let body = {
                        email: this.state.email,
                        name: this.state.loginTechnicianData.name,
                        password: this.state.password,
                        id: this.state.loginTechnicianData.id,
                    };

                    try {

                        let request = await fetch(`${Functions.getEndPoint()}/activateTechnician`, {headers: {"Content-Type": "application/json"}, method: 'POST', body: JSON.stringify(body) });
                        let response = await request.json();

                        console.log(response);

                    } catch (error) {

                        console.log(error);
                    }

                    toast.success('Login ativado');

                    this.setState({ loginModal: false, loginTechnicianData: null, email: '', password: '', passwordConfirmation: '', loadingModal: false });


                } else {

                    toast.warn('A senha deve conter ao menos 6 caracteres');
                }

            } else {

                toast.warn('As senhas são diferentes');
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    async changePassword()  {

        if (this.state.password && this.state.passwordConfirmation) {

            if (this.state.password === this.state.passwordConfirmation) {

                if (this.state.password.toString().length >= 6) {

                    this.setState({ loadingModal: true });

                    let body = {
                        password: this.state.password,
                        id: this.state.editId,
                    };

                    try {

                        await fetch(`${Functions.getEndPoint()}/setUserPassword`, {headers: {"Content-Type": "application/json"}, method: 'POST', body: JSON.stringify(body) });

                    } catch (error) {

                        toast.error('Houve um problema ao resetar a senha')
                    }

                    toast.success('Senha resetada com sucesso');
                    this.setState({ loginModal: false, technicianPasswordResetModal: false, password: '', passwordConfirmation: '', loadingModal: false });


                } else {

                    toast.warn('A senha deve conter ao menos 6 caracteres');
                }

            } else {

                toast.warn('As senhas são diferentes');
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    async resetPasswordEmail() {

        try {

            if (!this.state.loginTechnicianData.email) {
                
                throw new Error('Missing user e-mail');
            }

            await Auth.resetPassword(this.state.loginTechnicianData.email);

            this.setState({ loadingModal: true });
            this.setState({ loadingModal: false, technicianPasswordResetModal: false, editId: null, loginTechnicianData: null });

            toast.success('E-mail de redefinição enviado');

        } catch (error) {

            toast.error('Houve um problema ao enviar o e-mail');
        }
    }

    loginModal() {
        return (
            <div>
                <DefaultInput onBlur={(v) => { this.setState({ email: v }) }} label={'E-mail'}/>
                <DefaultInput type={'password'} onBlur={(v) => { this.setState({ password: v }) }} label={'Senha'}/>
                <DefaultInput type={'password'} onBlur={(v) => { this.setState({ passwordConfirmation: v }) }} label={'Confirme a senha'}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.activateLogin() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    technicianPasswordResetModal() {
        if (this.state.technicianPasswordResetModal) {
            return (
                <div>
                    <RadioGroup style={{ display: 'flex', flexDirection: 'row', gap: 10 }} aria-label="reset_type" name="reset_type" value={this.state.passwordResetType} onChange={(v) => { this.setState({ passwordResetType: v.target.value }) }}>
                        <FormControlLabel value="password" control={<Radio style={{color: Colors.semil.green}}/>} label="Escolher senha" />
                        <FormControlLabel value="email" control={<Radio style={{color: Colors.semil.green}}/>} label="Enviar e-mail de redefinição" />
                    </RadioGroup>
                    {
                        this.state.passwordResetType === 'password' ?
                        
                            <div>
                                <DefaultInput type={'password'} onBlur={(v) => { this.setState({ password: v }) }} label={'Senha'}/>
                                <DefaultInput type={'password'} onBlur={(v) => { this.setState({ passwordConfirmation: v }) }} label={'Confirme a senha'}/>
                            </div>

                        :

                            <div style={{ padding: 10, marginTop: 10, backgroundColor: 'white', boxShadow: Colors.boxShadow, borderRadius: 6, border: '0.5px solid lightgrey', fontWeight: 'bold' }}>
                                {`Email cadastrado: ${this.state.loginTechnicianData.email}`}
                            </div>
                    }
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.state.passwordResetType === 'password' ? this.changePassword() : this.resetPasswordEmail() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.setState({ technicianPasswordResetModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    async syncTechnicians() {
        try {

            this.setState({ loading: true });

            let headers = new Headers();
            headers.set('content-type', 'application/json');

            let request = await fetch(`${Functions.getEndPoint()}/syncTechnicians`, { method: 'GET', headers: headers });
            let json = await request.json();

            await this.getDocs();

            toast.success(`Ténicos Sincronizados com sucesso!\n Inseridos: ${json.inserted}, Atualizados: ${json.updated}`);
            
            
            this.setState({ loading: false });

        } catch (error) {

            this.setState({ loading: false });
            toast.error('Erro ao importar técnicos');
        }
    }

    async handleTechnicianPasswordReset(id) {

        try {

            if (!id) {
                toast.warn('Esse técnico ainda não posui login');
                return;
            }

            this.setState({ loadingModal: true, editId: id, technicianPasswordResetModal: true, passwordResetType: 'password' });

            let request = await fetch(`${Functions.getEndPoint()}/userHasAuth`, {headers: {"Content-Type": "application/json"}, method: 'POST', body: JSON.stringify({ id }) });
            let response = await request.json();

            if (!response.response) {

                toast.warn('Esse técnico ainda não posui login');
                this.setState({ editId: null, technicianPasswordResetModal: false });
            }

            this.setState({ loadingModal: false, loginTechnicianData: { email: response.response } });

        } catch (error) {

            this.setState({ loadingModal: false, editId: null, technicianPasswordResetModal: false });
            toast.error('Houve um problema ao buscar os dados do técnico');
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultButton onClick={() => { this.syncTechnicians() }} title={'Sincronizar'}/>

                <DefaultTable
                title={'Técnicos'}
                actions={[
                    {
                      icon: PhoneIphoneIcon,
                      tooltip: 'Ativar login do app',
                      onClick: (event, rowData) => { this.technicianLogin(rowData) }
                    },
                    {
                      icon: VpnKey,
                      tooltip: 'Redefinir senha',
                      onClick: (event, rowData) => { this.handleTechnicianPasswordReset(rowData.user_id) }
                    },
                ]}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'User Id', field: 'user_id', hidden: true },
                    { title: 'Nome', field: 'name', editable: false, },
                    { title: 'Foto', field: 'photo', editable: false, render: rowData => <PhotoCameraIcon onClick={() => { this.setState({ editId: rowData.id, photoModalURI: rowData.photo, photoModal: true }) }} style={{cursor: 'pointer', color: Colors.semil.green}}/> },
                    { title: 'Cpf', field: 'cpf', editable: false, render: rowData => { if (rowData.cpf.toString().length === 10) { rowData.cpf = '0'+rowData.cpf.toString(); } rowData.cpf.toString().replace(/\D/g , ""); return rowData.cpf.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"); } },
                    { title: 'Ativo', field: 'active', editable: false, render: rowData => rowData.active === 'S' ? <CheckIcon style={{color: Colors.semil.green}}/> : <ClearIcon style={{color: Colors.semil.red}}/>, },
                    { title: 'Cor', field: 'color', render: rowData => <div style={{backgroundColor: rowData.color, width: '36px', height: '14px', borderRadius: '2px', border: '1px solid rgba(0, 0, 0, .25)'}} ></div>, editComponent: rowData => <DefaultColorPicker value={rowData.value} onChange={(e) => { rowData.onChange(e) }} /> },
                ]}
                
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)] = newData;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let update = {
                            color: newData.color
                        };

                        await Firestore.update(update, 'technician', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}

                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        await Firestore.delete('technician', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.photoModal()} title={'Foto'} onClose={() => { this.setState({ photoModal: false }) }} open={this.state.photoModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.loginModal()} title={'Ativar login'} onClose={() => { this.setState({ loginModal: false }) }} open={this.state.loginModal}/>
                <DefaultModal loading={this.state.loadingModal} content={this.technicianPasswordResetModal()} title={'Resetar senha'} onClose={() => { this.setState({ technicianPasswordResetModal: false, password: '', passwordConfirmation: '' }) }} open={this.state.technicianPasswordResetModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
