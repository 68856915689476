import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';
import CloseIcon from '@material-ui/icons/Close';
import TodayIcon from '@material-ui/icons/Today';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import LabelIcon from '@material-ui/icons/Label';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import DefaultLoader from './DefaultLoader';
import DefaultButton from './DefaultButton';
import RelationField from './RelationField';
import { Delete, InfoRounded } from '@material-ui/icons';
import { Button, InputLabel, Select, MenuItem, FormLabel } from '@material-ui/core';
import DefaultModal from './DefaultModal';
import { toast } from 'react-toastify';
import CompanySelect from './CompanySelect';
import DefaultInput from './DefaultInput';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SATHelper from '../helper/SATHelper';
import SessionHelper from '../helper/SessionHelper';

export default class CalendarEventPanel extends Component {

    state = {
        loading: true,
        loadingModal: false,
        technicians: [],
        hourPricingDocs: [],
        deleteModal: false,
        satModal: false,
        selected_company: '',
        satPriority: '',
        hour_pricing: '',
        estimated_end_date: null,
        estimated_hours: '',
        requester: '',
        requester_email: '',
        requester_phone: '',
    }

    async componentDidMount() {

        if (this.props.event && this.props.event.id_technicians) {

            await this.getTechnicians();
        }

        await this.getHourPricing();

        this.setState({ loading: false });
    }

    async getHourPricing() {

        let query = await Firestore.customQuery('hour_pricing').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ hourPricingDocs: docs });
    }

    async getTechnicians() {

        let docs = [];

        for (let index = 0; index < this.props.event.id_technicians.length; index++) {
            
            const id = this.props.event.id_technicians[index];
            let query = await Firestore.getDoc('technician', id);

            if (query.exists) {

                docs.push({ ...query.data(), id: query.id });
            }
        }

        this.setState({ technicians: docs });
    }

    async delete() {

        const id = this.props.event.id;

        if (id) {

            try {

                this.setState({ loadingModal: true });

                await Firestore.delete('pre_appointment', id);

                this.setState({ loadingModal: false });
                toast.success('Evento removido com sucesso');

                this.props.onRequestClose();

            } catch (error) {

                this.setState({ loadingModal: false });
                toast.error('Houve um problema ao remover o evento');
            }
        }
    }

    async addSat() {
        
        if (this.state.selected_company && this.state.hour_pricing && this.state.estimated_end_date && this.state.satPriority) {

            this.setState({ loadingModal: true });

            try {

                let doc = await Firestore.getId('appointment');
                let technicians = [];

                for (let index = 0; index < this.state.technicians.length; index++) {
                    
                    let technician = this.state.technicians[index];
                    technicians.push({ id_technician: technician.id, start: this.props.event.start, end: this.props.event.end, main: false });

                    await SATHelper.historyIteration(doc, SessionHelper.getFirebaseAuth().uid, `Vinculou o técnico ${technician.name}`);
                }

                let data = {
                    answered_satisfaction_research: false,
                    attachments: [],
                    company_id: this.state.selected_company,
                    end_date: null,
                    estimated_end_date: this.state.estimated_end_date,
                    history: [],
                    hour_pricing_id: this.state.hour_pricing,
                    order_id: null,
                    priority: this.state.satPriority,
                    satisfaction_research_id: '',
                    start_date: new Date(),
                    status: 'open',
                    technicians,
                    order_items: [],
                    title: `Assistência #${moment().format('DDMMYYYYHHmmss')}`,
                    user_id: SessionHelper.getFirebaseAuth().uid,
                    requester: this.state.requester,
                    requester_email: this.state.requester_email,
                    requester_phone: this.state.requester_phone,
                    estimated_hours: this.state.estimated_hours,
                };

                await Firestore.insert(data, 'appointment', doc);
                await SATHelper.historyIteration(doc, data.user_id, 'Usuário criou a SAT');

                await Firestore.delete('pre_appointment', this.props.event.id);

                this.setState({ loadingModal: false, addSatModal: false });
                toast.success('SAT cadastrado com sucesso');

                this.props.onRequestClose();

            } catch (error) {

                this.setState({ loadingModal: false });
                toast.error('Erro ao cadastrar SAT');
            }

        } else {
            toast.warn('Preencha todos os campos');
        }
    }

    renderTechnicians() {

        return (

            <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll' }}>
                {
                    this.state.technicians.map((technician, key) => {

                        return (

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 40 }}>
                                <img style={{ width: 130, height: 130, borderRadius: 130 / 2, border: `5px solid ${Colors.semil.green}` }} src={technician.photo ? technician.photo : process.env.PUBLIC_URL + '/assets/image/empty-avatar.png'}/>
                                <div style={{ marginTop: 15, maxWidth: 100, textAlign: 'center', fontWeight: 'bold' }}>{technician.name}</div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    renderData() {

        if (this.props.event) {

            return (

                <div>
                    <h2>{this.props.event.type === 'sat' ? 'SAT' : 'PRÉ SAT'}</h2>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                            <TodayIcon style={{ marginRight: 10, color: Colors.semil.green }}/>
                            <div>{`De ${moment(this.props.event.start).format('DD/MM/YYYY HH:mm')} até ${moment(this.props.event.end).format('DD/MM/YYYY HH:mm')}`}</div>
                        </div>
                        { this.props.event.company_name ?
                        
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                                <BusinessIcon style={{ marginRight: 10, color: Colors.semil.green }}/>
                                <div>{this.props.event.company_name}</div>
                            </div>
                        : null }
                        { this.props.event.city ?
                        
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                                <LocationOnIcon style={{ marginRight: 10, color: Colors.semil.green }}/>
                                <div>{this.props.event.city}</div>
                            </div>
                        : null }
                        { this.props.event.appointment_number ?
                        
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                                <LabelIcon style={{ marginRight: 10, color: Colors.semil.green }}/>
                                <div>{this.props.event.appointment_number}</div>
                            </div>
                        : null }
                        { this.props.event.obs ?
                        
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                                <InfoRounded style={{ marginRight: 10, color: Colors.semil.green }}/>
                                <div>{this.props.event.obs}</div>
                            </div>
                        : null }
                        { this.props.event.id_user ?
                        
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                                <PersonIcon style={{ marginRight: 10, color: Colors.semil.green }}/>
                                <div style={{ marginRight: 5 }}>Aberto por </div><RelationField render={(doc) => doc ? doc.name : ''} id={this.props.event.id_user } collection={'user'} field={'name'}/>
                            </div>
                        : null }
                    </div>
                    <h2 style={{ marginTop: 50 }}>Técnicos</h2>
                    { this.renderTechnicians() }
                    <div style={{ marginTop: 50 }}/>
                    { this.props.event.type === 'pre_sat' ? <DefaultButton width={'100%'} leftIcon={<AddIcon />} onClick={() => { this.setState({ satModal: true })} } title={'Adicionar SAT'}/> : null }
                    <div style={{ marginTop: 8 }}/>
                    <DefaultButton color={'#dc3545'} disabled={this.props.event.type === 'sat'} width={'100%'} leftIcon={<Delete />} onClick={() => { this.setState({ deleteModal: true })} } title={'Deletar'}/>
                </div>
            )
        }
    }

    deleteModal() {

        return (
            <div>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.delete() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ deleteModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    satModal() {

        return (
            <div>
                <CompanySelect onLoadStart={() => { this.setState({ loadingModal: true }) }} onLoadEnd={() => { this.setState({ loadingModal: false }) }} hideLoading={true} onChange={(v) => { this.setState({ selected_company: v.target.value }) }} value={this.state.selected_company} label={'Selecionar empresa'}/>
                    
                <div style={{marginTop: 30}}>
                    <InputLabel id="demo-simple-select-label">Prioridade</InputLabel>
                    <Select
                    style={{width: '100%'}}
                    labelId="demo-simple-select-label"
                    value={this.state.satPriority}
                    onChange={(v) => { this.setState({ satPriority: v.target.value }) }}>
                            <MenuItem style={{color: 'green'}} value={'low'}>{'Baixa'}</MenuItem>
                            <MenuItem style={{color: 'orange'}} value={'normal'}>{'Normal'}</MenuItem>
                            <MenuItem style={{color: 'red'}} value={'high'}>{'Alta'}</MenuItem>
                    </Select>
                </div>

                <div style={{marginTop: 30}}>
                    <InputLabel id="hour-type">Tipo de Hora</InputLabel>
                    <Select
                    style={{width: '100%'}}
                    labelId="hour-type"
                    value={this.state.hour_pricing}
                    onChange={(v) => { this.setState({ hour_pricing: v.target.value }) }}>
                            { this.state.hourPricingDocs.map((doc, key) => {
                                return <MenuItem key={key} value={doc.id}>{doc.type}</MenuItem>
                            }) }
                    </Select>
                </div>

                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    style={{width: '100%', marginTop: 15, marginBottom: 15}}
                    invalidDateMessage={false}
                    format={'dd/MM/yyyy'}
                    autoOk={true}
                    label="Data Prevista"
                    cancelLabel={'Cancelar'}
                    okLabel={'Confirmar'}
                    onChange={(v) => { this.setState({ estimated_end_date: v }) }}
                    value={this.state.estimated_end_date}
                    />
                </MuiPickersUtilsProvider>

                <DefaultInput type={'number'} onBlur={(v) => { this.setState({ estimated_hours: v }) }} label={'Horas Estimadas pelo Comercial'}/>

                <FormLabel style={{marginTop: 10, marginBottom: 10}} component="legend">Solicitante</FormLabel>
                <DefaultInput onBlur={(v) => { this.setState({ requester: v }) }} label={'Nome'}/>
                <DefaultInput onBlur={(v) => { this.setState({ requester_email: v }) }} label={'E-mail'}/>
                <DefaultInput onBlur={(v) => { this.setState({ requester_phone: v }) }} label={'Telefone'}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addSat() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ satModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    render() {

        return (

            <SlidingPane
                className={'right-panel'}
                isOpen={this.props.show}
                title={''}
                closeIcon={<CloseIcon style={{ width: 30, marginTop: 5 }} />}
                width={window.screen.width < 1080 ? "80%" : "40%"}
                onRequestClose={() => this.props.onRequestClose()}>                
                    { !this.state.loading ? this.renderData() : <DefaultLoader/> }
                    <DefaultModal loading={this.state.loadingModal} content={this.deleteModal()} title={'Remover esse evento?'} onClose={() => { this.setState({ deleteModal: false }) }} open={this.state.deleteModal}/>
                    <DefaultModal loading={this.state.loadingModal} content={this.satModal()} title={'Nova SAT'} onClose={() => { this.setState({ satModal: false }) }} open={this.state.satModal}/>
            </SlidingPane>
        )
    }
}