import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, InputLabel, Select, MenuItem } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoIcon from '@material-ui/icons/Photo';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import Storage from '../api/firebase/Storage';
import moment from 'moment';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export default class ImagesPage extends Component {

    state = {
        docs: [],
        name: '',
        productImage: '',
        blobImage: null,
        loading: true,
        loadingModal: false,
        addModal: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('images').get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
    }

    async addProduct() {

        if (this.state.name && this.state.blobImage) {

            this.setState({ loadingModal: true });
            
            let ref = await Storage.uploadFile('website_images', this.state.blobImage);
            let downloadPath = await Storage.getFileFromRef(ref);

            if (!downloadPath) {
                throw new Error('upload error');
            }

            let data = {
                name: this.state.name,
                photo: downloadPath,
                date: new Date(),
            };

            try {

                await Firestore.insert(data, 'images');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, addModal: false, name: '', productImage: '', blobImage: null });
                await this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    addModal() {
        return (
            <div>
                <DefaultInput onBlur={(v) => { this.setState({ name: v }) }} label={'Nome'}/>

                <InputLabel style={{paddingTop: 15}} htmlFor="upload-button">Imagem</InputLabel>
                { this.state.productImage ? <img style={{ paddingTop: 15, paddingBottom: 15, height: 250 }} src={this.state.productImage}/> : <p style={{paddingTop: 30, paddingBottom: 30, textAlign: 'center'}}>{'Nenhuma imagem selecionada.'}</p> }

                <input
                accept="image/*"
                style={{display: 'none'}}
                id="contained-button-file"
                type="file"
                onChange={(v) => { this.setState({ blobImage: v.target.files[0], productImage: URL.createObjectURL(v.target.files[0]) }) }}
                />
                <label htmlFor="contained-button-file">
                    <Button startIcon={<CloudUploadIcon/>} component="span" style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '100%'}} variant={'contained'}>{'UPLOAD'}</Button>
                </label>

                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addProduct() }} style={{fontWeight: 'bold', backgroundColor: Colors.semil.green, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    copyToClipboard(text) {

        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        toast.success("Link copiado para área de transferência", {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultButton onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>

                <DefaultTable
                actions={[
                    {
                        icon: FileCopyIcon,
                        tooltip: 'Copiar Link',
                        onClick: (event, rowData) => { this.copyToClipboard(rowData.photo) }
                    }
                ]}
                title={'Imagens'}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'name' },
                    { title: 'Link', field: 'photo', editable: false },
                    { title: 'Data', editable: false, field: 'date', type: 'datetime', render: rowData => <div>{moment(rowData.date.toDate()).format('DD/MM/YYYY HH:mm')}</div> },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    let prev = this.state.docs;
                    prev[prev.indexOf(oldData)].name = newData.name;

                    this.setState({ docs: prev });

                    if (oldData.id) {
                        
                        let update = {
                            name: newData.name,
                        };

                        await Firestore.update(update, 'images', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        await Firestore.delete('images', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Nova Imagem'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
